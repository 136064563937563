import React, { Fragment } from 'react';
import { useOrderDetailModel } from './OrderDetailModel';
import { RouteComponentProps, Switch, Route, Link } from 'react-router-dom';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ReportType } from 'core/report/ReportData';
import classNames from 'classnames/bind';
import styles from './orderDetail.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { ROUTE_PATH } from 'enum/RoutePath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { L1Objects } from 'containers/L1Objects/L1Objects';
import { AddonFeatureManager, AuthenticationManager } from 'core';
import { L1ObjectList } from 'containers/L1Objects/L1ObjectList/L1ObjectList';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';

export const OrderDetail: React.FC<RouteComponentProps<{}> & {
  orderNumber: string,
  authenticationManager: AuthenticationManager,
  addonFeatureManager: AddonFeatureManager
}> = ({
  orderNumber,
  authenticationManager,
  addonFeatureManager,
  match
}) => {

  const {
    loading,
    order,
    viewData,
    canSettle,
    settleTips,
    showDetail,
    canEditOrder,
    showSettleButton,
    showRejectButton,
    showApproveButton,
    l1ObjectList,
    remainingStoredValueError,
    settleOrder,
    rejectOrder,
    approveOrder,
    getReportLink,
    toggleShowDetail,
    refreshOrderDetail
  } = useOrderDetailModel(orderNumber, authenticationManager, addonFeatureManager);

  const cssClassNames = classNames.bind(styles);

  const renderL1Objects = (props) => {
    if (!order) {
      return <LoadingIndicator/>;
    }
    return (
      <L1Objects {...props} order={order} addonFeatureManager={addonFeatureManager}/>
    );
  };

  const renderOrderData = (orderViewData, type) => {
    return Object.keys(orderViewData).map(key => {
      const title = i18n.t<string>(`orderDetail.labels.${key}`);
      let value = orderViewData[key];
      let hint = '';
      let valueClassName = styles.fieldValue;
      if (orderViewData[key] && orderViewData[key].change) {
        value = orderViewData[key].change;
        hint = orderViewData[key].origin;
        valueClassName = `${styles.fieldValue} ${styles.changeValue}`;
      } else if (orderViewData[key] && orderViewData[key].extraInfo) {
        const lis = orderViewData[key].extraInfo.map(extraInfo => (
          <li key={extraInfo}>{extraInfo}</li>
        ));
        return (
          <div className={styles.field} key={key}>
            <div className={styles.fieldLabel}>
              {title}
            </div>
            <div className={valueClassName}>
              {orderViewData[key].value}
              {orderViewData[key].extraInfo.length > 0 &&
                <OverlayTrigger
                  key={key}
                  placement={'right'}
                  trigger={['hover', 'focus']}
                  overlay={
                    <Tooltip id={key + '-orderExtraInfo'}>
                      <ul>{lis}</ul>
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle}/>
                </OverlayTrigger>
              }
            </div>
          </div>
        );
      }

      if (type === 'performance') {
        valueClassName = `${styles.fieldValue} ${styles.performanceField}`;
      }

      return (
        <div className={styles.field} key={key}>
          <div className={styles.fieldLabel}>
            {title}
          </div>
          <div className={valueClassName}>
            {value}
          </div>
          <div className={styles.fieldHint}>
            {hint}
          </div>
        </div>
      );
    });
  };

  const renderSection = (data, type) => {
    if (!data) {
      return (
        <></>
      );
    }
    return (
      <Fragment>
        <div className={styles.sepLine} />
        <div className={styles[type]}>
          {renderOrderData(data, type)}
        </div>
      </Fragment>
    );
  };

  const renderL1ObjectList = (props) => {
    if (!order) {
      return <div/>;
    }
    return (
      <L1ObjectList
        order={order}
        l1ObjectList={l1ObjectList}
        refreshList={refreshOrderDetail}
        addonFeatureManager={addonFeatureManager}
      />
    );
  };

  const renderContent = (props) => {
    if (_.isEmpty(viewData)) {
      return <LoadingIndicator />;
    }

    const settleClass = cssClassNames('settleBtn', {
      disable: !canSettle,
      cursorNotAllow: !canSettle
    });
    const orderDetailClass = cssClassNames('detail', {
      show: showDetail
    });
    return (
      <div className={styles.orderDetailContainer}>
        {loading && <LoadingIndicator />}
        <TitleWithBreadcrumbs
          title={i18n.t<string>('orderDetail.labels.title')}
          routes={[
            { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
            {
              path: '/orders/:orderNumber',
              breadcrumb: DynamicBreadcrumb,
              props: { label: _.get(order, 'projectName'), matchParam: 'orderNumber' }
            }
          ]}
        />
        <div className={styles.titleBottomLine} />
        <div className={styles.orderDetail}>
          <div className={styles.topArea}>
            <div
              className={styles.orderName}
              onClick={toggleShowDetail}
            >
              <FontAwesomeIcon
                icon={showDetail ? faAngleDown : faAngleUp}
              />
              <div>
                {i18n.t<string>('orderDetail.labels.name', { name: viewData.name })}
              </div>
            </div>
            <div className={styles.buttonArea}>
              {showApproveButton &&
                <PermissionChecker permissionAware={hasFuncs(Permission.ORDER_APPROVE)}>
                  {
                    remainingStoredValueError ?
                      <OverlayTrigger
                        placement={'bottom'}
                        trigger={['hover', 'focus']}
                        overlay={
                          <Tooltip id={'remainingStoredValueError'}>
                            {remainingStoredValueError}
                          </Tooltip>
                        }
                      >
                        <div className={`${styles.approveBtn} ${styles.disabled}`}>
                          {i18n.t<string>('orderDetail.labels.approve')}
                        </div>
                      </OverlayTrigger> :
                      <div className={styles.approveBtn} onClick={approveOrder}>
                        {i18n.t<string>('orderDetail.labels.approve')}
                      </div>
                  }

                </PermissionChecker>
              }
              {showRejectButton &&
                <PermissionChecker permissionAware={hasFuncs(Permission.ORDER_APPROVE)}>
                  <div className={styles.rejectBtn} onClick={rejectOrder}>
                    {i18n.t<string>('orderDetail.labels.reject')}
                  </div>
                </PermissionChecker>
              }
              {canEditOrder &&
                <Link to={`${orderNumber}/edit`}>{i18n.t<string>('orderDetail.labels.editOrder')}</Link>
              }
              {showSettleButton &&
                <PermissionChecker permissionAware={hasFuncs(Permission.ORDER_WRITE)}>
                  <div onClick={settleOrder} className={settleClass}>
                    {i18n.t<string>('orderDetail.buttons.settle')}
                    <span>{settleTips}</span>
                  </div>
                </PermissionChecker>
              }
              <Link
                to={getReportLink(ReportType.PERFORMANCE)}
                target='_blank'
              >
                {i18n.t<string>('orderDetail.labels.report')}
              </Link>
            </div>
          </div>
          <div className={orderDetailClass}>
            {renderSection(viewData.basic, 'basic')}
            {renderSection(viewData.performance, 'performance')}
          </div>
        </div>
        <div className={styles.other}>
          {renderL1ObjectList(props)}
        </div>
      </div>
    );
  };

  if (!viewData) {
    return <LoadingIndicator />;
  }

  return (
    <Switch>
      <RouteWithPermission
        permissionAware={hasFuncs(Permission.CAMPAIGN_READ).or(hasFuncs(Permission.CAMPAIGN_WRITE))}
        redirectPath={`${match.url}/campaign-groups/${ROUTE_PATH.ERROR403}`}
        path={`${match.url}/campaign-groups`}
        render={renderL1Objects}
      />
      <Route
        render={renderContent}
      />
    </Switch>
  );
};
