import { CreativeListDataProvider } from './CreativeListDataProvider';
import { CreativeListCommonColumns, commonColumnSetting, getCommonColumnData } from './CreativeListCommonColumnData';
import { renderColumn } from 'components/TableColumn/TableColumn';
import { getCreativeListFormatters } from './CreativeListFormatters';
import { CreativeListRecord } from 'core/creative/Creative';

export class ImageCreativeListDataProvider implements CreativeListDataProvider {

  formatters: any;
  constructor (model) {
    this.formatters = getCreativeListFormatters(model);
  }

  getColumns () {
    return [
      renderColumn(commonColumnSetting[CreativeListCommonColumns.NAME_ID], this.formatters.nameIdFormatter, this.formatters.nameIdHeaderFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.STATUS], this.formatters.stateFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.PREVIEW], this.formatters.previewFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.APPROVAL], this.formatters.approvalFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.LANDING_URL], this.formatters.urlFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.EDITBTNS], this.formatters.floatingEditBtnsFormatter)
    ];
  }

  getViewModel (creative: CreativeListRecord) {
    return {
      ...getCommonColumnData(creative)
    };
  }
}
