import React from 'react';
import Toggle from 'components/Toggle/Toggle';
import classnames from 'classnames/bind';
import styles from './switchField.module.scss';
import { get } from 'lodash';
import { FormikFieldProps, withFormikField } from 'hoc/withFormikField';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';

interface SwitchProps {
  name: string;
  disabled?: boolean;
  className?: string;
  onChange?: (value: any) => void;
  value: any;
  error?: any;
}

export interface FormikSwitchProps extends FormikFieldProps, Omit<SwitchProps, 'value'> {}
export interface FormikSwitchWithFieldLayoutProps extends FormikFieldProps, Omit<FieldLayoutProps, 'name'>, Omit<SwitchProps, 'value'> {}
export type FormikSwitchFieldProps = ({ withFieldLayout?: true } & FormikSwitchWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikSwitchProps);

const cx = classnames.bind(styles);

const SwitchContent: React.FC<SwitchProps> = ({
  name,
  value,
  error,
  disabled,
  className,
  onChange
}: SwitchProps) => {

  const hasError = error !== undefined;
  const toggleClassName = cx('switchComponent', className, {
    tips: true,
    error: hasError
  });

  return (
    <Toggle
      className={toggleClassName}
      checked={!!value}
      name={name}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
const MemorizedSwitchContent = React.memo(SwitchContent);

export const SwitchField = withFieldLayout<SwitchProps>(SwitchContent);

const FormikSwitch = withFormikField(
  MemorizedSwitchContent,
  (name, srcValue, setFieldValue) => {
    const targetValue = get(srcValue, 'target.checked');
    setFieldValue(name, targetValue);
    return targetValue;
  }
);

const FormikSwitchWithFieldLayout = withFormikField<FormikSwitchWithFieldLayoutProps>(
  withFieldLayout(SwitchContent),
  (name, srcValue, setFieldValue) => {
    const targetValue = get(srcValue, 'target.checked');
    setFieldValue(name, targetValue);
    return targetValue;
  }
);
FormikSwitchWithFieldLayout.defaultProps = {
  fieldContentWidth: 'auto'
};

export const FormikSwitchField: React.FC<FormikSwitchFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikSwitch {...props} />;
  } else {
    return <FormikSwitchWithFieldLayout {...props} />;
  }
};
