import { AxiosInstance } from 'axios';
import { AccessToken, Account, Actor } from 'core';
import { OAuthPCHState } from 'core/oauth/OAuthPCH';
import client from './RestClient';
import _ from 'lodash';

export interface OAuthWebService {
  getState (): Promise<OAuthPCHState>;
  oauthPCH (code: string, state: string, hmacState: string): Promise<Account>;
}

function wrapAccessToken (json: any): AccessToken {
  return {
    token: _.get(json, 'token', ''),
    expires: _.get(json, 'expires', 0)
  };
}

function wrapActor (json: any): Actor {
  return {
    id: _.get(json, 'actorId'),
    role: _.get(json, 'role'),
    roleName: _.get(json, 'roleName'),
    actorType: _.get(json, 'companyActorType'),
    companyName: _.get(json, 'comName'),
    displayOrder: _.get(json, 'listingOrdered', 0),
    accessToken: wrapAccessToken(_.get(json, 'accessToken')),
    permissions: _.get(json, 'permissions'),
    agencyId: _.get(json, 'agencyId', null),
    advertiserId: _.get(json, 'advertiserId', null)
  };
}

function wrapAccount (json: any): Account {
  const actorList: Array<any> = _.get(json, 'actorList', []);
  return {
    id: _.get(json, 'accountId', _.get(json, 'account_id')),
    name: _.get(json, 'name'),
    email: _.get(json, 'email'),
    agencyId: _.get(json, 'agencyId', 0),
    companyName: _.get(json, 'companyName', ''),
    language: _.get(json, 'language', 'zh_TW'),
    isAdmin: _.get(json, 'isAdmin', _.get(json, 'admin', false)),
    activated: _.get(json, 'activated', true),
    status: _.get(json, 'status'),
    actors: actorList.map(json => wrapActor(json)).sort((a, b) => a.displayOrder - b.displayOrder)
  };
}

export class RestfulOAuthWebService implements OAuthWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getState (): Promise<OAuthPCHState> {
    const response = await this.restClient.get('/v2/oauth/pch/state');
    return response.data;
  }

  async oauthPCH (code: string, state: string, hmacState: string): Promise<Account> {
    const response = await this.restClient.post('/v2/oauth/pch', {
      code,
      state,
      hmacState
    });
    return wrapAccount(response.data);
  }
}
