import { Account, AccountState } from 'core';
import { renderColumn, ColumnDefinition, SortDescriptor, sortableColumn, checkedFormatter } from 'components/TableColumn/TableColumn';
import styles from './accountList.module.scss';
import { nameFormatter, statusFormatter } from './accountListFormatters';
import _ from 'lodash';

export interface AccountListModel {

  getColumnDefinition (columnName: string): ColumnDefinition;

  readonly accounts: Array<Account>;
  readonly noDataDescription: string;
  readonly defaultSorts: SortDescriptor;
  readonly columns: any[];
}

export type AccountListProps = {

  readonly model: AccountListModel;
};

abstract class BaseAccountListModel implements AccountListModel {

  abstract accounts: any[];
  abstract get noDataDescription ();
  abstract get defaultSorts ();
  abstract get columns ();

  getColumnDefinition (columnName: string): ColumnDefinition {
    const columnClassGetter = () => {
      return styles[columnName];
    };
    return {
      ...sortableColumn(columnName === 'status' ? 'activated' : columnName, `accounts.headers.${columnName}`),
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    };
  }
}

export class DefaultAccountListModel extends BaseAccountListModel {
  accounts: any[];

  constructor (accounts: Array<Account>, protected isSysAdmin: boolean) {
    super();
    this.accounts = accounts.map(account => ({
      ...account,
      pending: account.status === AccountState.PENDING
    }));
  }

  get noDataDescription (): string {
    return 'accounts.placeholder.noDataAvailable';
  }

  get columns () {
    return _.compact([
      renderColumn(this.getColumnDefinition('name'), nameFormatter),
      renderColumn(this.getColumnDefinition('email')),
      renderColumn(this.getColumnDefinition('status'), statusFormatter),
      this.isSysAdmin ? renderColumn(this.getColumnDefinition('isAdmin'), checkedFormatter) : undefined,
      renderColumn(this.getColumnDefinition('pending'), checkedFormatter)
    ]);
  }

  get defaultSorts (): SortDescriptor {
    return [
      this.isSysAdmin ? {
        dataField: 'isAdmin',
        order: 'desc'
      } : {
        dataField: 'id',
        order: 'desc'
      }
    ];
  }
}
