import {
  OrderWebService,
  RestfulOrderWebService,
  OrdersWithPagination
} from 'ws/OrderWebService';

import { Order, State } from 'core/order/Order';
import { Pageable } from 'ws/Pageable';
import { AWSS3WebService, RestfulAWSS3WebService } from 'ws/AWSS3WebService';
import { Actor } from 'core/auth/Account';
import { Permission } from 'core/auth/Permission';

export interface OrderManager {
  getOrder (orderNumber: string): Promise<Order>;
  getOrderById (orderId: string | number): Promise<Order>;
  getOrders (
    advertiserId: number | null | undefined,
    pageable: Pageable,
    search?: string,
    deliveries?: string[]): Promise<OrdersWithPagination>;
  approveOrder (orderId: number): Promise<void>;
  rejectOrder (orderId: number): Promise<void>;
  createOrder (order: Order): Promise<Order>;
  updateOrder (order: any): Promise<Order>;
  settleOrder (orderId: number): Promise<void>;
  getOrderName (orderId: number): Promise<string | null>;
  canOrderBeSettled (order: Order): boolean;
  canOrderBeApproved (order: Order): boolean;
  canOrderBeReject (order: Order): boolean;
  canOrderBeEdit (actor: Actor | null, order: Order): boolean;
}

export class DefaultOrderManager implements OrderManager {
  webService: OrderWebService;
  cloudStorageWebService: AWSS3WebService;

  constructor (
    webService: OrderWebService = new RestfulOrderWebService(),
    cloudStorageWebService: AWSS3WebService = new RestfulAWSS3WebService()
  ) {
    this.webService = webService;
    this.cloudStorageWebService = cloudStorageWebService;
  }

  async getOrder (orderNumber: string): Promise<Order> {
    const order = await this.webService.getOrder(orderNumber);
    return order;
  }

  async getOrderById (orderId: string | number): Promise<Order> {
    return this.webService.getOrderById(orderId);
  }

  async getOrders (
    advertiserId: number | null | undefined,
    pageable: Pageable,
    search?: string,
    deliveries?: string[]): Promise<OrdersWithPagination> {
    const ordersWithPagination = await this.webService.getOrders(advertiserId, pageable, search, deliveries);
    return ordersWithPagination;
  }

  async approveOrder (orderId: number) {
    await this.webService.approveOrder(orderId);
  }

  async rejectOrder (orderId: number) {
    await this.webService.rejectOrder(orderId);
  }

  async createOrder (order: Order) {
    const newOrder = await this.webService.createOrder(order);
    return newOrder;
  }

  async updateOrder (order: any) {
    const updatedOrder = await this.webService.updateOrder(order);
    return updatedOrder;
  }

  async settleOrder (orderId: number) {
    await this.webService.settleOrder(orderId);
  }

  async getOrderName (orderId: number): Promise<string | null> {
    return this.webService.getOrderName(orderId);
  }

  canOrderBeSettled (order: Order): boolean {
    if ([State.SETTLED, State.SETTLE].includes(order.state)) {
      return false;
    }

    return true;
  }

  canOrderBeApproved (order: Order): boolean {
    return order.state === State.NOT_APPROVE || order.state === State.CHANGE_PENDING;
  }

  canOrderBeReject (order: Order): boolean {
    return order.state === State.NOT_APPROVE || order.state === State.CHANGE_PENDING;
  }

  canOrderBeEdit (actor: Actor | null, order: Order): boolean {
    if (!actor) {
      return false;
    }
    return ![State.SETTLE, State.SETTLED].includes(order.state) &&
      actor.permissions.indexOf(Permission.ORDER_WRITE) > -1;
  }
}
