import styles from './creativeList.module.scss';
import _ from 'lodash';
import { CreativeListRecord } from 'core/creative/Creative';
import { CreativeManager, DefaultCreativeManager } from 'core/creative/CreativeManager';

export enum CreativeListCommonColumns {
  NAME_ID = 'bannerId',
  STATUS = 'status',
  PREVIEW = 'preview',
  LANDING_URL = 'bannerUrl',
  APPROVAL = 'approvalStatus',
  EDITBTNS = 'editBtns'
}

export const commonColumnSetting = {
  [CreativeListCommonColumns.NAME_ID]: {
    ...getColumnSetting(CreativeListCommonColumns.NAME_ID),
    sort: true
  },
  [CreativeListCommonColumns.STATUS]:
    getColumnSetting(CreativeListCommonColumns.STATUS),
  [CreativeListCommonColumns.PREVIEW]:
    getColumnSetting(CreativeListCommonColumns.PREVIEW),
  [CreativeListCommonColumns.APPROVAL]:
    getColumnSetting(CreativeListCommonColumns.APPROVAL),
  [CreativeListCommonColumns.LANDING_URL]:
    getColumnSetting(CreativeListCommonColumns.LANDING_URL),
  [CreativeListCommonColumns.EDITBTNS]: {
    ...getColumnSetting(CreativeListCommonColumns.EDITBTNS),
    text: ''
  }
};

export function getCommonColumnData (
  creative: CreativeListRecord,
  creativeManager: CreativeManager = new DefaultCreativeManager()
) {

  return {
    tenmaxCategory: creative.tenmaxCategory,
    origTenmaxCategory: creative.origTenmaxCategory,
    [CreativeListCommonColumns.NAME_ID]: {
      id: creative.creativeId,
      name: creative.name
    },
    [CreativeListCommonColumns.STATUS]: creative.creativeState,
    [CreativeListCommonColumns.LANDING_URL]: _.get(creative, 'bannerUrl'),
    // [CreativeListCommonColumns.BIND_STATE]: _.get(creative, 'bindingCampaign'),
    [CreativeListCommonColumns.PREVIEW]: creative,
    [CreativeListCommonColumns.APPROVAL]: creative
  };
}

export function getColumnSetting (columnName) {
  const columnClassGetter = () => {
    return styles[columnName];
  };

  return {
    sort: false,
    text: `creativeList.headers.${columnName}`,
    dataField: columnName,
    classes: columnClassGetter,
    headerClasses: columnClassGetter
  };
}
