import { BasicFormProps } from './BasicFormProps';
import { AbstractFormContentModel, CreativeFormData } from './FormContentModel';
import _ from 'lodash';
import { Creative } from 'core/creative/Creative';
import { Product } from 'core/product/Product';
import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModelInterface';
import { ImageFormModel } from './ImageFormModel';
import { ProductNativeFormModel } from './ProductNativeFormModel';
import RichProductNativeForm from './RichProductNativeForm';

export type RichProductNativeFormProps = {
  readonly model: RichProductNativeFormModel;
  readonly hintModalData?: any;
  readonly setHintModalData: (modalData: any) => void;
} & BasicFormProps;

export class RichProductNativeFormModel extends AbstractFormContentModel {

  public needBannerUrl: boolean = false;
  public imageFormModel: ImageFormModel;
  public productNativeFormModel: ProductNativeFormModel;
  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;

  constructor (
    public flowPageModel: CreativeSetupFlowPageModel,
    products: Product[] = []
  ) {
    super(flowPageModel);
    this.imageFormModel = new ImageFormModel(flowPageModel, {
      960: [320],
      2440: [250]
    });
    this.productNativeFormModel = new ProductNativeFormModel(flowPageModel, products);
  }

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getInitTypeProperties (): any {
    return this.productNativeFormModel.getInitTypeProperties();
  }

  getFormContent = (): any => {
    return RichProductNativeForm;
  }

  getFormModelData (creative: Creative): CreativeFormData {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const imageUrl = _.get(creativeValue, 'images.0.imageUrl', creative.basic.bannerImageUrl);
    const width = _.get(creativeValue, 'images.0.w', 960);
    const height = _.get(creativeValue, 'images.0.h', 320);
    const medias = _.omitBy({
      image: _.omitBy({
        url: imageUrl,
        width,
        height
      }, _.isUndefined)
    }, _.isEmpty);
    const productNativeFormModelData = this.productNativeFormModel.getFormModelData(creative);
    return {
      basic: {
        ...creativeBasic,
        typeProperties: productNativeFormModelData.basic.typeProperties,
        medias
      }
    };
  }
}
