import React, { Fragment } from 'react';
import { CreativeSetupFlowProps, CreativeSetupFlowState } from './CreativeSetupFlowPageModel';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import styles from './creativeSetupFlowPage.module.scss';
import { LoadingIndicator } from 'components/LoadingIndicator';
import i18n from 'i18next';
import NavigationPrompt from 'components/NavigationPrompt/NavigationPrompt';
import { CreativeSetupFlowDataContext } from './CreativeSetupFlowDataContext';
import { StepPage } from 'containers/StepPage/StepPage';
import { ChooseAdvertiserStep } from './FlowSteps/ChooseAdvertiserStep';
import { DefaultChooseAdvertiserStepModel } from './FlowSteps/ChooseAdvertiserStepModel';
import { CreativeSetUpStep } from './FlowSteps/CreativeSetupStep';
import { CreativeSummaryStep } from './FlowSteps/CreativeSummaryStep';
import { AppFooter } from 'components/AppFooter';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import _ from 'lodash';
import { Modal } from 'components/Modal/Modal';

export class CreativeSetupFlowPage extends React.Component<RouteComponentProps<{}> & CreativeSetupFlowProps, CreativeSetupFlowState> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  onPromptCancel = () => {
    this.props.model.setRedirectData(undefined);
  }

  renderTitle = () => {
    return (
      <div className={styles.topArea}>
        <TitleWithBreadcrumbs
          title={this.props.model.type === 'edit' ?
            i18n.t<string>('creativeSetupFlow.labels.editCreative') :
            i18n.t<string>('creativeSetupFlow.labels.createCreative')}
          routes={[
            { path: '/creatives', breadcrumb: i18n.t<string>('creativeSetupFlow.labels.creatives') },
            { path: '/creatives/new', breadcrumb: i18n.t<string>('creativeSetupFlow.labels.createCreative') },
            {
              path: '/creatives/:creativeId/edit',
              breadcrumb: DynamicBreadcrumb,
              props: {
                prefix: i18n.t<string>('common.labels.edit'),
                label: _.get(this.props.model.state.creative, 'basic.name'),
                matchParam: 'creativeId'
              }
            }
          ]}
        />
      </div>
    );
  }

  renderModal = () => {
    const modalData = this.props.model.state.modalData;
    const render = modalData.render;
    const props = modalData.props;
    return (
      <Modal
        fullScreen={true}
        title={props.title}
        primaryButton={props.primaryButton}
        secondaryButton={props.secondaryButton}
        dismiss={props.dismiss}
        className={props.className}
        animation={false}
      >
        {render ? render() : <div />}
      </Modal>
    );
  }

  renderAdvertiserStep = ({
    goNext,
    registerValidateMethod
  }) => {
    return (
      <>
        {this.renderTitle()}
        <div className={styles.contentContainer}>
          <ChooseAdvertiserStep model={new DefaultChooseAdvertiserStepModel(goNext, registerValidateMethod)} />
        </div>
      </>
    );
  }

  renderMainStep = ({
    subStepIndex,
    goSubStep,
    goNext,
    goLast,
    registerValidateMethod
  }) => {
    const isModalOpen = this.props.model.isModalOpen;
    const creative = this.props.model.state.creative;
    if (!creative) {
      return <div />;
    }
    const hasChooseAdvertiserStep = this.props.model.type === 'create' && this.props.model.canChooseAdvertiser;
    return (
      <Fragment>
        {!isModalOpen ?
          <>
            {this.renderTitle()}
            <div className={styles.contentContainer}>
              <CreativeSetUpStep
                model={this.props.model.getCreativeSetupStepModel(
                  creative.basic.creativeType,
                  this.props.model.type,
                  hasChooseAdvertiserStep ? goLast : undefined,
                  goNext,
                  registerValidateMethod
                )}
              />
            </div>
          </> :
          this.renderModal()
        }
      </Fragment>
    );
  }

  renderSummaryStep = ({
    goLast,
    goStep
  }) => {
    return (
      <>
        {this.renderTitle()}
        <div className={styles.contentContainer}>
          <CreativeSummaryStep model={this.props.model.getCreativeSummaryModel(goLast, goStep)} />
        </div>
      </>
    );
  }

  render () {
    const {
      type,
      state,
      dataContenxt,
      canChooseAdvertiser,
      cancel
    } = this.props.model;
    const redirectData = state.redirectData;
    if (!state.creative) {
      return <LoadingIndicator />;
    }
    const hasChooseAdvertiserStep = type === 'create' && canChooseAdvertiser;
    return (
      <div className={styles.creativeSetupFlowPage}>
        {state.loading && <LoadingIndicator />}
        <CreativeSetupFlowDataContext.Provider value={dataContenxt}>
          <NavigationPrompt when={!state.finished} onCancel={this.onPromptCancel} />
          <StepPage cancel={state.canCancel ? cancel : undefined}>
            {hasChooseAdvertiserStep &&
              <StepPage.Step
                label={i18n.t<string>('stepSideBar.labels.creativeChooseAdvertiser')}
                renderStepContent={this.renderAdvertiserStep}
              />
            }
            <StepPage.Step
              label={i18n.t<string>('stepSideBar.labels.creativeSetUp')}
              renderStepContent={this.renderMainStep}
            />
            <StepPage.Step
              label={i18n.t<string>('stepSideBar.labels.creativeSummary')}
              renderStepContent={this.renderSummaryStep}
            />
            <StepPage.SideBarFooter>
              <AppFooter/>
            </StepPage.SideBarFooter>
          </StepPage>
          {redirectData &&
            <Redirect
              to={redirectData}
            />
          }
        </CreativeSetupFlowDataContext.Provider>
      </div>
    );
  }
}
