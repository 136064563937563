import i18n from 'i18next';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';

export interface ChooseAdvertiserStepModel {
  readonly currentAdvertiserId?: number;
  readonly errorMsg?: string;
  goNext ();
  onSelectAdvertiser ();
  setCurrentAdvertiser (advertiserId?: number);
  event: UpdateEventListener<ChooseAdvertiserStepModel>;
}

export type ChooseAdvertiserStepProps = {
  readonly model: ChooseAdvertiserStepModel;
};

export class DefaultChooseAdvertiserStepModel implements ChooseAdvertiserStepModel {
  currentAdvertiserId?: number;
  errorMsg?: string;
  stepChangeListener?: (stepIndex, subStepName?: string) => void;
  event: FireableUpdateEventListener<ChooseAdvertiserStepModel>;

  constructor (
    public goNext: () => void,
    registerValidateMethod: (validateMethod) => void
  ) {
    registerValidateMethod(this.validate);
    this.event = new FireableUpdateEventListener<ChooseAdvertiserStepModel>();
  }

  validate = () => {
    if (!this.currentAdvertiserId) {
      const errors = [i18n.t<string>('formValidate.labels.emptyError')];
      this.errorMsg = errors.join(',');
      this.event.fireEvent(this);
      return errors;
    }
    this.errorMsg = undefined;
    this.event.fireEvent(this);
    return [];
  }

  setCurrentAdvertiser (advertiserId: number) {
    if (advertiserId) {
      this.errorMsg = undefined;
    }
    this.currentAdvertiserId = advertiserId;
  }

  onSelectAdvertiser = () => {
    this.errorMsg = undefined;
    this.event.fireEvent(this);
  }
}
