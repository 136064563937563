import _ from 'lodash';

import client from './RestClient';
import { Agency, AgencyDetail, AgencyWithPagination } from 'core/agency/Agency';
import { CompanyMember, Account } from 'core';
import { SelectOptions } from 'components/commonType';
import { defaultCampaignBidPrice } from 'containers/Agencies/AgencyForm/defaultCampaignBidPrice';
import { AxiosInstance } from 'axios';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { Pageable } from './Pageable';
import { Pagination } from 'core/pagination/Pagination';

export interface AgencyWebService {
  getAgencies (addonFeature?: string): Promise<Array<Agency>>;
  getAgenciesWithPagination (pageable: Pageable, searchString: string): Promise<AgencyWithPagination>;
  getAgency (agencyId: number): Promise<Agency>;
  updateAgency (request: AgencyDetail): Promise<number>;
  createAgency (request: AgencyDetail): Promise<number>;
  getAgencyMember (agencyId: number): Promise<Array<CompanyMember>>;
  getAgenciesOptions (addonFeature?: string, extraFields?: string[]): Promise<Array<SelectOptions>>;
  getAgencyName (agencyId: number): Promise<string | null>;
}

function wrapAgencyDetail (json: any): AgencyDetail {
  const showcasingTypes = [AdType.DISPLAY.toString(), AdType.KEYWORD.toString()];
  let addonProps = _.get(json, 'property.addonProps', _.get(json, 'addonProps', {}));
  if (!addonProps.campaignBidPrice || addonProps.campaignBidPrice.length === 0 || Array.isArray(addonProps.campaignBidPrice[0].autoBidCap)) {
    addonProps = {
      ...addonProps,
      campaignBidPrice: _.cloneDeep(defaultCampaignBidPrice)
    };
  } else {
    const mergedCampaignBidPrice = defaultCampaignBidPrice.map((item) => {
      const found = addonProps.campaignBidPrice.find((addonItem) => addonItem.type === item.type);
      if (found) {
        return {
          ...item,
          autoBidCap: {
            ...item.autoBidCap,
            ...found.autoBidCap
          },
          bidFloor: {
            ...item.bidFloor,
            ...found.bidFloor
          }
        };
      }
      return _.cloneDeep(item);
    });
    addonProps = {
      ...addonProps,
      campaignBidPrice: mergedCampaignBidPrice.filter((item) => showcasingTypes.includes(item.type))
    };
  }
  return {
    ...json,
    agencyId: _.get(json, 'id', _.get(json, 'agency_id')),
    companyName: _.get(json, 'companyName', _.get(json, 'com_name')),
    vendorNumber: _.get(json, 'vendorNumber', ''),
    comComment: _.get(json, 'comComment', ''),
    addonProps,
    targetBudgetMinimum: _.get(json, 'property.targetBudgetMinimum', 0),
    campaignBudgetMinimum: _.get(json, 'property.campaignBudgetMinimum', 0)
  };
}

function wrapCompanyMember (json: any): CompanyMember {
  return {
    id: _.get(json, 'id'),
    account: wrapAccount(_.get(json, 'account')),
    roleName: _.get(json, 'roleName')
  };
}

function wrapAccount (json: any): Account {
  return {
    language: _.get(json, 'language'),
    activated: _.get(json, 'activated'),
    email: _.get(json, 'email'),
    name: _.get(json, 'name'),
    id: _.get(json, 'accountId'),
    isAdmin: _.get(json, 'isAdmin'),
    status: _.get(json, 'status'),
    actors: []
  };
}

function wrapAgency (json: any): Agency {
  return {
    id: _.get(json, 'id', _.get(json, 'agency_id')),
    name: _.get(json, 'companyName', _.get(json, 'com_name', '')),
    vendorNumber: _.get(json, 'vendorNumber', ''),
    currency: _.get(json, 'currency'),
    timeZone: _.get(json, 'timeZone'),
    loginTitle: _.get(json, 'loginTitle', _.get(json, 'login_title')),
    loginDomain: _.get(json, 'loginDomain', _.get(json, 'login_domain')),
    addOnFeatures: _.get(
      json,
      'addonFeatures',
      _.get(json, 'addon_features', {})
    ),
    detail: wrapAgencyDetail(_.get(json, 'agencyDetail', {})),
    partnershipModeId: _.get(json, 'partnershipModeId')
  };
}

function wrapAgencyOptions (json, extraFields?: string[]) {
  let extra;
  if (extraFields) {
    extra = extraFields.reduce((acc, field) => {
      acc[field] = _.get(json, field);
      return acc;
    }, {});
  }
  return {
    value: _.get(json, 'agency_id'),
    label: _.get(json, 'com_name'),
    extra
  };
}

function wrapPagination (json: any): Pagination {
  return {
    page: _.get(json, 'page', 1),
    size: _.get(json, 'size', 10),
    totalCount: _.get(json, 'totalCount', 0)
  };
}

export class RestfulAgencyWebService implements AgencyWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getAgencies (addonFeature?: string): Promise<Array<Agency>> {
    if (addonFeature) {
      const response = await this.restClient.get(`/agencies?addonFeature=${addonFeature}`);
      return _.defaultTo(response.data, []).map(json => wrapAgencyDetail(json));
    }
    const response = await this.restClient.get('/v2/agencies');
    return _.defaultTo(response.data, []).flatMap((json: any) =>
      wrapAgency(json)
    );
  }

  async getAgenciesWithPagination (pageable: Pageable, searchString: string): Promise<AgencyWithPagination> {
    let { page, sizePerPage, sort, direction } = pageable;
    const response = await this.restClient.get(`/v2/agencies-remote?page=${page}&size=${sizePerPage}&sort=${sort},${direction}&search=${searchString}`);
    return {
      agencies: _.defaultTo(response.data.records, []).flatMap((json: any) => wrapAgency(json)),
      pagination: wrapPagination(response.data.pagination)
    };
  }

  async getAgency (agencyId: number): Promise<Agency> {
    const response = await this.restClient.get(`/v2/agencies/${agencyId}`);
    return wrapAgency(response.data);
  }

  async updateAgency (request: AgencyDetail): Promise<number> {
    const body = await this.prepareBody(request, request.agencyId);
    const response = await this.restClient.put('/v2/agencies', body);
    return _.get(response.data, ['id'], 0);
  }

  async createAgency (request: AgencyDetail): Promise<number> {
    const body = await this.prepareBody(request, null);
    const response = await this.restClient.post('/v2/agencies', body);
    return _.get(response.data, ['id'], 0);
  }

  async prepareBody (request: AgencyDetail, agencyId) {
    const realRequest = _.omit(request, ['agencyId', 'id', 'addonProps', 'currentRate', 'targetBudgetMinimum', 'campaignBudgetMinimum']);
    const finalRequest = {
      ...realRequest,
      id: agencyId,
      property: {
        addonProps: request.addonProps,
        targetBudgetMinimum: request.targetBudgetMinimum,
        campaignBudgetMinimum: request.campaignBudgetMinimum
      }
    };
    return finalRequest;
  }

  async getAgencyMember (agencyId: number): Promise<Array<CompanyMember>> {
    const response = await this.restClient.get(
      `/v2/agencies/${agencyId}/members`
    );
    return _.defaultTo(response.data.records, []).flatMap((json: any) =>
      wrapCompanyMember(json)
    );
  }

  async getAgenciesOptions (addonFeature?: string, extraFields?: string[]): Promise<Array<SelectOptions>> {
    if (addonFeature) {
      const response = await this.restClient.get(`/agencies?addonFeature=${addonFeature}`);
      return _.get(response, 'data', []).map(json => wrapAgencyOptions(json, extraFields));
    }
    const response = await this.restClient.get('/v2/agencies/options');
    return _.get(response, 'data.records', []);
  }

  async getAgencyName (agencyId: number): Promise<string | null> {
    const response = await this.restClient.get(`/v2/agencies/${agencyId}/name`);
    return response.data.result;
  }
}
