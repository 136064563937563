import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { DefaultL1ObjectManager, L1ObjectManager } from 'core/l1Object/L1ObjectManager';

const defaultL1ObjectManager = new DefaultL1ObjectManager();
export const L1ObjectRedirect: React.FC<{
  l1ObjectId: string | number,
  action?: string,
  l1ObjectManager?: L1ObjectManager
}> = ({
  l1ObjectId,
  action,
  l1ObjectManager = defaultL1ObjectManager
}) => {

  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);

  const getOrderNumber = useCallback(async () => {
    try {
      const orderNumber = await l1ObjectManager.getOrderNumber(l1ObjectId);
      const redirectPath = action ?
        `/orders/${orderNumber}/campaign-groups/${l1ObjectId}/${action}` :
        `/orders/${orderNumber}/campaign-groups/${l1ObjectId}`;
      setRedirectPath(redirectPath);
    } catch (e) {
      setRedirectPath('/');
    }
  }, [l1ObjectManager, action, l1ObjectId]);

  useEffect(() => {
    getOrderNumber();
  }, [getOrderNumber]);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return (
    <LoadingIndicator />
  );
};
