export type L1Object = {
  l1ObjectId: number;
  name: string;
  adsOrderId: number;
  state: L1ObjectState;
  budget?: number | string | null;
  channel: L1ObjectChannel;
  budgetBalance: number;
  currencyRate: number;
  objective: L1ObjectObjective;
  rtb?: any;
  report?: any;
  l2ObjectMinStartDate?: string;
  l2ObjectMaxEndDate?: string;
  l2ObjectRunningStatus: {
    [key: string]: number | string | boolean;
  }
};

export enum L2ObjectRunningStatus {
  AHEAD = 'ahead',
  BEHIND = 'behind',
  BUDGET_NOT_ALLOCATED = 'budgetNotAllocated',
  BUDGET_REMAIN = 'budgetRemain',
  END = 'end',
  INACTIVE = 'inactive',
  NO_CREATIVES = 'noCreatives',
  NORMAL = 'normal',
  NOT_START = 'notStart',
  SEVERELY_BEHIND = 'severelyBehind',
  ACTUAL_PROGRESS = 'actualProgress',
  EXPECTED_PROGRESS = 'expectedProgress',
  BUDGET = 'budget',
  SPENT = 'spent'
}

export enum L1ObjectChannel {
  RTB = 'RTB'
}

export enum L1ObjectState {
  ACTIVE = 1,
  DEACTIVATE = 2,
  DELETE = 99
}

export enum L1ObjectObjective {
  AWARENESS = 'AWARENESS',
  TRAFFIC = 'TRAFFIC',
  UNSPECIFIED = 'UNSPECIFIED'
}
