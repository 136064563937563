import React, { useState } from 'react';
import styles from './rtbCampaignOptimize.module.scss';
import i18n from 'i18next';
import { FormikField } from 'components/form/field/FormikField';
import { Dayparts } from 'components/Dayparts/Dayparts';
import _ from 'lodash';
import cx from 'classnames/bind';
import { RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import Toggle from 'components/Toggle/Toggle';
import { LabelField } from 'components/form/field/LabelField';
const classNames = cx.bind(styles);

const renderDayParts = (props) => <Dayparts {...props} />;

const RtbCampaignOptimize = ({
  formType,
  currentL2Object,
  dayPartEnable,
  onDaypartSwitchChanged
}) => {

  const [useFrequency, setUseFrequency] = useState(!_.isEmpty(currentL2Object.frequency));

  const optimizeLabelFormatter = optimization => {
    return i18n.t<string>(`optimizationGoal.${optimization.toLowerCase()}`);
  };

  const renderFrequencyControlInput = (formikProps) => {
    const onMaxFrequencyChange = e => {
      formikProps.setFieldValue('frequency.maxFrequency', e.target.value < 0 ? 0 : e.target.value);
    };
    const onIntervalDaysChange = e => {
      formikProps.setFieldValue('frequency.intervalDays', e.target.value < 0 ? 0 : e.target.value);
    };
    const frequency = formikProps.values[formikProps.name];
    const errorMaxFrequency = _.get(formikProps.errors, 'frequency.maxFrequency');
    const errorintervalDays = _.get(formikProps.errors, 'frequency.intervalDays');
    const maxFrequencyClass = classNames('maxFrequency', {
      error: errorMaxFrequency
    });
    const intervalDaysClass = classNames('intervalDays', {
      error: errorintervalDays
    });
    return (
      <div className={styles.frequencyControl}>
        <div>
          <input
            className={maxFrequencyClass}
            value={frequency.maxFrequency}
            name='frequency.maxFrequency'
            onChange={onMaxFrequencyChange}
            min={1}
            max={20}
            type='number'
          />
          {i18n.t<string>('campaignForm.labels.frequencyCap.event', {
            event: i18n.t<string>('campaignForm.descriptions.impression'),
            plural: +(frequency.maxFrequency) > 1 ? 's' : ''
          })}
          <input
            className={intervalDaysClass}
            name='frequency.intervalDays'
            value={frequency.intervalDays}
            onChange={onIntervalDaysChange}
            min={1}
            max={7}
            type='number'
          />
          {i18n.t<string>('campaignForm.labels.frequencyCap.unit', {
            unit: i18n.t<string>('common.units.day'),
            plural: +(frequency.intervalDays) > 1 ? 's' : ''
          })}
        </div>
        {errorMaxFrequency && <div className='errorTip'>{errorMaxFrequency}</div>}
        {errorintervalDays && <div className='errorTip'>{errorintervalDays}</div>}
      </div>
    );
  };

  const renderFrequencyControlLabel = frequencyControl => {
    if (!frequencyControl) {
      return '';
    }
    return i18n.t<string>('campaignForm.descriptions.frequencyControl', {
      event: i18n.t<string>('campaignForm.descriptions.impression'),
      eventPlural: +(frequencyControl.maxFrequency) > 1 ? 's' : '',
      interval_days: frequencyControl.intervalDays,
      max_frequency: frequencyControl.maxFrequency,
      unit: i18n.t<string>('common.units.day'),
      unitPlural: +(frequencyControl.intervalDays) > 1 ? 's' : ''
    });
  };

  const renderUseFrequency = formikProps => {
    const onUseFrequencyChanged = (e) => {
      const use = e.target.checked;
      setUseFrequency(use);
      if (use) {
        formikProps.setFieldValue('frequency', {
          intervalDays: 1,
          maxFrequency: 5
        });
      } else {
        formikProps.setFieldValue('frequency', undefined);
      }
    };

    return (
      <Toggle
        className={styles.useFrequency}
        checked={useFrequency}
        onChange={onUseFrequencyChanged}
      />
    );
  };

  return (
    <fieldset>
      <legend>
      <span>{i18n.t<string>('campaignForm.titles.optimization')}</span>
      </legend>
      <div className={styles.component}>
        <FormikField.Label
          label={i18n.t<string>('campaignForm.labels.optimizationGoal')}
          name='optimize'
          formatter={optimizeLabelFormatter}
        />
        <LabelField
          name='billingEvent'
          label={i18n.t<string>('campaignForm.labels.billingEvent')}
          className={styles.billingEvent}
          value={
            !currentL2Object.priceModel || !currentL2Object.optimize ?
            i18n.t<string>('rtbCampaign.billingEvent.unspecified') :
            currentL2Object.priceModel === RtbCampaignPlanType.RS ?
              i18n.t<string>(`rtbCampaign.billingEvent.${currentL2Object.priceModel}.${currentL2Object.optimize.toLowerCase()}`) :
              i18n.t<string>(`rtbCampaign.billingEvent.${currentL2Object.priceModel}`)
          }
        />
        {formType === 'create' &&
          <FormikField.Custom
            label={i18n.t<string>('campaignForm.labels.useFrequencyControl')}
            render={renderUseFrequency}
          />
        }
        {useFrequency && !_.isEmpty(currentL2Object.frequency) && (
          formType === 'create' ?
            <FormikField.Custom
              label={i18n.t<string>('campaignForm.labels.frequencyControl')}
              name='frequency'
              render={renderFrequencyControlInput}
            /> :
            <FormikField.Label
              label={i18n.t<string>('campaignForm.labels.frequencyControl')}
              name='frequency'
              formatter={renderFrequencyControlLabel}
            />
          )
        }
        <div className={styles.section}>
          <FormikField.Switch
            label={i18n.t<string>('campaignInfo.labels.daypart')}
            name='dayPart.enabled'
            onChange={onDaypartSwitchChanged}
          />
          {!!dayPartEnable &&
            <FormikField.Custom
              label=''
              name='dayPart'
              render={renderDayParts}
            />
          }
        </div>
      </div>
    </fieldset>
  );
};

export default React.memo(RtbCampaignOptimize);
