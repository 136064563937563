import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import productStyles from './productNativeForm.module.scss';
import imageStyles from './imageForm.module.scss';
import styles from './richProductNativeForm.module.scss';
import { RichProductNativeFormProps } from './RichProductNativeFormModel';
import { connect, FormikContextType } from 'formik';
import { FormConfig } from 'components/form/FormConfig';
import { FormContent } from 'components/form/Form';
import _, { partial } from 'lodash';
import { ProductHome } from 'containers/RetailRMN/Products/ProductHome';
import { ProductListDataContext } from 'containers/RetailRMN/Products/ProductListDataContext';
import Tags from 'components/Tags/Tags';
import { SelectOptions } from 'components/commonType';
import { faInfoCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { FormikField } from 'components/form/field/FormikField';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImageCreativeSizeHint } from './ImageCreativeSizeHint';

export const RichProductNativeForm: React.FunctionComponent<RichProductNativeFormProps & { formik: FormikContextType<any> }> = ({
  basicFields,
  hintModalData,
  renderHintModal,
  setHintModalData,
  getInitFormConfig,
  ...props
}) => {

  const { values, setFieldValue } = props.formik;
  const advertiserId = values.advertiserId;
  const location = useLocation();
  const defaultFilters = useMemo(() => _.get(location.state, 'filters', {
    advertiser: advertiserId
  }), [advertiserId, location.state]);
  const [formConfig, setFormConfig] = useState<FormConfig>(getInitFormConfig());
  const [sizeInstructionModalData, setSizeInstructionModalData] = useState<any>(undefined);

  const { flowPageModel } = props.model;
  const {
    products,
    filterSet,
    handleRemoveProduct,
    handleProductsButtonClick,
    handleSelectedProducts,
    onModalConfirm,
    onModalCancel,
    validateProducts,
    handleFilterChanged
  } = props.model.productNativeFormModel;

  const {
    validTypes,
    validateImage
  } = props.model.imageFormModel;

  const productListModalData = useMemo(() => {
    const modalData = {
      render: () =>
        <ProductListDataContext.Provider
          value={{
            selectedProducts: products,
            handleSelectedProducts,
            handleFilterChanged
          }}
        >
          <ProductHome
            defaultFilters={filterSet || defaultFilters}
            enableCreateProductNativeCreative={false}
          />
        </ProductListDataContext.Provider>,
      props: {
        title: '',
        primaryButton: {
          title: i18n.t<string>('common.buttons.confirm'),
          callback: onModalConfirm
        },
        secondaryButton: {
          title: i18n.t<string>('common.buttons.cancel'),
          callback: onModalCancel
        },
        dismiss: onModalCancel,
        className: productStyles.productListModal
      }
    };
    return modalData;
  }, [
    filterSet,
    defaultFilters,
    products,
    handleSelectedProducts,
    handleFilterChanged,
    onModalConfirm,
    onModalCancel
  ]);

  const renderProductsField = useCallback(() => {
    const onModalOpen = _.partial(handleProductsButtonClick, productListModalData);
    const productsOptions: SelectOptions[] = products.map((product) => ({
      label: product.name,
      value: product.productId.toString()
    }));
    const renderFieldContent = () => (
      <Fragment>
        {products.length > 0 ?
          <div className={productStyles.tagsContainer}>
            <Tags
              value={productsOptions}
              disableInput
              onChange={handleRemoveProduct}
              className={productStyles.tags}
            />
            <div className={productStyles.editIcon}>
              <IconWithTooltip
                icon={faPencilAlt}
                tooltipProps={{
                  id: `RichProductNativeFormEditProducts`,
                  tooltip: i18n.t<string>('creativeSetupFlow.labels.editProductsHint')
                }}
                onClick={onModalOpen}
              />
            </div>
          </div> :
          <div className={productStyles.selectProductsRow}>
            <button
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={onModalOpen}
            >
              {i18n.t<string>('creativeSetupFlow.labels.selectProducts')}
            </button>
            <FormikField.Label
              isFlexibleContent={true}
              fieldContentWidth={20}
              inputColSm={3}
              name='selectProducts'
              validate={validateProducts}
            />
          </div>}
      </Fragment>
    );
    return (
      <Fragment>
        {!flowPageModel.isModalOpen && renderFieldContent()}
      </Fragment>
    );
  }, [
    flowPageModel.isModalOpen,
    products,
    productListModalData,
    handleProductsButtonClick,
    handleRemoveProduct,
    validateProducts
  ]);

  const showSizeInstruction = useCallback(() => {
    const modalData = {
      component: ImageCreativeSizeHint,
      props: {
        sizeList: [{ width: 960, height: 320 }, { width: 2440, height: 250 }],
        customStyles: styles,
        closeHint: partial(setHintModalData, undefined)
      }
    };
    setSizeInstructionModalData(modalData);
    setHintModalData(modalData);
  }, [setHintModalData]);

  const closeSizeInstruction = useCallback(() => {
    setSizeInstructionModalData(undefined);
    setHintModalData(undefined);
  }, [setHintModalData]);

  useEffect(() => {
    if (hintModalData !== sizeInstructionModalData) {
      setSizeInstructionModalData(undefined);
    }
  }, [hintModalData, sizeInstructionModalData]);

  useEffect(() => {
    if (flowPageModel.type === 'create') {
      setFieldValue('typeProperties.products', products);
    }
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...basicFields])
            .addFormikFileInput({
              type: 'image',
              className: imageStyles.imageInput,
              label: i18n.t<string>('creativeSetupFlow.labels.image'),
              name: 'medias.image',
              validTypes,
              fieldContentWidth: 318,
              hints: [],
              permanentHint: (
                <div>
                  <span className={imageStyles.hint}>
                    {i18n.t<string>('creativeSetupFlow.labels.imageTypeHint')}
                  </span>
                  <span className={imageStyles.hint}>
                    {i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })}
                  </span>
                  <div
                    className={imageStyles.sizeInstructionBtn}
                    onClick={sizeInstructionModalData ?
                      closeSizeInstruction : showSizeInstruction
                    }
                  >
                    {i18n.t<string>('imageCreativeSizeHint.labels.sizeInstruction')}
                    <FontAwesomeIcon icon={faInfoCircle}/>
                  </div>
                </div>
              ),
              validate: validateImage
            })
            .addFormikCustom({
              name: 'typeProperties.products',
              label: i18n.t<string>('creativeSetupFlow.labels.products'),
              render: renderProductsField,
              formGroupClassName: productStyles.productsFieldContainer
            }, flowPageModel.type === 'edit')
            .addFormikLabel({
              name: 'typeProperties.product.name',
              label: i18n.t<string>('creativeSetupFlow.labels.product')
            }, flowPageModel.type === 'create')
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .withHintModal(renderHintModal())
        .build()
      ).build()
    );
  }, [
    flowPageModel.type,
    products,
    basicFields,
    validTypes,
    validateImage,
    setFieldValue,
    renderHintModal,
    renderProductsField,
    showSizeInstruction,
    closeSizeInstruction,
    sizeInstructionModalData
  ]);

  return (
    <FormContent
      formConfig={formConfig}
    />
  );
};

export default connect(RichProductNativeForm);
