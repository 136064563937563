import client from './RestClient';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { AxiosInstance } from 'axios';
import { omit } from 'lodash';

export type BindingCreateRequest = {
  l2ChannelIds?: string[],
  l3ChannelIds?: string[],
  creativeIds?: string[],
  active: boolean
};

export type BindingCreateRequests = BindingCreateRequest[];

export interface BindingWebService {
  createBinding (channel: L1ObjectChannel, createRequest: BindingCreateRequests): Promise<void>;
  updateBinding (channel: L1ObjectChannel, updateRequest: BindingCreateRequests): Promise<void>;
}

export class RestfulBindingWebService implements BindingWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async createBinding (channel: L1ObjectChannel, createRequest: BindingCreateRequests): Promise<void> {
    return this.restClient.post(`/v2/l3-object/${channel}/create`, createRequest.map(this.toServerPayload));
  }

  async updateBinding (channel: L1ObjectChannel, updateRequest: BindingCreateRequests): Promise<void> {
    return this.restClient.put(`/v2/l3-object/${channel}/update`, updateRequest.map(this.toServerPayload));
  }

  toServerPayload = (binding: BindingCreateRequest) => (omit({
    ...binding,
    goCreativeIds: binding.creativeIds
  }, ['creativeIds']))
}
