import React from 'react';
import { Link } from 'react-router-dom';

import styles from './accountsHome.module.scss';
import i18n from 'i18n';

import { LoadingIndicator } from 'components/LoadingIndicator';
import { AccountsHomeProps, AccountsHomeState } from './AccountsHomeModel';
import { AccountList } from '../AccountList';
import { SearchBar } from 'components/SearchBar';

export class AccountsHome extends React.Component<AccountsHomeProps, AccountsHomeState> {

  handler: number;

  constructor (props: any) {
    super(props);
    this.state = this.props.model.state;
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidMount () {
    this.props.model.refresh();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.refresh();
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  render () {
    return (
      <div className={styles.accountsHome}>
        {this.state.loading && <LoadingIndicator />}
        <div className={styles.topPanel}>
          <h3>{i18n.t<string>('accounts.home.title')}</h3>
        </div>
        <div className={styles.operationPanel}>
          <Link
            to={`${this.props.model.rootPath}/new`}
            className={'btn btn-tertiary btn-sm'}
          >
            {i18n.t<string>('accounts.home.addAccount')}
          </Link>
          <SearchBar
            model={{
              placeholder: i18n.t<string>('accounts.home.searchPlaceholder'),
              search: this.props.model.handleOnSearch,
              defaultValue: this.props.model.state.searchString
            }}
          />
        </div>
        <div className={styles.listContainer}>
          <AccountList model={this.props.model.getAccountListModel()} />
        </div>
      </div>
    );
  }
}
