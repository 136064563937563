import React, { Fragment } from 'react';
import { AdvertiserFormState, AdvertiserFormProps, AdvertiserFormData, CreateAdvertiserFormModel } from './AdvertiserFormModel';
import { Formik, FormikProps } from 'formik';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { Form } from 'react-bootstrap';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import i18n from 'i18next';
import styles from './advertiserForm.module.scss';
import _ from 'lodash';
import NavigationPrompt from 'components/NavigationPrompt/NavigationPrompt';
import { FormikField } from 'components/form/field/FormikField';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { RoleNames } from 'core';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasRoles } from 'core/permission/PermissionDSL';

export class AdvertiserForm extends React.PureComponent<RouteComponentProps<any> & AdvertiserFormProps, AdvertiserFormState> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleSubmit = () => {
    const errors = this.props.model.validateAndSetError();
    if (!_.isEmpty(errors)) {
      return;
    }
    this.props.model.submit();
  }

  cancelSubmit = () => {
    this.props.history.goBack();
  }

  validate = (advertiser: AdvertiserFormData): any => {
    return this.props.model.validate(advertiser);
  }

  renderForm = (formProps: FormikProps<AdvertiserFormData>) => {
    const model = this.props.model;
    model.setFormikProps(formProps);

    const { initAdvertiser } = model.state;
    if (!initAdvertiser) {
      return <LoadingIndicator />;
    }

    const agencyOptions = model instanceof CreateAdvertiserFormModel ? model.agencyOptions : undefined;
    const agencyNameFormatter = (value: string | number) => {
      return i18n.t<string>(`agency.name.${value}`);
    };
    return (
      <Fragment>
        <Form>
          <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
            {agencyOptions ? (
              <FormikField.Select
                label={i18n.t<string>('advertiserForm.labels.agency')}
                name='agencyId'
                simpleValue
                options={agencyOptions}
              />
            ) : (
              <FormikField.Label
                label={i18n.t<string>('advertiserForm.labels.agency')}
                name='agencyId'
                formatter={agencyNameFormatter}
              />
            )}
          </PermissionChecker>
          {formProps.values.id && (
            <FormikField.Label
              label={i18n.t<string>('advertiserForm.labels.id')}
              name='id'
            />
          )}
          <FormikField.Input
            label={i18n.t<string>('advertiserForm.labels.name')}
            name='advertiserName'
          />
          <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
            <FormikField.Select
              label={i18n.t<string>('advertiserForm.labels.partnershipMode')}
              name='partnershipModeId'
              simpleValue
              options={model.partnershipModeOptions}
            />
          </PermissionChecker>
        </Form>
        <div className={styles.buttonArea}>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={this.handleSubmit}
          >
            {i18n.t<string>('common.buttons.submit')}
          </button>
          <button
            type='button'
            className='btn btn-secondary btn-sm'
            onClick={this.cancelSubmit}
          >
            {i18n.t<string>('common.buttons.cancel')}
          </button>
        </div>
      </Fragment>
    );
  }

  render () {
    const model = this.props.model;
    const state = model.state;
    if (state.redirectPath) {
      return <Redirect to={state.redirectPath} />;
    }
    if (state.loading || !model.advertiser) {
      return <LoadingIndicator />;
    }
    return (
      <div className={styles.advertiserFormContainer}>
        <NavigationPrompt/>
        <div className={styles.titleArea}>
          <TitleWithBreadcrumbs
            title={model.title}
            routes={model.breadcrumbs}
          />
        </div>
        <div className={styles.titleBottomLine} />
        <Formik
          initialValues={model.advertiser}
          onSubmit={this.handleSubmit}
          validate={this.validate}
          validateOnBlur={false}
        >
          {this.renderForm}
        </Formik>
      </div>
    );
  }
}
