import { AccountListModel, DefaultAccountListModel } from '../AccountList';
import { SelectOptions } from 'components/commonType';
import { AccountManager, Account } from 'core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';

export type AccountsHomeState = {

  readonly loading: boolean;
  readonly error: Error | null;
  readonly selectAgency?: number;
  readonly searchString: string;
};

export interface AccountsHomeModel {

  refresh (): Promise<void>;
  getAccountListModel (): AccountListModel;
  onAgencyChange (agencyId): void;
  handleOnSearch (searchString: string): void;

  readonly agencyOptions?: SelectOptions[];
  readonly state: AccountsHomeState;
  readonly event: UpdateEventListener<AccountsHomeModel>;
  readonly rootPath: string;
  readonly filteredAccounts?: Array<Account>;
}

export type AccountsHomeProps = {

  readonly model: AccountsHomeModel;
};

export class DefaultAccountsHomeModel implements AccountsHomeModel {
  loading: boolean;
  error: Error | null;
  event: FireableUpdateEventListener<AccountsHomeModel>;

  manager: AccountManager;
  accounts: Array<Account> = [];
  rootPath: string = '/accounts';
  searchString: string = '';
  filteredAccounts: Array<Account> = [];

  constructor (manager: AccountManager, protected isSysAdmin: boolean) {
    this.manager = manager;
    this.error = null;
    this.loading = false;
    this.event = new FireableUpdateEventListener<AccountsHomeModel>();
  }

  get state (): AccountsHomeState {
    return {
      error: this.error,
      loading: this.loading,
      searchString: this.searchString
    };
  }

  getAccountListModel (): AccountListModel {
    return new DefaultAccountListModel(this.filteredAccounts, this.isSysAdmin);
  }

  async refresh (): Promise<void> {
    this.notify(true);
    try {
      this.accounts = await this.manager.getAccounts();
      this.updateViewData();
      this.notify(false);
    } catch (error) {
      this.notify(false, error as Error);
    }
  }

  updateViewData () {
    this.filteredAccounts = this.accounts.filter(account =>
      this.searchString === '' ||
      account.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
      account.email.toLowerCase().includes(this.searchString.toLowerCase())
    );
  }

  onAgencyChange (_) {}

  handleOnSearch = (searchString: string) => {
    this.searchString = searchString;
    this.updateViewData();
    this.notify(false);
  }

  notify (loading: boolean, error: Error | null = null) {
    this.error = error;
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
