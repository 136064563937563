import React from 'react';
import i18n from 'i18n';
import { Trans } from 'react-i18next';

export const renderOverBudgetWording = (currency, overBudget) => {
  const wording = i18n.t<string>('campaign.descriptions.overed');
  return (
    <Trans i18nKey='campaign.descriptions.overBudget'>
      {{ wording }}<span className='tip_normal'>Total budget</span>
      <span className='text-dark'> : {{ currency }} {{ overBudget }}</span>
    </Trans>
  );
};
