import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ProductHome } from './ProductHome';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { get } from 'lodash';

const ProductHomeView = withErrorBoundary(ERROR_CODE.PRODUCT_HOME, ProductHome);
export const Products: React.FC<any> = () => {

  const renderHomePage = (props) => {
    const locationState = props.location.state;
    const defaultFilters = get(locationState, 'filters');
    return (
      <ProductHomeView
        defaultFilters={defaultFilters}
        enableCreateProductNativeCreative={true}
      />
    );
  };

  return (
    <Switch>
      <Route render={renderHomePage} />
    </Switch>
  );
};
