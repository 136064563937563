import { Currency } from 'core/agency/Agency';
import { numberWithCommas } from 'utils/StringUtil';
import _ from 'lodash';

export function getPriceValue (currency: Currency | string, amount: number, decorator?: (value: number) => number): number {
  let targetAmount = amount;
  const isNegative = amount < 0;
  if (isNegative) {
    targetAmount = amount * -1;
  }
  let result;
  switch (currency) {
    case Currency.NTD:
      result = decorator ? decorator(targetAmount) : _.floor(targetAmount, 2);
      break;
    default:
      if (targetAmount < 1) {
        result = decorator ? decorator(targetAmount) : _.floor(targetAmount, 2);
      } else {
        result = decorator ? decorator(targetAmount) : Math.floor(targetAmount);
      }
      break;
  }
  return isNegative ? result * -1 : result;
}

export function formatPriceAny (amount: number): string {
  return numberWithCommas(amount);
}

export function formatPrice (currency: Currency | string, amount: number, decorator?: (value: number) => number): string {
  return numberWithCommas(getPriceValue(currency, amount, decorator));
}

export function formatPriceWithCurrency (currency: Currency | string, amount: number) {
  return `${currency} ${formatPrice(currency, amount)}`;
}
