import { Product, ProductWithPagination } from 'core/product/Product';
import client from './RestClient';
import _ from 'lodash';
import { Pageable } from './Pageable';
import { Pagination } from 'core/pagination/Pagination';
import { AxiosInstance } from 'axios';

export interface DmpWebService {
  getRetailProducts (pageable: Pageable, queries?: any): Promise<ProductWithPagination>;
  getProductCaches (agencyId: number, advertiser: number, productIds: string[]): Promise<Product[]>;
  getProductName (productId: string): Promise<string | null>;
  getAdServingUrl (productId: string): Promise<string>;
}

const wrapPagination = (json: any): Pagination => {
  return {
    page: _.get(json, 'page', 1),
    size: _.get(json, 'size', 10),
    totalCount: _.get(json, 'totalCount', 0)
  };
};

const wrapProductFromServer: any = (json: any) => {
  const skus = _.get(json, 'skus[0]', {});

  return {
    productId: _.get(json, 'productId', 0),
    name: _.get(skus, 'name', ''),
    category: _.get(skus, 'category', ''),
    listPrice: _.get(skus, 'listPrice', 0),
    imgLink: _.get(skus, 'imgLink', ''),
    multipack: _.get(skus, 'multipack', 0),
    vendorNumber: _.get(skus, 'customLabel0', '')
  };
};

export class RestfulDmpWebService implements DmpWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getRetailProducts (pageable: Pageable, queries: any = {}): Promise<ProductWithPagination> {
    let { page, sizePerPage } = pageable;
    const response = await this.restClient.post(`/dmp/retail/products/search?page=${page}&size=${sizePerPage}`, queries);
    return {
      products: response.data.records.map(product => wrapProductFromServer(product)),
      pagination: wrapPagination(response.data.pagination)
    };
  }

  async getProductCaches (agencyId: number, advertiser: number, productIds: string[]): Promise<Product[]> {
    const response = await this.restClient.post(`/dmp/products/cache?agencyId=${agencyId}&advertiserId=${advertiser}`, productIds);
    return response.data.records.map(product => wrapProductFromServer(product));
  }

  async getProductName (productId: string): Promise<string | null> {
    const response = await this.restClient.get(`/dmp/products/${productId}/name`);
    return response.data.result;
  }

  async getAdServingUrl (productId: string): Promise<string> {
    const response = await this.restClient.get(`/dmp/products/${productId}/ad-serving-url`);
    return response.data;
  }
}
