import { FormikSwitchField, FormikSwitchFieldProps } from './field/SwitchField';
import { FormikTagsField, FormikTagsFieldProps } from './field/TagsField';
import { FormikRadioField, FormikRadioFieldProps } from './field/RadioField';
import { FormikCheckboxField, CheckboxField, CheckboxFieldProps, FormikCheckboxFieldProps } from './field/CheckboxField';
import { FormikInputField, FormikInputFieldProps } from './field/InputField';
import { FormikLabelField, FormikLabelFieldProps, LabelField, LabelFieldProps } from './field/LabelField';
import { FormikInputGroupField, FormikInputGroupFieldProps } from './field/InputGroupField';
import { FormikUrlInputField } from './field/UrlInputField';
import { FormikTextAreaField } from './field/TextAreaField';
import { FormikSelectField, FormikSelectFieldProps } from './field/SelectField';
import { FormikDatePickerField, FormikDatePickerFieldProps } from './field/DatePickerField';
import { FormikDateRangePickerField, FormikDateRangePickerFieldProps } from './field/DateRangePickerField';
import { FormikCustomField, CustomField, CustomFieldProps, FormikCustomFieldProps } from './field/CustomField';
import { FormikFileInputField, FormikFileInputFieldProps } from './field/FileInputField';
import { FormikMultiImageInputField, FormikMultiImageInputFieldProps } from './field/MultiImageInputField';
import { FieldLayoutProps } from 'hoc/withFieldLayout';
import { ReactNode } from 'react';

export interface FormField {
  component: (props: any) => ReactNode;
  props: any;
  hide: boolean;
}

export interface FormSection {
  title?: string;
  basicFields: FormField[];
  advancedFields?: FormField[];
  hintModal?: any;
}

export class FormConfig {

  sections: FormSection[];

  static Builder = class {

    sections: any[] = [];

    addSection (section: FormSection) {
      this.sections.push(section);
      return this;
    }

    build () {
      return new FormConfig(this.sections);
    }
  };

  static SectionBuilder = class {

    title?: string;
    basicFields: FormField[];
    advancedFields?: FormField[];
    hintModal?: any;

    constructor (fields: FormField[]) {
      this.basicFields = fields;
    }

    withTitle (title: string) {
      this.title = title;
      return this;
    }

    addAdvancedFields (fields: FormField[]) {
      this.advancedFields = fields;
      return this;
    }

    withHintModal (hintModal: any) {
      this.hintModal = hintModal;
      return this;
    }

    build (): FormSection {
      return {
        title: this.title,
        hintModal: this.hintModal,
        basicFields: this.basicFields,
        advancedFields: this.advancedFields
      };
    }
  };

  static FieldsBuilder = class {

    fields: FormField[];
    constructor (basicFields?: FormField[]) {
      this.fields = basicFields ? basicFields : [];
    }

    addField = <T extends FieldLayoutProps>(component, props: T, hide: boolean) => {
      this.fields.push({
        component,
        props,
        hide
      });
    }

    removeField = (name: string) => {
      this.fields = this.fields.filter(field => field.props.name !== name);
    }

    addFormikCheckbox (props: FormikCheckboxFieldProps, hide: boolean = false) {
      this.addField(FormikCheckboxField, props, hide);
      return this;
    }

    addFormikSelect (props: FormikSelectFieldProps, hide: boolean = false) {
      this.addField(FormikSelectField, props, hide);
      return this;
    }

    addFormikInput (props: FormikInputFieldProps, hide: boolean = false) {
      this.addField(FormikInputField, props, hide);
      return this;
    }

    addFormikTextarea (props: FormikInputFieldProps, hide: boolean = false) {
      this.addField(FormikTextAreaField, props, hide);
      return this;
    }

    addFormikLabel (props: FormikLabelFieldProps, hide: boolean = false) {
      this.addField(FormikLabelField, props, hide);
      return this;
    }

    addFormikSwitch (props: FormikSwitchFieldProps, hide: boolean = false) {
      this.addField(FormikSwitchField, props, hide);
      return this;
    }

    addFormikCustom (props: FormikCustomFieldProps, hide: boolean = false) {
      this.addField(FormikCustomField, props, hide);
      return this;
    }

    addFormikInputGroup (props: FormikInputGroupFieldProps, hide: boolean = false) {
      this.addField(FormikInputGroupField, props, hide);
      return this;
    }

    addFormikDatePicker (props: FormikDatePickerFieldProps, hide: boolean = false) {
      this.addField(FormikDatePickerField, props, hide);
      return this;
    }

    addFormikDateRangePicker (props: FormikDateRangePickerFieldProps, hide: boolean = false) {
      this.addField(FormikDateRangePickerField, props, hide);
      return this;
    }

    addFormikTags (props: FormikTagsFieldProps, hide: boolean = false) {
      this.addField(FormikTagsField, props, hide);
      return this;
    }

    addFormikFileInput (props: FormikFileInputFieldProps, hide: boolean = false) {
      this.addField(FormikFileInputField, props, hide);
      return this;
    }

    addFormikUrlInput (props: FormikInputFieldProps, hide: boolean = false) {
      this.addField(FormikUrlInputField, props, hide);
      return this;
    }

    addFormikMultiImageInput (props: FormikMultiImageInputFieldProps, hide: boolean = false) {
      this.addField(FormikMultiImageInputField, props, hide);
      return this;
    }

    addFormikRadio (props: FormikRadioFieldProps, hide: boolean = false) {
      this.addField(FormikRadioField, props, hide);
      return this;
    }

    addLabel (props: LabelFieldProps, hide: boolean = false) {
      this.addField(LabelField, props, hide);
      return this;
    }

    addCheckbox (props: CheckboxFieldProps, hide: boolean = false) {
      this.addField(CheckboxField, props, hide);
      return this;
    }

    addCustom (props: CustomFieldProps, hide: boolean = false) {
      this.addField(CustomField, props, hide);
      return this;
    }

    build (): FormField[] {
      return this.fields;
    }
  };

  constructor (sections) {
    this.sections = sections;
  }
}
