import config from 'config';
import { RestfulAWSS3WebService, AWSS3WebService } from 'ws/AWSS3WebService';

export interface CloudStorageManager {
  uploadFileToCloud (file: File): Promise<string>;
}
export class AWSS3Manager implements CloudStorageManager {

  constructor (
    private webService: AWSS3WebService = new RestfulAWSS3WebService()
  ) {}

  async uploadFileToCloud (file: File) {
    return this.webService.uploadFileToCloud(file, config.cloudStorageConfig.bucket);
  }
}
