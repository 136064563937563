import React from 'react';
import { Link } from 'react-router-dom';

import './AppMenuItem.scss';

import { partial } from 'lodash';
import { AppMenuItemProps, AppMenuItemType } from './AppMenuBarModel';

export class AppMenuItem extends React.Component<AppMenuItemProps> {

  actionRenderMap = {
    [AppMenuItemType.LINK]: this.renderLink.bind(this),
    [AppMenuItemType.DOWNLOAD]: this.renderDownload.bind(this),
    [AppMenuItemType.OPEN_NEW]: this.renderOpenNew.bind(this)
  };

  renderLink () {
    const { path, title } = this.props.model;
    return (
      <Link to={path}>
        <div>{title}</div>
      </Link>
    );
  }

  renderDownload () {
    const { download, path, title } = this.props.model;
    return (
      <div onClick={partial(download, path)}>{title}</div>
    );
  }

  renderOpenNew () {
    const { path, title } = this.props.model;
    const openNew = () => window.open(path, '_blank', 'noopener');
    return (
      <div onClick={openNew}>{title}</div>
    );
  }

  render () {
    const { path, type } = this.props.model;
    const current = window.location.pathname.startsWith(path);
    const classes = current ? 'app-menu-item now' : 'app-menu-item';
    return (
      <div className={classes}>
        {this.actionRenderMap[type]()}
      </div>
    );
  }
}
