import client from './RestClient';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import _ from 'lodash';
import { AxiosInstance } from 'axios';

export type L1ObjectUpdateRequest = {
  name: string;
  l1ObjectLifetimeBudget?: number;
};

export type L1ObjectCreateRequest = L1ObjectUpdateRequest & {
  adsOrderId: number;
  channel: L1ObjectChannel;
};

export interface L1ObjectWebService {
  createL1Object (l1Object: L1ObjectCreateRequest): Promise<number>;
  updateL1Object (l1ObjectId: number | string, l1Object: L1ObjectUpdateRequest): Promise<void>;
  getL1Object (l1ObjectId: number | string): Promise<any>;
  getL1Objects (orderId: number | string): Promise<Array<any>>;
  deleteL1Object (l1ObjectId: number): Promise<void>;
  updateL1ObjectState (l1ObjectIds: (number | string)[], state: 'activate' | 'deactivate'): Promise<void>;
  getL1ObjectName (l1ObjectId: number): Promise<string | null>;
  getOrderNumber (l1ObjectId: string | number): Promise<string>;
}

const wrapL1Object = json => {
  return _.omit({
    ...json,
    budget: json.l1ObjectLifetimeBudget
  }, 'l1ObjectLifetimeBudget');
};

export class RestfulL1ObjectWebService implements L1ObjectWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async createL1Object (l1Object: L1ObjectCreateRequest): Promise<number> {
    const response = await this.restClient.post('/v2/l1-objects', l1Object);
    return response.data.l1ObjectId;
  }
  async updateL1Object (l1ObjectId: number | string, l1Object: L1ObjectUpdateRequest): Promise<void> {
    return this.restClient.put(`/v2/l1-objects/${l1ObjectId}`, l1Object);
  }
  async getL1Object (l1ObjectId: number | string): Promise<any> {
    const response = await this.restClient.get(`/v2/l1-objects/${l1ObjectId}`);
    return wrapL1Object(response.data);
  }
  async getL1Objects (orderId: number | string): Promise<any> {
    const response = await this.restClient.get(`/v2/adsOrders/${orderId}/l1-objects`);
    return _.get(response.data, 'records', []).map(wrapL1Object);
  }
  async deleteL1Object (l1ObjectId: number): Promise<void> {
    await this.restClient.delete(`/v2/l1-objects/${l1ObjectId}`);
  }
  async updateL1ObjectState (l1ObjectIds: (number | string)[], state: 'activate' | 'deactivate'): Promise<void> {
    const l1ObjectParameters = l1ObjectIds.join(',');
    await this.restClient.put(`/v2/l1-objects/state/${state}?l1ObjectIds=${l1ObjectParameters}`);
  }
  async getL1ObjectName (l1ObjectId: number): Promise<string | null> {
    const response = await this.restClient.get(`/v2/l1-objects/${l1ObjectId}/name`);
    return response.data.result;
  }
  async getOrderNumber (l1ObjectId: string | number): Promise<string> {
    const response = await this.restClient.get(`/v2/l1-objects/${l1ObjectId}/orderNumber`);
    return response.data;
  }
}
