import { ColumnDefinition } from 'components/TableColumn/TableColumn';
import moment from 'moment';
import { OrdersWithPagination } from 'ws/OrderWebService';
import { OrderManager, DefaultOrderManager } from 'core/order/OrderManager';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { State, OrderDelivery } from 'core/order/Order';
import i18n from 'i18next';
import { formatPrice } from 'helper/CurrencyHelper';
import styles from './OrderList.module.scss';
import _ from 'lodash';
import { AddonFeatureManager } from 'core';
import { SortableList, AbstractSortableList } from 'containers/Common/AbstractSortableList';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { SelectOptions } from 'components/commonType';
import { FilterMenuTabConfig } from 'components/FilterMenuTab/FilterMenuTab';

export interface OrderListModel extends SortableList {
  readonly event: UpdateEventListener<OrderListModel>;
  readonly state: OrderListState;
  readonly noDataDescription: string;
  readonly filterMenuTabConfigs: Array<FilterMenuTabConfig>;
  readonly ordersSizePerPage: number;
  readonly searchString: string;
  readonly orderDeliveriesOptions: Array<SelectOptions>;
  readonly orderDeliveries: Array<string>;
  readonly orderTags: Array<string>;
  init (page?: number): Promise<void>;
  changeAdvertiser (advertiserId): Promise<void>;
  getViewModelData (): any;
  setAdvertisers (advertisers): void;
  getColumnDefinition (columnName): ColumnDefinition;
  handleOnSearch (searchString: string): void;
  setSearchbarRef (ref): void;
  handleOnStateFilterApply (stateDesList): void;
  handleOnTagFilterApply (tags): void;
  handleCreatorInfoApply (orderIds: number[]): void;
  sortOrders (field, direction): void;
  getReportPath (order): string;
  onUnmount (handler): void;
}

export enum OrderListColumns {
  NAME = 'orderNumber',
  ADVERTISER = 'advertiser',
  EXTERNALID = 'externalId',
  APPROVAL = 'state',
  ENDDATE = 'endDate',
  REMAINDAY = 'remainingDay',
  PROGRESS = 'progress',
  BUDGET = 'targetBudget',
  SPENT = 'spent',
  EDITBTNS = 'editBtns'
}

export type OrderListProps = {
  readonly model: OrderListModel;
};

export type OrderListState = {
  readonly ordersWithPagination?: OrdersWithPagination;
  readonly loading: boolean;
  readonly redirectPath?: string;
  readonly selectedStateFilter: string[];
  readonly selectedOrdersToShowInfo: number[];
  readonly selectedTagFilter: string[];
};

export class DefaultOrderListModel extends AbstractSortableList implements OrderListModel {
  loading: boolean;
  manager: OrderManager;
  ordersWithPagination?: OrdersWithPagination;
  event: FireableUpdateEventListener<OrderListModel>;
  ordersSizePerPage: number;
  advertiserId?: number;
  advertisers: any;
  orderDeliveries: string[] = [];
  orderDeliveriesOptions: SelectOptions[] = [];
  orderTags: string[] = [];
  searchString: string;
  searchbarRef: any;
  selectedStateFilter: string[] = [];
  selectedOrdersToShowInfo: number[] = [];
  selectedTagFilter: string[] = [];
  debouncedInit: any;
  redirectPath?: string;
  addonFeatureManager: AddonFeatureManager;

  constructor (advertiserId: number | undefined, addonFeatureManager: AddonFeatureManager, manager: OrderManager = new DefaultOrderManager()) {
    super(OrderListColumns.REMAINDAY, 'desc');
    this.manager = manager;
    this.advertiserId = advertiserId;
    this.addonFeatureManager = addonFeatureManager;
    this.loading = false;
    this.event = new FireableUpdateEventListener<OrderListModel>();
    this.ordersSizePerPage = 10;
    this.searchString = '';
    this.debouncedInit = _.debounce(this.init.bind(this), 1000);
  }

  async init (page: number | undefined = 1): Promise<void> {
    this.updateState(true);
    try {
      let sort = this.sortField;
      if (this.sortField === 'spent') {
        sort = 'adMetrics.spent';
      }
      const deliveries = this.orderDeliveriesOptions.filter(selectOption => this.selectedStateFilter.includes(selectOption.label));
      const pageable = { page, sizePerPage: this.ordersSizePerPage, sort, direction: this.sortOrder };
      this.ordersWithPagination = await this.manager.getOrders(this.advertiserId, pageable, this.searchString, deliveries.map(option => option.value.toString()));
      this.selectedOrdersToShowInfo = [];
      this.updateState(false);
    } catch (error) {
      this.updateState(false);
    }
  }

  setAdvertisers (advertisers) {
    this.advertisers = advertisers;
  }

  setSearchbarRef (ref) {
    this.searchbarRef = ref;
  }

  handleOnSearch = (searchString: string): void => {
    this.searchString = searchString;
    if (this.searchString === '') {
      this.debouncedInit && this.debouncedInit.cancel();
      this.init();
    } else {
      this.debouncedInit();
    }
  }

  handleOnStateFilterApply = (stateDesList: any): void => {
    this.selectedStateFilter = [...stateDesList];
    this.init();
  }

  handleOnTagFilterApply = (tags: any): void => {
    this.selectedTagFilter = [...tags];
    this.init();
  }

  handleCreatorInfoApply = (orderIds: number[]): void => {
    this.selectedOrdersToShowInfo = [...orderIds];
    this.event.fireEvent(this);
  }

  getReportPath (order) {
    const from = encodeURIComponent(moment(order.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
    const to = encodeURIComponent(moment(order.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
    return `/reports/performance?dimension=adsOrderId&from=${from}&to=${to}&adsOrderId=${order.id}`;
  }

  getRemainDayDesc (order) {
    let today = moment().startOf('day');
    let startDate = moment(order.startDate, 'YYYY-MM-DD');
    let endDate = moment(order.endDate, 'YYYY-MM-DD');
    let diffStart = today.diff(startDate, 'days');
    let diffEnd = endDate.diff(today, 'days');
    if (diffStart < 0) {
      return i18n.t<string>('orderDetail.labels.notstart');
    } else {
      if (diffEnd < 0) {
        return i18n.t<string>('orderDetail.labels.finished');
      } else if (diffEnd === 0) {
        return i18n.t<string>('orderDetail.labels.today');
      } else {
        return diffEnd + 1;
      }
    }
  }

  getViewModelData () {
    this.orderDeliveriesOptions = createSelectOptionsFromEnum(OrderDelivery, 'orderDetail.labels.');
    this.orderDeliveries = [];
    this.orderTags = [];
    if (!this.ordersWithPagination) {
      return [];
    }

    const viewModelData: Array<any> = this.ordersWithPagination.orders.map(order => {
      this.orderDeliveries = this.orderDeliveriesOptions.map(selectOption => selectOption.label);
      return {
        id: order.id,
        needAlert: order.needAlert,
        startDate: order.startDate,
        endDate: order.endDate,
        orderNumber: {
          value: order.orderNumber,
          name: order.projectName,
          orderNumber: `ID: ${order.orderNumber}`
        },
        advertiser: this.advertisers ? this.advertisers[order.advertiserId] : '',
        externalId: order.externalId ? order.externalId : i18n.t<string>('campaignList.labels.emptyContent'),
        state: {
          value: order.state,
          desc: i18n.t<string>(`order.status.${State[order.state].toLowerCase().replace(/_/g, '')}`)
        },
        remainingDay: this.getRemainDayDesc(order),
        progress: order.rtbCampaignRunningStatus && !_.isEmpty(order.rtbCampaignRunningStatus) ? order.rtbCampaignRunningStatus : [],
        targetBudget: formatPrice(order.currency, order.budget),
        spent: formatPrice(order.currency, order.spent),
        creator: order.creator,
        creatorEmail: order.creatorEmail,
        createDateTime: order.createDateTime
      };
    });

    return viewModelData;
  }

  getColumnDefinitionAddition (columnName) {
    switch (columnName) {
      case OrderListColumns.ADVERTISER:
      case OrderListColumns.PROGRESS:
        return {
          sort: false
        };
      case OrderListColumns.EDITBTNS:
        return {
          text: '',
          sort: false
        };
      default:
        return {};
    }
  }

  getColumnDefinition (columnName): ColumnDefinition {
    const columnClassGetter = () => {
      return styles[columnName];
    };

    return {
      sort: true,
      text: `orderList.headers.${columnName}`,
      dataField: columnName,
      classes: columnClassGetter,
      headerClasses: columnClassGetter,
      ...this.getColumnDefinitionAddition(columnName)
    };
  }

  async changeAdvertiser (advertiserId): Promise<void> {
    this.advertiserId = advertiserId;
    if (this.searchbarRef) {
      this.searchbarRef.current.clear();
    } else {
      await this.init();
    }
  }

  get state () {
    return {
      ordersWithPagination: this.ordersWithPagination,
      loading: this.loading,
      redirectPath: this.redirectPath,
      selectedStateFilter: this.selectedStateFilter,
      selectedTagFilter: this.selectedTagFilter,
      selectedOrdersToShowInfo: this.selectedOrdersToShowInfo
    };
  }

  get noDataDescription (): string {
    return 'orderList.headers.noDataAvailable';
  }

  get filterMenuTabConfigs (): FilterMenuTabConfig[] {
    return [
      {
        filterType: i18n.t<string>('orderList.labels.stateFilter'),
        menuTitle: i18n.t<string>('orderList.labels.stateFilterMenuTitle'),
        tag: i18n.t<string>('orderList.labels.stateTag'),
        selectedValues: this.selectedStateFilter,
        options: this.orderDeliveries,
        applyMethod: this.handleOnStateFilterApply
      },
      {
        filterType: i18n.t<string>('orderList.labels.tagFilter'),
        menuTitle: i18n.t<string>('orderList.labels.tagFilterMenuTitle'),
        tag: i18n.t<string>('orderList.labels.tagTag'),
        selectedValues: this.selectedTagFilter,
        options: this.orderTags,
        applyMethod: this.handleOnTagFilterApply
      }
    ];
  }

  sortOrders = async (field, direction) => {
    const currentSortSetting = this.sortDescriptor[0];
    if (field === currentSortSetting.dataField && direction === currentSortSetting.order) {
      return;
    }
    this.handleSort(field, direction);
    await this.init();
  }

  onUnmount (handler) {
    this.event.remove(handler);
    this.redirectPath = undefined;
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
