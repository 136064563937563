import { hasFuncs } from 'core/permission/PermissionDSL';
import { PMaxCore, PermissionAware, AccountManager, PermissionContext, Actor, AuthenticationManager, RoleNames } from 'core';
import { AccountsHomeModel, DefaultAccountsHomeModel } from './AccountsHome/AccountsHomeModel';
import { Permission } from 'core/auth/Permission';
import { AccountFormModel, CreateAccountForm, EditAccountForm } from 'components/AccountForm';
import { AccountDetailModel, DefaultAccountDetailModel } from './AccountDetail';
import { SelectOptions } from 'components/commonType';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import _ from 'lodash';

export interface AccountsModel {

  readonly operator: Actor | null;
  readonly accountManager: AccountManager;
  readonly createCancelPath: string;

  readonly canEditAccount: boolean;
  readonly canViewAccount: boolean;
  readonly canCreateAccount: boolean;
  readonly canViewAllAccounts: boolean;
  readonly rootPath: string;
  readonly event: UpdateEventListener<AccountsModel>;
  readonly state: AccountsState;
  init (): void;
  getAccountHomeModel (): AccountsHomeModel;
  getCreateAccountFormModel (): AccountFormModel;
  getEditAccountFormModel (accountId: number): AccountFormModel;
  getAccountDetailModel (accountId: number): AccountDetailModel | undefined;
  onUnmount (handler?: number): void;
}

export type AccountsProps = {

  readonly model: AccountsModel;
};

export type AccountsState = {
  readonly loading: boolean;
  readonly agencyOptions?: SelectOptions[];
};

export class DefaultAccountsModel implements AccountsModel {
  operator: Actor | null;
  context: PermissionContext;
  accountManager: AccountManager;
  authenticationManager: AuthenticationManager;

  editPermission: PermissionAware;
  viewPermission: PermissionAware;
  createPermission: PermissionAware;
  viewAllPermission: PermissionAware;

  homeModel?: AccountsHomeModel;
  createAccountFormModel?: CreateAccountForm;
  editAccountFormModel?: EditAccountForm;
  accountDetailModel?: AccountDetailModel;
  rootPath: string = '/accounts';
  loading: boolean = false;
  event: FireableUpdateEventListener<AccountsModel> = new FireableUpdateEventListener<AccountsModel>();

  constructor (core: PMaxCore) {
    this.accountManager = core.accountManager;
    this.operator = core.authenticationManager.actor;
    this.authenticationManager = core.authenticationManager;
    this.context = {
      actor: core.authenticationManager.actor,
      agencyAddon: core.addonFeatureManager.addonFeature
    };

    this.createPermission = hasFuncs(Permission.SYS_ADMIN_MANAGEMENT);
    this.editPermission = hasFuncs(Permission.SYS_ADMIN_MANAGEMENT);
    this.viewAllPermission = hasFuncs(Permission.ACCOUNT_LIST_READ);
    this.viewPermission = hasFuncs(Permission.ACCOUNT_READ);
  }

  async init () {
    // This is intentional
  }

  get state () {
    return {
      loading: this.loading
    };
  }

  get createCancelPath (): string {
    return this.canViewAllAccounts ? this.rootPath : '/';
  }

  get canEditAccount (): boolean {
    return this.editPermission.visible(this.context);
  }

  get canViewAccount (): boolean {
    return this.viewPermission.visible(this.context);
  }

  get canCreateAccount (): boolean {
    return this.createPermission.visible(this.context);
  }

  get canViewAllAccounts (): boolean {
    return this.viewAllPermission.visible(this.context);
  }

  getAccountHomeModel (): AccountsHomeModel {
    if (this.homeModel) {
      return this.homeModel;
    }
    this.homeModel = new DefaultAccountsHomeModel(this.accountManager, _.get(this.operator, 'roleName') === RoleNames.sysAdmin);
    return this.homeModel;
  }

  getCreateAccountFormModel (): AccountFormModel {
    if (this.createAccountFormModel) {
      return this.createAccountFormModel;
    }
    this.createAccountFormModel = new CreateAccountForm(this.createCancelPath);
    return this.createAccountFormModel;
  }

  getEditAccountFormModel (accountId: number): AccountFormModel {
    if (this.editAccountFormModel &&
      this.editAccountFormModel.accountId === accountId) {
      return this.editAccountFormModel;
    }
    this.editAccountFormModel = new EditAccountForm(accountId, this.authenticationManager);
    return this.editAccountFormModel;
  }

  getAccountDetailModel (accountId: number): AccountDetailModel | undefined {
    if (!this.operator) {
      return undefined;
    }
    if (this.accountDetailModel &&
      this.accountDetailModel.accountId === accountId) {
      return this.accountDetailModel;
    }
    this.accountDetailModel = new DefaultAccountDetailModel(accountId, this.operator, this.accountManager);
    return this.accountDetailModel;
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }

  onUnmount (handler?: number) {
    handler && this.event.remove(handler);
    this.accountDetailModel = undefined;
    this.createAccountFormModel = undefined;
    this.editAccountFormModel = undefined;
  }
}
