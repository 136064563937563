import React from 'react';
import Select from 'components/Select/Select';
import styles from './dayparts.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import { SelectOptions } from 'components/commonType';
import classNames from 'classnames/bind';
import Tips from 'components/Tips/Tips';
const cx = classNames.bind(styles);

export const getDayPartSummary = (dayPart) => {
  let dayPartValue = _.omitBy(_.omit(dayPart, 'enabled'), _.isEmpty);
  return Object.keys(dayPartValue).map(day => {
    return `${getDayOfWeekLabelByValue(parseInt(day, 10))},${i18n.t<string>('daypart.labels.hourUnit')}: ${dayPartValue[day].join(', ')}`;
  }).join('\r\n');
};

export function getDayOfWeekLabelByValue (value) {
  switch (value) {
    case 0:
      return '';
    case 1:
      return i18n.t<string>('daypart.labels.monday');
    case 2:
      return i18n.t<string>('daypart.labels.tuesday');
    case 3:
      return i18n.t<string>('daypart.labels.wednesday');
    case 4:
      return i18n.t<string>('daypart.labels.thursday');
    case 5:
      return i18n.t<string>('daypart.labels.friday');
    case 6:
      return i18n.t<string>('daypart.labels.saturday');
    default:
      return i18n.t<string>('daypart.labels.sunday');
  }
}

export function getInitDaypart () {
  return {
    '1' : Array.from({ length: 24 }, (_, index) => index),
    '2' : Array.from({ length: 24 }, (_, index) => index),
    '3' : Array.from({ length: 24 }, (_, index) => index),
    '4' : Array.from({ length: 24 }, (_, index) => index),
    '5' : Array.from({ length: 24 }, (_, index) => index),
    '6' : Array.from({ length: 24 }, (_, index) => index),
    '7' : Array.from({ length: 24 }, (_, index) => index)
  };
}

export class Dayparts extends React.Component<any> {

  state = {dayPart: {
    ...getInitDaypart(),
    enabled: '1'
  }, option: undefined};

  componentDidMount () {
    let dayPart = this.props.values[this.props.name];
    if (dayPart && Object.keys(dayPart).length > 1) {
      this.setState({ dayPart: dayPart });
    } else {
      this.props.setFieldValue(this.props.name, this.state.dayPart);
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      nextState.dayPart === this.state.dayPart &&
      this.props.errors[this.props.name] === nextProps.errors[this.props.name] &&
      this.props.submitCount === nextProps.submitCount &&
      this.props.touched[this.props.name] === nextProps.touched[this.props.name]
    ) {
      return false;
    }

    return true;
  }

  onDaypartChanged = (newDaypart) => {
    this.props.setFieldValue(this.props.name, newDaypart);
    this.props.onChange && this.props.onChange(newDaypart);
  }

  onCellClick = (dayValue, hourValue) => {
    let hours = this.state.dayPart[dayValue.toString()];
    if (!hours) {
      return;
    }

    let indexOfValue = hours.indexOf(hourValue);
    if (indexOfValue === -1) {
      hours.push(hourValue);
    } else {
      hours.splice(indexOfValue, 1);
    }

    let newDaypart = {
      ...this.state.dayPart,
      [dayValue.toString()]: hours
    };

    this.setState({ dayPart: newDaypart }, () => this.onDaypartChanged(newDaypart));
  }

  onHourColumnClick = (hourValue: number) => {
    let dayPart = this.state.dayPart;
    let hasHourNotSelected = false;
    for (let dayValue = 1; dayValue < 8; ++dayValue) {
      if (dayPart[dayValue.toString()].indexOf(hourValue) === -1) {
        hasHourNotSelected = true;
        break;
      }
    }

    let newDaypart;
    if (hasHourNotSelected) {
      const addHour = (hourArr: number[], hour: number) => {
        const hours = hourArr ? hourArr.concat(hour) : [hour];
        return _.uniq(hours).sort((a, b) => {
          return a - b;
        });
      };
      newDaypart = {
        '1': addHour(dayPart['1'], hourValue),
        '2': addHour(dayPart['2'], hourValue),
        '3': addHour(dayPart['3'], hourValue),
        '4': addHour(dayPart['4'], hourValue),
        '5': addHour(dayPart['5'], hourValue),
        '6': addHour(dayPart['6'], hourValue),
        '7': addHour(dayPart['7'], hourValue),
        enabled: '1'
      };
    } else {
      newDaypart = {
        '1': _.without(dayPart['1'], hourValue),
        '2': _.without(dayPart['2'], hourValue),
        '3': _.without(dayPart['3'], hourValue),
        '4': _.without(dayPart['4'], hourValue),
        '5': _.without(dayPart['5'], hourValue),
        '6': _.without(dayPart['6'], hourValue),
        '7': _.without(dayPart['7'], hourValue),
        enabled: '1'
      };
    }

    this.setState({ dayPart: newDaypart }, () => this.onDaypartChanged(newDaypart));
  }

  onDayOfWeekClick = (dayValue) => {
    let hours = this.state.dayPart[dayValue.toString()];
    let newHours = hours && hours.length === 24 ? [] : Array.from({ length: 24 }, (_, index) => index);
    let newDaypart = {
      ...this.state.dayPart,
      [dayValue.toString()]: newHours
    };

    this.setState({ dayPart: newDaypart }, () => this.onDaypartChanged(newDaypart));
  }

  onDayPartOptionsChanged = (event) => {
    let newDaypart = {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      enabled: '1'
    };

    event.sets.forEach(set => {
      set.days.forEach(day => {
        newDaypart[day.toString()] = Array.from(set.hours);
      });
    });

    this.setState({ dayPart: newDaypart }, () => this.onDaypartChanged(newDaypart));
  }

  findDaypartOptionByDaypart = (daypart) => {
    if (_.isEmpty(daypart)) {
      return _.find(this.daypartOptions, option => option.value === '1');
    }
    const hoursDaysMap = Object.keys(daypart).filter(key => key !== 'enabled').reduce((acc, day) => {
      const hourKey = daypart[day].join(',');
      if (hourKey !== '') {
        const daysWithSameHours = [..._.defaultTo(acc[hourKey], []), day];
        acc[hourKey] = daysWithSameHours;
      }
      return acc;
    }, {});

    return _.find(this.daypartOptions, option => {
      return Object.keys(hoursDaysMap).every(hours => {
        const days = hoursDaysMap[hours];
        return option.sets.some(set => {
          return set.hours.join(',') === hours && set.days.join(',') === days.join(',');
        });
      });
    });
  }

  renderDayOfWeek = () => {
    return Array.from({ length: 8 }, (_, index) => {
      const clickFunc = () => this.onDayOfWeekClick(index);
      return (
        <div key={`dayOfWeek-${index}`} className={styles.dayOfWeek} onClick={clickFunc}>
          {getDayOfWeekLabelByValue(index)}
        </div>
      );
    });
  }

  renderContent = () => {
    return Array.from({ length: 8 }, (_, rowIndex) => {
      return (
        <div key={`cell-row${rowIndex}`} className={styles.row}>
          {
            rowIndex === 0 ?
              this.renderHeaders() :
              Array.from({ length: 24 }, (_, columnIndex) => {
                let className;
                let hours = this.state.dayPart[rowIndex.toString()];
                hours && hours.indexOf(columnIndex) >= 0 ? className = [styles.selected, styles.cell].join(' ') : className = styles.cell;
                const clickFunc = () => this.onCellClick(rowIndex, columnIndex);
                return (
                  <div key={`cell-${rowIndex}${columnIndex}`} className={className} onClick={clickFunc}/>
                );
              })
          }
        </div>
      );
    });
  }

  renderHeaders = () => {
    return Array.from({ length: 24 }, (_, index) => {
      const onClick = () => this.onHourColumnClick(index);
      return (
        <div key={`header-${index}`} className={[styles.cell, styles.header].join(' ')} onClick={onClick}>
          {index}
        </div>
      );
    });
  }

  get daypartOptions (): SelectOptions[] {
    return [
      // { label : "選一個時段", value : "0", sets:[ {days:[0,1,2,3,4,5,6], hours:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]}]},
      { label: i18n.t<string>('daypart.labels.allDay'), value: '1', sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t<string>('daypart.labels.morning'), value: '4', sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [6, 7, 8, 9, 10, 11] }] },
      { label: i18n.t<string>('daypart.labels.afternoon'), value: '2', sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [12, 13, 14, 15, 16, 17] }] },
      { label: i18n.t<string>('daypart.labels.evening'), value: '3', sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t<string>('daypart.labels.weekday'), value: '5', sets: [{ days: [1, 2, 3, 4, 5], hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t<string>('daypart.labels.weekend'), value: '6', sets: [{ days: [6, 7], hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t<string>('daypart.labels.conversionOptimize'), value: '7', sets: [{ days: [6, 7], hours: [0, 8, 9, 10, 19, 20, 21, 22, 23] }, { days: [1, 2, 3, 4, 5], hours: [0, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 23] }] },
      { label: i18n.t<string>('daypart.labels.midnightDisabled'), value: '8', sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [0, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t<string>('daypart.labels.mobileOptimize'), value: '9', sets: [{ days: [6, 7], hours: [0, 1, 6, 7, 8, 9, 10, 11, 13, 15, 16, 17, 19, 23] }, { days: [1, 2, 3, 4, 5], hours: [0, 1, 6, 7, 8, 9, 11, 12, 13, 14, 16, 17, 19, 20, 23] }] },
      { label: i18n.t<string>('daypart.labels.pcOptimize'), value: '10', sets: [{ days: [6, 7], hours: [0, 1, 8, 9, 10, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23] }, { days: [1, 2, 3, 4, 5], hours: [0, 1, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 23] }] }
    ];
  }

  render () {
    const touched = _.defaultTo(this.props.submitCount, 0) > 0 || this.props.touched[this.props.name];
    const error = this.props.errors[this.props.name];
    const hasError = error !== undefined && touched;
    const daypartOptionClass = cx(styles.daypartOptions, { error: hasError });
    const daypartClass = cx(styles.dayPart, { error: hasError });
    return (
      <div>
        <Select
          className={daypartOptionClass}
          options={this.daypartOptions}
          name='dayPart'
          value={this.findDaypartOptionByDaypart(this.state.dayPart)}
          placeholder={i18n.t<string>('daypart.labels.optionsPlaceholder')}
          onChange={this.onDayPartOptionsChanged}
        />
        <div className={daypartClass}>
          <div className={styles.dayOfWeekColumn}>
            {this.renderDayOfWeek()}
          </div>
          <div>
            <div className={styles.hint}>
              <label>{i18n.t<string>('daypart.labels.am')}</label>
              <label>{i18n.t<string>('daypart.labels.pm')}</label>
            </div>
            {this.renderContent()}
          </div>
        </div>
        {hasError && <Tips>{error}</Tips>}
      </div>
    );
  }
}
