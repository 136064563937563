import React from 'react';
import { AdvertiserListProps, AdvertiserListState } from './AdvertiserListModel';
import styles from './advertiserList.module.scss';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { SearchBar } from 'components/SearchBar';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';

export class AdvertiserList extends React.Component<AdvertiserListProps, AdvertiserListState> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.onUnmount(this.handler);
  }

  handleTableChange = (type, props) => {
    if (type === 'sort') {
      this.props.model.sortAdvertisers(props.sortField, props.sortOrder);
      return;
    }
    this.props.model.init(props.page);
  }

  handleOnSearch = (searchString) => {
    this.props.model.handleOnSearch(searchString);
  }

  render () {
    const model = this.props.model;
    if (!model.state.pagination) {
      return <LoadingIndicator/>;
    }

    return (
      <div className={styles.advertiserListContainer}>
        {model.state.loading && <LoadingIndicator/>}
        <div className={styles.operationArea}>
          <PermissionChecker permissionAware={hasFuncs(Permission.ADVERTISER_WRITE)}>
            <Link
              to={'/advertisers/new'}
              className={`btn btn-tertiary btn-sm`}
            >
              {i18n.t<string>('advertiserList.labels.newAdvertiser')}
            </Link>
          </PermissionChecker>
          <SearchBar
            model={{
              placeholder: i18n.t<string>('advertiserList.placeholders.searchbar'),
              search: this.handleOnSearch,
              defaultValue: model.searchString
            }}
          />
        </div>
        <div className={styles.advertiserList}>
          <StickableBootstrapTable
            stickFirstColumn
            stickLastColumn
            remote
            keyField={'id'}
            data={model.state.viewModelData}
            columns={model.columnSettings}
            pagination={{
              page: model.state.pagination.page,
              sizePerPage: 10,
              totalSize: model.state.pagination.totalCount
            }}
            defaultSorted={model.sortDescriptor}
            onTableChange={this.handleTableChange}
            noDataIndication={i18n.t<string>('advertiserList.labels.nodata')}
          />
        </div>
      </div>
    );
  }
}
