import i18next from 'i18next';

import en from './assets/locales/en.json';
import tw from './assets/locales/zh-tw.json';

import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: en
  },
  'zh-TW': {
    translation: tw
  }
};

i18next
  .use(initReactI18next)
  .init({
    resources,
    // lng: navigator.language || 'zh-TW',
    lng: 'zh-TW',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18next;
