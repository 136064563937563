import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { AdType, CampaignState } from 'core/rtbCampaign/RtbCampaign';
import formatters from './listFormatters';
import styles from './campaignDashboard.module.scss';
import { useCallAPI } from 'hooks/useCallAPI';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { CampaignDashboardManager, DefaultCampaignDashboardManager } from 'core/campaignDashboard/CampaignDashboardManager';
import i18n from 'i18n';
import { useCoreContext } from 'contexts/coreContext';
import { defaultTo, floor, get, values } from 'lodash';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { numberWithCommas } from 'utils/StringUtil';
import moment from 'moment';

export enum CampaignDashboardColumns {
  ID = 'campaignId',
  AGENCY = 'vendorNumberInt',
  PROGRESS = 'executeRate',
  OPTIMIZE = 'bidInfo',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  EDITBTNS = 'editBtns'
}

export enum CampaignDashboardExcelColumns {
  ORDER_ID = 'orderId',
  CAMPAIGN_ID = 'campaignId',
  CAMPAIGN_NAME = 'campaignName',
  ADTYPE = 'adType',
  AGENCY = 'agencyName',
  VENDOR_NUMBER = 'vendorNumber',
  BUDGET = 'budget',
  SPENT = 'spent',
  OPTIMIZE = 'optimize',
  BID_PRICE = 'bidPrice',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  EDITBTNS = 'editBtns'
}

const defaultCampaignManager: RtbCampaignManager = new DefaultRtbCampaignManager();
const defaultCampaignDashboardManager: CampaignDashboardManager = new DefaultCampaignDashboardManager();

const initSummary = [{
  label: i18n.t<string>('campaignDashboard.labels.orderCount'),
  value: 0
}, {
  label: i18n.t<string>('campaignDashboard.labels.orderTotalBudget'),
  value: 0
}, {
  label: i18n.t<string>('campaignDashboard.labels.orderTotalSpent'),
  value: 0
}, {
  label: i18n.t<string>('campaignDashboard.labels.campaignCount'),
  value: 0
}, {
  label: i18n.t<string>('campaignDashboard.labels.impres'),
  value: 0
}, {
  label: i18n.t<string>('campaignDashboard.labels.clicks'),
  value: 0
}, {
  label: i18n.t<string>('campaignDashboard.labels.ctr'),
  value: '0%'
}, {
  label: i18n.t<string>('campaignDashboard.labels.spent'),
  value: 0
}];

export const useCampaignDashboardModel = (
  campaignManager: RtbCampaignManager = defaultCampaignManager,
  campaignDashboardManager: CampaignDashboardManager = defaultCampaignDashboardManager
) => {

  const campaignListFilters = useRef<{
    dayFilter: number,
    adTypeFilter?: AdType[],
    campaignStatusFilter?: number,
    searchString: string
  } | null>(null);
  const summaryInfoFilters = useRef<{
    adTypeFilter?: AdType[]
  } | null>(null);
  const [campaignList, setCampaignList] = useState<any[]>([]);
  const [filteredList, setFilteredList] = useState<any[]>([]);
  const {
    loading,
    callAPIs
  } = useCallAPI();
  // const [campaignToChangeBidWeight, setCampaignToChangeBidWeight] = useState<number | string | undefined>();
  const [searchString, setSearchString] = useState('');
  const [todaySummary, setTodaySummary] = useState(initSummary);
  const [yesterdaySummary, setYesterdaySummary] = useState(initSummary);
  const [days, setDays] = useState<number>(30);
  const [timeOption, setTimeOption] = useState<number>(1);
  const [campaignStatus, setCampaignStatus] = useState<number>(CampaignState.ACTIVATE);
  const [adType, setAdType] = useState<AdType | ''>('');
  const core = useCoreContext();
  const currency = get(core, 'accountManager.localeMeta.currency', 'NTD');

  const adTypes = useMemo(() => adType === '' ? undefined : [adType], [adType]);

  const getCampaignData = useCallback((campaign) => {
    const isDailyBudgetCampaign = campaign.dailyTargetBudget > 0;
    const campaignProgress = isDailyBudgetCampaign
      ? campaignManager.getDailyBudgetCampaignProgressRate(campaign.budget, campaign.spent, campaign.startDate, campaign.endDate)
      : campaignManager.getCampaignProgressRate(campaign.budget, campaign.currencyRate, campaign.olapActualSpent, campaign.olapExpectedSpent);
    return {
      ...campaign,
      [CampaignDashboardColumns.PROGRESS]: campaignProgress.executeRate,
      predictRate: campaignProgress.predictRate,
      [CampaignDashboardColumns.AGENCY]: +campaign.vendorNumber,
      [CampaignDashboardColumns.OPTIMIZE]: campaign.bidPrice
    };
  }, [campaignManager]);

  const setUpFilteredList = useCallback((newCampaignList) => {
    const filteredList = newCampaignList.filter(campaign => {
      const nameInclude = campaign.campaignName.toLowerCase().includes(searchString.toLowerCase());
      const idInclude = campaign.campaignId.toString().includes(searchString);
      const agencyInclude = campaign.agencyName.includes(searchString);
      const vendorNumberInclude = campaign.vendorNumber.includes(searchString);
      return nameInclude || idInclude || agencyInclude || vendorNumberInclude;
    });
    setFilteredList(filteredList);
  }, [searchString]);

  const fetchCampaignList = useCallback((dayFilter, adTypeFilter, campaignStatusFilter, callback) => {
    callAPIs([() => campaignDashboardManager.getCampaignList(dayFilter, adTypeFilter, campaignStatusFilter)], (campaignList) => {
      const newCampaignList = campaignList.map(getCampaignData);
      setCampaignList(newCampaignList);
      setUpFilteredList(newCampaignList);
      callback();
    });
  }, [setUpFilteredList, campaignDashboardManager, callAPIs, getCampaignData]);

  const getSummaryInfo = useCallback((adTypeFilter) => {
    if (summaryInfoFilters.current && summaryInfoFilters.current.adTypeFilter === adTypeFilter) {
      return;
    }
    const columns = ['orderCount', 'orderTotalBudget', 'orderTotalSpent', 'campaignCount', 'impres', 'clicks', 'ctr', 'spent'];
    const formattersMap = {
      orderTotalSpent: value => formatPriceWithCurrency(currency, value),
      orderTotalBudget: value => formatPriceWithCurrency(currency, value),
      spent: value => `${currency} ${numberWithCommas(floor(value, 2))}`
    };
    const getSummaryViewData = summary => Object.keys(summary)
      .filter(key => key !== 'date')
      .sort((a, b) => columns.indexOf(a) - columns.indexOf(b))
      .map(key => ({
        label: i18n.t<string>(`campaignDashboard.labels.${key}`),
        value: formattersMap[key] ? formattersMap[key](summary[key]) : numberWithCommas(summary[key])
      }));

    callAPIs([() => campaignDashboardManager.getSummaryInfo(adTypeFilter)], (
      summary
    ) => {
      setYesterdaySummary(getSummaryViewData(summary[0]));
      setTodaySummary(getSummaryViewData(summary[1]));
      summaryInfoFilters.current = {
        adTypeFilter
      };
    });
  }, [campaignDashboardManager, currency, callAPIs]);

  const onSearchButtonClick = useCallback(() => {
    const dayFilter = days * timeOption;
    const adTypeFilter = adTypes;
    const campaignStatusFilter = campaignStatus === -1 ? undefined : campaignStatus;
    if (
      campaignListFilters.current &&
      campaignListFilters.current.dayFilter === dayFilter &&
      campaignListFilters.current.adTypeFilter === adTypeFilter &&
      campaignListFilters.current.campaignStatusFilter === campaignStatusFilter
    ) {
      if (campaignListFilters.current.searchString !== searchString) {
        setUpFilteredList(campaignList);
        campaignListFilters.current = {
          ...campaignListFilters.current,
          searchString
        };
      }
      return;
    }

    getSummaryInfo(adTypeFilter);

    fetchCampaignList(
      dayFilter,
      adTypeFilter,
      campaignStatusFilter,
      () => {
        campaignListFilters.current = {
          dayFilter,
          adTypeFilter,
          campaignStatusFilter,
          searchString
        };
      }
    );
  }, [days, timeOption, adTypes, campaignStatus, searchString, campaignList, fetchCampaignList, setUpFilteredList, getSummaryInfo]);

  useEffect(() => {
    if (!summaryInfoFilters.current) {
      getSummaryInfo(adTypes);
    }
  }, [adTypes, getSummaryInfo]);

  const columnDefinition = (columnName, sortable: boolean = true, customLabel?: string): ColumnDefinition => {
    const label = defaultTo(customLabel, `campaignDashboard.headers.${columnName}`);
    return {
      ...sortableColumn(columnName, label, sortable),
      classes: () => styles[columnName],
      headerClasses : () => styles[columnName]
    };
  };

  const columns = useMemo(() => [
    renderColumn(columnDefinition(CampaignDashboardColumns.ID), formatters.nameFormatter),
    renderColumn(columnDefinition(CampaignDashboardColumns.AGENCY), formatters.agencyFormatter),
    renderColumn(columnDefinition(CampaignDashboardColumns.PROGRESS), formatters.progressFormatter),
    renderColumn(columnDefinition(CampaignDashboardColumns.OPTIMIZE), formatters.optimizeFormatter),
    renderColumn(columnDefinition(CampaignDashboardColumns.START_DATE), value => moment(value).format('YYYY-MM-DD')),
    renderColumn(columnDefinition(CampaignDashboardColumns.END_DATE), value => moment(value).format('YYYY-MM-DD')),
    renderColumn({
      ...columnDefinition(CampaignDashboardColumns.EDITBTNS),
      text: '',
      sort: false
    }, formatters.floatingEditBtnsFormatter)
  ], []);

  const excelColumns = useMemo(() => [
    columnDefinition(CampaignDashboardExcelColumns.ORDER_ID),
    columnDefinition(CampaignDashboardExcelColumns.CAMPAIGN_ID, false, 'campaignDashboard.headers.campaignId_excel'),
    columnDefinition(CampaignDashboardExcelColumns.CAMPAIGN_NAME),
    columnDefinition(CampaignDashboardExcelColumns.AGENCY),
    columnDefinition(CampaignDashboardExcelColumns.VENDOR_NUMBER),
    columnDefinition(CampaignDashboardExcelColumns.START_DATE),
    columnDefinition(CampaignDashboardExcelColumns.END_DATE),
    columnDefinition(CampaignDashboardExcelColumns.BUDGET),
    columnDefinition(CampaignDashboardExcelColumns.SPENT),
    columnDefinition(CampaignDashboardExcelColumns.OPTIMIZE),
    columnDefinition(CampaignDashboardExcelColumns.BID_PRICE),
    columnDefinition(CampaignDashboardExcelColumns.ADTYPE)
  ], []);

  const excelColumnDecorater = useMemo(() => ({
    [CampaignDashboardExcelColumns.BUDGET]: value => formatPriceWithCurrency(currency, value),
    [CampaignDashboardExcelColumns.SPENT]: value => formatPriceWithCurrency(currency, value),
    [CampaignDashboardExcelColumns.OPTIMIZE]: value => campaignManager.getOptimizeDes(value),
    [CampaignDashboardExcelColumns.BID_PRICE]: value => formatPriceWithCurrency(currency, value),
    [CampaignDashboardExcelColumns.START_DATE]: value => moment(value).format('YYYY-MM-DD'),
    [CampaignDashboardExcelColumns.END_DATE]: value => moment(value).format('YYYY-MM-DD'),
    [CampaignDashboardExcelColumns.ADTYPE]: value => campaignManager.getCampaignTypeDes(value)
  }), [campaignManager, currency]);

  // const updateCampaignBidWeight = async (bidWeight) => {
  //   if (!campaignToChangeBidWeight) {
  //     return;
  //   }
  //   callAPIs([() => campaignManager.updateCampaignBidWeight(campaignToChangeBidWeight, bidWeight)], () => {
  //     setCampaignToChangeBidWeight(undefined);
  //     fetchCampaignList();
  //   });
  // };

  const download = useCallback(async () => {
    const getHeader = (setting) => {
      return i18n.t<string>(setting.text);
    };
    const metricsMapI18n = excelColumns.reduce((acc, setting) => {
      acc[setting.dataField] = getHeader(setting);
      return acc;
    }, {});
    const textDecorators = excelColumns.reduce((acc, setting) => {
      acc[setting.dataField] = excelColumnDecorater[setting.dataField];
      return acc;
    }, {});
    const metrics: string[] = values(metricsMapI18n);
    const excelDataList = filteredList.reduce((excelData, rowData) => {
      const newRowData = {};
      Object.keys(rowData).filter(key => !!metricsMapI18n[key]).forEach(key => {
        const textDecorator = textDecorators[key];
        newRowData[metricsMapI18n[key]] = textDecorator ? textDecorator(rowData[key]) : rowData[key];
      });
      excelData.push(newRowData);
      return excelData;
    }, []);
    const XLSXModule = await import(/* webpackChunkName: "xlsx" */ 'xlsx');
    const XLSX = XLSXModule.default;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelDataList, { header: metrics });
    XLSX.utils.book_append_sheet(wb, ws, 'report');
    XLSX.writeFile(wb, 'campaignDashboard.xlsx');
  }, [filteredList, excelColumns, excelColumnDecorater]);

  return {
    loading,
    days,
    searchString,
    timeOption,
    todaySummary,
    yesterdaySummary,
    columns,
    filteredList,
    // campaignToChangeBidWeight,
    campaignStatus,
    adType,
    searched: campaignListFilters.current !== null,
    download,
    setAdType,
    setCampaignStatus,
    setTimeOption,
    setSearchString,
    onSearchButtonClick,
    setDays
    // updateCampaignBidWeight,
    // hideManageBidWeightModal: () => setCampaignToChangeBidWeight(undefined)
  };
};
