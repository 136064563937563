import styles from './selectableColumn.module.scss';
import { ChangeEvent, PropsWithChildren, useCallback, useMemo } from 'react';
import { PermissionAware } from 'core';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';

export type SelectableColumnProps = {
  id: string | number;
  isSelected: boolean;
  selectPermissionAware?: PermissionAware;
  onSelect: (id: string | number, select: boolean) => void;
  enableSelect: (id: string | number) => boolean;
};

export const SelectableColumn: React.FC<SelectableColumnProps & PropsWithChildren> = ({
  id,
  isSelected,
  selectPermissionAware,
  children,
  onSelect,
  enableSelect
}) => {

  const onCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onSelect && onSelect(id, e.target.checked);
  }, [id, onSelect]);

  const renderCheckbox = useCallback(() => (
    <>
      <input
        type='checkbox'
        checked={isSelected}
        onChange={onCheckboxChange}
        id={`checkbox-input-${id}`}
      />
      <label htmlFor={`checkbox-input-${id}`} />
    </>
  ), [id, isSelected, onCheckboxChange]);

  const showCheckBox = useMemo(() => {
    return enableSelect(id);
  }, [enableSelect, id]);

  return (
    <div className={`${styles.cell}`}>
      <div className={styles.selectCheckBox}>
        {
          showCheckBox ?
            selectPermissionAware ?
              <PermissionChecker permissionAware={selectPermissionAware}>
                {renderCheckbox()}
              </PermissionChecker> :
              renderCheckbox() :
            <div />
        }
      </div>
      {children}
    </div>
  );
};
