import React from 'react';
import classnames from 'classnames/bind';
import styles from './tagsField.module.scss';
import NativeTags from 'components/Tags/Tags';
import { FormikFieldProps, withFormikField } from 'hoc/withFormikField';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';
import { SelectOptions } from 'components/commonType';

const cx = classnames.bind(styles);

interface TagsProps {
  className?: string;
  onChange: (...args: any[]) => void;
  error?: any;
  value: any;
  placeholder?: string;
  addOnBlur?: boolean;
  suggestions?: SelectOptions[];
}

export interface FormikTagsProps extends FormikFieldProps, Omit<TagsProps, 'value' | 'onChange'> {}
export interface FormikTagsWithFieldLayoutProps extends FormikFieldProps, Omit<FieldLayoutProps, 'name'>, Omit<TagsProps, 'value' | 'onChange'> {}
export type FormikTagsFieldProps = ({ withFieldLayout?: true } & FormikTagsWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikTagsProps);

const TagsContent: React.FC<TagsProps> = ({
  value,
  error,
  addOnBlur,
  className,
  placeholder,
  suggestions,
  onChange
}) => {

  const hasError = error !== undefined;
  const classNameWithError = cx(className, {
    error: hasError
  });

  return (
    <NativeTags
      className={classNameWithError}
      value={value}
      addOnBlur={addOnBlur}
      onChange={onChange}
      placeholder={placeholder}
      suggestions={suggestions}
    />
  );
};

const MemorizedTagsContent = React.memo(TagsContent);
const FormikTags = withFormikField<FormikTagsProps>(MemorizedTagsContent);
const FormikTagsWithFieldLayout = withFormikField<FormikTagsProps>(withFieldLayout(TagsContent));
export const FormikTagsField: React.FC<FormikTagsFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikTags {...props} />;
  } else {
    return <FormikTagsWithFieldLayout {...props} />;
  }
};
