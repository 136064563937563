import React, { RefObject } from 'react';
import { CreativeSetupFlowDataContext } from '../CreativeSetupFlowDataContext';
import { Button } from 'react-bootstrap';
import i18n from 'i18next';
import { CreativeSetupStepProps, CreativeSetupStepState } from './CreativeSetupStepModel';
import styles from './creativeSetupStep.module.scss';
import { CreativeBasicForm } from './SubSteps/CreativeBasicForm';
import { Form, Formik } from 'formik';
import { ErrorHandler } from 'components/form/FormErrorHandler/FormErrorHandler';
export class CreativeSetUpStep extends React.Component<CreativeSetupStepProps, CreativeSetupStepState> {

  handler: any;
  mainDomRef: RefObject<any>;

  constructor (props: any) {
    super(props);
    this.mainDomRef = React.createRef();
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  render () {
    const model = this.props.model;
    return (
      <CreativeSetupFlowDataContext.Consumer>
        {(creativeContext) => {
          const creativeData = creativeContext.creative;
          const handleSubmit = (values) => {
            model.goNext(() => {
              creativeContext.setCreative({
                basic: values
              });
            });
          };
          const basicFormModel = model.getBasicFormModel(
            creativeData.basic,
            creativeContext.supportedCreativeType,
            creativeContext.getFormContentModelOfType
          );
          return (
            <div className={styles.setupStep} ref={this.mainDomRef}>
              <Formik
                initialValues={basicFormModel.initCreative}
                enableReinitialize
                onSubmit={handleSubmit}
                validateOnBlur={false}
              >
                {formikProps => {
                  const onSubmitButtonClick = () => model.validate();
                  basicFormModel.setFormikProps(formikProps);
                  return (
                    <Form>
                      <ErrorHandler
                        parentRef={this.mainDomRef}
                        isSubmitting={formikProps.isSubmitting}
                        submitCount={formikProps.submitCount}
                      />
                      <CreativeBasicForm
                        model={basicFormModel}
                      />
                      <div className={styles.buttonArea}>
                        <Button
                          variant='primary'
                          size='sm'
                          type='submit'
                          onClick={onSubmitButtonClick}
                        >
                          {i18n.t<string>('campaign.buttons.completeAndCheck')}
                        </Button>
                        {model.goLast &&
                          <Button variant='secondary' size='sm' onClick={model.goLast}>
                            {i18n.t<string>('campaign.buttons.back')}
                          </Button>
                        }
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          );
        }}
      </CreativeSetupFlowDataContext.Consumer>
    );
  }
}
