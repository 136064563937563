import fileDownload from 'js-file-download';
import client from './RestClient';
import { AxiosInstance } from 'axios';

export interface SupportWebService {
  downloadSupportFile (filePath: string, fileName: string): Promise<void>;
}

export class RestfulSupportWebService implements SupportWebService {
  restClient: AxiosInstance;
  defaultFileDownload: any;

  constructor (
    restClient: AxiosInstance = client,
    defaultFileDownload: any = fileDownload
    ) {
    this.restClient = restClient;
    this.defaultFileDownload = defaultFileDownload;
  }

  async downloadSupportFile (filePath: string, fileName: string): Promise<void> {
    const response = await this.restClient.get(`/v2/support/download/${filePath}`, { responseType: 'blob' });
    this.defaultFileDownload(response.data, `${fileName}.pdf`);
  }
}
