import React, { useRef } from 'react';
import { ProductFilter } from './ProductFilter';
import { Product } from 'core/product/Product';
import { ProductListColumns, useProductListModel } from './ProductListModel';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import i18n from 'i18n';
import styles from './productList.module.scss';
import { Pagination } from 'core/pagination/Pagination';
import { TableChangeHandler } from 'react-bootstrap-table-next';
import { ProductListOverview } from './ProductListOverview';
import { SearchBar } from 'components/SearchBar';

export type ProductListProps = {
  filterSet: ProductFilter;
  productList: Product[];
  allProducts?: Product[];
  pagination: Pagination;
  editActions?: {
    label: string,
    onClick: (selectedProducts: string[], cb?: () => void) => void | Promise<void>
  }[];
  handleCloseModal?: () => void;
  onListChange?: TableChangeHandler<Product>;
  onSearchStringChange: (searchString: string) => void;
};

export const ProductList: React.FC<ProductListProps> = ({
  filterSet,
  pagination,
  productList,
  editActions,
  allProducts = productList,
  onListChange,
  onSearchStringChange
}) => {

  const searchBarRef = useRef<any>(null);
  const {
    state,
    columns,
    selectedProducts,
    availbleProducts,
    // onProductListTypeChange,
    handleRemoveSelect,
    handleRemoveProduct
  } = useProductListModel(filterSet, productList, allProducts);

  const renderOverviewBar = () => {
    const renderItems = () => {
      if (!editActions) {
        return;
      }

      return editActions.map(editAction => {
        const onClick = event => {
          event && event.stopPropagation();
          editAction.onClick(selectedProducts, handleRemoveSelect);
        };
        return (
          <div key={editAction.label} onClick={onClick}>
            {i18n.t<string>(editAction.label)}
          </div>
        );
      });
    };
    const productToOverview = availbleProducts.filter(product => selectedProducts.includes(product.productId.toString()));
    return (
      <ProductListOverview
        products={productToOverview}
        handleRemoveAll={handleRemoveSelect}
        handleRemoveProduct={handleRemoveProduct}
      >
        {renderItems()}
      </ProductListOverview>
    );
  };

  return (
    <div className={styles.productListArea}>
      <div className={styles.productListTitleBar}>
        {renderOverviewBar()}
      </div>
      <div className={styles.searchBarArea}>
        <SearchBar
          ref={searchBarRef}
          disabled={filterSet.advertiser === undefined}
          model={{
            placeholder: i18n.t<string>('productHome.placeholders.searchbar'),
            search: onSearchStringChange,
            defaultValue: filterSet.search
          }}
        />
      </div>
      {productList.length === 0 && <div className={styles.seperationLine}/>}
      <div className={styles.contentArea}>
        <StickableBootstrapTable
          key={state.selectedProductListType}
          stickFirstColumn
          scrollByElementControl={false}
          remote={onListChange ? true : false}
          keyField={ProductListColumns.ID}
          data={productList}
          columns={columns}
          noDataIndication={filterSet.advertiser ? i18n.t<string>('productList.labels.noData') : i18n.t<string>('productList.labels.needAdvertiser')}
          defaultSorted={[{
            dataField: ProductListColumns.ID,
            order: 'desc'
          }]}
          pagination={{
            page: pagination.page,
            sizePerPage: 25,
            totalSize: pagination.totalCount
          }}
          onTableChange={onListChange}
        />
      </div>
    </div>
  );
};
