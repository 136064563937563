import { Pagination } from 'core/pagination/Pagination';

export type Pmp = {
  id: number,
  name: string,
  startTime: string,
  endTime: string,
  pmpType: PmpType,
  budget: number,
  adAgencyId: number[],
  space: string[],
  dayPart: { [key: string]: string[] | number[] },
  targetType?: TargetType,
  targetQuantity?: number,
  dealId: string,
  status: PmpStatus,
  adminStatus: PmpAdminStatus,
  campaignId?: number
};

export type PmpsWithPagination = {
  pagination: Pagination,
  pmps: Pmp[]
};

export enum PmpType {
  PROGRAMMATIC_GUARANTEED = 'PROGRAMMATIC_GUARANTEED',
  SPONSORSHIP = 'SPONSORSHIP'
}

export enum TargetType {
  IMPRESSION = 'IMPRESSION',
  CLICK = 'CLICK'
}

export enum PmpStatus {
  ACTIVE = 1,
  INACTIVE = 2
}

export enum PmpAdminStatus {
  NOT_BINDING = 1,
  BINDING = 2,
  STOP = 3,
  FINISH = 4,
  BINDING_PARTIAL_SUSPEND = 5,
  BINDING_ALL_SUSPEND = 6
}

export type PmpFormType = Omit<Pmp, 'id' | 'dealId' | 'status' | 'adminStatus'>;
