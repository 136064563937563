import React from 'react';
import i18next from 'i18n';
import styles from './addon.module.scss';
import { InfoDisplaySection } from 'components/InfoDisplaySection/InfoDisplaySection';
import { CreativeType } from 'core/creative/Creative';
import _ from 'lodash';

type AddonProps = {
  AgencyDetail: {};
};

function addonProps (AgencyDetail, addonGroup, addonItem): any {
  if (addonItem === 'fixedExchangeRate') {
    return i18next.t<string>('agency.addonItem.company.ExchangeRate') + ' ' + AgencyDetail['property']['addonProps']['fixedExchangeRate'];
  }
}

function isAddonAllFalse (addonGroup, excludeList) {
  let result = true;
  if (!addonGroup) {
    return result;
  }
  Object.keys(addonGroup).forEach(function (addonItem) {
    if (addonGroup[addonItem] === true &&
      !excludeList.includes(addonItem)
    ) {
      result = false;
    }
  });

  return result;
}

const Addon: React.FC<AddonProps> = ({
  AgencyDetail
}) => {
  const keylist = ['channel', 'creatives', 'company', 'campaign', 'campaignLimitation'];
  const excludeList = [
    'bidPriceConstraint',
    'campaignDashboard',
    'deliveryAmountConstraint',
    // temporary exclude since the feature is on but no need to show
    'showOutComeOnRebateReport',
    'provideReportToWorkflow',
    'age',
    'gender'
  ];

  const addonData = keylist.map((addonGroup) => {
    if (isAddonAllFalse(AgencyDetail['addonFeatures'][addonGroup], excludeList)) {
      return {
        sectionTitle: i18next.t<string>('agency.addonGroup.' + addonGroup),
        sectionData: []
      };
    } else {
      return {
        sectionTitle: i18next.t<string>('agency.addonGroup.' + addonGroup),
        sectionData: _.compact(Object.keys(AgencyDetail['addonFeatures'][addonGroup])
          .filter((addonItem) =>
            AgencyDetail['addonFeatures'][addonGroup][addonItem] === true &&
            !excludeList.includes(addonItem))
          .map(addonItem => {
            let i18nkey = addonItem;
            if (_.startsWith(addonItem, 'option_')) {
              const creativeTypeKey = CreativeType[+(addonItem.replace('option_', ''))];
              if (!creativeTypeKey) {
                return undefined;
              }
              i18nkey = `option_${_.camelCase(creativeTypeKey)}`;
            }
            return {
              label: i18next.t<string>('agency.addonItem.' + addonGroup + '.' + i18nkey),
              value: addonProps(AgencyDetail, addonGroup, addonItem)
            };
          })
        )
      };
    }
  });
  return (
    <div className={styles.container}>
      {
        addonData.map((addonGroup) => {
          return (
            <InfoDisplaySection key={addonGroup.sectionTitle} data={addonGroup.sectionData} title={addonGroup.sectionTitle} />
          );
        })
      }
    </div>
  );
};

export default Addon;
