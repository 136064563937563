import React from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';

export class CreativeRedirect extends React.PureComponent<{creativeId: string | number, action?: string, creativeManager?: CreativeManager}, {redirectPath: string | undefined}> {

  constructor (props) {
    super(props);
    this.state = {
      redirectPath: undefined
    };
  }

  componentDidMount () {
    this.getCreativeData();
  }

  async getCreativeData () {
    const creativeManager = this.props.creativeManager ? this.props.creativeManager : new DefaultCreativeManager();
    const creative = await creativeManager.getCreative(+(this.props.creativeId.toString()));
    creative.basic.advertiserId && SessionStorageHelper.setItem(SessionStorageItemKeys.ADVERTISER, creative.basic.advertiserId);
    creative.basic.agencyId && SessionStorageHelper.setItem(SessionStorageItemKeys.AGENCY, creative.basic.agencyId);
    this.setState({
      redirectPath: `/creatives?creativeSearch=${creative.basic.creativeId}`
    });
  }

  render () {
    if (this.state.redirectPath) {
      return <Redirect to={this.state.redirectPath} />;
    }
    return (
      <LoadingIndicator />
    );
  }
}
