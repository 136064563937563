import React from 'react';
import styles from './retailRMN.module.scss';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router';
import { RetailRMNProps } from './RetailRMNModel';
import { Products } from './Products/Products';

import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { RoleNames } from 'core';
import { notRoles } from 'core/permission/PermissionDSL';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';

const ProductsView = withErrorBoundary(ERROR_CODE.PRODUCT_CATEGORY, Products);
export class RetailRMN extends React.Component<RouteComponentProps<{}> & RetailRMNProps> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  renderProducts = () => {

    return (
      <div className={styles.container}>
        <ProductsView />
      </div>
    );
  }

  redirectToRoot = () => {
    return <Redirect to={this.props.mainPagePath} />;
  }

  render () {
    const { sysAdmin, adsReport } = RoleNames;

    return (
      <div className={styles.retailRMN}>
        <Switch>
          <RouteWithPermission
            path='/products'
            permissionAware={notRoles(sysAdmin, adsReport)}
            redirectPath={`/products/${ROUTE_PATH.ERROR403}`}
            render={this.renderProducts}
          />
          <Route render={this.redirectToRoot} />
        </Switch>
      </div>
    );
  }
}
