import { RestfulStoredValueWebService, StoredValueWebService } from 'ws/StoredValueWebService';
import { StoredValueAction, StoredValueAgencyRecordsWithPagination, StoredValueLog, StoredValueSummary } from './StoredValueLog';
import { defaultTo } from 'lodash';

export const FREEZE_ACTIONS = [
  StoredValueAction.FREEZE_BUDGET,
  StoredValueAction.UNFREEZE_BUDGET,
  StoredValueAction.REMOVE_FREEZE_BUDGET
];

export interface StoredValueManager {
  getStoredValueAgencyRecords (page: number, sortField: string, sortOrder: string, search: string): Promise<StoredValueAgencyRecordsWithPagination>;
  getStoredValueLogsByAgencyId (agencyId: number): Promise<StoredValueLog[]>;
  getStoredValueSummary (storedValueLogs: StoredValueLog[]): StoredValueSummary[];
  editStoredValue (agencyId: number, amount: number, action: string, description: string, comment?: string): Promise<void>;
  getRemainingStoredValue (agencyId: number): Promise<number | undefined>;
}

export class DefaultStoredValueManager implements StoredValueManager {
  webService: StoredValueWebService;

  constructor (webService: StoredValueWebService = new RestfulStoredValueWebService()) {
    this.webService = webService;
  }

  async getStoredValueAgencyRecords (page: number, sortField: string, sortOrder: string, search: string): Promise<StoredValueAgencyRecordsWithPagination> {
    return this.webService.getStoredValueAgencyRecords(page, sortField, sortOrder, search);
  }

  async getStoredValueLogsByAgencyId (agencyId: number): Promise<StoredValueLog[]> {
    const logs = await this.webService.getStoredValueLogsByAgencyId(agencyId);
    this.addRemianValueToLogs(logs);
    return logs;
  }

  getStoredValueSummary (storedValueLogs: StoredValueLog[]): StoredValueSummary[] {
    const results = storedValueLogs.reduce<{[id: string]: StoredValueSummary}>((acc, log) => {
      const { adAgencyId, adAgencyName, vendorNumber, currency, amount, action } = log;
      let summaryOfAgency = defaultTo(acc[adAgencyId], {
        adAgencyId,
        adAgencyName,
        vendorNumber,
        currency,
        stored: 0,
        used: 0,
        remain: 0,
        freezed: 0,
        logs: []
      });
      switch (action) {
        case StoredValueAction.RECHARGE:
        case StoredValueAction.EDIT_BUDGET:
          summaryOfAgency.stored += amount;
          summaryOfAgency.remain += amount;
          break;
        case StoredValueAction.CREATE_ORDER:
        case StoredValueAction.EDIT_ORDER:
        case StoredValueAction.SETTLE_ORDER:
          summaryOfAgency.remain += amount;
          summaryOfAgency.used -= amount;
          break;
        case StoredValueAction.FREEZE_BUDGET:
          summaryOfAgency.remain -= amount;
          summaryOfAgency.freezed += amount;
          break;
        case StoredValueAction.UNFREEZE_BUDGET:
          summaryOfAgency.remain += amount;
          summaryOfAgency.freezed -= amount;
          break;
        case StoredValueAction.REMOVE_FREEZE_BUDGET:
          summaryOfAgency.freezed -= amount;
          break;
        default:
          break;
      }
      acc[adAgencyId] = summaryOfAgency;
      acc[adAgencyId].logs.push(log);
      return acc;
    }, {});
    return Object.values(results);
  }

  async editStoredValue (agencyId: number, amount: number, action: string, description: string, comment?: string): Promise<void> {
    return this.webService.editStoredValue(agencyId, amount, action, description, comment);
  }

  async getRemainingStoredValue (agencyId: number) {
    const storedValueLogs = await this.getStoredValueLogsByAgencyId(agencyId);
    if (storedValueLogs.length === 0) return undefined;
    const storedValueSummary = this.getStoredValueSummary(storedValueLogs);
    return storedValueSummary[0].remain;
  }

  private addRemianValueToLogs (logs: StoredValueLog[]) {
    const sortedLogs = logs.sort((a, b) => a.storedValueId - b.storedValueId);
    let remainValueMap = {};
    sortedLogs.forEach(log => {
      const remainValue = defaultTo(remainValueMap[log.adAgencyId], 0);
      switch (log.action) {
        case StoredValueAction.FREEZE_BUDGET:
          log.remain = remainValue - log.amount;
          break;
        case StoredValueAction.REMOVE_FREEZE_BUDGET:
          log.remain = remainValue;
          break;
        case StoredValueAction.UNFREEZE_BUDGET:
        default:
          log.remain = remainValue + log.amount;
          break;
      }
      remainValueMap[log.adAgencyId] = log.remain;
    });
  }
}
