import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import { CreativeOverviewState, BindCreativeState, CreativeSummaryState, ModifyCreativeActivationState } from './CreativeManagementState';
import { ModalStateFactory, ModalState } from 'containers/Common/ModalStateFactory';
import { CreativeOfCampaign } from 'core/creative/Creative';
import { AddonFeatureManager } from 'core';
import * as H from 'history';
import { RtbBindCreativeStateContentModel } from './BindCreativeStateContentModel';
import { RtbCreativeSummaryStateContentModel } from './CreativeSummaryStateContentModel';
import { RtbModifyCreativeActivationStateContentModel } from './ModifyCreativeActivationStateContentModel';
import { RtbCreativeOverviewStateContentModel } from './CreativeOverviewStateContentModel';
import { CampaignBindInfo } from 'core/binding/CampaignBindInfo';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import _ from 'lodash';
import { CampaignCreativeData } from './CampaignCreativeData';

export enum CreativeManagementAction {
  BIND = 'bind',
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  MANAGE = 'manage'
}

export abstract class CreativeManagementStateFactory implements ModalStateFactory {
  manager: CreativeManager;
  campaigns: Array<CampaignBindInfo>;
  editAction: string;
  advertiserId: number;
  addonFeatureManager: AddonFeatureManager;
  history: H.History;
  orderNumber: string;
  channel: L1ObjectChannel;

  constructor (
    campaigns: Array<CampaignBindInfo>,
    advertiserId: number,
    editAction: string,
    addonFeatureManager: AddonFeatureManager,
    orderNumber: string,
    history: H.History,
    protected l1Object: L1Object,
    manager: CreativeManager = new DefaultCreativeManager()
  ) {
    this.manager = manager;
    this.addonFeatureManager = addonFeatureManager;
    this.editAction = editAction;
    this.advertiserId = advertiserId;
    this.campaigns = campaigns;
    this.history = history;
    this.orderNumber = orderNumber;
    this.channel = _.get(l1Object, 'channel', L1ObjectChannel.RTB);
    if (this.campaigns.length >= 1 && this.editAction === 'manage') {
      this.campaigns = [this.campaigns[0]];
    }
  }

  async initStates (goModalStateFn: (state: ModalState, beforeGoState?: () => Promise<void>) => void, closeModalFn: (dirty) => void) {
    const creativeData = await this.getCreativeData();
    switch (this.editAction) {
      case CreativeManagementAction.BIND:
        return this.initBindStates(creativeData, goModalStateFn, closeModalFn);
      case CreativeManagementAction.ACTIVATE:
        return this.initModifyActivationStates(creativeData, goModalStateFn, closeModalFn, true);
      case CreativeManagementAction.DEACTIVATE:
        return this.initModifyActivationStates(creativeData, goModalStateFn, closeModalFn, false);
      default:
        return this.initManageStates(creativeData, goModalStateFn, closeModalFn);
    }
  }

  async getCreativeData () {
    let creativeData: CampaignCreativeData[] = [];
    try {
      await Promise.all(
        this.campaigns.map(async campaign => {
          try {
            const creatives = await this.getCreatives(campaign.id);
            creativeData.push({
              id: campaign.id,
              name: campaign.name,
              startDate: campaign.startDate,
              endDate: campaign.endDate,
              creatives: creatives
            });
          } catch (e) {
            creativeData.push({
              id: campaign.id,
              name: campaign.name,
              startDate: campaign.startDate,
              endDate: campaign.endDate,
              creatives: []
            });
          }
        })
      );
    } catch (error) {
      console.log('cannot get creative datas of campaigns', error);
    }
    creativeData = creativeData.sort((campaign1, campaign2) => {
      return +campaign2.id - +campaign1.id;
    });
    return creativeData;
  }

  abstract getCreatives (l2ChannelId: number | string): Promise<CreativeOfCampaign[]>;

  abstract getOverviewStateContentModel (creatives, l3ObjectStateFn, refreshCreative, closeModalFn: (dirty) => void);

  abstract getBindCreativeStateContentModel (forbidCreatives: number[]);

  abstract getModifyCreativeActivationStateContentModel (creativeData: CampaignCreativeData[], activate);

  abstract getCreativeSummaryStateContentModel (creativeData: CampaignCreativeData[]);

  async initManageStates (creativeData: CampaignCreativeData[], goModalStateFn: (state: ModalState, beforeGoState?: () => Promise<void>) => void, closeModalFn: (dirty) => void) {
    const refreshCreative = async () => {
      const newCreativeData = await this.getCreativeData();
      return newCreativeData[0].creatives;
    };
    let creatives: Array<CreativeOfCampaign> = creativeData[0].creatives;
    let bindCreativeState = new BindCreativeState(
      this.campaigns,
      this.getBindCreativeStateContentModel(creatives.map(creative => creative.id))
    );
    let creativeOverviewState = new CreativeOverviewState(
      this.campaigns,
      this.getOverviewStateContentModel(
        creatives,
        () => {
          bindCreativeState.contentModel.reset();
          goModalStateFn(bindCreativeState);
        },
        refreshCreative,
        closeModalFn)
    );

    let creativeSummaryState = new CreativeSummaryState(
      this.campaigns,
      this.getCreativeSummaryStateContentModel(creativeData)
    );

    creativeOverviewState.setCloseFn(closeModalFn);
    bindCreativeState.setNextFn(() => goModalStateFn(creativeSummaryState));
    bindCreativeState.setBackFn(() => goModalStateFn(creativeOverviewState));
    creativeSummaryState.setBackFn(() => goModalStateFn(bindCreativeState));
    creativeSummaryState.setCloseFn(closeModalFn);
    return creativeOverviewState;
  }

  async initBindStates (creativeData: CampaignCreativeData[], goModalStateFn: (state: ModalState, beforeGoState?: () => Promise<void>) => void, closeModalFn: (dirty) => void) {
    const forbidCreatives = creativeData.length === 1 ? creativeData[0].creatives.map(creative => creative.id) : [];
    let bindCreativeState = new BindCreativeState(
      this.campaigns,
      this.getBindCreativeStateContentModel(forbidCreatives)
    );
    let creativeSummaryState = new CreativeSummaryState(
      this.campaigns,
      this.getCreativeSummaryStateContentModel(creativeData)
    );
    bindCreativeState.setNextFn(() => goModalStateFn(creativeSummaryState));
    bindCreativeState.setCloseFn(closeModalFn);
    creativeSummaryState.setBackFn(() => goModalStateFn(bindCreativeState));
    creativeSummaryState.setCloseFn(closeModalFn);
    return bindCreativeState;
  }

  async initModifyActivationStates (creativeData: CampaignCreativeData[], goModalStateFn: (state: ModalState, beforeGoState?: () => Promise<void>) => void, closeModalFn: (dirty) => void, activate: boolean) {
    let modifyCreativeActivationState = new ModifyCreativeActivationState(
      this.campaigns,
      this.getModifyCreativeActivationStateContentModel(creativeData, activate)
    );
    let creativeSummaryState = new CreativeSummaryState(
      this.campaigns,
      this.getCreativeSummaryStateContentModel(creativeData)
    );

    modifyCreativeActivationState.setNextFn(() => goModalStateFn(creativeSummaryState));
    modifyCreativeActivationState.setCloseFn(closeModalFn);
    creativeSummaryState.setBackFn(() => goModalStateFn(modifyCreativeActivationState));
    creativeSummaryState.setCloseFn(closeModalFn);
    return modifyCreativeActivationState;
  }

  goCreateCreativeFn = () => {
    const campaignParams = `campaignId=${this.campaigns[0].id}`;
    let path = `/creatives/new?${campaignParams}&orderNumber=${this.orderNumber}`;
    if (this.l1Object) {
      path = `${path}&l1ObjectId=${this.l1Object.l1ObjectId}&channel=${this.channel}`;
    }
    this.history.push(path);
  }
}

export class RtbCreativeManagementStateFactory extends CreativeManagementStateFactory {

  async getCreatives (l2ChannelId) {
    return this.manager.getCreativesByCampaignId(l2ChannelId, false);
  }

  getOverviewStateContentModel (creatives, l3ObjectStateFn, refreshCreative, closeModalFn: (dirty) => void) {
    return new RtbCreativeOverviewStateContentModel(
      this.l1Object,
      this.campaigns[0],
      creatives,
      this.orderNumber,
      l3ObjectStateFn,
      this.goCreateCreativeFn,
      refreshCreative,
      closeModalFn
    );
  }

  getBindCreativeStateContentModel (forbidCreatives: number[]) {
    return new RtbBindCreativeStateContentModel(
      this.campaigns,
      this.advertiserId,
      forbidCreatives,
      this.manager
    );
  }

  getModifyCreativeActivationStateContentModel (creativeData: CampaignCreativeData[], activate) {
    return new RtbModifyCreativeActivationStateContentModel(creativeData, activate, this.l1Object);
  }

  getCreativeSummaryStateContentModel (creativeData: CampaignCreativeData[]) {
    return new RtbCreativeSummaryStateContentModel(creativeData);
  }
}
