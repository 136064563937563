import _ from 'lodash';

import client from './RestClient';
import { SelectOptions } from 'components/commonType';
import { AxiosInstance } from 'axios';
import { SuggestKeywords } from 'core/product/Product';
export interface AdRequestSourceWebService {
  getOS (): Promise<SelectOptions[]>;
  getSpaceTypes (): Promise<SelectOptions[]>;
  getDevice (): Promise<SelectOptions[]>;
  getProductHotKeywords (goodsCodes: string[]): Promise<SuggestKeywords[]>;
  getTrendingKeywords (): Promise<string[]>;
}

export class RestfulAdRequestSourceWebService
  implements AdRequestSourceWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  pickRecords (response: any) {
    return _.sortBy(_.get(response, 'records', []), ['label']).map(record => ({
      ...record,
      options: record.options ? _.sortBy(record.options, ['label']) : record.options
    }));
  }
  async getOS (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/os'
    );
    return this.pickRecords(response.data);
  }
  async getSpaceTypes (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/space-types'
    );
    return this.pickRecords(response.data);
  }

  async getDevice (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      `/v2/ad-request-source/device-types`
    );
    return this.pickRecords(response.data);
  }

  async getProductHotKeywords (goodsCodes: string[]): Promise<SuggestKeywords[]> {
    const response = await this.restClient.post(
      '/pchome/goodsHotKeywords',
      goodsCodes
    );
    return _.defaultTo(response.data.records, []).map(record => {
      return {
        suggestKeyword: _.get(record, 'suggestKeyword'),
        bidPrice: _.get(record, 'bidPrice', 0),
        keywordSearchVolume: _.get(record, 'keywordSearchVolume', 0),
        last7DayGrowthRate: _.get(record, 'last7DayGrowthRate', 0),
        last30DayGrowthRate: _.get(record, 'last30DayGrowthRate', 0),
        keywordAdCompetition: _.get(record, 'keywordAdCompetition', 0)
      };
    });
  }

  async getTrendingKeywords (): Promise<string[]> {
    const response = await this.restClient.get('/pchome/trendingKeywords');
    return _.defaultTo(response.data.records, []);
  }
}
