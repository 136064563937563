import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { DefaultRtbCampaignBasicFormModel, RtbCampaignBasicFormModel, RtbCampaignBasicFormModelConstructorParams } from './RtbCampaignBasicFormModel';

export interface PmpFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractPmpFormModel extends DefaultRtbCampaignBasicFormModel implements PmpFormModel {

  get campaignAdType (): AdType {
    return AdType.PMP;
  }

  get needSetupAgeGender (): boolean {
    return false;
  }

  get showDeliverType (): boolean {
    return false;
  }

  get showTags (): boolean {
    return false;
  }

  get showOptimizeSection (): boolean {
    return false;
  }

  get limitationOperates (): {
    need: string[],
    notNeed?: string[],
    other?: string[]
  } {
    return {
      need: [],
      notNeed: undefined,
      other: undefined
    };
  }
}

export class EditPmpFormModel extends AbstractPmpFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
}

export class CreatePmpFormModel extends AbstractPmpFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
}
