import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModelInterface';
import { Creative, CreativeType } from 'core/creative/Creative';
import { get } from 'lodash';

export type CreativeFormBasicData = {
  creativeId?: number,
  tenmaxCategory: string,
  creativeType: CreativeType,
  name?: string,
  landingPageUrl?: string,
  bannerUrl?: string,
  advertiserId?: number,
  typeProperties?: any,
  medias?: any,
  agencyId?: number
};

export type CreativeFormData = {
  basic: CreativeFormBasicData;
};

export interface FormContentModel {
  readonly needBannerUrl: boolean;
  readonly defaultCreativeName?: string;
  getFormContent: () => any;
  getInitTypeProperties: () => any;
  getFormModelData: (creative: Creative) => CreativeFormData;
}

export abstract class AbstractFormContentModel implements FormContentModel {

  needBannerUrl: boolean = true;

  constructor (
    public flowPageModel: CreativeSetupFlowPageModel
  ) {}

  abstract getFormContent (): any;
  abstract getInitTypeProperties (): any;
  abstract getFormModelData (creative: Creative): CreativeFormData;

  get defaultCreativeName (): string | undefined {
    return get(this.flowPageModel, 'campaign.basic.name');
  }
}
