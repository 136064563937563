import { ObjectFitContainer } from 'components/ObjectFitContainer/ObjectFitContainer';
import Progress from 'components/Progress/Progress';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18n';
import styles from './campaignDashboard.module.scss';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faChartArea, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
// import { RoleNames } from 'core';
import moment from 'moment';
import { floor, isNumber } from 'lodash';
import { numberWithCommas } from 'utils/StringUtil';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { BidStrategy } from 'core/l2Object/L2Object';
import { formatPriceAny } from 'helper/CurrencyHelper';
// import { ADDONFEATURE } from 'core/agency/AddonFeature';

const getReportLink = (campaign) => {
  const timeFormat = 'YYYY-MM-DD HH:mm:ss';
  const from = encodeURIComponent(moment(campaign.startDate).startOf('day').format(timeFormat));
  const to = encodeURIComponent(moment(campaign.endDate).endOf('day').format(timeFormat));
  const dimension = 'l2ChannelId';
  return `/reports/performance?dimension=${dimension}&from=${from}&to=${to}&${dimension}=${campaign.campaignId}`;
};

const defatulCampaignManager = new DefaultRtbCampaignManager();

const nameFormatter = (id: string | number, campaign: any): any => {

  const renderLabel = () => <span className={styles.campaignName}>{campaign.campaignName}</span>;
  const editPath = `/orders/${campaign.orderNumber}/campaign-groups/${campaign.l1ObjectId}/campaigns/${id}/edit`;
  return (
    <div className={styles.info}>
      <PermissionChecker
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}
        renderOtherwise={renderLabel}
      >
        <Link
          to={editPath}
          className={`${styles.campaignName} ${styles.link}`}
        >
          {campaign.campaignName}
        </Link>
      </PermissionChecker>
      <div className={styles.campaignID}>
        <div>
          {`ID: ${id}`}
        </div>
        <div>
          <ObjectFitContainer
            key={i18n.language}
            containerHeight={16}
            onlyScaleDown={true}
            alignItems='auto'
            justifyContent='auto'
            margin={0}
          >
            {defatulCampaignManager.getCampaignTypeDes(campaign.adType)}
          </ObjectFitContainer>
        </div>
      </div>
    </div>
  );
};

const agencyFormatter = (_1, campaign: any): any => {
  return (
    <div className={styles.info}>
      <span className={styles.campaignName}>{campaign.agencyName}</span>
      <div className={styles.campaignID}>
        <div>
          {`${i18n.t<string>('campaignDashboard.labels.vendorNumber')}: ${campaign.vendorNumber}`}
        </div>
      </div>
    </div>
  );
};

const progressFormatter = (executeRate, campaign: any): any => {
  const predictRate = Math.min(campaign.predictRate, 1);
  const warnignLine = campaign.budget * 1.1;
  const discrepancy = predictRate - executeRate;

  const spent = `${campaign.currency} ${numberWithCommas(floor(campaign.spent, 2).toFixed(2))}`;
  const budget = `${campaign.currency} ${numberWithCommas(floor(campaign.budget, 2).toFixed(2))}`;
  const danger = campaign.spent > warnignLine;
  const deepWarning = discrepancy > 0.03;
  const warning = discrepancy > 0.01 && discrepancy <= 0.03;

  return (
    <div className={styles.progressContent}>
      <div className={styles.progress}>
        <Progress
          progress={executeRate}
          danger={danger}
          deepWarning={deepWarning}
          warning={warning}
        />
      </div>
      {`${spent} / ${budget}`}
      <br />
      {`${(executeRate * 100).toFixed(2)}% / ${(predictRate * 100).toFixed(2)}%`}
    </div>
  );
};

const optimizeFormatter = (bidPrice, campaign: any): any => {
  const optimization = defatulCampaignManager.getOptimizeDes(campaign.optimize);
  const bidStrategy = isNumber(bidPrice) ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP;
  const currency = campaign.currency;
  return (
    <div className={styles.optimizationCell}>
      <div className={styles.cellTitle}>
        {i18n.t<string>('campaignDashboard.labels.optimization')}
      </div>
      <div>:</div>
      <div>
        {optimization}
      </div>
      <div className={styles.cellTitle}>
        {i18n.t<string>('campaignDashboard.labels.bidStrategy')}
      </div>
      <div>:</div>
      <div>
        {i18n.t<string>(`l1Object.labels.bidStrategy.${bidStrategy.toLowerCase()}`)}
      </div>
      <div className={styles.cellTitle}>
        {i18n.t<string>('campaignDashboard.labels.bidCap')}
      </div>
      <div>:</div>
      <div>
        {bidPrice ?
          `${currency} ${formatPriceAny(+(bidPrice))}` :
          i18n.t<string>('common.labels.noData')
        }
      </div>
    </div>
  );
};

const floatingEditBtnsFormatter = (cell: any, campaign: any): any => {
  const campaignId: number = campaign.campaignId;
  const editPath = `/orders/${campaign.orderNumber}/campaign-groups/${campaign.l1ObjectId}/campaigns/${campaignId}/edit`;
  // const handleShowManageBidWeightModal = partial(formatExtraData.showManageBidWeightModal, campaignId);

  return (
    <TableRowToolBar className={styles.campaignFloatingEditArea}>
      <PermissionChecker
        permissionAware={
          hasFuncs(Permission.CAMPAIGN_WRITE)
        }
      >
        <Link to={editPath}>
          <IconWithTooltip
            icon={faPencilAlt}
            tooltipProps={{
              id: `campaignListEditTip-${campaignId}`,
              tooltip: i18n.t<string>('campaignList.labels.editHint')
            }}
          />
        </Link>
      </PermissionChecker>
      <PermissionChecker permissionAware={hasFuncs(Permission.REPORT_ADS)}>
        <Link to={getReportLink(campaign)}>
          <IconWithTooltip
            icon={faChartArea}
            tooltipProps={{
              id: `campaignListReportTip-${campaignId}`,
              tooltip: i18n.t<string>('campaignList.labels.reportHint')
            }}
          />
        </Link>
      </PermissionChecker>
    </TableRowToolBar>
  );
};

const formatters = {
  nameFormatter,
  agencyFormatter,
  progressFormatter,
  optimizeFormatter,
  floatingEditBtnsFormatter
};

export default formatters;
