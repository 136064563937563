import moment from 'moment-timezone';
/**
 * LocalDateTimeUtil use in agency time zone date time
 */

export const DEFAULT_FORMAT = 'YYYY-MM-DD_HH:mm:ss';

/**
 * This method ignore time zone.
 * If you want to date time of another time zone, you can use this method.
 * You will get user browser date time if you use moment().toDate().
 *
 * example:
 *
 * moment.tz.setDefault('America/Los_Angeles');
 * moment().toDate();  // Wed Nov 27 2019 15:00:00 GMT+0800
 * new Date(moment().format('YYYY-MM-DD HH:mm:ss')) // Tue Nov 26 2019 23:00:00 GMT+0800
 */

function getYMDHMS (): string {
  return moment().format('YYYYMMDDHHmmss');
}

const utilFunctions = {
  getYMDHMS
};
export default utilFunctions;
