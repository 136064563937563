import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import moment from 'moment';
import { ReportManager, DefaultReportManager } from 'core/report/ReportManager';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { AddonFeatureManager } from 'core';
import { SelectOptions } from 'components/commonType';
import i18n from 'i18n';

export type DownloadReportPanelState = {
  readonly startDate: string;
  readonly endDate: string;
  readonly loading: boolean;
  readonly selectFields: { [key: string]: boolean }
  readonly groupBy: string;
  readonly reportType: string;
  readonly enableDownload: boolean;
};
export interface DownloadReportPanelModel {
  readonly state: DownloadReportPanelState;
  readonly event: UpdateEventListener<DownloadReportPanelModel>;
  readonly defaultStartDate: string;
  readonly defaultEndDate: string;
  readonly reportTypeOptions: SelectOptions[];
  readonly addonFeatureManager: AddonFeatureManager;
  readonly supportGroupBy: GroupBy[];
  readonly errors: {[key: string]: string};
  cancel (): void;
  updateDateRange (startDate, endDate): void;
  downloadReport (): void;
  onQuickFieldSelect (type: string): void;
  onFieldSelect (event: any): void;
  changeGroupBy (event: any): void;
}

export type DownloadReportPanelProps = {
  readonly model: DownloadReportPanelModel;
};

export enum Fields {
  IMPRES = 'IMPRESS',
  CLICKS = 'CLICK',
  CTR = 'CTR',
  SPENT = 'SPENT',
  CPM = 'CPM',
  CPC = 'CPC'
}

export const fieldsDependentOn = {
  [Fields.CLICKS]: [Fields.CTR, Fields.CPC],
  [Fields.IMPRES]: [Fields.CTR, Fields.CPM],
  [Fields.SPENT]: [Fields.CPM, Fields.CPC]
};

export enum GroupBy {
  L1_OBJECT = 'L1_OBJECT',
  CREATIVE = 'CREATIVE',
  CAMPAIGN = 'CAMPAIGN'
}

const initFields = {
  [Fields.IMPRES]: false,
  [Fields.CLICKS]: false,
  [Fields.CTR]: false,
  [Fields.SPENT]: false,
  [Fields.CPM]: false,
  [Fields.CPC]: false
};

const sortingFields = ['IMPRESS', 'CLICK', 'CTR', 'CPM', 'CPC', 'SPENT'];

export const defaultFormat = 'YYYY-MM-DD';
abstract class DefaultDownloadReportPanelModel implements DownloadReportPanelModel {
  event: FireableUpdateEventListener<DownloadReportPanelModel>;
  startDate: string;
  endDate: string;
  loading: boolean;
  reportType: string = 'cpm';
  enableDownload: boolean = true;
  selectFields = { ...initFields };

  groupBy: string = GroupBy.CREATIVE;

  constructor (
    public defaultStartDate: string,
    public defaultEndDate: string,
    public supportGroupBy: GroupBy[],
    public callback: () => void,
    public addonFeatureManager: AddonFeatureManager,
    protected reportManager: ReportManager = new DefaultReportManager()
  ) {
    this.event = new FireableUpdateEventListener<DownloadReportPanelModel>();
    this.startDate = moment(defaultStartDate).format(defaultFormat);
    this.endDate = moment(defaultEndDate).endOf('day').format(defaultFormat);
    this.loading = false;
  }

  get errors () {
    return Object.keys(this.selectFields).reduce<{[key: string]: string}>((acc, field) => {
      const checked = this.selectFields[field];
      const checkedDependentFields = fieldsDependentOn[field] ?
        fieldsDependentOn[field].filter(dependentField => this.selectFields[dependentField]) :
        [];
      if (!checked && checkedDependentFields.length > 0) {
        const errorMessage = i18n.t<string>('downloadReportPanel.errors.fieldRequired', {
          field: i18n.t<string>(`downloadReportPanel.labels.${_.camelCase(field)}`),
          checkedDependentFields: checkedDependentFields.map(field => i18n.t<string>(`downloadReportPanel.labels.${_.camelCase(field)}`)).join(', ')
        });
        return { ...acc, [field]: errorMessage };
      }
      return acc;
    }, {});
  }

  get state (): DownloadReportPanelState {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      loading: this.loading,
      selectFields: this.selectFields,
      groupBy: this.groupBy,
      reportType: this.reportType,
      enableDownload: this.enableDownload
    };
  }

  get quickSelectFields () {
    return {
      cpm: [Fields.IMPRES, Fields.CLICKS, Fields.CTR, Fields.CPM, Fields.SPENT],
      cpc: [Fields.IMPRES, Fields.CLICKS, Fields.CTR, Fields.CPC, Fields.SPENT],
      reset: []
    };
  }

  async downloadReport () {
    this.updateState(true);
    try {
      let fields = Object.keys(this.selectFields).filter(key => this.selectFields[key] === true).sort(function (a, b) {
        return sortingFields.indexOf(a) - sortingFields.indexOf(b);
      });
      await this.callDownloadReportAPI(this.startDate, this.endDate, this.groupBy, fields);
      this.updateState(false);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  abstract callDownloadReportAPI (startDate: string, endDate: string, groupBy: string, fields: string[]);

  cancel () {
    this.callback();
  }

  get reportTypeOptions (): Array<SelectOptions> {
    return _.compact([
      {
        label: i18n.t<string>('downloadReportPanel.labels.cpmOption'),
        value: 'cpm'
      },
      {
        label: i18n.t<string>('downloadReportPanel.labels.cpcOption'),
        value: 'cpc'
      }
    ]);
  }

  onQuickFieldSelect = (type: string) => {
    Object.keys(this.selectFields)
      .forEach(field => {
        this.selectFields[field] = false;
        if (this.quickSelectFields[type].includes(field)) {
          this.selectFields[field] = true;
        }
      });
    this.checkEnableDownload();
    this.updateState(false);
  }

  onFieldSelect = (event: any) => {
    this.selectFields = { ...this.selectFields, [event.target.id]: event.target.checked };
    this.checkEnableDownload();
    this.updateState(false);
  }

  checkEnableDownload = () => {
    this.enableDownload = Object.values(this.selectFields).reduce((acc, checked) => {
      return acc || checked;
    }, false) && _.isEmpty(this.errors);
  }

  changeGroupBy = (event: any) => {
    this.groupBy = event.target.value;
    this.updateState(false);
  }

  updateDateRange (startDate: string, endDate: string) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.updateState();
  }

  updateState (loading = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
export class DownloadRtbCampaignGroupReportPanelModel extends DefaultDownloadReportPanelModel {

  constructor (
    private l1Object: {
      name: string,
      l1ObjectId: number
    },
    defaultStartDate: string,
    defaultEndDate: string,
    supportGroupBy: GroupBy[],
    callback: () => void,
    addonFeatureManager: AddonFeatureManager,
    reportManager: ReportManager = new DefaultReportManager()
  ) {
    super(
      defaultStartDate,
      defaultEndDate,
      supportGroupBy,
      callback,
      addonFeatureManager,
      reportManager
    );
  }

  async callDownloadReportAPI (startDate: string, endDate: string, groupBy: string, fields: string[]) {
    await this.reportManager.downloadL1ObjectReport(this.l1Object, startDate, endDate, groupBy, fields);
  }
}
