import _ from 'lodash';
import { toast } from 'react-toastify';
import i18n from 'i18n';

import { AgencyDetail, PartnershipMode } from 'core';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AgencyManager, DefaultAgencyManager } from 'core/agency/AgencyManager';
import { SelectOptions } from 'components/commonType';
import { validateEmpty, validateMinimum } from 'utils/ValidateUtils';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import agencyData from './agencyData';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';

export type AgencyFormState = {
  isLoading: boolean;
  redirectUrl?: string;
  currentTab: string;
  errors?: any;
};
export interface AgencyFormModel {
  readonly agencyId?: number;
  readonly defaultAgency: AgencyDetail;
  readonly timeZoneOptions: SelectOptions[];
  readonly languageOptions: SelectOptions[];
  readonly currencyOptions: SelectOptions[];
  readonly priorityOptions: SelectOptions[];
  readonly partnershipModeOptions: SelectOptions[];
  readonly isNew: boolean;
  readonly cancelPath: string;
  readonly title: string;
  readonly breadcrumbs: any;
  state: AgencyFormState;
  event: UpdateEventListener<AgencyFormModel>;
  validate (basic: AgencyDetail): any;
  save (value: any): void;
  init (): Promise<void>;
  onUnmount (handler): void;
  onChangeTab (newTab: string | null): void;
  setErrors (errors?: any): void;
}

export interface AgencyFormProps {
  readonly model: AgencyFormModel;
}

abstract class DefaultAgencyFormModel implements AgencyFormModel {
  agencyDetail: AgencyDetail;
  modelEvent: FireableUpdateEventListener<AgencyFormModel>;
  isLoading: boolean;
  agencyManager: AgencyManager;
  redirectUrl?: string;
  currentTab: string = 'basic';
  errors?: any;

  constructor (
    agencyManager: AgencyManager = new DefaultAgencyManager()
  ) {
    this.agencyManager = agencyManager;
    this.modelEvent = new FireableUpdateEventListener<AgencyFormModel>();
    this.isLoading = false;
    this.agencyDetail = agencyData.defaultAgency;
  }

  abstract get cancelPath (): string;
  abstract get isNew (): boolean;
  abstract get breadcrumbs (): any[];
  abstract init (): Promise<void>;
  abstract save (agencyDetail: any): void;

  get event (): UpdateEventListener<AgencyFormModel> {
    return this.modelEvent;
  }

  onChangeTab = (newTab: string | null) => {
    if (newTab === null) {
      return;
    }
    this.currentTab = newTab;
    this.updateState(false);
  }

  get defaultAgency (): AgencyDetail {
    return this.agencyDetail;
  }

  validate = (basicValue: AgencyDetail): any => {
    return _.omitBy({
      companyName: validateEmpty(basicValue.companyName),
      targetBudgetMinimum: validateMinimum(basicValue.targetBudgetMinimum, 1),
      campaignBudgetMinimum: validateMinimum(basicValue.campaignBudgetMinimum, 1)
    }, _.isEmpty);
  }

  get title (): string {
    return 'agency.form.titles.edit';
  }

  get state (): AgencyFormState {
    return {
      isLoading: this.isLoading,
      redirectUrl: this.redirectUrl,
      currentTab: this.currentTab,
      errors: this.errors
    };
  }

  get timeZoneOptions (): SelectOptions[] {
    return agencyData.timeZoneOptions;
  }

  get currencyOptions (): SelectOptions[] {
    return agencyData.currencyOptions;
  }

  get languageOptions (): SelectOptions[] {
    return agencyData.languageOptions;
  }

  get partnershipModeOptions (): SelectOptions[] {
    return createSelectOptionsFromEnum(PartnershipMode, 'common.partnershipMode.');
  }

  get priorityOptions (): SelectOptions[] {
    return Array.from(Array(11).keys())
      .map(x => x - 5)
      .reverse()
      .reduce((all: any, currentValue) => {
        return [...all, { value: currentValue, label: '' + currentValue }];
      }, []);
  }

  setErrors (errors?: any): void {
    this.errors = errors;
    this.updateState(false);
  }

  updateState (
    isLoading: boolean,
    redirectUrl?: string
  ) {
    this.isLoading = isLoading;
    this.redirectUrl = redirectUrl;
    this.modelEvent.fireEvent(this);
  }

  onUnmount (handler): void {
    handler && this.event.remove(handler);
    this.redirectUrl = undefined;
  }
}

export class EditAgencyFormModel extends DefaultAgencyFormModel {
  agencyId: number;

  constructor (
    agencyId: number,
    private updateLocalMeta: () => Promise<void>,
    agencyManager: AgencyManager = new DefaultAgencyManager()
  ) {
    super(agencyManager);
    this.agencyId = agencyId;
  }

  async init (): Promise<void> {
    this.updateState(true);
    const { detail } = await this.agencyManager.fetchAgency(this.agencyId);
    this.agencyDetail = detail;
    this.updateState(false);
  }

  get title (): string {
    return 'agency.form.titles.edit';
  }

  get cancelPath (): string {
    return `/agencies/${this.agencyId}`;
  }

  get isNew (): boolean {
    return false;
  }

  get breadcrumbs () {
    return [
      { path: '/agencies', breadcrumb: i18n.t<string>('agencies.home.title') },
      { path: '/agencies/:agencyId', breadcrumb: DynamicBreadcrumb, props: { label: _.get(this.agencyDetail, 'companyName'), matchParam: 'agencyId' } },
      { path: '/agencies/:agencyId/edit', breadcrumb: DynamicBreadcrumb, props: { prefix: i18n.t<string>('common.labels.edit'), label: _.get(this.agencyDetail, 'companyName'), matchParam: 'agencyId' } }
    ];
  }

  async save (agencyDetail: any) {
    this.updateState(true);
    try {
      await this.agencyManager.updateAgency(agencyDetail);
      await this.updateLocalMeta();
    } catch (e) {}
    toast.success(i18n.t<string>('common.messages.succeeded'));
    this.updateState(false, `/agencies/${this.agencyId}`);
  }
}

export class CreateAgencyFormModel extends DefaultAgencyFormModel {

  constructor (
    agencyManager: AgencyManager = new DefaultAgencyManager()
  ) {
    super(agencyManager);
  }

  async init (): Promise<void> {
    this.agencyDetail.targetBudgetMinimum = this.agencyManager.getRTBDefaultMinBudgetPerDay(this.agencyDetail.currency);
    this.agencyDetail.campaignBudgetMinimum = this.agencyManager.getRTBDefaultMinBudget(this.agencyDetail.currency);
  }

  get cancelPath (): string {
    return '/agencies';
  }

  // readonly by field get
  get isNew (): boolean {
    return true;
  }

  get title (): string {
    return 'agency.form.titles.new';
  }

  get breadcrumbs () {
    return [
      { path: '/agencies', breadcrumb: i18n.t<string>('agencies.home.title') },
      { path: '/agencies/new', breadcrumb:  i18n.t<string>('agency.form.titles.new') }
    ];
  }

  async save (agencyDetail: any) {
    this.updateState(true);
    try {
      const agencyId = await this.agencyManager.createAgency(agencyDetail);
      toast.success(i18n.t<string>('common.messages.succeeded'));
      this.updateState(false, `/agencies/${agencyId}`);
    } catch (e) {
      this.updateState(false);
    }
  }
}
