import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { renderColumn, SortDescriptor } from 'components/TableColumn/TableColumn';
import { CreativeOfCampaign } from 'core/creative/Creative';
import styles from './CreativeList.module.scss';
import {
  DefaultCreativeManager,
  CreativeManager
} from 'core/creative/CreativeManager';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import moment from 'moment';
import _ from 'lodash';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { L1Object } from 'core/l1Object/L1Object';
import { PermissionItem } from 'core';
import { ColumnDescription, HeaderFormatter } from 'react-bootstrap-table-next';
import { CreativeListRow } from './CreativeListRow';
// import { numberWithCommas } from 'utils/StringUtil';

export interface CreativeListModel {
  readonly l1Object: L1Object;
  creativeList?: Array<CreativeOfCampaign>;
  readonly event: UpdateEventListener<CreativeListModel>;
  readonly state: CreativeListState;
  readonly noDataDescription: string;
  readonly handleOnSelect: any;
  readonly handleOnSelectAll: any;
  readonly selectedCreatives: Array<number | undefined>;
  readonly columnsToShow: Array<string>;
  readonly columns: any[];
  readonly summaryData: any;
  readonly sizePerPage: number;
  reviewCreative: (adx, creativeId) => void;
  goReportPage: (creative) => void;
  getEditPath: (creativeId) => string;
  onPageChange: (page: number, sizePerPage: number) => void;
}

export type CreativeListModelProps = {
  readonly model: CreativeListModel;
};

export type CreativeListState = {
  readonly page: number;
  readonly loading: boolean;
  readonly redirectPath?: string;
};

export enum CreativeListColumns {
  ID = 'id',
  PREVIEW = 'creativePreview',
  STATUS = 'bindingState',
  DELIVERY = 'effectiveStatus',
  TYPE = 'type',
  APPROVALSTATUS = 'approvalStatus',
  TOOL = 'tool'
}

export type CreativeIDExtra = {
  handleOnSelect: (id: number) => void;
  modifyPermissionAware: PermissionItem;
};
export class DefaultCreativeListModel implements CreativeListModel {
  event: FireableUpdateEventListener<CreativeListModel>;
  creativeList?: CreativeListRow[];
  selectedCreatives: Array<number | undefined>;
  onSelect: (creativeId: Array<any>) => void;
  onSelectAll: (page: number, sizePerPage: number) => void;
  columnsToShow: Array<string>;
  creativeManager: CreativeManager;
  loading: boolean;
  redirectPath?: string;
  editPath: (creativeId) => string;
  summaryData: any;
  page: number = 1;
  sizePerPage: number = 10;

  constructor (
    public l1Object: L1Object,
    campaignInfo: any,
    creativeList: Array<CreativeOfCampaign>,
    selectedCreatives: Array<number | undefined>,
    handleOnSelect,
    handleOnSelectAll,
    editPath: (creativeId) => string,
    private listFormatters: { [key: string]: any },
    columnsToShow?: Array<string>,
    private modifyPermissionAware?: PermissionItem,
    creativeManager: CreativeManager = new DefaultCreativeManager()
  ) {
    this.creativeList = this.getViewModelData(
      creativeList.map((creative) => {
        return {
          ...creative,
          lifetimeUU: _.get(creative, 'report.lifetimeUU', 0),
          creativeId: creative.id,
          campaignInfo: campaignInfo,
          selected: selectedCreatives
            ? selectedCreatives.indexOf(creative.id) > -1
            : false
        };
      })
    );
    const adType = campaignInfo.adType;
    this.event = new FireableUpdateEventListener<CreativeListModel>();
    this.selectedCreatives = selectedCreatives;
    this.onSelect = handleOnSelect;
    this.onSelectAll = handleOnSelectAll;
    const hasApproval = adType !== AdType.KEYWORD;
    this.columnsToShow = columnsToShow ?
      columnsToShow :
      _.compact([
        CreativeListColumns.ID,
        CreativeListColumns.PREVIEW,
        CreativeListColumns.STATUS,
        CreativeListColumns.DELIVERY,
        CreativeListColumns.TYPE,
        CreativeListColumns.APPROVALSTATUS,
        CreativeListColumns.TOOL
      ]);
    if (!hasApproval) {
      this.columnsToShow = this.columnsToShow.filter((column) => column !== CreativeListColumns.APPROVALSTATUS);
    }
    this.creativeManager = creativeManager;
    this.loading = false;
    this.editPath = editPath;
  }

  get columns () {
    return [
      this.creativeIDColumn,
      this.creativePreviewColumn,
      this.approvalStatusColumn,
      this.creativeStatusColumn,
      this.creativeDeliveryColumn,
      this.vctrColumn,
      this.ctrColumn,
      this.vimpsColumn,
      this.impsColumn,
      this.clicksColumn,
      this.typeAndSizeColumn,
      this.toolColumn
    ].filter((column) => this.columnsToShow.indexOf(column.dataField) > -1);
  }

  handleOnSelect = (id) => {
    this.onSelect(id);
    this.updateState();
  }

  handleOnSelectAll = () => {
    this.onSelectAll(this.page, this.sizePerPage);
    this.updateState();
  }

  onPageChange = (page: number) => {
    this.page = page;
    this.updateState();
  }

  getEditPath (creativeId) {
    return this.editPath(creativeId);
  }

  getViewModelData (creativeList?: Array<CreativeListRow>) {
    return creativeList;
  }

  reviewCreative = async (adx, creativeId) => {
    this.updateState(true);
    try {
      await this.creativeManager.reviewCreatives([creativeId], [adx]);
      toast.success(i18next.t<string>('creativeList.labels.reviewSuccess'));
      this.updateState(false);
    } catch (e) {
      toast.error(i18next.t<string>('creativeList.labels.reviewFailed'));
      this.updateState();
    }
  }

  get state (): CreativeListState {
    return {
      page: this.page,
      loading: this.loading,
      redirectPath: this.redirectPath
    };
  }

  get noDataDescription (): string {
    return 'campaignCreativeList.headers.noDataAvailable';
  }

  get nameHeaderFormatter (): HeaderFormatter<CreativeListRow> {
    const offset = (this.page - 1) * this.sizePerPage;
    const end = offset + this.sizePerPage;
    const currentPageCreatives = this.creativeList ? this.creativeList.slice(offset, end).map(creative => creative.id) : [];
    const notSelected = _.difference(currentPageCreatives, this.selectedCreatives);
    const currentPageAllSelected = notSelected.length === 0;
    return _.partial(
      this.listFormatters.nameHeaderFormatter,
      currentPageAllSelected,
      this.selectedCreatives.length,
      this.modifyPermissionAware,
      this.handleOnSelectAll
    );
  }

  get creativeIDColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.creativeIdColumn;
    };
    return renderColumn<CreativeListRow, CreativeListRow['id'], CreativeIDExtra>(
      {
        dataField: 'id',
        text: 'campaignCreativeList.headers.creativeName',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter,
        formatExtraData: {
          handleOnSelect: this.handleOnSelect,
          modifyPermissionAware: this.modifyPermissionAware
        }
      },
      this.listFormatters.creatvieIDFormatter,
      this.nameHeaderFormatter
    );
  }

  get creativePreviewColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.preveiwColumn;
    };
    return renderColumn(
      {
        dataField: 'creativePreview',
        text: 'campaignCreativeList.headers.creativePreview',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.creativePreviewFormatter
    );
  }

  get creativeStatusColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.statusColumn;
    };
    return renderColumn(
      {
        dataField: 'bindingState',
        text: 'campaignCreativeList.headers.creativeStatus',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.stateFormatter
    );
  }

  get creativeDeliveryColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.deliveryColumn;
    };
    return renderColumn(
      {
        dataField: 'effectiveStatus',
        text: 'campaignCreativeList.headers.effectiveStatus',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.deliveryFormatter
    );
  }

  get vctrColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.ctrColumn;
    };
    return renderColumn(
      {
        dataField: 'vctr',
        text: 'campaignCreativeList.headers.vctr',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.percentageFormatter
    );
  }

  get ctrColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.ctrColumn;
    };
    return renderColumn(
      {
        dataField: 'ctr',
        text: 'campaignCreativeList.headers.ctr',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.percentageFormatter
    );
  }

  get vimpsColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.impsColumn;
    };
    return renderColumn(
      {
        dataField: 'vimps',
        text: 'campaignCreativeList.headers.vimps',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.numberFormatter
    );
  }

  get impsColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.impsColumn;
    };
    return renderColumn(
      {
        dataField: 'imps',
        text: 'campaignCreativeList.headers.imps',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.numberFormatter
    );
  }

  get clicksColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.clicksColumn;
    };
    return renderColumn(
      {
        dataField: 'clicks',
        text: 'campaignCreativeList.headers.clicks',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.numberFormatter
    );
  }

  get typeAndSizeColumn (): ColumnDescription {
    return renderColumn(
      {
        dataField: 'type',
        text: 'campaignCreativeList.headers.typeAndSize',
        sort: false
      },
      this.listFormatters.typeAndSizeFormatter
    );
  }

  get approvalStatusColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.approvalStatusColumn;
    };
    return renderColumn(
      {
        dataField: 'approvalStatus',
        text: 'campaignCreativeList.headers.approvalStatus',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter,
        formatExtraData: {
          reviewCreative: this.reviewCreative.bind(this)
        }
      },
      this.listFormatters.approvalFormatter
    );
  }

  get toolColumn (): ColumnDescription {
    return renderColumn(
      {
        dataField: 'tool',
        text: '',
        sort: false,
        classes: 'editCreativeArea',
        formatExtraData: {
          goReportPage: this.goReportPage.bind(this),
          getEditPath: this.getEditPath.bind(this),
          l1Object: this.l1Object
        }
      },
      this.listFormatters.floatingEditBtnsFormatter
    );
  }

  get defaultSorts (): SortDescriptor {
    return [
      {
        dataField: 'creativeNumber',
        order: 'asc'
      }
    ];
  }

  goReportPage (creative) {
    const campaignInfo = creative.campaignInfo;
    const from = encodeURIComponent(
      moment(campaignInfo.startDate)
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
    );
    const to = encodeURIComponent(
      moment(campaignInfo.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    );
    this.redirectPath = `/reports/performance?dimension=l3ChannelId&from=${from}&to=${to}&l2ChannelId=${campaignInfo.id}&l3ChannelId=${creative.l3ChannelId}`;
    this.updateState(false);
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
