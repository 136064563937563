import {
  DmpWebService,
  RestfulDmpWebService
} from 'ws/DmpWebService';
import { Product, ProductWithPagination } from 'core/product/Product';
import { Pageable } from 'ws/Pageable';
import moment from 'moment';

export interface DmpManager {
  getRetailProducts (pageable: Pageable, queries?: any): Promise<ProductWithPagination>;
  getProductCaches (agencyId: number, advertiser: number, productIds: string[]): Promise<Product[]>;
  getProductName (productId: string): Promise<string | null>;
  getCategories (): Promise<object>;
  getAdServingUrl (productId: string): Promise<string>;
}

export class DefaultDmpManager implements DmpManager {
  webService: DmpWebService;

  constructor (webService: DmpWebService = new RestfulDmpWebService()) {
    this.webService = webService;
  }

  async getRetailProducts (pageable: Pageable, queries: any = {}): Promise<ProductWithPagination> {
    return this.webService.getRetailProducts(pageable, queries);
  }

  async getProductCaches (agencyId: number, advertiser: number, productIds: string[]): Promise<Product[]> {
    return this.webService.getProductCaches(agencyId, advertiser, productIds);
  }

  async getProductName (productId: string): Promise<string | null> {
    return this.webService.getProductName(productId);
  }

  async getCategories (): Promise<object> {
    const date = moment().format('YYYY-MM-DD');
    if (!process.env.REACT_APP_PRODUCT_CATEGORY_PATH) {
      return {};
    }
    const response = await fetch(`${process.env.REACT_APP_PRODUCT_CATEGORY_PATH}?t=${date}`);
    const categories = await response.json();
    return categories;
  }

  async getAdServingUrl (productId: string): Promise<string> {
    return this.webService.getAdServingUrl(productId);
  }
}
