import { Modal } from 'components/Modal/Modal';
import React, { useCallback, useMemo, useRef } from 'react';
import i18n from 'i18n';
import { StoredValueEditModel } from './StoredValueEditModalModel';
import { LoadingIndicator } from 'components/LoadingIndicator';
import styles from './storedValueEditModal.module.scss';
import _ from 'lodash';
import { LabelField } from 'components/form/field/LabelField';
import { Formik, FormikProps } from 'formik';
import { FormikField } from 'components/form/field/FormikField';

export const StoredValueEditModal: React.FC<{
  agencyId: number,
  agencyName: string,
  currency: string,
  remainingValue: number,
  onClose: (refresh: boolean) => void,
  useModel: (agencyId: number, remainingValue: number, onClose: (refresh: boolean) => void) => StoredValueEditModel
}> = ({ agencyId, agencyName, currency, remainingValue, onClose, useModel }) => {

  const {
    title,
    loading,
    showConfirm,
    validate,
    onEditValue,
    setShowConfirm
  } = useModel(agencyId, remainingValue, onClose);

  const formikRef = useRef<FormikProps<any>>(null);

  const initValue = useMemo(() => ({
    amount: 0,
    comment: ''
  }), []);

  const close = _.partial(onClose, false);

  const onSubmit = useCallback(async () => {
    formikRef.current && formikRef.current.handleSubmit();
  }, []);

  const onClickSubmit = useCallback(async () => {
    if (formikRef.current) {
      const errors = await formikRef.current.validateForm();
      if (errors && Object.keys(errors).length > 0) {
        formikRef.current.handleSubmit();
        return;
      }
      setShowConfirm(true);
    }
  }, [setShowConfirm]);

  return (
    <>
      {loading && <LoadingIndicator />}
      <>
        <Modal
          title={title}
          dismiss={close}
          className={styles.storedValueEditModal}
          primaryButton={{ title: i18n.t<string>('storedValueEditModal.buttons.stored'), callback: onClickSubmit }}
          secondaryButton={{ title: i18n.t<string>('common.buttons.cancel'), callback: close }}
          enforceFocus={false}
        >
          <Formik
            innerRef={formikRef}
            initialValues={initValue}
            enableReinitialize
            validate={validate}
            onSubmit={onEditValue}
            validateOnBlur={false}
          >
            {
              () => (
                <>
                  <LabelField
                    name='agencyName'
                    label={i18n.t<string>('storedValueEditModal.labels.agency')}
                    value={agencyName}
                    fieldContentWidth={315}
                  />
                  <FormikField.InputGroup
                    name='amount'
                    label={i18n.t<string>('storedValueEditModal.labels.amount')}
                    prefix={currency}
                    type='number'
                    fieldContentWidth={315}
                  />
                  <FormikField.TextArea
                    name='comment'
                    label={i18n.t<string>('storedValueEditModal.labels.comment')}
                    fieldContentWidth={315}
                  />
                </>
              )
            }
          </Formik>
        </Modal>
        {showConfirm &&
          <Modal
            title={title}
            primaryButton={{ title: i18n.t<string>('common.buttons.confirm'), callback: onSubmit }}
            secondaryButton={{ title: i18n.t<string>('common.buttons.cancel'), callback: _.partial(setShowConfirm, false) }}
            dismiss={_.partial(setShowConfirm, false)}
          >
            {i18n.t<string>('storedValueEditModal.labels.editConfirm', { agencyName })}
          </Modal>
        }
      </>
    </>
  );
};
