import { AUDITLOG_SRC, AuditLogRecord } from 'core/auditLog/AuditLog';
import { AbstractAuditLogModel } from './AuditLogModel';
import { AuditLogManager, DefaultAuditLogManager } from 'core/auditLog/AuditLogManager';
import { toast } from 'react-toastify';
import _ from 'lodash';
import i18n from 'i18n';
import formatters, { getAgencyLogLabel } from './AuditLogFormatters';
import { SelectOptions } from 'components/commonType';
import { ColumnDescription, ExpandRowProps } from 'react-bootstrap-table-next';
import { renderColumn } from 'components/TableColumn/TableColumn';

export enum AGENCY_AUDITLOG_COLUMN {
  DATE = 'ctime',
  SOURCE_INFO = 'srcID',
  EMAIL = 'email',
  EVENT = 'funcType',
  DETAIL = 'msg'
}

export type AgencyAuditLogListRow = {
  id: number,
  [AGENCY_AUDITLOG_COLUMN.DETAIL]: { [key: string]: {
    adminOnly?: boolean,
    org: string,
    to: string
  } }[],
  [AGENCY_AUDITLOG_COLUMN.DATE]: string;
  [AGENCY_AUDITLOG_COLUMN.SOURCE_INFO]: {
    id: number;
    type: AUDITLOG_SRC;
    name: string
  },
  [AGENCY_AUDITLOG_COLUMN.EMAIL]: string;
  [AGENCY_AUDITLOG_COLUMN.EVENT]: string;
};

export class AgencyAuditLogModel extends AbstractAuditLogModel<AgencyAuditLogListRow> {

  nonExpandRowKey: number[] = [];

  constructor (
    private isAdmin: boolean,
    public agencyId: number,
    auditLogManager: AuditLogManager = new DefaultAuditLogManager()
  ) {
    super(auditLogManager);
  }

  getSearchString () {
    let searchString = super.getSearchString();
    if (!_.isEmpty(searchString)) {
      return `${searchString}`;
    }
    return '';
  }

  getIgnorePaths () {
    return [
      'addonFeatures.order.',
      'addonFeatures.limitation.',
      'addonFeatures.product.',
      'addonFeatures.conversiontracking.',
      'addonFeatures.monitor.',
      'addonFeatures.creative_studio.'
    ];
  }

  // 暫時先把 UI 上沒有出現的設定 ignore 掉，不改其他地方的程式碼
  getIgnoreFields () {
    return [
      'goGanGroupId',
      'l1ObjectId',
      'providerId',
      'sysPercent',
      'maxOrderProfit', // duplicate with 'property.addonProps.maxOrderProfit',
      'agcPercent',
      'defaultCountryOp',
      'defaultSpaceOp',
      'interstitial',
      'limitAdx',
      'limitAdxOp',
      'sysPercentIn',
      'sysPercentOut',
      'taxPercent',
      // addon features channel
      'addonFeatures.channel.retail_media',
      // addon features company
      'addonFeatures.company.rebate',
      'addonFeatures.company.allow',
      'addonFeatures.company.singleOrderBudgetConstraint',
      'addonFeatures.company.orderBudgetMaximum',
      'addonFeatures.company.useAgencyProfitOnRebateReport',
      'addonFeatures.company.showOutComeOnRebateReport',
      'addonFeatures.company.creditLimit',
      'addonFeatures.company.agencyProfit',
      'addonFeatures.company.advertiserViewSpentInReport',
      'addonFeatures.company.provideReportToWorkflow',
      'addonFeatures.company.outdoorAgency',
      // addon features campaign
      'addonFeatures.campaign.bid_weight_setting',
      'addonFeatures.campaign.deliveryAmountConstraint',
      'addonFeatures.campaign.bidPriceBaseVal',
      'addonFeatures.campaign.bidPriceCPCMin',
      'addonFeatures.campaign.bidPriceCPMMin',
      'addonFeatures.campaign.bidPriceCPVMin',
      'addonFeatures.campaign.bidPricevCPMMin',
      'addonFeatures.campaign.thirdparty_tracking',
      'addonFeatures.campaign.customizeAdLogo',
      'addonFeatures.campaign.dynamic_cpm',
      'addonFeatures.campaign.bidPriceConstraint',
      'addonFeatures.campaign.orderAgcProfitSetting',
      'addonFeatures.campaign.enableOptimizedPrice',
      'addonFeatures.campaign.retargeting_tracker',
      'addonFeatures.campaign.roadblock',
      // addon features creatives
      'addonFeatures.creatives.option_2',
      'addonFeatures.creatives.option_3',
      'addonFeatures.creatives.option_4',
      'addonFeatures.creatives.option_5',
      'addonFeatures.creatives.option_6',
      'addonFeatures.creatives.option_7',
      'addonFeatures.creatives.option_8',
      'addonFeatures.creatives.option_10',
      'addonFeatures.creatives.option_11',
      'addonFeatures.creatives.option_13',
      'addonFeatures.creatives.option_20',
      'addonFeatures.creatives.option_21',
      'addonFeatures.creatives.option_22',
      // addon features campaignLimitation
      'addonFeatures.campaignLimitation.country',
      'addonFeatures.campaignLimitation.target_audience',
      'addonFeatures.campaignLimitation.adexchange',
      'addonFeatures.campaignLimitation.domain',
      'addonFeatures.campaignLimitation.city',
      'addonFeatures.campaignLimitation.enablePMPDeal',
      'addonFeatures.campaignLimitation.gender',
      'addonFeatures.campaignLimitation.age',
      // addon features report
      'addonFeatures.report.viewableCtr',
      'addonFeatures.report.booking_calendar',
      'addonFeatures.report.yahooAd',
      'addonFeatures.report.closingReport',
      'addonFeatures.report.campaign_dashboard',
      'addonFeatures.report.orderCustom',
      // property addonProps
      'property.addonProps.outdoorAdImpresMultiply',
      'property.addonProps.outdoorMachineCount',
      'property.addonProps.defaultMonitorBudget',
      'property.addonProps.orderBudgetMaximum'
    ];
  }

  override getFilters (): {[key: string]: {
    options: SelectOptions[],
    value?: string | number,
    onSelect: (campaignTarget: string | number) => void;
  }} {
    const filters = {};
    let generalOptions: SelectOptions[] = [];
    this.auditLogFields
      .filter(field => !this.getIgnorePaths().some(ignorePath => field.includes(ignorePath)) &&
        !this.getIgnoreFields().includes(field))
      .forEach(field => {
        const label = getAgencyLogLabel(field);
        label && generalOptions.push({
          label: label,
          value: field
        });
      });
    const auditLogFieldOptions = generalOptions;
    auditLogFieldOptions.unshift({
      label: i18n.t<string>('common.placeholder.all'),
      value: ''
    });
    filters['fieldFilter'] = {
      options: auditLogFieldOptions,
      value: this.selectedAuditLogField,
      onSelect: this.setAuditLogField
    };
    return filters;
  }

  getAuditLogDetail = (messages) => {
    return messages && Array.isArray(messages) ?
      messages.filter(msg => {
        if (_.some(Object.keys(msg), key => this.getIgnorePaths().some(ignorePath => key.includes(ignorePath))) ||
          _.some(Object.keys(msg), key => this.getIgnoreFields().includes(key))) {
          return false;
        }
        const values: any = _.defaultTo(Object.values(msg), []);
        if (values.length === 0) return true;
        const content = values[0];
        return content.adminOnly ? content.adminOnly && this.isAdmin : true;
      }) :
      messages;
  }

  getAuditLogDataOfRecord = (record: AuditLogRecord) => {
    if (!record.msg || record.msg.length === 0) {
      this.nonExpandRowKey.push(record.id);
    }
    return {
      id: record.id,
      [AGENCY_AUDITLOG_COLUMN.DETAIL]: this.getAuditLogDetail(record.msg),
      [AGENCY_AUDITLOG_COLUMN.DATE]: record.ctime,
      [AGENCY_AUDITLOG_COLUMN.SOURCE_INFO]: {
        id: record.srcID,
        type: record.srcType,
        name: ''
      },
      [AGENCY_AUDITLOG_COLUMN.EMAIL]: record.email,
      [AGENCY_AUDITLOG_COLUMN.EVENT]: record.funcType
    };
  }

  async initAuditLog (page: number = 1) {
    try {
      const pageable = { page: page, sizePerPage: 10, sort: this.sortField === AGENCY_AUDITLOG_COLUMN.EMAIL ? 'userId' : this.sortField , direction: this.sortOrder };
      const auditLogFromServer = await this.auditLogManager.getAgencyAuditLog(this.agencyId, pageable, this.getSearchString());
      this.pagination = auditLogFromServer.pagination;
      this.nonExpandRowKey = [];
      this.auditLogData = auditLogFromServer.records.map(this.getAuditLogDataOfRecord);
      this.updateFilteredAuditLogData();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.auditLogData = [];
    }
  }

  async getAuditLogFields () {
    try {
      this.auditLogFields = await this.auditLogManager.getAgencyAuditLogFields(this.agencyId);
      this.auditLogFields.sort();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
  }

  updateFilteredAuditLogData () {
    this.filteredAuditLogData = this.auditLogData ? this.auditLogData : [];
    // TODO: implement filter feature later
    return this.filteredAuditLogData;
  }

  getColumnFormatter (columnName) {
    switch (columnName) {
      case AGENCY_AUDITLOG_COLUMN.SOURCE_INFO:
        return formatters.srcInfoFormatter;
      case AGENCY_AUDITLOG_COLUMN.EVENT:
        return formatters.i18nFormatter;
      default:
        return;
    }
  }

  getSubFilters (): {
    [key: string]: {
      options: SelectOptions[],
      value?: string | number,
      onSelect: (target: string | number) => void;
    }} {
    return {
    };
  }

  getColumns (): ColumnDescription<AgencyAuditLogListRow>[] {
    const definitions = Object.values(AGENCY_AUDITLOG_COLUMN).map(columnToRender => {
      if (columnToRender === AGENCY_AUDITLOG_COLUMN.DETAIL) {
        return undefined;
      }
      return renderColumn(this.getAuditLogColumnDefinition(columnToRender), this.getColumnFormatter(columnToRender));
    });
    return _.compact(definitions);
  }

  getExpandRenderer (): ExpandRowProps<AgencyAuditLogListRow, number> {
    return {
      nonExpandable: this.nonExpandRowKey,
      renderer: formatters.detailExpandRenderer
    };
  }
}
