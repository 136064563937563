import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { CreativeOfCampaign } from 'core/creative/Creative';
import _ from 'lodash';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { DefaultCreativeListModel, CreativeListColumns, CreativeListModel } from './CampaignCreativeList/CreativeListModel';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import rtbBindingListFormatters from './CampaignCreativeList/defaultListFormatters';
import { CreativeManagementStateContext } from './CreativeManagementStateContext';
import { BindingManager, DefaultBindingManager } from 'core/binding/BindingManager';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Permission } from 'core/auth/Permission';
import { addOnEnabled, hasFuncs } from 'core/permission/PermissionDSL';
import { PermissionItem } from 'core';
import styles from './creativeOverviewStateContent.module.scss';
import { BindingCreateRequests } from 'ws/BindingWebService';
import { CampaignBindInfo } from 'core/binding/CampaignBindInfo';

export interface CreativeOverviewStateContentModel {
  readonly state: CreativeOverviewStateContentState;
  readonly channel: L1ObjectChannel;
  readonly event: UpdateEventListener<CreativeOverviewStateContentModel>;
  readonly creatives: Array<CreativeOfCampaign>;
  readonly selectedCreatives: Array<number>;
  readonly stateContext?: CreativeManagementStateContext;
  readonly campaignInfo: any;
  readonly showCreateCreativeBtn: boolean;
  readonly columnsToShow?: Array<string>;
  readonly l1Object?: L1Object;
  readonly bindPermissionAware: PermissionItem;
  readonly modalClassName?: string;
  l3ObjectStateFn: () => void;
  goCreateCreativeFn: () => void;
  handleOnSelect (creativeId: Array<string | number>): void;
  handleOnSelectAll (page: number, sizePerPage: number): void;
  handleOnUnSelectAll (): void;
  activeCreatives (): Promise<void>;
  deactiveCreatives (): Promise<void>;
  setStateContext (stateContext: CreativeManagementStateContext): void;
  getEditPath (creativeId): string;
  canActive (): boolean;
  canDeactive (): boolean;
  getCreativeListModel (): CreativeListModel;
  closeModalFn (): void;
  handleOnSearch (searchString: string): void;
}

export type CreativeOverviewStateContentProps = {
  readonly model: CreativeOverviewStateContentModel;
};

export type CreativeOverviewStateContentState = {
  readonly selectedCreatives: Array<number>;
  readonly loading: boolean;
  readonly dirty: boolean;
  readonly searchString: string;
  readonly filteredCreativeList: CreativeOfCampaign[];
};

abstract class DefaultCreativeOverviewStateContentModel implements CreativeOverviewStateContentModel {
  event: FireableUpdateEventListener<CreativeOverviewStateContentModel>;
  creatives: Array<CreativeOfCampaign>;
  campaignInfo: CampaignBindInfo;
  selectedCreatives: Array<number>;
  loading: boolean;
  stateContext?: CreativeManagementStateContext;
  dirty: boolean;
  orderNumber: string;
  showCreateCreativeBtn = true;
  bindingIdMap: {[key: number]: string} = {};
  modalClassName: string = styles.creativeOverviewStateContentModal;
  closeModalFn: () => void;
  searchString: string = '';
  filteredCreativeList: CreativeOfCampaign[];

  constructor (
    public l1Object: L1Object,
    campaignInfo: any,
    creatives: Array<CreativeOfCampaign>,
    orderNumber: string,
    public l3ObjectStateFn,
    public goCreateCreativeFn,
    protected refreshCreative,
    closeModalFn: (dirty) => void,
    protected bindingManager: BindingManager = new DefaultBindingManager()
  ) {
    this.closeModalFn = () => {
      closeModalFn(this.state.dirty);
    };
    this.event = new FireableUpdateEventListener<CreativeOverviewStateContentModel>();
    this.creatives = creatives;
    this.filteredCreativeList = this.creatives;
    this.campaignInfo = campaignInfo;
    this.selectedCreatives = [];
    this.loading = false;
    this.dirty = false;
    this.orderNumber = orderNumber;
    creatives.forEach(creative => {
      this.bindingIdMap[creative.id] = creative.l3ChannelId;
    });
  }

  get state (): CreativeOverviewStateContentState {
    return {
      selectedCreatives: this.selectedCreatives,
      loading: this.loading,
      dirty: this.dirty,
      searchString: this.searchString,
      filteredCreativeList: this.filteredCreativeList
    };
  }

  get bindPermissionAware () {
    const channelAddonFeature = ADDONFEATURE.CHANNEL[this.channel];
    return channelAddonFeature ?
      hasFuncs(Permission.CAMPAIGN_WRITE).and(addOnEnabled(channelAddonFeature)) :
      hasFuncs(Permission.CAMPAIGN_WRITE);
  }

  getEditPath = (creativeId) => {
    const postfitPath = `&campaignId=${this.campaignInfo.id}`;
    return this.l1Object ?
      `/creatives/${creativeId}/edit?orderNumber=${this.orderNumber}&l1ObjectId=${this.l1Object.l1ObjectId}${postfitPath}` :
      `/creatives/${creativeId}/edit?orderNumber=${this.orderNumber}${postfitPath}`;
  }

  setStateContext = (stateContext) => {
    this.stateContext = stateContext;
  }

  abstract canActive ();

  abstract canDeactive ();

  abstract get channel ();

  async updateStatusMethod (active: boolean) {
    let payload: BindingCreateRequests = [{
      active,
      l3ChannelIds: this.selectedCreatives.map((creativeId: number) => this.bindingIdMap[creativeId])
    }];
    await this.bindingManager.updateBinding(this.channel, payload);
  }

  abstract get listFormatters ();

  abstract get columnsToShow ();

  activeCreatives = async () => {
    if (this.selectedCreatives.length <= 0) {
      return;
    }

    try {
      this.updateState(true);
      await this.updateStatusMethod(true);
      this.creatives = await this.refreshCreative();
      this.selectedCreatives = [];
      toast.success(i18n.t<string>('creativeOverviewState.labels.activeSuccess'));
      this.dirty = true;
      this.handleOnSearch(this.searchString);
    } catch (e) {
      toast.success(i18n.t<string>('creativeOverviewState.labels.activeFailed'));
      this.updateState();
    }
  }

  deactiveCreatives = async () => {
    if (this.selectedCreatives.length <= 0) {
      return;
    }

    try {
      this.updateState(true);
      await this.updateStatusMethod(false);
      this.creatives = await this.refreshCreative();
      this.selectedCreatives = [];
      toast.success(i18n.t<string>('creativeOverviewState.labels.deactiveSuccess'));
      this.dirty = true;
      this.handleOnSearch(this.searchString);
    } catch (e) {
      toast.success(i18n.t<string>('creativeOverviewState.labels.deactiveFailed'));
      this.updateState();
    }
  }

  handleOnSelect = (creativeId) => {
    if (this.selectedCreatives.indexOf(creativeId) > -1) {
      _.remove(this.selectedCreatives, id => id === creativeId);
    } else {
      this.selectedCreatives.push(creativeId);
    }
    this.updateState();
  }

  handleOnSelectAll = (page: number, sizePerPage: number) => {
    const offset = (page - 1) * sizePerPage;
    const end = offset + sizePerPage;
    const creatives = this.filteredCreativeList.slice(offset, end).map(creative => creative.id);
    const notSelected = _.difference(creatives, this.selectedCreatives);
    if (notSelected.length > 0) {
      this.selectedCreatives = _.concat(this.selectedCreatives, notSelected);
    } else {
      _.remove(this.selectedCreatives, creativeId => creatives.includes(creativeId));
    }
    this.updateState();
  }

  handleOnUnSelectAll = () => {
    this.selectedCreatives = [];
    this.updateState();
  }

  handleOnSearch = (searchString) => {
    this.searchString = searchString;
    this.filteredCreativeList = this.creatives.filter((creative: CreativeOfCampaign) => {
      const nameMatch = creative.name.toLowerCase().includes(searchString.toLowerCase());
      const idMatch = creative.id.toString().includes(searchString);
      return nameMatch || idMatch;
    });
    this.updateState();
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }

  getCreativeListModel (): CreativeListModel {
    return new DefaultCreativeListModel(
      this.l1Object,
      this.campaignInfo,
      this.filteredCreativeList,
      this.selectedCreatives,
      this.handleOnSelect,
      this.handleOnSelectAll,
      this.getEditPath,
      this.listFormatters,
      this.columnsToShow,
      this.bindPermissionAware
    );
  }
}

export class RtbCreativeOverviewStateContentModel extends DefaultCreativeOverviewStateContentModel {

  get channel () {
    return L1ObjectChannel.RTB;
  }

  canActive = () => {
    return true;
  }

  canDeactive = () => {
    return true;
  }

  get listFormatters () {
    return rtbBindingListFormatters;
  }

  get columnsToShow () {
    const basicColumns = [
      CreativeListColumns.ID,
      CreativeListColumns.PREVIEW,
      CreativeListColumns.STATUS,
      CreativeListColumns.DELIVERY,
      CreativeListColumns.TYPE,
      CreativeListColumns.APPROVALSTATUS,
      CreativeListColumns.TOOL
    ];
    return basicColumns;
  }
}
