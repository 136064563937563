import React from 'react';
import { AdvertiserHomeProps, AdvertiserHomeState } from './AdvertiserHomeModel';
import styles from './advertiserHome.module.scss';
import i18n from 'i18next';
import { RecentGroupDropdown } from 'components/RecentGroupDropdown/RecentGroupDropdown';
import { SessionStorageItemKeys } from 'helper/StorageHelper';
import { AdvertiserList } from '../AdvertiserList/AdvertiserList';

export class AdvertiserHome extends React.Component<AdvertiserHomeProps, AdvertiserHomeState> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  render () {
    const model = this.props.model;
    return (
      <div className={styles.container}>
        <div className={styles.titleArea}>
          {i18n.t<string>('advertiserHome.labels.title')}
          {
            model.isSysAdmin &&
            <div className={styles.agencyOptions}>
              <div className={styles.title}>
                {i18n.t<string>('advertiserHome.labels.agencyOptionsTitle')}
              </div>
              <RecentGroupDropdown
                value={model.state.agencyId}
                options={model.agencyOptions}
                storageKey={SessionStorageItemKeys.AGENCY}
                callback={model.setAgencyId}
              />
            </div>
          }
        </div>
        <div className={styles.advertiserListArea}>
          <AdvertiserList model={model.getAdvertiserListModel()}/>
        </div>
      </div>
    );
  }
}
