import React, { Fragment } from 'react';
import { Fields, DownloadReportPanelProps, DownloadReportPanelState } from './DownloadReportPanelModel';
import styles from './downloadReportPanel.module.scss';
import moment from 'moment';
import { Modal } from 'components/Modal/Modal';
import i18n from 'i18next';
import _ from 'lodash';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { DateRange } from 'components/DateRangePicker/DateRangePicker';
import { Form } from 'react-bootstrap';
import { DateRangePickerField } from 'components/form/field/DateRangePickerField';
import { CustomField } from 'components/form/field/CustomField';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

export const defaultFormat = 'YYYY-MM-DD_HH:mm:ss';
export class DownloadReportPanel extends React.Component<DownloadReportPanelProps, DownloadReportPanelState> {

  handler: number;

  constructor (props) {
    super(props);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  hide = () => {
    this.props.model.cancel();
  }

  handleDateRangeChange = (startDate, endDate) => {
    this.props.model.updateDateRange(startDate, endDate);
  }

  download = () => {
    this.props.model.downloadReport();
  }

  renderFieldChecks = (...fields) => {
    const errors = this.props.model.errors;
    const fieldsDom = fields.map(field => {
      const className = cx({
        error: errors[field] !== undefined
      });
      return (
        <Form.Check
          id={field}
          key={field}
          className={className}
          type='checkbox'
          checked={this.props.model.state.selectFields[field]}
          label={i18n.t<string>(`downloadReportPanel.labels.${_.camelCase(field)}`)}
          onChange={this.props.model.onFieldSelect}
        />
      );
    });
    return (
      <div className={styles.fieldChecks}>
        {fieldsDom}
      </div>
    );
  }

  renderGroupBy () {
    const model = this.props.model;
    return model.supportGroupBy.map(groupByType => {
      const groupById = _.camelCase(`group_by_${groupByType.toLowerCase()}`);
      return (
        <Form.Check
          key={groupByType}
          type='radio'
          label={i18n.t<string>(`downloadReportPanel.labels.${groupById}`)}
          value={groupByType}
          name='groupBy'
          id={groupById}
          checked={model.state.groupBy === groupByType}
          onChange={model.changeGroupBy}
        />
      );
    });
  }

  render () {
    const dateRangeShortCut: DateRange[] = [
      {
        label: 'Today',
        dateRange: [moment().startOf('day').toDate(), moment().endOf('day').toDate()]
      },
      {
        label: 'Yesterday',
        dateRange: [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]
      },
      {
        label: 'Last week',
        dateRange: [moment().subtract(7, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]
      }
    ];
    const model = this.props.model;
    const renderQuickSelect = () => model.reportTypeOptions.map(option => (
      <div
        key={option.value}
        onClick={_.partial(model.onQuickFieldSelect, option.value.toString())}
      >
        {option.label}
      </div>
    ));
    const renderErrors = () => Object.keys(model.errors).map(key => {
      const error = model.errors[key];
      return (
        <div key={key} className={styles.errorHint}>
          {error}
        </div>
      );
    });
    return (
      <Fragment>
        {model.state.loading && <LoadingIndicator />}
        <Modal
          title={i18n.t<string>('downloadReportPanel.labels.downloadReport')}
          dismiss={this.hide}
          animation={false}
          className={styles.downloadReportPanel}
          fullScreen
          enforceFocus={false}
          primaryButton={{
            title: i18n.t<string>('downloadReportPanel.buttons.download'),
            callback: this.download,
            disabled: !model.state.enableDownload
          }}
          secondaryButton={{
            title: i18n.t<string>('common.buttons.cancel'),
            callback: this.hide
          }}
        >
          <div className={styles.content}>
            <DateRangePickerField
              label={i18n.t<string>('downloadReportPanel.labels.dateRange')}
              popOverClassName={styles.datePicker}
              onChange={this.handleDateRangeChange}
              startDate={model.state.startDate}
              endDate={model.state.endDate}
              minDate={model.defaultStartDate}
              maxDate={model.defaultEndDate}
              format={'YYYY-MM-DD'}
              showTimePicker={false}
              shortcuts={dateRangeShortCut}
            />
            <CustomField
              name='groupBy'
              label={i18n.t<string>('downloadReportPanel.labels.groupBy')}
            >
              <div className={styles.groupBy}>
                {this.renderGroupBy()}
              </div>
            </CustomField>
            <CustomField
              label={i18n.t<string>('downloadReportPanel.labels.dataContent')}
              formGroupClassName={styles.dataContent}
            />
            <CustomField
              name='quickSelect'
              label={i18n.t<string>('downloadReportPanel.labels.quickSelect')}
            >
              <div>
                <div className={styles.quickSelect}>
                  {renderQuickSelect()}
                  <div onClick={_.partial(model.onQuickFieldSelect, 'reset')}>
                    {i18n.t<string>('downloadReportPanel.labels.reset')}
                  </div>
                </div>
                {this.renderFieldChecks(Fields.IMPRES, Fields.CPM)}
                {this.renderFieldChecks(Fields.CLICKS, Fields.CPC)}
                {this.renderFieldChecks(Fields.CTR, Fields.SPENT)}
                <div className={styles.errorContainer}>
                  {renderErrors()}
                </div>
              </div>
            </CustomField>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
