import {
  AuditLogWithPagination,
  AuditLogWebService,
  RestfulAuditLogWebService
} from 'ws/AuditLogWebService';
import { Pageable } from 'ws/Pageable';

export interface AuditLogManager {
  getOrderAuditLog (
    orderId: number | string,
    pageable: Pageable,
    searchString?: string
  ): Promise<AuditLogWithPagination>;
  getOrderAuditLogFields (
    orderId: number | string,
    campaignId?: number | string
  ): Promise<string[]>;
  getAgencyAuditLog (
    agencyId: number,
    pageable: Pageable,
    searchString?: string
  ): Promise<AuditLogWithPagination>;
  getAgencyAuditLogFields (
    agencyId: number
  ): Promise<string[]>;
  getAdvertiserAuditLog (
    advertiserId: string | number,
    pageable: Pageable,
    searchString?: string
  ): Promise<AuditLogWithPagination>;
  getAdvertiserAuditLogFields (
    advertiserId: string | number
  ): Promise<string[]>;
}

export class DefaultAuditLogManager implements AuditLogManager {

  constructor (
    private webService: AuditLogWebService = new RestfulAuditLogWebService()
  ) {}

  async getOrderAuditLog (
    orderId: number | string,
    pageable: Pageable,
    searchString?: string
  ): Promise<AuditLogWithPagination> {
    const auditLogWithPagination = await this.webService.getOrderAuditLog(orderId, pageable, searchString);
    return auditLogWithPagination;
  }

  async getOrderAuditLogFields (
    orderId: number | string,
    campaignId?: number | string
  ): Promise<string[]> {
    return this.webService.getOrderAuditLogFields(orderId, campaignId);
  }

  async getAgencyAuditLog (
    agencyId: number,
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination> {
    const auditLogWithPagination = await this.webService.getAgencyAuditLog(agencyId, pageable, searchString);
    return auditLogWithPagination;
  }

  async getAgencyAuditLogFields (
    agencyId: number
  ): Promise<string[]> {
    return this.webService.getAgencyAuditLogFields(agencyId);
  }

  async getAdvertiserAuditLog (
    advertiserId: string | number,
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination> {
    const auditLogWithPagination = await this.webService.getAdvertiserAuditLog(advertiserId, pageable, searchString);
    return auditLogWithPagination;
  }

  async getAdvertiserAuditLogFields (
    advertiserId: string | number
  ): Promise<string[]> {
    return this.webService.getAdvertiserAuditLogFields(advertiserId);
  }
}
