import React, { lazy, Suspense } from 'react';
import i18n from 'i18n';
import { ReportDimension, Metrics, ReportType } from 'core/report/ReportData';
import styles from './reportContent.module.scss';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTimesCircle, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { ReportTable } from './ReportTable/ReportTable';
import { SearchBar } from 'components/SearchBar';
import classNames from 'classnames/bind';
import { REPORT_COLUMNS } from './ReportTable/reportTableColumnSettings';
import { InputGroup, Button } from 'react-bootstrap';
import Select from 'components/Select/Select';
import { ReportContentProps, ReportContentState } from './ReportContentModel';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { CustomField } from 'components/form/field/CustomField';
const ReportChart = lazy(() =>
  import('./ReportChart/ReportChart').then(({ ReportChart }) => ({
    default: ReportChart
  }))
);
export class ReportContent extends React.PureComponent<ReportContentProps, ReportContentState> {

  cssClassNames: any;
  handler?: number;
  searchbarRef: any;

  constructor (props) {
    super(props);
    this.cssClassNames = classNames.bind(styles);
    this.searchbarRef = React.createRef();
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    const reportParams = new URLSearchParams(this.props.search);
    this.props.model.updateReportData(reportParams);
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      this.handler && prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      const reportParams = new URLSearchParams(this.props.search);
      this.props.model.updateReportData(reportParams);
    } else if (prevProps.search !== this.props.search) {
      if (_.isEmpty(this.props.search)) {
        if (this.searchbarRef.current) {
          this.searchbarRef.current.clear();
        }
        this.props.model.initReportData();
      } else {
        const reportParams = new URLSearchParams(this.props.search);
        this.props.model.updateReportData(reportParams);
      }
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  renderTags () {
    const model = this.props.model;
    return model.state.tags.map(tag => {
      const onFilterClick = () => {
        model.handleOnTagFilterClicked(tag);
      };
      const tagFilterClass = this.cssClassNames('tagFilter', {
        selected: model.state.selectedTagFilter.includes(tag)
      });
      return (
        <div key={tag} className={tagFilterClass} onClick={onFilterClick}>
          {tag}
        </div>
      );
    });
  }

  renderDownload () {
    return (
      <span onClick={this.props.model.download} className={styles.downloadWording}>
        <FontAwesomeIcon icon={faDownload} className={styles.downloadIcon} />
        {i18n.t<string>('report.labels.download')}
      </span>
    );
  }

  renderFilters () {
    const {
      getFilterOptions,
      addFilter,
      removeFilter,
      canFilterSelect,
      canFilterRemove
    } = this.props.model;
    const filterObject = this.props.model.state.filter;
    return Object.keys(filterObject).map(filter => (
      <CustomField
        key={filter}
        label={i18n.t<string>(`report.labels.${filter}`)}
        name={`${filter}Filter`}
      >
        <InputGroup>
          {
            <Select
              className={styles.filterList}
              options={getFilterOptions(filter)}
              name={`${filter}Filter`}
              simpleValue
              isDisabled={!canFilterSelect(filter)}
              value={filterObject[filter]}
              onChange={_.partial(addFilter, filter)}
            />
          }
          {canFilterRemove(filter) &&
            <InputGroup.Append>
              <FontAwesomeIcon
                className={styles.removeFilterBtn}
                icon={faTimesCircle}
                onClick={_.partial(removeFilter, filter)}
              />
            </InputGroup.Append>
          }
        </InputGroup>
      </CustomField>
    ));
  }

  render () {
    const {
      dimension,
      reportData,
      reportType,
      tableDimensions,
      tableData,
      tableColumnSettings,
      showReportChart,
      showDimensionSelectArea,
      dayRangeError,
      redirectPath,
      loading,
      modalData
    } = this.props.model.state;
    const {
      title,
      searchString,
      dimensionsShowId,
      toggleShowReportChart,
      toggleShowDimensionSelectArea,
      updateSearchPath,
      handleOnSearch,
      queryDataWithDimension,
      hideModal
    } = this.props.model;
    if (!reportData || tableColumnSettings.length === 0) {
      return <LoadingIndicator />;
    }
    if (redirectPath) {
      return (
        <Redirect to={redirectPath} push />
      );
    }
    const isDateDimension = [ReportDimension.MONTH, ReportDimension.DAY, ReportDimension.HOUR].includes(dimension);
    const searchBarPlaceholder = isDateDimension ?
      i18n.t<string>('reportTable.placeholders.searchDate') :
      dimensionsShowId.includes(dimension) ?
        i18n.t<string>('reportTable.placeholders.searchNameOrId') :
        i18n.t<string>('reportTable.placeholders.searchName');
    const dimensionSelectAreaClass = this.cssClassNames('dimensionSelectArea', {
      show: showDimensionSelectArea
    });
    const reportChartAreaClass = this.cssClassNames('reportChartArea', {
      show: showReportChart
    });
    const CustomDimensionComponent = this.props.customDimensionComponent;
    return (
      <div className={styles.reportContent}>
        {loading && <LoadingIndicator />}
        <div className={styles.titleArea}>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.triggerAreaButtons}>
            <div
              className={styles.triggerAreaButton}
              onClick={toggleShowDimensionSelectArea}
            >
              <FontAwesomeIcon icon={showDimensionSelectArea ? faAngleDown : faAngleUp} />
              {i18n.t<string>('report.labels.dimentSelectArea')}
            </div>
            <div
              className={styles.triggerAreaButton}
              onClick={toggleShowReportChart}
            >
              <FontAwesomeIcon icon={showReportChart ? faAngleDown : faAngleUp} />
              {i18n.t<string>('report.labels.reportChartArea')}
            </div>
          </div>
        </div>
        <div className={styles.contentArea}>
          <div className={dimensionSelectAreaClass}>
            {CustomDimensionComponent && <CustomDimensionComponent model={this.props.model} />}
            {this.renderFilters()}
            <CustomField
              formGroupClassName={styles.reportSearchBtnContainer}
              label=''
              name='reportSearchButton'
              withFieldLayout={true}
              permanentHint={reportType === ReportType.PERFORMANCE ? i18n.t<string>('report.labels.searchHint') : undefined}
            >
              <Button variant='primary' size='sm' onClick={_.partial(updateSearchPath, false)} disabled={dayRangeError !== undefined}>
                {i18n.t<string>('report.buttons.search')}
              </Button>
            </CustomField>
          </div>
          <Suspense fallback={<LoadingIndicator />}>
            <div className={reportChartAreaClass}>
              <ReportChart
                dimension={reportData.dimension}
                tableData={tableData}
                metrics={
                  tableColumnSettings
                    .map(columnSetting => columnSetting.dataField)
                    .filter(column => column !== REPORT_COLUMNS.NAME && column !== REPORT_COLUMNS.EDITBTNS)
                }
                defaultMertic1={Metrics.IMPRES}
                defaultMertic2={Metrics.CLICKS}
                textDecorators={
                  tableColumnSettings.reduce((acc, setting) => {
                    acc[setting.dataField] = setting.formatExtraData.textDecorator;
                    return acc;
                  }, {})
                }
              />
            </div>
          </Suspense>
          <div className={styles.filterArea}>
            <SearchBar
              ref={this.searchbarRef}
              model={{
                placeholder: searchBarPlaceholder,
                search: handleOnSearch,
                defaultValue: searchString
              }}
            />
            {this.renderTags()}
          </div>
          <div className={styles.downloadContainer}>{this.renderDownload()}</div>
          <div className={styles.reportTableContainer}>
            <ReportTable
              dimension={dimension}
              dimensions={tableDimensions}
              tableData={tableData}
              columnSettings={tableColumnSettings}
              onDimensionChange={queryDataWithDimension}
              modalData={modalData}
              hideModal={hideModal}
            />
          </div>
        </div>
      </div>
    );
  }
}
