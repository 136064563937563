import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { AdType } from '../rtbCampaign/RtbCampaign';
export type CampaignBindInfo = {
  id: string;
  startDate: string;
  endDate: string;
  name: string;
  adType: AdType;
  channel: L1ObjectChannel;
};

export function createCampaignBindInfo (campaign): CampaignBindInfo {
  return {
    id: campaign.id,
    startDate: campaign.startDate,
    endDate: campaign.endDate,
    name: campaign.name,
    adType: campaign.adType,
    channel: L1ObjectChannel.RTB
  } as CampaignBindInfo;
}
