import { StoredValueAction, StoredValueLog } from 'core/storedValue/StoredValueLog';
import { formatPrice, formatPriceWithCurrency } from 'helper/CurrencyHelper';
import styles from './storedValueDetail.module.scss';
import { FREEZE_ACTIONS } from 'core/storedValue/StoredValueManager';

export const priceFormatter = (value: number, record: StoredValueLog) => {
  if (value < 0) {
    return (
      <span className={styles.danger}>
        {formatPriceWithCurrency(record.currency, value)}
      </span>
    );
  }
  return formatPriceWithCurrency(record.currency, value);
};

export const amountFormatter = (value: number, record: StoredValueLog) => {
  if (FREEZE_ACTIONS.includes(record.action)) {
    return undefined;
  }
  if (value < 0) {
    return (
      <span className={styles.danger}>
        {formatPriceWithCurrency(record.currency, value)}
      </span>
    );
  }
  return `${record.currency} +${formatPrice(record.currency, value)}`;
};

export const freezeAmountFormatter = (_1: any, record: StoredValueLog) => {
  if (!FREEZE_ACTIONS.includes(record.action)) {
    return undefined;
  }
  switch (record.action) {
    case StoredValueAction.UNFREEZE_BUDGET:
      return `${record.currency} -${formatPrice(record.currency, record.amount)}`;
    case StoredValueAction.FREEZE_BUDGET:
      return (
        <span className={styles.danger}>
          {`${record.currency} +${formatPrice(record.currency, record.amount)}`}
        </span>
      );
    case StoredValueAction.REMOVE_FREEZE_BUDGET:
      return (
        <span className={styles.danger}>
          {`${record.currency} -${formatPrice(record.currency, record.amount)}`}
        </span>
      );
    default:
      return undefined;
  }
};
