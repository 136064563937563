import { SearchBar } from 'components/SearchBar';
import styles from './sectionWithSearchbar.module.scss';

export type SectionWithSearchbarProps = {
  defaultSearchString?: string;
  searchbarPlaceholder: string;
  onHandleSearch: (searchString: string) => void;
};

export const SectionWithSearchbar: React.FC<SectionWithSearchbarProps & React.PropsWithChildren> = ({
  children,
  defaultSearchString,
  searchbarPlaceholder,
  onHandleSearch
}) => {

  return (
    <div className={styles.operationArea}>
      {children}
      <SearchBar
        model={{
          placeholder: searchbarPlaceholder,
          search: onHandleSearch,
          defaultValue: defaultSearchString
        }}
      />
    </div>
  );
};
