import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import i18n from 'i18next';
import _ from 'lodash';
import { FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { validateEmpty, validateUrl } from 'utils/ValidateUtils';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { SelectOptions } from 'components/commonType';
import { PartnershipMode } from 'core';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';

export type AdvertiserFormState = {
  readonly loading: boolean;
  readonly initAdvertiser?: AdvertiserFormData;
  readonly redirectPath?: string;
};
export interface AdvertiserFormModel {
  readonly advertiserId?: string | number;
  readonly state: AdvertiserFormState;
  readonly event: UpdateEventListener<AdvertiserFormModel>;
  readonly title: string;
  readonly advertiser?: AdvertiserFormData;
  readonly formikProps?: FormikProps<AdvertiserFormData>;
  readonly breadcrumbs: any[];
  readonly partnershipModeOptions: SelectOptions[];
  init (): Promise<void>;
  validate (advertiser: AdvertiserFormData): any;
  validateAndSetError (): any;
  setFormikProps (props: FormikProps<AdvertiserFormData>): void;
  submit (): void;
  onUnmount (eventHandler): void;
}

export type AdvertiserFormProps = {
  readonly model: AdvertiserFormModel;
};

export type AdvertiserFormData = {
  id?: number,
  agencyId?: number,
  advertiserName: string,
  website: string,
  category?: string,
  note?: string
};

abstract class DefaultAdvertiserFormModel implements AdvertiserFormModel {
  event: FireableUpdateEventListener<AdvertiserFormModel>;
  loading: boolean;
  advertiser: any;
  formikProps?: FormikProps<AdvertiserFormData>;
  redirectPath?: string;
  partnershipModeOptions: SelectOptions[] = createSelectOptionsFromEnum(PartnershipMode, 'common.partnershipMode.');

  constructor (
    protected manager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    this.event = new FireableUpdateEventListener<AdvertiserFormModel>();
    this.loading = true;
  }

  abstract get title ();

  abstract initAdvertiser (): Promise<void>;

  abstract submit (): void;

  abstract get breadcrumbs ();

  async init () {
    this.updateState(true);
    try {
      await this.initAdvertiser();
    } catch (e) {}
    this.updateState(false);
  }

  validate = (advertiser: AdvertiserFormData) => {
    return _.omitBy(
      {
        advertiserName: validateEmpty(advertiser.advertiserName),
        website: validateUrl(advertiser.website),
        category: validateEmpty(advertiser.category)
      }, (value) => value === undefined);
  }

  setFormikProps (props: FormikProps<AdvertiserFormData>) {
    this.formikProps = props;
  }

  onUnmount = (eventHandler) => {
    this.event.remove(eventHandler);
    this.redirectPath = undefined;
  }

  validateAndSetError = () => {
    if (!this.formikProps) {
      return {};
    }

    let errors = this.validate(this.formikProps.values);
    this.formikProps && this.formikProps.setErrors(errors);
    let touched = {};
    this.generateFormikTouchedObj(errors, touched);
    this.formikProps && this.formikProps.setTouched(touched);
    return errors;
  }

  generateFormikTouchedObj (errors, touched) {
    let keys = Object.keys(errors);
    keys.forEach(key => {
      let value = errors[key];
      if (typeof value === 'object') {
        touched[key] = {};
        this.generateFormikTouchedObj(errors[key], touched[key]);
      } else {
        touched[key] = true;
      }
    });
  }

  get state (): AdvertiserFormState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath,
      initAdvertiser: this.advertiser
    };
  }

  updateState (loading: boolean, redirectPath?: string) {
    this.loading = loading;
    this.redirectPath = redirectPath;
    this.event.fireEvent(this);
  }
}

export class CreateAdvertiserFormModel extends DefaultAdvertiserFormModel {

  constructor (
    public agencyOptions: SelectOptions[],
    public agencyId: number | null | undefined,
    manager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    super(manager);
  }

  get breadcrumbs () {
    return [
      { path: '/advertisers', breadcrumb: i18n.t<string>('appMenus.comapnyManagement.items.advertisers') },
      { path: '/advertisers/new', breadcrumb: this.title }
    ];
  }

  async initAdvertiser (): Promise<void> {
    this.advertiser = {
      advertiserName: '',
      website: 'https://pchome.com.tw',
      comment: '',
      category: 'e_commerce',
      agencyId: this.agencyId,
      partnershipMode: PartnershipMode.SELF_SERVING
    };
  }

  async submit () {
    if (!this.formikProps) {
      return;
    }
    const newAdvertiser = this.formikProps.values;
    this.updateState(true);
    try {
      const advertiserId = await this.manager.createAdvertiser(newAdvertiser);
      this.updateState(false, `/advertisers/${advertiserId}`);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  get title () {
    return i18n.t<string>('advertiserForm.labels.createTitle');
  }
}

export class EditAdvertiserFormModel extends DefaultAdvertiserFormModel {

  agencyId?: number;
  advertiserId: string | number;

  constructor (
    advertiserId: string | number,
    manager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    super(manager);
    this.advertiserId = advertiserId;
  }

  get title () {
    return i18n.t<string>('advertiserForm.labels.editTitle');
  }

  get breadcrumbs () {
    const advertiserName = _.get(this.advertiser, 'advertiserName');
    return [
      { path: '/advertisers', breadcrumb: i18n.t<string>('appMenus.comapnyManagement.items.advertisers') },
      { path: '/advertisers/:advertiserId', breadcrumb: DynamicBreadcrumb, props: { label: advertiserName, matchParam: 'advertiserId' } },
      { path: '/advertisers/:advertiserId/edit', breadcrumb: DynamicBreadcrumb, props: { prefix: i18n.t<string>('common.labels.edit'), label: advertiserName, matchParam: 'advertiserId' } }
    ];
  }

  async initAdvertiser (): Promise<void> {
    this.advertiser = await this.manager.getAdvertiser(this.advertiserId);
  }

  async submit () {
    if (!this.formikProps) {
      return;
    }

    const editedAdvertiser = { ...this.formikProps.values };
    this.updateState(true);
    try {
      await this.manager.updateAdvertiser(editedAdvertiser);
      this.updateState(false, `/advertisers/${this.advertiserId}`);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }
}
