import { Currency } from 'core/agency/Agency';
import { Pagination } from 'core/pagination/Pagination';

export type StoredValueAgencyRecordsWithPagination = {
  pagination: Pagination,
  records: StoredValueAgencyRecord[]
};

export type StoredValueLog = {
  storedValueId: number,
  adAgencyId: number,
  vendorNumber: string,
  amount: number,
  description: string,
  comment: string,
  createTime: string,
  adAgencyName: string,
  currency: Currency,
  remain: number,
  action: StoredValueAction
};

export type StoredValueAgencyRecord = {
  adAgencyId: number,
  vendorNumber: string,
  supplierName: string,
  currency: Currency,
  remain: number,
  totalAmount: number,
  remainingAmount: number,
  usedAmount: number
  action: StoredValueAction
};

export type StoredValueSummary = {
  adAgencyId: number,
  vendorNumber: string,
  adAgencyName: string,
  currency: Currency,
  stored: number,
  used: number,
  remain: number,
  freezed: number,
  logs: StoredValueLog[]
};

export enum StoredValueAction {
  RECHARGE = 'RECHARGE',
  EDIT_BUDGET = 'EDIT_BUDGET',
  CREATE_ORDER = 'CREATE_ORDER',
  EDIT_ORDER = 'EDIT_ORDER',
  SETTLE_ORDER = 'SETTLE_ORDER',
  FREEZE_BUDGET = 'FREEZE_BUDGET',
  UNFREEZE_BUDGET = 'UNFREEZE_BUDGET',
  REMOVE_FREEZE_BUDGET = 'REMOVE_FREEZE_BUDGET'
}
