import { getDateRangeColumn, getLabelValueColumn, getLinkColumn, getStatusDesColumn, getStringArrayColumn, headerWithIconFormatter, sortableColumn } from 'components/TableColumn/TableColumn';
import { Pmp, PmpAdminStatus, PmpStatus, PmpType, PmpsWithPagination } from 'core/pmp/Pmp';
import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18n';
import { DefaultPmpManager } from 'core/pmp/PmpManager';
import { camelCase, compact, get, uniq, defaultTo } from 'lodash';
import { Pageable } from 'ws/Pageable';
import { useCoreContext } from 'contexts/coreContext';
import { SelectOptions } from 'components/commonType';
// import { FilterMenuTabConfig } from 'components/FilterMenuTab/FilterMenuTab';
import { PmpDurationError, usePmpDurationValidator } from './PmpDurationValidator';
import infoIcon from 'assets/error_outlined_small.svg';

export enum PmpListColumns {
  NAME = 'name',
  STATUS = 'adminStatus',
  AGENCYID = 'adAgencyId',
  SCHEDULE = 'startTime',
  BUDGET = 'budget',
  SPACE = 'space'
}

const pmpManager = new DefaultPmpManager();

export const usePmpListModel = (
  spaceOptions: SelectOptions[],
  singleSelect?: boolean,
  defaultSelectedPmp: number[] = [],
  pmpList?: Pmp[],
  canEdit: boolean = true,
  showStatus: boolean = true
) => {

  const fetchPmpsFromServer = !pmpList;
  const [pmps, setPmps] = useState<Pmp[]>(defaultTo(pmpList, []));
  const [searchString, setSearchString] = useState<string>('');
  const [selectedPmp, setSelectedPmp] = useState<number[]>(defaultSelectedPmp);
  // const [selectedAdminStatus, setSelectedAdminStatus] = useState<number[]>([]);
  // const [selectedStatusFilter, setSelectedStatusFilter] = useState<string[]>([]);
  const [pageable, setPageable] = useState<Pageable & { totalCount: number}>({
    page: 1,
    sizePerPage: 10,
    sort: 'id',
    direction: 'desc',
    totalCount: pmpList ? pmpList.length : 0
  });
  const [pmpDurationError, setPmpDurationError] = useState<PmpDurationError & Pmp | undefined>();

  const core = useCoreContext();
  const currency = get(core, 'accountManager.localeMeta.currency', 'NTD');

  const {
    loading,
    callAPIs
  } = useCallAPI();

  const {
    loading: loadingDurationValidator,
    fetchPmpWithinDuration,
    validatePmpDuration,
    clearCacheWithin
  } = usePmpDurationValidator();

  const fetchInvalidPmpWithinDuration = useCallback((pmps: Pmp[]) => {
    const inactivePmps: Pmp[] = pmps.filter(pmp => pmp.status === PmpStatus.INACTIVE);
    inactivePmps.forEach(pmp => fetchPmpWithinDuration(pmp.startTime, pmp.endTime));
  }, [fetchPmpWithinDuration]);

  const getPmpStatusFilterString = useCallback((status: PmpAdminStatus) => {
    return i18n.t<string>(`pmp.adminStatus.${camelCase(PmpAdminStatus[status])}`);
  }, []);

  const getPmpStatusColor = useCallback((status: PmpAdminStatus) => {
    switch (status) {
      case PmpAdminStatus.BINDING:
        return 'primary';
      case PmpAdminStatus.STOP:
        return 'black';
      case PmpAdminStatus.BINDING_PARTIAL_SUSPEND:
        return 'info';
      case PmpAdminStatus.BINDING_ALL_SUSPEND:
        return 'danger';
      case PmpAdminStatus.FINISH:
      case PmpAdminStatus.NOT_BINDING:
        return 'light';
      default:
        return '';
    }
  }, []);

  const getPmpStatusDesData = useCallback((status: PmpAdminStatus) => {
    return {
      des: getPmpStatusFilterString(status),
      color: getPmpStatusColor(status)
    };
  }, [getPmpStatusFilterString, getPmpStatusColor]);

  const fetchPmps = useCallback((page: number = 1) => {
    callAPIs([() => pmpManager.getPmpList({ page, sort: pageable.sort, direction: pageable.direction, sizePerPage: pageable.sizePerPage }, searchString)], (pmpsWithPagination: PmpsWithPagination) => {
      setPmps(pmpsWithPagination.pmps);
      setPageable(prev => ({
        ...prev,
        page: pmpsWithPagination.pagination.page,
        sizePerPage: pmpsWithPagination.pagination.size,
        totalCount: pmpsWithPagination.pagination.totalCount
      }));
      fetchInvalidPmpWithinDuration(pmpsWithPagination.pmps);
    });
  }, [pageable.sort, pageable.direction, pageable.sizePerPage, searchString, callAPIs, fetchInvalidPmpWithinDuration]);

  useEffect(() => {
    fetchPmpsFromServer && fetchPmps();
  }, [fetchPmpsFromServer, fetchPmps]);

  const handleTableChange = useCallback((type, props) => {
    if (type === 'sort') {
      (props.sortField !== pageable.sort || props.sortOrder !== pageable.direction) &&
        setPageable(prev => ({
          ...prev,
          page: 1,
          sort: props.sortField,
          direction: props.sortOrder
        }));
      return;
    }
    fetchPmps(props.page);
  }, [pageable.sort, pageable.direction, fetchPmps]);

  const onHandleSelect = useCallback((id: string | number, select: boolean) => {
    if (singleSelect) {
      setSelectedPmp(select ? [+id] : []);
      return;
    }
    setSelectedPmp(prev => select ? uniq([...prev, +id]) : prev.filter(p => p.toString() !== id.toString()));
  }, [singleSelect]);

  const onHandleSelectPage = useCallback((select: boolean) => {
    const allId = pmps.map(pmp => pmp.id);
    const offset = (pageable.page - 1) * pageable.sizePerPage;
    const end = offset + pageable.sizePerPage;
    const targetId = allId.slice(offset, end);
    setSelectedPmp(prev => select ?
      uniq([...prev, ...targetId]) :
      prev.filter(p => !targetId.includes(p))
    );
  }, [pmps, pageable.page, pageable.sizePerPage]);

  const statusColumn = useMemo(() => getStatusDesColumn(
    sortableColumn(PmpListColumns.STATUS, i18n.t<string>('pmpList.headers.status'), false),
    (pmp: Pmp) => getPmpStatusDesData(pmp.adminStatus)
  ), [getPmpStatusDesData]);

  const columns = useMemo(() => (compact([
    canEdit ? getLinkColumn(sortableColumn(PmpListColumns.NAME, i18n.t<string>('pmpList.headers.name'), false), (pmp: Pmp) => {
      if (pmp.campaignId) {
        return;
      }
      return `/pmp/${pmp.id}/edit`;
    }) : sortableColumn(PmpListColumns.NAME, i18n.t<string>('pmpList.headers.name'), false),
    showStatus ? {
      ...statusColumn,
      formatExtraData: {
        ...statusColumn.formatExtraData,
        hint: i18n.t<string>('pmpList.hints.pmpStatus'),
        icon: infoIcon
      },
      headerFormatter: headerWithIconFormatter
    } : undefined,
    getDateRangeColumn(sortableColumn(PmpListColumns.SCHEDULE, i18n.t<string>('pmpList.headers.schedule'), false), (pmp: Pmp) => ({ startDate: pmp.startTime, endDate: pmp.endTime })),
    getLabelValueColumn(
      sortableColumn(PmpListColumns.BUDGET, i18n.t<string>('pmpList.headers.budget'), false),
      (pmp: Pmp) => (compact([
        { label: i18n.t<string>('pmp.labels.pmpType'), value: i18n.t<string>(`pmp.pmpTypes.${camelCase(pmp.pmpType)}`) },
        { label: i18n.t<string>('pmp.labels.budget'), value: `${currency} ${pmp.budget}` },
        pmp.pmpType === PmpType.PROGRAMMATIC_GUARANTEED ? { label: i18n.t<string>('pmp.labels.targetQuantity'), value: pmp.targetQuantity! } : undefined
      ]))
    ),
    getStringArrayColumn(sortableColumn(PmpListColumns.SPACE, i18n.t<string>('pmpList.headers.space'), false), (value: string | number) => spaceOptions.find(option => option.value.toString() === value.toString())?.label || value.toString())
  ])), [canEdit, showStatus, currency, spaceOptions, statusColumn]);

  const onHandleSearch = useCallback((searchString: string) => {
    setSearchString(searchString);
  }, []);

  const filterdPmps = useMemo(() => {
    return pmps.filter(pmp => {
      const nameMatch = pmp.name.includes(searchString);
      return (nameMatch);
    });
  }, [pmps, searchString]);

  const activatePmp = useCallback(() => {
    const pmp = pmps.find(pmp => selectedPmp.includes(pmp.id));
    if (!pmp) {
      return;
    }
    const pmpDurationError = validatePmpDuration(pmp);
    if (pmpDurationError) {
      setPmpDurationError({
        ...pmpDurationError,
        ...pmp
      });
    } else {
      callAPIs([() => pmpManager.updatePmpState(selectedPmp, 'activate')], () => {
        const pmp = pmps.find(pmp => selectedPmp.includes(pmp.id));
        // clear duration cache if pmp status is changed
        pmp && clearCacheWithin(pmp.startTime, pmp.endTime);
        fetchPmps();
        setSelectedPmp([]);
      });
    }
  }, [pmps, selectedPmp, clearCacheWithin, validatePmpDuration, callAPIs, fetchPmps]);

  const deactivePmp = useCallback(() => {
    callAPIs([() => pmpManager.updatePmpState(selectedPmp, 'deactivate')], () => {
      const pmp = pmps.find(pmp => selectedPmp.includes(pmp.id));
      // clear duration cache if pmp status is changed
      pmp && clearCacheWithin(pmp.startTime, pmp.endTime);
      fetchPmps();
      setSelectedPmp([]);
    });
  }, [pmps, selectedPmp, clearCacheWithin, callAPIs, fetchPmps]);

  const batchOperations = useMemo(() => ([{
    operation: {
      text: i18n.t<string>('pmpList.buttons.activate'),
      hint: '',
      enable: true,
      onClick: activatePmp
    }
  }, {
    operation: {
      text: i18n.t<string>('pmpList.buttons.deactivate'),
      enable: selectedPmp.length > 0,
      hint: '',
      onClick: deactivePmp
    }
  }]), [deactivePmp, activatePmp, selectedPmp]);

  // const filterMenuTabConfigs: FilterMenuTabConfig[] = useMemo(() => {
  //   const pmpAdminStatusList = uniq(pmps.map(pmp => pmp.adminStatus));
  //   return [
  //     {
  //       filterType: i18n.t<string>('pmpList.labels.statusFilter'),
  //       menuTitle: i18n.t<string>('pmpList.labels.statusFilterMenuTitle'),
  //       tag: i18n.t<string>('pmpList.labels.statusTag'),
  //       selectedValues: selectedStatusFilter,
  //       options: pmpAdminStatusList.map(adminStatus => getPmpStatusFilterString(adminStatus)),
  //       applyMethod: (adminStatusDesList: string[]) => {
  //         setSelectedStatusFilter([...adminStatusDesList]);
  //         setSelectedAdminStatus(pmpAdminStatusList.filter(adminStatus => adminStatusDesList.includes(getPmpStatusFilterString(adminStatus))));
  //       }
  //     }
  //   ];
  // }, [pmps, selectedStatusFilter, getPmpStatusFilterString]);

  return {
    loading: loading || loadingDurationValidator,
    columns,
    pageable,
    filterdPmps,
    selectedPmp,
    searchString,
    batchOperations,
    pmpDurationError,
    // filterMenuTabConfigs,
    fetchPmps,
    cancelActivate: () => setPmpDurationError(undefined),
    onHandleSearch,
    onHandleSelect,
    onHandleSelectPage,
    handleTableChange
  };
};
