import React from 'react';

import './loginBackground.scss';
import loginFormImageSvg from 'assets/login-page-illustration.svg';
import { PrivacyPolicy } from 'components/PrivacyPolicy/PrivacyPolicy';
import className from 'classnames';
// import { Announcement } from 'components/Announcement/Announcement';

export const LoginBackground = ({ children }) => {

  const formImageStyle = className('form-image', {
    fill: true
  });
  return (
    <div className='login-background'>
      <div className='form-container'>
        <img
          className={formImageStyle}
          src={loginFormImageSvg}
          alt='illustration'
        />
        {children}
        <div className='bottom-area'>
          {/* <Announcement className='loginPageAnnouncement'/> */}
          <PrivacyPolicy className='loginPagePrivacyPolicy'/>
        </div>
      </div>
    </div>
  );
};
