import React from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';

const defaultCampaignManager: RtbCampaignManager = new DefaultRtbCampaignManager();
export class CampaignRedirect extends React.PureComponent<{
  campaignId: string | number,
  action?: string,
  campaignManager?: RtbCampaignManager
}, {redirectPath: string | undefined}> {

  constructor (props) {
    super(props);
    this.state = {
      redirectPath: undefined
    };
  }

  componentDidMount () {
    this.getParentInfo();
  }

  async getParentInfo () {
    const campaignManager = this.props.campaignManager ? this.props.campaignManager : defaultCampaignManager;
    try {
      const {
        orderNumber,
        l1ObjectId
      } = await campaignManager.getRedirectInfo(this.props.campaignId);
      const redirectPath = this.props.action ?
        `/orders/${orderNumber}/campaign-groups/${l1ObjectId}/campaigns/${this.props.campaignId}/${this.props.action}` :
        `/orders/${orderNumber}/campaign-groups/${l1ObjectId}?search=${this.props.campaignId}`;
      this.setState({
        redirectPath
      });
    } catch (e) {
      this.setState({
        redirectPath: '/'
      });
    }
  }

  render () {
    if (this.state.redirectPath) {
      return <Redirect to={this.state.redirectPath} />;
    }
    return (
      <LoadingIndicator />
    );
  }
}
