import _, { isNil } from 'lodash';

import client from './RestClient';
import { Advertiser } from 'core/advertiser/Advertiser';
import { SelectOptions } from 'components/commonType';
import { Pageable } from './Pageable';
import { Pagination } from 'core/pagination/Pagination';
import { CompanyMember } from 'core/companyMember/CompanyMember';
import { Account } from 'core';
import { CompanyRole } from 'core/companyMember/CompanyRole';
import { AxiosInstance } from 'axios';

export interface AdvertiserWebService {
  getAdvertiser (advertiserId): Promise<Advertiser>;
  createAdvertiser (advertiser: Partial<Advertiser>): Promise<number>;
  updateAdvertiser (advertiser: Partial<Advertiser>): Promise<number>;
  getAdvertisers (
    agencyId: number | string | undefined,
    pageable: Pageable,
    search?: string): Promise<AdvertisersWithPagination>;
  getAdvertiserOptions (agencyId?: number | null): Promise<Array<SelectOptions>>;
  getAdvertiserName (advertiserId: number): Promise<string | null>;
  getAdvertiserMembers (advertiserId: number | string): Promise<Array<CompanyMember>>;
  deleteAdvertiserMembers (advertiserId: number, accountId: number): Promise<void>;
  getAdvertiserMember (advertiserId: number, accountId: number): Promise<CompanyMember>;
  createAdvertiserMember (advertiserId: number, companyRole: CompanyRole): Promise<void>;
  updateAdvertiserMember (advertiserId: number, companyRole: CompanyRole): Promise<void>;
}

export type AdvertisersWithPagination = {
  pagination: Pagination,
  advertisers: Array<Advertiser>
};

function wrapPagination (json: any): any {
  return {
    page: _.get(json, 'page', 1),
    size: _.get(json, 'size', 10),
    totalCount: _.get(json, 'totalCount', 0)
  };
}

function wrapAdvertiser (json: any): Advertiser {
  const note = _.get(json, 'note', '');
  return {
    ...json,
    id: _.get(json, 'advertiserId'),
    agencyId: _.get(json, 'agencyId'),
    advertiserName: _.get(json, 'advertiserName', ''),
    website: _.get(json, 'website', ''),
    category: _.get(json, 'category', ''),
    note: note === null ? undefined : note,
    productFeedID: _.get(json, 'productFeedID', ''),
    isProductFeedReady: _.get(json, 'isProductFeedReady', false),
    preferredPublisherId: _.get(json, 'preferredPublisherId', [])
  };
}

function wrapCompanyMember (json: any): CompanyMember {
  return {
    id: _.get(json, 'id'),
    account: wrapAccount(_.get(json, 'account')),
    roleName: _.get(json, 'roleName')
  };
}

function wrapAccount (json: any): Account {
  return {
    language: _.get(json, 'language'),
    activated: _.get(json, 'activated'),
    email: _.get(json, 'email'),
    name: _.get(json, 'name'),
    id: _.get(json, 'account_id'),
    isAdmin: _.get(json, 'is_admin'),
    status: _.get(json, 'status'),
    actors: []
  };
}

export class RestfulAdvertiserWebService implements AdvertiserWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getAdvertiser (advertiserId): Promise<Advertiser> {
    const response = await this.restClient.get(`/v2/advertisers/${advertiserId}`);
    return wrapAdvertiser(response.data);
  }

  async createAdvertiser (advertiser: Partial<Advertiser>): Promise<number> {
    const response = await this.restClient.post(`/v2/advertisers`, advertiser);
    return _.get(response.data, 'advertiserId', 0);
  }

  async updateAdvertiser (advertiser: Partial<Advertiser>): Promise<number> {
    const response = await this.restClient.put(`/v2/advertisers`, {
      ..._.omit(advertiser, ['id']),
      advertiserId: advertiser.id
    });
    return _.get(response.data, 'advertiserId', 0);
  }

  async getAdvertisers (
    agencyId: number | string | undefined,
    pageable: Pageable,
    search: string = ''): Promise<AdvertisersWithPagination> {
    let { page, sizePerPage, sort, direction } = pageable;
    sort = sort ? sort : 'id';
    direction = direction ? direction : 'desc';
    let response ;
    let url = `/v2/advertisers?search=${encodeURIComponent(search)}&page=${page}&size=${sizePerPage}&sort=${sort},${direction}`;
    url = agencyId ? `${url}&agencyId=${agencyId}` : url;
    response = await this.restClient.get(url);
    return {
      advertisers: _.defaultTo(response.data.records, []).flatMap((json: any) =>
        wrapAdvertiser(json)
      ),
      pagination: wrapPagination(response.data.pagination)
    };
  }

  async getAdvertiserOptions (agencyId?: number | null): Promise<Array<SelectOptions>> {
    const path = !isNil(agencyId) ? `/v2/advertisers/options?agencyId=${agencyId}` : '/v2/advertisers/options';
    const response = await this.restClient.get(path);
    return _.get(response, 'data.records', []);
  }

  async getAdvertiserName (advertiserId: number): Promise<string | null> {
    const response = await this.restClient.get(`/v2/advertisers/${advertiserId}/name`);
    return response.data.result;
  }

  async getAdvertiserMembers (advertiserId: number | string): Promise<Array<CompanyMember>> {
    const response = await this.restClient.get(`/v2/advertisers/${advertiserId}/members`);
    return _.get(response, 'data.records', []).map(member => wrapCompanyMember(member));
  }

  async deleteAdvertiserMembers (advertiserId: number, accountId: number): Promise<void> {
    return this.restClient.delete(`/v2/advertisers/${advertiserId}/members/${accountId}`);
  }

  async getAdvertiserMember (advertiserId: number, accountId: number): Promise<CompanyMember> {
    const response = await this.restClient.get(`/v2/advertisers/${advertiserId}/members/${accountId}`);
    return wrapCompanyMember(_.get(response, 'data', []));
  }

  async createAdvertiserMember (advertiserId: number, companyRole: CompanyRole): Promise<void> {
    return this.restClient.post(`/v2/advertisers/${advertiserId}/members?email=${companyRole.email}&role_name=${companyRole.role}`);

  }

  async updateAdvertiserMember (advertiserId: number, companyRole: CompanyRole): Promise<void> {
    return this.restClient.put(`/v2/advertisers/${advertiserId}/members?email=${companyRole.email}&role_name=${companyRole.role}`);
  }
}
