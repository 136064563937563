import React, { useMemo, useRef } from 'react';
import { Formik, Form } from 'formik';
import { ErrorHandler } from 'components/form/FormErrorHandler/FormErrorHandler';
import { CreativeBasicForm } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/SubSteps/CreativeBasicForm';
import { Button } from 'react-bootstrap';
import i18n from 'i18n';
import { DefaultCreativeBasicFormModel } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/SubSteps/CreativeBasicFormModel';
import { DefaultCreativeManager } from 'core/creative/CreativeManager';
import styles from './creativeSetupStep.module.scss';
import { CreateCreativeInL2ObjectSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { LoadingIndicator } from 'components/LoadingIndicator';

export const CreativeSetupStep: React.FC<{
  model: CreateCreativeInL2ObjectSetupFlowPageModel,
  goLast: () => void,
  goNext: (cb?: () => void) => void
}> = ({
  model,
  goNext,
  goLast
}) => {

  const mainDomRef = useRef(null);

  const handleSubmit = (values) => {
    goNext(() => {
      model.setCreative({
        basic: values
      });
    });
  };

  const basicFormModel = useMemo(() => model.creative ?
    new DefaultCreativeBasicFormModel(
      'create',
      model.creative.basic,
      new DefaultCreativeManager(),
      model.supportedCreativeType,
      () => {},
      model.getFormContentModelOfType
    ) : undefined,
  [model.creative, model.getFormContentModelOfType, model.supportedCreativeType]);

  if (!basicFormModel) {
    return <LoadingIndicator />;
  }

  return (
    <div className={styles.setupStep} ref={mainDomRef}>
      <Formik
        initialValues={basicFormModel.initCreative}
        enableReinitialize
        onSubmit={handleSubmit}
        onValidate={basicFormModel.validate}
        validateOnBlur={false}
      >
        {formikProps => {
          basicFormModel.setFormikProps(formikProps);
          return (
            <Form>
              <ErrorHandler
                parentRef={mainDomRef}
                isSubmitting={formikProps.isSubmitting}
                submitCount={formikProps.submitCount}
              />
              <CreativeBasicForm
                model={basicFormModel}
              />
              <div className={styles.buttonArea}>
                <Button
                  variant='primary'
                  size='sm'
                  type='submit'
                >
                  {i18n.t<string>('campaign.buttons.completeAndCheck')}
                </Button>
                <Button variant='secondary' size='sm' onClick={goLast}>
                  {i18n.t<string>('campaign.buttons.back')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
