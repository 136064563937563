import { CreativeListRecord, CreativeOfCampaign, CreativeType } from 'core/creative/Creative';
import { NormalCreativePreview } from './NormalCreativePreview';
import { ProductNativeCreativePreview } from './ProductNativePreview';
import { RichProductNativeCreativePreview } from './RichProductNativePreview';

const previewComponentSettings = {
  [CreativeType.IMAGE]: NormalCreativePreview,
  [CreativeType.PRODUCT_NATIVE]: ProductNativeCreativePreview,
  [CreativeType.RICH_PRODUCT_NATIVE]: RichProductNativeCreativePreview
};

export interface CreativePreviewModel {
  creative: CreativeOfCampaign | CreativeListRecord;
  previewComponent: any;
}

export type CreativePreviewProps = {
  model: CreativePreviewModel
};

export class DefaultCreativePreviewModel implements CreativePreviewModel {

  creative: CreativeOfCampaign | CreativeListRecord;
  previewComponent: any;

  constructor (creative: CreativeOfCampaign | CreativeListRecord) {
    this.creative = creative;
    if (this.creative.creativeType) {
      this.previewComponent = previewComponentSettings[this.creative.creativeType];
    }
  }
}
