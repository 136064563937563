import React from 'react';
import classNames from 'classnames/bind';
import { ModifyCreativeActivationStateContentProps, ModifyCreativeActivationStateContentState } from './ModifyCreativeActivationStateContentModel';
import styles from './modifyCreativeActivationStateContent.module.scss';
import { StateDataContext } from 'containers/Common/StateDataContext';
import { Slider } from 'components/Slider/Slider';
import { DefaultSliderModel } from 'components/Slider/SliderModel';
import { CreativeList } from './CampaignCreativeList/CreativeList';
import prevIcon from 'assets/slider-prev.svg';
import nextIcon from 'assets/slider-next.svg';
import { CreativeManagementStateContext } from './CreativeManagementStateContext';

export class ModifyCreativeActivationStateContent extends React.Component<ModifyCreativeActivationStateContentProps, ModifyCreativeActivationStateContentState> {

  handler: number;
  cssClassNames: any;

  constructor (props) {
    super(props);
    this.cssClassNames = classNames.bind(styles);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  renderCampaignOptions () {
    return this.props.model.creativeData.map(creativeData => {
      const key = creativeData.id;
      return <div className={styles.campaignOption} key={key}>{creativeData.name}</div>;
    });
  }

  render () {
    const model = this.props.model;
    return (
      <StateDataContext.Consumer>
        {(stateContext: CreativeManagementStateContext) => {
          model.setStateContext(stateContext);
          return (
            <div className={styles.modifyCreativeActivationStateContent}>
              {model.creativeData.length > 1 &&
                <div className={styles.sliderArea}>
                  <Slider
                    nextIcon={<img src={nextIcon} alt={'nextIcon'}/>}
                    prevIcon={<img src={prevIcon} alt={'prevIcon'}/>}
                    model={new DefaultSliderModel(680, undefined, true, false, model.state.currentCampaignIndex, false, model.onCampaignChange)}
                  >
                    {this.renderCampaignOptions()}
                  </Slider>
                </div>
              }
              <div className={styles.title}>
                {model.getTitle()}
              </div>
              <div className={styles.creatives}>
                <CreativeList
                  model={model.getCreativeListModel()}
                />
              </div>
            </div>
          );
        }}
      </StateDataContext.Consumer>
    );
  }
}
