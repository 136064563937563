import { PMaxCore, AddonFeatureManager, RoleNames } from 'core';
import { AdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { CreativesHomeModel, DefaultCreativesHomeModel, SysAdminCreativesHomeModel } from './CreativesHome/CreativesHomeModel';
import {
  CreateCreativeSetupFlowPageModel,
  EditCreativeSetupFlowPageModel,
  CreateCreativeFromRtbCampaignSetupFlowPageModel,
  CreateProductNativeCreativeSetupFlowPageModel
} from './CreativeSetupFlow/CreativeSetupFlowPageModel';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { SelectOptions } from 'components/commonType';
import { notRoles } from 'core/permission/PermissionDSL';
import { isSystemAdmin } from 'helper/ActorHelper';
import { Product } from 'core/product/Product';
import { ProductFilter } from 'containers/RetailRMN/Products/ProductFilter';
import { CreativeSetupFlowPageModel } from './CreativeSetupFlow/CreativeSetupFlowPageModelInterface';

export interface CreativesModel {
  readonly event: UpdateEventListener<CreativesModel>;
  readonly state: CreativesState;
  readonly addonFeatureManager: AddonFeatureManager;
  init (): void;
  getCreativesHomeModel (): CreativesHomeModel | undefined;
  getCreativeCreateModel (): CreativeSetupFlowPageModel | undefined;
  getCreativeProductNativeCreateModel (products: Product[], filters: ProductFilter): CreativeSetupFlowPageModel | undefined;
  getCreativeEditModel (creativeId: number, campaignId?: string, orderNumber?: string, l1ObjectId?: string): CreativeSetupFlowPageModel | undefined;
  getCreativeCreateFromCampaignModel (orderNumber: string, l1ObjectId?: string | null, campaignId?: string | null, channel?: string | null): CreativeSetupFlowPageModel | undefined;
  onUnmount (eventHandler?: number): void;
}

export type CreativesProps = {
  readonly model: CreativesModel;
};

export type CreativesState = {
  readonly loading: boolean;
  readonly advertisers?: Array<SelectOptions>;
};

export class DefaultCreativesModel implements CreativesModel {

  advertiserManager: AdvertiserManager;
  addonFeatureManager: AddonFeatureManager;
  advertisers?: Array<SelectOptions>;
  loading: boolean;
  event: FireableUpdateEventListener<CreativesModel>;
  creativesHomeModel?: CreativesHomeModel;
  creativeCreateModel?: CreateCreativeSetupFlowPageModel;
  creativeEditModel?: EditCreativeSetupFlowPageModel;
  creativeCreateFromCampaignModel?: CreativeSetupFlowPageModel;
  canChooseAdvertiser: boolean;
  agencyId?: number;
  productNativeCreativeCreateModel?: CreateProductNativeCreativeSetupFlowPageModel;

  constructor (private core: PMaxCore) {
    this.advertiserManager = core.advertiserManager;
    this.addonFeatureManager = core.addonFeatureManager;
    this.loading = true;
    this.event = new FireableUpdateEventListener<CreativesModel>();
    const chooseAdvertiserPermission = notRoles(RoleNames.adsAdmin, RoleNames.adsSales, RoleNames.adsReport);
    this.canChooseAdvertiser = chooseAdvertiserPermission.visible({ actor: core.authenticationManager.actor });
    this.agencyId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY);
  }

  get state () {
    return {
      loading: this.loading,
      advertisers: this.advertisers
    };
  }

  async init (): Promise<void> {
    this.updateState(true);
    try {
      this.advertisers = await this.advertiserManager.getAdvertiserOptions();
      this.updateState(false);
    } catch (error) {
      this.updateState(false);
    }
  }

  onAgencyChange = async (agencyId: number) => {
    this.updateState(true);
    try {
      this.advertisers = await this.advertiserManager.getAdvertiserOptions(agencyId);
      this.agencyId = agencyId;
    } catch (error) {}
    this.updateState(false);
  }

  getCreativesHomeModel () {
    if (!this.advertisers) {
      return undefined;
    }

    const currentAdvertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
    if (this.creativesHomeModel &&
      this.creativesHomeModel.advertisers === this.advertisers &&
      this.creativesHomeModel.advertiserId === currentAdvertiserId) {
      return this.creativesHomeModel;
    }

    if (isSystemAdmin(this.core.authenticationManager.actor)) {
      this.creativesHomeModel = new SysAdminCreativesHomeModel(this.advertisers, this.addonFeatureManager, this.onAgencyChange);
    } else {
      this.creativesHomeModel = new DefaultCreativesHomeModel(this.advertisers, this.addonFeatureManager);
    }
    return this.creativesHomeModel;
  }

  getCreativeCreateFromCampaignModel (orderNumber: string, l1ObjectId?: string, campaignId?: string, channel?: string | null): CreativeSetupFlowPageModel | undefined {
    if (!this.advertisers) {
      return undefined;
    }

    if (this.creativeCreateFromCampaignModel &&
      this.creativeCreateFromCampaignModel.campaignId === campaignId &&
      this.creativeCreateFromCampaignModel.orderNumber === orderNumber &&
      this.creativeCreateFromCampaignModel.l1ObjectId === l1ObjectId
    ) {
      return this.creativeCreateFromCampaignModel;
    }

    this.creativeCreateFromCampaignModel = new CreateCreativeFromRtbCampaignSetupFlowPageModel(false, this.advertisers, this.addonFeatureManager, campaignId, orderNumber, l1ObjectId);
    return this.creativeCreateFromCampaignModel;
  }

  getCreativeCreateModel (): CreativeSetupFlowPageModel | undefined {
    if (!this.advertisers) {
      return undefined;
    }

    if (this.creativeCreateModel) {
      return this.creativeCreateModel;
    }

    this.creativeCreateModel = new CreateCreativeSetupFlowPageModel(this.canChooseAdvertiser, this.advertisers, this.addonFeatureManager);
    return this.creativeCreateModel;
  }

  getCreativeProductNativeCreateModel (products: Product[], filters: ProductFilter): CreativeSetupFlowPageModel | undefined {
    if (!this.advertisers) {
      return undefined;
    }

    if (this.productNativeCreativeCreateModel &&
      this.productNativeCreativeCreateModel.products === products &&
      this.productNativeCreativeCreateModel.filters === filters) {
      return this.productNativeCreativeCreateModel;
    }

    this.productNativeCreativeCreateModel = new CreateProductNativeCreativeSetupFlowPageModel(products, filters, false, this.advertisers, this.addonFeatureManager);
    return this.productNativeCreativeCreateModel;
  }

  getCreativeEditModel (creativeId: number, campaignId?: string, orderNumber?: string, l1ObjectId?: string): CreativeSetupFlowPageModel | undefined {
    if (!this.advertisers) {
      return undefined;
    }

    if (this.creativeEditModel &&
      this.creativeEditModel.creativeId === creativeId &&
      this.creativeEditModel.campaignId === campaignId &&
      this.creativeEditModel.orderNumber === orderNumber &&
      this.creativeEditModel.l1ObjectId === l1ObjectId
    ) {
      return this.creativeEditModel;
    }

    this.creativeEditModel = new EditCreativeSetupFlowPageModel(creativeId, this.canChooseAdvertiser, this.advertisers, this.addonFeatureManager, campaignId, orderNumber, l1ObjectId);
    return this.creativeEditModel;
  }

  onUnmount (eventHandler: number | undefined = undefined): void {
    this.loading = true;
    if (eventHandler !== undefined) {
      this.event.remove(eventHandler);
    }
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
