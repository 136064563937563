import React from 'react';

import './LoginPage.scss';

import _ from 'lodash';
import i18n from 'i18n';

import { alertError, alertMessage } from 'components/AlertDialog';
import { LoadingIndicator } from 'components/LoadingIndicator';

import { LoginPageProps, LoginPageState } from './LoginPageModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { validateEmpty, validateEmail } from 'utils/ValidateUtils';
import loginLogoSvg from 'assets/logo.svg';
import classnames from 'classnames';
import { LoginBackground } from 'containers/LoginBackground/LoginBackground';
import { Link } from 'react-router-dom';

export class LoginPage extends React.Component<LoginPageProps, LoginPageState> {

  emailRef: any;
  passwordRef: any;

  handler: number;

  constructor (props: any) {
    super(props);
    this.handler = 0;
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
    this.state = this.props.model.state;
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  login = (event) => {
    event.preventDefault();
    const email = this.emailRef.current.value;
    const password = this.passwordRef.current.value;
    const emailError = validateEmail(email);
    const passwordError = validateEmpty(password);
    emailError && this.props.model.setFormError('email', emailError);
    passwordError && this.props.model.setFormError('password', passwordError);
    if (!emailError && !passwordError) {
      this.props.model.login(email, password);
    }
  }

  oauthLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.props.model.oauthRedirect();
  }

  dismiss = () => {
    this.props.model.dismiss();
  }

  onEmailChange = (event) => {
    const error = validateEmail(event.target.value);
    this.props.model.setFormError('email', error);
  }

  onPasswordChange = (event) => {
    const error = validateEmpty(event.target.value);
    this.props.model.setFormError('password', error);
  }

  triggerPasswordVisibility = () => {
    this.props.model.triggerPasswordVisibility();
  }

  render () {
    const indicator = this.state.loading ? <LoadingIndicator /> : null;
    const loginLabel: string = _.defaultTo(i18n.t<string>('login.form.buttons.login'), 'Login');
    const oauthLoginLabel: string = _.defaultTo(i18n.t<string>('login.form.buttons.oauthLogin'), 'Login with OAuth');
    const emailError = this.state.formError['email'];
    const passwordError = this.state.formError['password'];
    const emailInputGroupClass = classnames('input-group', {
      'with-error': !!emailError
    });
    const passwordInputGroupClass = classnames('input-group', {
      'with-error': !!passwordError,
      'last-child': true
    });
    return (
      <LoginBackground>
        <div className='loginForm'>
          {indicator}
          {this.props.model.state.alertMessage &&
            alertMessage(
              i18n.t<string>('common.warning'),
              this.props.model.state.alertMessage,
              () => {
                this.props.model.setAlertMessage(undefined);
              }
            )
          }
          <form onSubmit={this.login}>
            <div className='iconGroup'>
              <div>
                <img
                  className='form-title'
                  src={loginLogoSvg}
                  alt='tenmax icon'
                />
              </div>
            </div>
            <div className={emailInputGroupClass}>
              <label>{i18n.t<string>('loginPage.labels.emailTitle')}</label>
              <input
                type='email'
                ref={this.emailRef}
                placeholder={i18n.t<string>('login.form.placeholders.email')}
                onChange={this.onEmailChange}
              />
              {!!emailError && <div className='input-error'>{emailError}</div>}
            </div>
            <div className={passwordInputGroupClass}>
              <label>{i18n.t<string>('loginPage.labels.passwordTitle')}</label>
              <input
                className='password-input'
                type={this.state.passwordVisibility ? 'text' : 'password'}
                ref={this.passwordRef}
                placeholder={i18n.t<string>('login.form.placeholders.password')}
                onChange={this.onPasswordChange}
              />
              <FontAwesomeIcon icon={faEye} onClick={this.triggerPasswordVisibility} />
              {!!passwordError && <div className='input-error'>{passwordError}</div>}
            </div>
            <button
              type='submit'
              className='btn btn-primary'
            >
              {loginLabel}
            </button>
            <Link className='underscore-text' to='/forgot-password'>
              {i18n.t<string>('loginPage.labels.forgotPassword')}
            </Link>
            <span className='login-or-text'>
              {i18n.t<string>('loginPage.labels.orText')}
            </span>
            <button
              type='button'
              className='btn btn-primary oauth-login'
              onClick={this.oauthLogin}
            >
              {oauthLoginLabel}
            </button>
          </form>
          {this.state.error && alertError(this.state.error, this.dismiss)}
        </div>
      </LoginBackground>
    );
  }
}
