import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AddonFeatureManager } from 'core';
import { RtbCampaignBasic, CampaignState, AdType, DeliverType } from 'core/rtbCampaign/RtbCampaign';
import { ColumnDefinition } from 'components/TableColumn/TableColumn';
import { Order, State } from 'core/order/Order';
import { formatPrice, formatPriceAny, formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import { toast } from 'react-toastify';
import {
  CampaignListColumns,
  performanceColumns,
  basicColumns,
  ecommerceColumns
} from './CampaignListColumnSetting';
import { RtbCampaignManager, DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { numberWithCommas } from 'utils/StringUtil';
import styles from './campaignList.module.scss';
import moment from 'moment';
import i18n from 'i18n';
import _ from 'lodash';
import { SortableList, AbstractSortableList } from 'containers/Common/AbstractSortableList';
import { SelectOptions } from 'components/commonType';
import { L1Object } from 'core/l1Object/L1Object';
import { FilterMenuTabConfig } from 'components/FilterMenuTab/FilterMenuTab';
import { L1ObjectManager, DefaultL1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { getEffectiveStatusDefaultColor } from 'components/Status/Status';
import { getCpc, getCtr, getDivideValue } from 'helper/MetricsHelper';
import { Pmp, PmpStatus, PmpType } from 'core/pmp/Pmp';
import { DefaultPmpManager, PmpManager } from 'core/pmp/PmpManager';

const timeFormat = 'YYYY-MM-DD HH:mm:ss';
export interface CampaignListModel extends SortableList {
  readonly order: Order;
  readonly l1Object: L1Object;
  readonly campaignList: Array<RtbCampaignBasic>;
  readonly state: CampaignListState;
  readonly event: UpdateEventListener<CampaignListModel>;
  readonly campaignTags: Array<string>;
  readonly campaignStates: Array<string>;
  readonly canCreateCampaign: boolean;
  readonly canSplitCampaign: boolean;
  readonly showSplitBtn: boolean;
  readonly searchString: string;
  readonly lang: string;
  readonly canNotCreateMessage: string;
  readonly newCampaignPath: string;
  readonly editCampaignPath: string;
  readonly copyCampaignPath: string;
  readonly filterMenuTabConfigs: Array<FilterMenuTabConfig>;
  deleteCampaignIds: Array<number>;
  onCampaignChange: () => void;
  handleOnSelect (campaign): void;
  handleOnSelectAll (): void;
  handleRemoveSelect (): void;
  getColumnDefinition (columnName): ColumnDefinition;
  showTable (listType, e): void;
  handleOnSearch (campaignName): void;
  handleOnTagFilterApply (tags): void;
  handleOnStateFilterApply (stateDesList): void;
  activeCampaign (event): Promise<void>;
  deactiveCampaign (event): Promise<void>;
  allSelectedCampaignSameType (): boolean;
  selectUnknowAdTypeCampaign (): boolean;
  deleteCampaign (campaignIds): Promise<void>;
  canDeleteSelectedCampaigns (campaignIds: Array<number>): boolean;
  setVisibilityOfDeleteConfirmModal (show: boolean): void;
  getReportLink (campaign): string;
  updateModelData (order: Order, campaignList: Array<RtbCampaignBasic>): void;
  updateViewModelData (): void;
  onSearchChange (searchString): void;
  getTabs (): SelectOptions[];
  getCampaignStatusDesData (campaign: any): {
    des: string;
    color: string;
  };
  getCampaignEffectiveStatusDesData (campaign: any): {
    des: string;
    color: string;
    extraInfo?: any;
  };
  isCampaignBudgetRemain (campaign): boolean;
}

export type CampaignListProps = {
  readonly model: CampaignListModel;
};

export enum CampaignListType {
  BASIC = 'basic',
  PERFORMANCE = 'performance',
  ECOMMERCE = 'ecommerce'
}

export type CampaignListState = {
  readonly loading: boolean;
  readonly selectedCampaign: number[];
  readonly columnsToShow: CampaignListColumns[];
  readonly seletedTab: string;
  readonly viewModelData: any[];
  readonly summaryData: any;
  readonly selectedTagFilter: string[];
  readonly selectedStateFilter: string[];
  readonly selectedEffectiveStatusFilter: string[];
  readonly showDeleteConfirmModal: boolean;
  readonly needUpdateViewModelData: boolean;
};

export class DefaultCampaignListModel extends AbstractSortableList implements CampaignListModel {

  event: FireableUpdateEventListener<CampaignListModel> = new FireableUpdateEventListener<CampaignListModel>();
  selectedCampaign: number[] = [];
  filteredViewModelData: any[] = [];
  columnsToShow: Array<CampaignListColumns>;
  seletedTab: string = CampaignListType.BASIC;
  campaignTags: string[] = [];
  campaignStates: string[] = [];
  campaignEffectiveStatus: string[] = [];
  searchString: string;
  selectedTagFilter: string[] = [];
  selectedStateFilter: string[] = [];
  selectedEffectiveStatusFilter: string[] = [];
  loading: boolean = false;
  showDeleteConfirmModal: boolean = false;
  lang: string = i18n.language;
  modelDeleteCampaignIds: number[] = [];
  needUpdateViewModelData: boolean = false;
  summaryData: any = {};
  isChannelAllowed: boolean;

  constructor (
    public order: Order,
    public l1Object: L1Object,
    public campaignList: any[],
    protected addonFeatureManager: AddonFeatureManager,
    public onCampaignChange,
    public onSearchChange: (search: string) => void,
    defaultSearchString: string | null,
    private budgetBalance: number,
    private inactivePmpSpaces: SelectOptions[],
    public manager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    l1ObjectManager: L1ObjectManager = new DefaultL1ObjectManager(),
    private pmpManager: PmpManager = new DefaultPmpManager()
  ) {
    super('id', 'desc');
    this.columnsToShow = [...basicColumns];
    this.searchString = defaultSearchString ? defaultSearchString : '';
    this.isChannelAllowed = l1Object ?
      l1ObjectManager.isChannelAllowed(l1Object.channel, addonFeatureManager) :
      true;
    this.updateViewModelData();
  }

  get canNotCreateMessage (): string {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    if (canNotCreateState.includes(state)) {
      return i18n.t<string>('orderDetail.labels.orderStateCannotCreate');
    }

    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    if (isEnd) {
      return i18n.t<string>('orderDetail.labels.isEndCannotCreate');
    }

    if (this.budgetBalance <= 0) {
      return i18n.t<string>('orderDetail.labels.lessThanBudgetMinimum', { budget: 0 });
    }

    return '';
  }

  getCanNotEditMessage (): string {
    const canNotEditState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    if (canNotEditState.includes(state)) {
      return i18n.t<string>('orderDetail.labels.orderStateCannotEdit');
    }

    return '';
  }

  getCanNotCopyMessage (campaign: any): string {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    if (canNotCreateState.includes(state)) {
      return i18n.t<string>('orderDetail.labels.orderStateCannotCreate');
    }

    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    if (isEnd) {
      return i18n.t<string>('orderDetail.labels.isEndCannotCreate');
    }
    const campaignIsStart = moment().isAfter(campaign.startDate);
    const campaignIsEnd = moment().isAfter(campaign.endDate);
    let startDate = moment(campaign.startDate);
    if (campaignIsStart) {
      startDate = moment().startOf('day');
    }
    let endDate = moment(campaign.endDate);
    if (campaignIsEnd) {
      endDate = moment(this.order.endDate).endOf('day');
    }
    const minCampaignBudgetPerDay = this.order.campaignConstraint.budgetMinimum;
    const scheduleDateCount = endDate.diff(startDate, 'days') + 1;
    const minBudgetOfNewCampaign = minCampaignBudgetPerDay * scheduleDateCount;
    if (this.budgetBalance < minBudgetOfNewCampaign) {
      return i18n.t<string>('campaignList.labels.canCopyNeedMore', {
        parent: this.l1Object && !_.isNil(this.l1Object.budget) ? 'Campaign group' : 'Order',
        budget: formatPriceWithCurrency(this.order.currency, minBudgetOfNewCampaign - this.budgetBalance)
      });
    }

    return '';
  }

  getCanNotSplitMessage (campaign: any): string {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    if (canNotCreateState.includes(state)) {
      return i18n.t<string>('orderDetail.labels.orderStateCannotCreate');
    }

    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    if (isEnd) {
      return i18n.t<string>('orderDetail.labels.isEndCannotCreate');
    }

    const targetBudget = campaign.budget;
    const expectSpent = campaign.spents * 1.1;
    const minCampaignBudgetPerDay = this.order.campaignConstraint.budgetMinimum;
    const startDate = moment(campaign.startDate).startOf('day');
    const today = moment().startOf('day');
    const isStart = today.isAfter(startDate);
    const endDate = moment(campaign.endDate);
    const scheduleDateCount = endDate.diff(isStart ? today : startDate, 'days') + 1;
    const minBudgetFromNow = minCampaignBudgetPerDay * scheduleDateCount;
    const firstCampaignBudget = isStart ?
      Math.max(expectSpent, minCampaignBudgetPerDay * today.diff(startDate, 'days')) + minBudgetFromNow :
      minBudgetFromNow;
    const secondCampaignBudget = minBudgetFromNow;
    const minBudgetFor2Campaign = firstCampaignBudget + secondCampaignBudget;
    const isBelowMinBudget = targetBudget < minBudgetFor2Campaign;
    if (isBelowMinBudget) {
      return i18n.t<string>('campaignList.labels.canSplitMinimum', { budget: formatPriceWithCurrency(this.order.currency, minBudgetFor2Campaign) });
    }

    return '';
  }

  get deleteCampaignIds (): Array<number> {
    return this.modelDeleteCampaignIds;
  }

  set deleteCampaignIds (campaignIds) {
    this.modelDeleteCampaignIds = campaignIds;
  }

  get state (): CampaignListState {
    return {
      selectedCampaign: this.selectedCampaign,
      columnsToShow: this.columnsToShow,
      seletedTab: this.seletedTab,
      needUpdateViewModelData: this.needUpdateViewModelData,
      viewModelData: this.filteredViewModelData,
      summaryData: this.summaryData,
      selectedTagFilter: this.selectedTagFilter,
      selectedStateFilter: this.selectedStateFilter,
      selectedEffectiveStatusFilter: this.selectedEffectiveStatusFilter,
      loading: this.loading,
      showDeleteConfirmModal: this.showDeleteConfirmModal
    };
  }

  get canCreateCampaign (): boolean {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    const noBudget = this.budgetBalance <= 0;
    return !(canNotCreateState.includes(state) || isEnd || noBudget) && this.isChannelAllowed;
  }

  get canSplitCampaign (): boolean {
    const canNotSplitState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    return !(canNotSplitState.includes(state) || isEnd) && this.isChannelAllowed;
  }

  get showSplitBtn (): boolean {
    return true;
  }

  get newCampaignPath () {
    return 'new';
  }

  get editCampaignPath () {
    return 'edit';
  }

  get copyCampaignPath () {
    return 'copy';
  }

  get filterMenuTabConfigs (): FilterMenuTabConfig[] {
    return [
      {
        filterType: i18n.t<string>('l2ObjectList.labels.statusFilter'),
        menuTitle: i18n.t<string>('l2ObjectList.labels.statusFilterMenuTitle'),
        tag: i18n.t<string>('l2ObjectList.labels.statusTag'),
        selectedValues: this.state.selectedStateFilter,
        options: this.campaignStates,
        applyMethod: this.handleOnStateFilterApply
      },
      {
        filterType: i18n.t<string>('l2ObjectList.labels.deliveryFilter'),
        menuTitle: i18n.t<string>('l2ObjectList.labels.deliveryFilterMenuTitle'),
        tag: i18n.t<string>('l2ObjectList.labels.deliveryTag'),
        selectedValues: this.state.selectedEffectiveStatusFilter,
        options: this.campaignEffectiveStatus,
        applyMethod: this.handleOnEffectiveStatusFilterApply
      },
      {
        filterType: i18n.t<string>('campaignList.labels.tagFilter'),
        menuTitle: i18n.t<string>('campaignList.labels.tagFilterMenuTitle'),
        tag: i18n.t<string>('campaignList.labels.tagTag'),
        selectedValues: this.state.selectedTagFilter,
        options: this.campaignTags,
        applyMethod: this.handleOnTagFilterApply
      }
    ];
  }

  getTabs () {
    return Object.values(CampaignListType).map(tab => ({
      label: i18n.t<string>(`campaignList.tabs.${tab}`),
      value: tab
    }));
  }

  sortComparator = (field: string | undefined, dataA, dataB, order) => {
    const dataAToCompare = field ? dataA[field] : dataA;
    const dataBToCompare = field ? dataB[field] : dataB;
    if (order === 'desc') {
      return dataAToCompare < dataBToCompare ? 1 : -1;
    }

    return dataAToCompare > dataBToCompare ? 1 : -1;
  }

  getSortFunc = (dataField?: string) => {
    return _.partial(this.sortComparator, dataField);
  }

  get nameColumnAdditional () {
    return {
      events: {
        onClick: () => {
          // This is intentional
        }
      },
      sort: true,
      onSort: this.handleSort,
      sortFunc: (dataA, dataB, order) => {
        const compareListId = _.get(dataA, 'listId', 0).toString().localeCompare(_.get(dataB, 'listId', 0).toString());
        if (compareListId === 0) {
          return 0;
        }
        if (order === 'desc') {
          return compareListId > 0 ? -1 : 1;
        } else {
          return compareListId < 0 ? -1 : 1;
        }
      }
    };
  }

  get stateColumnAdditional () {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: (dataA, dataB, order) => {
        const stateDes = [
          i18n.t<string>('campaignList.labels.activateState'),
          i18n.t<string>('campaignList.labels.stoppingState'),
          i18n.t<string>('campaignList.labels.deactivateState'),
          i18n.t<string>('campaignList.labels.deleteState')
        ];
        const dataAToCompare = stateDes.indexOf(dataA['des']);
        const dataBToCompare = stateDes.indexOf(dataB['des']);
        if (order === 'desc') {
          return dataAToCompare > dataBToCompare ? 1 : -1;
        } else {
          return dataAToCompare < dataBToCompare ? 1 : -1;
        }
      }
    };
  }

  get deliveryColumnAdditional () {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: (dataA, dataB, order) => {
        const stateDes = [
          i18n.t<string>('campaignList.labels.budgetRemainState'),
          i18n.t<string>('campaignList.labels.activateState'),
          i18n.t<string>('campaignList.labels.stoppingState'),
          i18n.t<string>('campaignList.labels.deactivateState'),
          i18n.t<string>('campaignList.labels.noCreativesState'),
          i18n.t<string>('campaignList.labels.notStartState'),
          i18n.t<string>('campaignList.labels.endState'),
          i18n.t<string>('campaignList.labels.deleteState')
        ];
        const dataAToCompare = stateDes.indexOf(dataA['des']);
        const dataBToCompare = stateDes.indexOf(dataB['des']);
        if (order === 'desc') {
          return dataAToCompare > dataBToCompare ? 1 : -1;
        } else {
          return dataAToCompare < dataBToCompare ? 1 : -1;
        }
      }
    };
  }

  get scheduleColumnAdditional () {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: (dataA, dataB, order) => {
        if (!dataA || !dataB) {
          return !dataA ? -1 : 1;
        }

        if (order === 'desc') {
          return moment(dataA.start).isBefore(moment(dataB.start)) ? 1 : -1;
        }

        return moment(dataB.start).isBefore(moment(dataA.start)) ? 1 : -1;
      }
    };
  }

  get resultColumnAdditional () {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: this.getSortFunc('value'),
      formatExtraData: {
        objective: _.get(this.l1Object, 'objective')
      }
    };
  }

  getCommonColumnAdditional (columnName?: string) {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: this.getSortFunc(columnName)
    };
  }

  getColumnDefinition (columnName): ColumnDefinition {
    let additional = {};

    let customLabel;
    switch (columnName) {
      case CampaignListColumns.NAME:
        additional = this.nameColumnAdditional;
        break;
      case CampaignListColumns.STATE:
        additional = this.stateColumnAdditional;
        break;
      case CampaignListColumns.DELIVERY:
        additional = this.deliveryColumnAdditional;
        break;
      case CampaignListColumns.CREATIVE:
        additional = this.getCommonColumnAdditional();
        break;
      case CampaignListColumns.SCHEDULE:
        additional = this.scheduleColumnAdditional;
        break;
      case CampaignListColumns.PROGRESS:
        additional = this.getCommonColumnAdditional('executeRate');
        break;
      case CampaignListColumns.BUDGET:
        customLabel = i18n.t<string>('campaignList.headers.budgetColumn');
        additional = this.getCommonColumnAdditional('budget');
        break;
      case CampaignListColumns.EDITBTNS:
        additional = {
          text: ''
        };
        break;
      case CampaignListColumns.SPENT:
        customLabel = i18n.t<string>(`campaignList.headers.${CampaignListColumns.SPENT}`, {
          currency: this.order.currency
        });
        additional = {
          sort: true,
          onSort: this.handleSort
        };
        break;
      case CampaignListColumns.CPC:
        customLabel = i18n.t<string>(`campaignList.headers.${CampaignListColumns.CPC}`) + ` (${this.order.currency})`;
        additional = this.getCommonColumnAdditional('value');
        break;
      case CampaignListColumns.IMPRES:
      case CampaignListColumns.CLICKS:
      case CampaignListColumns.CTR:
        additional = this.getCommonColumnAdditional('value');
        break;
      case CampaignListColumns.DIRECT_ADD_TO_CART:
      case CampaignListColumns.DIRECT_ORDER:
      case CampaignListColumns.DIRECT_PRODUCT_SALES_COUNT:
      case CampaignListColumns.DIRECT_CONVERSION_RATE:
      case CampaignListColumns.DIRECT_ROAS:
      case CampaignListColumns.INDIRECT_ADD_TO_CART:
      case CampaignListColumns.INDIRECT_ORDER:
      case CampaignListColumns.INDIRECT_PRODUCT_SALES_COUNT:
      case CampaignListColumns.INDIRECT_CONVERSION_RATE:
      case CampaignListColumns.INDIRECT_ROAS:
        additional = this.getCommonColumnAdditional('value');
        break;
      case CampaignListColumns.DIRECT_PRODUCT_SALES_AMOUNT:
      case CampaignListColumns.INDIRECT_PRODUCT_SALES_AMOUNT:
        additional = {
          ...this.getCommonColumnAdditional('value'),
          text: `${i18n.t<string>(`campaignList.headers.${columnName}`, {
            currency: this.order.currency
          })}`
        };
        break;
      default:
        break;
    }

    return this.columnDefinition(columnName, customLabel, additional);
  }

  columnDefinition (columnName, customLabel, additional = {}): ColumnDefinition {
    const columnClassGetter = () => {
      return styles[columnName];
    };

    return {
      sort: false,
      text: customLabel ? customLabel : i18n.t<string>(`campaignList.headers.${columnName}`),
      dataField: columnName,
      classes: columnClassGetter,
      headerClasses: columnClassGetter,
      ...additional
    };
  }

  getReportLink (campaign) {
    const from = encodeURIComponent(moment(campaign.startDate).startOf('day').format(timeFormat));
    const to = encodeURIComponent(moment(campaign.endDate).endOf('day').format(timeFormat));
    const dimension = 'l2ChannelId';
    return `/reports/performance?dimension=${dimension}&from=${from}&to=${to}&${dimension}=${campaign.id}`;
  }

  getCampaignStatusDesData = (campaign) => {
    let des = '';
    let color;
    let extraInfo;
    let hintData;
    const now = moment();
    switch (campaign.state) {
      case CampaignState.DEACTIVATE:
        des = i18n.t<string>('campaignList.labels.deactivateState');
        color = 'black';
        break;
      case CampaignState.DELETE:
        des = i18n.t<string>('campaignList.labels.deleteState');
        color = 'danger';
        break;
      case CampaignState.ACTIVATE:
        const pmp = _.get(campaign, 'additionalInfo.pmp');
        const spaces = _.get(pmp, 'space', []);
        const matchedSpaces = _.intersection(spaces, this.inactivePmpSpaces.map(space => space.value));
        const pmpIsEnd = pmp && moment(pmp.endTime).isBefore(now);
        const pmpIsInactive = pmp && pmp.status === PmpStatus.INACTIVE;
        const pmpSpaceAllSuspend = pmp && pmp.status === PmpStatus.ACTIVE && matchedSpaces.length === spaces.length;
        const pmpSpacePartialSuspend = pmp && pmp.status === PmpStatus.ACTIVE && matchedSpaces.length > 0;
        if (pmpIsEnd) {
          des = i18n.t<string>('campaignList.labels.pmpEndState');
          color = 'light';
        } else if (pmpIsInactive) {
          des = i18n.t<string>('campaignList.labels.pmpInactiveState');
          color = 'black';
        } else if (pmpSpaceAllSuspend) {
          des = i18n.t<string>('campaignList.labels.pmpSpaceAllSuspendState');
          color = 'danger';
          extraInfo = i18n.t<string>('campaignList.labels.pmpSpaceAllSuspendHint');
        } else if (pmpSpacePartialSuspend) {
          des = i18n.t<string>('campaignList.labels.pmpSpacePartialSuspendState');
          color = 'info';
          hintData = this.inactivePmpSpaces.filter(space => matchedSpaces.includes(space.value)).map(space => space.label);
        } else {
          des = i18n.t<string>('campaignList.labels.activateState');
        }
        break;
      default:
        des = i18n.t<string>('campaignList.labels.stoppingState');
        color = 'whiteTheme4';
        break;
    }

    return {
      des,
      color,
      extraInfo,
      hintData
    };
  }

  getCampaignEffectiveStatusDesData = (campaign) => {
    const bidPriceSettingData = this.order.campaignBidPrice;
    const adTypeBidPriceSetting = bidPriceSettingData.find(data => data.type === campaign.adType);
    const bidFloor = _.get(adTypeBidPriceSetting, `bidFloor.${_.camelCase(this.manager.getOptimizeDes(campaign.optimize))}`);
    if (!_.isNil(campaign.bidPrice) && bidFloor && bidFloor > campaign.bidPrice) {
      return {
        des: i18n.t<string>('campaignList.labels.bidPriceLowerThanFloor'),
        color: 'danger'
      };
    }
    let des = _.startCase(_.lowerCase(campaign.effectiveStatus));
    let color = getEffectiveStatusDefaultColor(campaign.effectiveStatus);
    let extraInfo;
    const now = moment();
    switch (campaign.effectiveStatus) {
      case 'PAUSED':
      case 'CAMPAIGN_GROUP_PAUSED':
        if (campaign.budget - campaign.spents > 0) {
          des = i18n.t<string>('campaignList.labels.budgetRemainState');
          color = 'danger';
        } else {
          des = i18n.t<string>('campaignList.labels.deactivateState');
          color = 'black';
        }
        if (campaign.effectiveStatus === 'CAMPAIGN_GROUP_PAUSED') {
          extraInfo = 'Campaign Group Paused';
        }
        break;
      case 'DELETED':
      case 'ARCHIVED':
        des = i18n.t<string>('campaignList.labels.deleteState');
        color = 'danger';
        break;
      case 'ACTIVE':
        if (moment(campaign.startDate).isAfter(now)) {
          des = i18n.t<string>('campaignList.labels.notStartState');
          color = 'light';
        } else if (moment(campaign.endDate).isBefore(now)) {
          des = i18n.t<string>('campaignList.labels.endState');
          color = 'light';
        } else if (campaign.additionalInfo && campaign.additionalInfo.creativeAmount.enableCount === 0) {
          des = i18n.t<string>('campaignList.labels.noCreativesState');
          color = 'black';
        } else {
          des = i18n.t<string>('campaignList.labels.activateState');
        }
        break;
      default:
        break;
    }

    return {
      des,
      color,
      extraInfo
    };
  }

  isCampaignBudgetRemain = (campaign) => {
    return ['PAUSED', 'CAMPAIGN_GROUP_PAUSED'].includes(campaign.effectiveStatus) &&
      campaign.budget - campaign.spents > 0;
  }

  getProgress (campaign) {
    const pmp = _.get(campaign, 'additionalInfo.pmp');
    if (pmp && pmp.pmpType === PmpType.SPONSORSHIP) {
      const campaignProgress = this.getSponsorshipPmpCampaignProgressRate(pmp);
      const spents = `${this.order.currency} ${numberWithCommas(_.floor(pmp.budget * campaignProgress, 2).toFixed(2))}`;
      const budget = `${this.order.currency} ${numberWithCommas(pmp.budget.toFixed(2))}`;
      return {
        spents,
        budget,
        executeRate: campaignProgress,
        predictRate: campaignProgress,
        danger: false,
        deepWarning: false,
        warning: false
      };
    }
    const isDailyBudgetCampaign = campaign.dailyTargetBudget > 0;
    const campaignProgress = isDailyBudgetCampaign
      ? this.getDailyBudgetCampaignProgressRate(campaign)
      : this.getCampaignProgressRate(campaign);
    const executeRate = campaignProgress.executeRate;
    const predictRate = Math.min(campaignProgress.predictRate, 1);
    const warnignLine = campaign.budget * 1.1;
    const discrepancy = predictRate - executeRate;
    return {
      spents: `${this.order.currency} ${numberWithCommas(_.floor(campaign.spents, 2).toFixed(2))}`,
      budget: `${this.order.currency} ${numberWithCommas(_.floor(campaign.budget, 2).toFixed(2))}`,
      executeRate,
      predictRate,
      danger: campaign.spents > warnignLine,
      deepWarning: discrepancy > 0.03,
      warning: discrepancy > 0.01 && discrepancy <= 0.03
    };
  }

  getDistributeDes (dailyTargetBudget) {
    return dailyTargetBudget && dailyTargetBudget > 0 ?
      i18n.t<string>('campaignList.labels.budgetDailyDistribution', { currency: this.order.currency, budget: formatPrice(this.order.currency, dailyTargetBudget) }) :
      i18n.t<string>('campaignList.labels.budgetSchedule');
  }

  getPriceModelDes (priceModel) {
    return i18n.t<string>(`campaign.labels.${priceModel}`);
  }

  getLimitationsToShow (campaign) {
    let limitations = _.get(campaign, 'additionalInfo.limitations', {});
    if (campaign.adType === AdType.KEYWORD) {
      limitations = {
        include: limitations.include
      };
    }
    return this.manager.getLimitationSummaryData(limitations);
  }

  getDeliverTypeDesc (deliverType) {
    if (deliverType === DeliverType.STANDARD) {
      return i18n.t<string>('campaignList.labels.deliverStandard');
    }

    return i18n.t<string>('campaignList.labels.deliverAsap');
  }

  getViewModelData () {
    this.campaignTags = [];
    this.campaignStates = [];
    const viewModelData: Array<any> = this.campaignList.map(campaign => {
      this.campaignTags = _.uniq(_.concat(this.campaignTags, _.defaultTo(campaign.tags, [])));
      const stateData = this.getCampaignStatusDesData(campaign);
      const effectiveData = this.getCampaignEffectiveStatusDesData(campaign);
      this.campaignStates = _.uniq(_.concat(this.campaignStates, stateData.des));
      this.campaignEffectiveStatus = _.uniq(_.concat(this.campaignEffectiveStatus, effectiveData.des));
      const listId = campaign.id;
      const ecommerceReport = _.get(campaign, 'ecommerceReport', {});
      const indirectProductSalesCount = _.get(ecommerceReport, 'indirectProductSalesCount', 0);
      const indirectProductSalesAmount = _.get(ecommerceReport, 'indirectProductSalesAmount', 0);
      const directProductSalesCount = _.get(ecommerceReport, 'directProductSalesCount', 0);
      const directProductSalesAmount = _.get(ecommerceReport, 'directProductSalesAmount', 0);
      const campaignData: any = {
        listId,
        pmp: _.get(campaign, 'additionalInfo.pmp'),
        id: campaign.id,
        adType: campaign.adType,
        spents: campaign.spents,
        budget: campaign.budget,
        dailyTargetBudget: campaign.dailyTargetBudget,
        priceModel: campaign.priceModel,
        optimize: campaign.optimize,
        bidPrice: campaign.bidPrice,
        impres: campaign.impres,
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        state: campaign.state,
        effectiveStatus: campaign.effectiveStatus,
        stateColumn: stateData,
        deliveryColumn: effectiveData,
        additionalInfo: campaign.additionalInfo,
        nameColumn: {
          name: campaign.name,
          listId,
          typeDes: this.manager.getCampaignTypeDes(campaign.adType)
        },
        creativeColumn: _.get(campaign.additionalInfo, 'creativeAmount.enableCount', 0),
        scheduleColumn: {
          start: moment(campaign.startDate).format(timeFormat),
          end: moment(campaign.endDate).format(timeFormat)
        },
        progressColumn: this.getProgress(campaign),
        budgetColumn: {
          budget: campaign.budget,
          budgetDes: formatPriceWithCurrency(this.order.currency, campaign.budget),
          distributeDes: this.getDistributeDes(campaign.dailyTargetBudget),
          deliverTypeDes: this.getDeliverTypeDesc(campaign.deliverType)
        },
        priceColumn: {
          priceModel: this.getPriceModelDes(campaign.priceModel),
          type: campaign.optimize,
          bidPriceDes: `${this.order.currency} ${numberWithCommas(campaign.bidPrice)}`
        },
        limitationColumn: this.getLimitationsToShow(campaign),
        tagsColumn: campaign.tags,
        impresColumn: {
          value: campaign.impres,
          desc: numberWithCommas(campaign.impres)
        },
        clicksColumn: {
          value: campaign.clicks,
          desc: numberWithCommas(campaign.clicks)
        },
        cpcColumn: { value: getCpc(campaign.spents, campaign.clicks) },
        ctrColumn: { value: getCtr(campaign.impres, campaign.clicks) },
        directAddToCartColumn: {
          value: _.get(ecommerceReport, 'directAddToCart', 0),
          desc: numberWithCommas(_.get(ecommerceReport, 'directAddToCart', 0))
        },
        directOrderColumn: {
          value: _.get(ecommerceReport, 'directOrder', 0),
          desc: numberWithCommas(_.get(ecommerceReport, 'directOrder', 0))
        },
        directProductSalesCountColumn: {
          value: directProductSalesCount,
          desc: numberWithCommas(directProductSalesCount)
        },
        directProductSalesAmountColumn: { value: directProductSalesAmount },
        directConversionRateColumn: { value: getDivideValue(directProductSalesCount * 100, campaign.clicks, value => _.round(value, 2)) },
        directRoasColumn: { value: getDivideValue(directProductSalesAmount, _.floor(campaign.spents, 2), value => _.round(value, 2)) },
        indirectAddToCartColumn: {
          value: _.get(ecommerceReport, 'indirectAddToCart', 0),
          desc: numberWithCommas(_.get(ecommerceReport, 'indirectAddToCart', 0))
        },
        indirectOrderColumn: {
          value: _.get(ecommerceReport, 'indirectOrder', 0),
          desc: numberWithCommas(_.get(ecommerceReport, 'indirectOrder', 0))
        },
        indirectProductSalesCountColumn: {
          value: indirectProductSalesCount,
          desc: numberWithCommas(indirectProductSalesCount)
        },
        indirectProductSalesAmountColumn: { value: indirectProductSalesAmount },
        indirectConversionRateColumn: { value: getDivideValue(indirectProductSalesCount * 100, campaign.clicks, value => _.round(value, 2)) },
        indirectRoasColumn: { value: getDivideValue(indirectProductSalesAmount, _.floor(campaign.spents, 2), value => _.round(value, 2)) }
      };
      campaignData.canNotCopyMessage = this.getCanNotCopyMessage(campaignData);
      campaignData.canNotSlitMessage = this.getCanNotSplitMessage(campaignData);
      campaignData.canNotEditMessage = this.getCanNotEditMessage();
      return campaignData;
    });

    this.summaryData = {
      id: 0,
      listId: 'summaryRow',
      ...this.getSummaryData(this.campaignList)
    };

    return viewModelData;
  }

  showTable = (listType: CampaignListType, e) => {
    e && e.stopPropagation();
    this.seletedTab = listType;
    switch (listType) {
      case CampaignListType.PERFORMANCE:
        this.columnsToShow = [...performanceColumns];
        break;
      case CampaignListType.ECOMMERCE:
        this.columnsToShow = [...ecommerceColumns];
        break;
      default:
        this.columnsToShow = [...basicColumns];
        break;
    }
    this.updateState();
  }

  handleOnSelect = (campaign) => {
    const objectId = campaign.id;
    const selectedArray: number[] = this.selectedCampaign;
    if (selectedArray.indexOf(objectId) > -1) {
      _.remove(selectedArray, id => id === objectId);
    } else {
      selectedArray.push(objectId);
    }

    this.updateState();
  }

  handleOnSelectAll = () => {
    const selectedArrays = [...this.selectedCampaign];
    if (selectedArrays.length === this.filteredViewModelData.length) {
      this.selectedCampaign = [];
    } else {
      this.selectedCampaign = this.filteredViewModelData
        .map(viewModelData => viewModelData.id);
    }
    this.updateState();
  }

  handleOnSearch = (searchString) => {
    this.searchString = searchString;
    this.onSearchChange(searchString);
    this.updateViewModelData();
  }

  handleOnTagFilterApply = (tags) => {
    this.selectedTagFilter = [...tags];
    this.updateViewModelData();
  }

  handleOnStateFilterApply = (stateDesList) => {
    this.selectedStateFilter = [...stateDesList];
    this.updateViewModelData();
  }

  handleOnEffectiveStatusFilterApply = (effectiveStatusDesList) => {
    this.selectedEffectiveStatusFilter = [...effectiveStatusDesList];
    this.updateViewModelData();
  }

  updateViewModelData = () => {
    this.needUpdateViewModelData = false;
    const allViewModelData = this.getViewModelData();
    this.filteredViewModelData = _.filter(allViewModelData,
      viewModelData => {
        const campaign = this.campaignList.find(campaign => campaign.id === viewModelData.id);
        if (!campaign) {
          return false;
        }
        const stateIsMatch = (this.selectedStateFilter.length === 0 && viewModelData.state !== CampaignState.DELETE) || this.selectedStateFilter.includes(viewModelData.stateColumn.des);
        const effecitveStatuseIsMatch = this.selectedEffectiveStatusFilter.length === 0 || this.selectedEffectiveStatusFilter.includes(viewModelData.deliveryColumn.des);
        const tagsIsMatch = this.selectedTagFilter.length === 0 || _.intersection(viewModelData.tagsColumn, this.selectedTagFilter).length > 0;
        const nameIsMatch = viewModelData.nameColumn.name.toLowerCase().includes(this.searchString.toLowerCase());
        const listIdIsMatch = viewModelData.listId ? viewModelData.listId.toString().includes(this.searchString) : false;
        return (nameIsMatch || listIdIsMatch) && tagsIsMatch && stateIsMatch && effecitveStatuseIsMatch;
      }
    );
    const selectedObjects = _.intersectionWith(this.campaignList, this.filteredViewModelData,
      (campaign, viewModel) => campaign.id === viewModel.id);
    this.summaryData = {
      id: 0,
      listId: 'summaryRow',
      ...this.getSummaryData(selectedObjects)
    };

    this.updateState();
  }

  getSummaryData = (campaignList: Array<any>) => {
    const clickSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.clicks, 0);
    const spentsSum = campaignList.reduce<number>((partial, campaign) => partial + getPriceValue(this.order.currency, campaign.spents), 0);
    const impresSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.impres, 0);
    const directAddToCartSum = campaignList.reduce<number>((partial, campaign) => partial + _.get(campaign.ecommerceReport, 'directAddToCart', 0), 0);
    const directOrderSum = campaignList.reduce<number>((partial, campaign) => partial + _.get(campaign.ecommerceReport, 'directOrder', 0), 0);
    const directProductSalesCountSum = campaignList.reduce<number>((partial, campaign) => partial + _.get(campaign.ecommerceReport, 'directProductSalesCount', 0), 0);
    const directProductSalesAmountSum = campaignList.reduce<number>((partial, campaign) => partial + _.get(campaign.ecommerceReport, 'directProductSalesAmount', 0), 0);
    const indirectAddToCartSum = campaignList.reduce<number>((partial, campaign) => partial + _.get(campaign.ecommerceReport, 'indirectAddToCart', 0), 0);
    const indirectOrderSum = campaignList.reduce<number>((partial, campaign) => partial + _.get(campaign.ecommerceReport, 'indirectOrder', 0), 0);
    const indirectProductSalesCountSum = campaignList.reduce<number>((partial, campaign) => partial + _.get(campaign.ecommerceReport, 'indirectProductSalesCount', 0), 0);
    const indirectProductSalesAmountSum = campaignList.reduce<number>((partial, campaign) => partial + _.get(campaign.ecommerceReport, 'indirectProductSalesAmount', 0), 0);
    return {
      nameColumn: i18n.t<string>('campaignList.labels.campaignCount', { count: campaignList.length }),
      budgetColumn: i18n.t<string>('campaignList.labels.campaignBudget', {
        number: formatPriceWithCurrency(
          this.order.currency,
          campaignList.reduce<number>((partial, campaign) => partial + campaign.budget, 0)
        )}
      ),
      impresColumn: numberWithCommas(impresSum),
      clicksColumn: numberWithCommas(clickSum),
      spents: numberWithCommas(getPriceValue(this.order.currency, spentsSum)),
      cpcColumn: formatPriceAny(clickSum === 0 ? 0.00 : _.floor(spentsSum / clickSum, 2)),
      ctrColumn: `${impresSum === 0 ? 0.00 : _.floor((clickSum / impresSum) * 100, 2).toFixed(2)}%`,
      directAddToCartColumn: numberWithCommas(directAddToCartSum),
      directOrderColumn: numberWithCommas(directOrderSum),
      directProductSalesCountColumn: numberWithCommas(directProductSalesCountSum),
      directProductSalesAmountColumn: `${numberWithCommas(_.floor(directProductSalesAmountSum, 2).toFixed(2))}`,
      directConversionRateColumn: `${numberWithCommas(_.floor(getDivideValue(directProductSalesCountSum * 100, clickSum, value => _.round(value, 2)), 2).toFixed(2))}%`,
      directRoasColumn: `${numberWithCommas(_.floor(getDivideValue(directProductSalesAmountSum, spentsSum, value => _.round(value, 2)), 2).toFixed(2))}`,
      indirectAddToCartColumn: numberWithCommas(indirectAddToCartSum),
      indirectOrderColumn: numberWithCommas(indirectOrderSum),
      indirectProductSalesCountColumn: numberWithCommas(indirectProductSalesCountSum),
      indirectProductSalesAmountColumn: `${numberWithCommas(_.floor(indirectProductSalesAmountSum, 2).toFixed(2))}`,
      indirectConversionRateColumn: `${numberWithCommas(_.floor(getDivideValue(indirectProductSalesCountSum * 100, clickSum, value => _.round(value, 2)), 2).toFixed(2))}%`,
      indirectRoasColumn: `${numberWithCommas(_.floor(getDivideValue(indirectProductSalesAmountSum, spentsSum, value => _.round(value, 2)), 2).toFixed(2))}`
    };
  }

  getDailyBudgetCampaignProgressRate = ({ budget, spents, startDate, endDate }) => {
    const startTime = moment(startDate).startOf('day');
    const endTime = moment(endDate).startOf('day');
    const now = moment().startOf('day');
    const isStart = moment().isSameOrAfter(moment(startDate));
    const isEnd = moment().isAfter(moment(endDate));

    let predictRate;
    if (!isStart) {
      predictRate = 0;
    } else if (isEnd) {
      predictRate = 1;
    } else {
      predictRate = (now.diff(startTime, 'day') + 1) / (endTime.diff(startTime, 'day') + 1);
    }

    const canCalRate = budget !== 0;
    if (canCalRate) {
      return { executeRate: spents / budget, predictRate };
    }
    return { executeRate: 0, predictRate: 0 };
  }

  getCampaignProgressRate = ({ budget, currencyRate, olapActualSpent, olapExpectSpent }) => {
    const budgetUsd = budget / currencyRate;
    const canCalRate = budgetUsd !== 0;
    if (canCalRate) {
      return {
        executeRate: olapActualSpent / budgetUsd,
        predictRate: olapExpectSpent / budgetUsd
      };
    }
    return {
      executeRate: 0,
      predictRate: 0
    };
  }

  getSponsorshipPmpCampaignProgressRate = (pmp: Pmp) => {
    const now = moment();
    const isStart = now.isSameOrAfter(moment(pmp.startTime));
    const isEnd = now.isAfter(moment(pmp.endTime));
    if (!isStart) {
      return 0;
    } else if (isEnd) {
      return 1;
    } else {
      const allDaypart = this.pmpManager.getDaypartDescriptions(pmp.startTime, pmp.endTime, pmp.dayPart);
      const passedDaypart = this.pmpManager.getDaypartDescriptions(pmp.startTime, now.format('YYYY-MM-DD HH:mm:ss'), pmp.dayPart);
      return passedDaypart.length / allDaypart.length;
    }
  }

  handleRemoveSelect = () => {
    this.selectedCampaign = [];
    this.updateState();
  }

  activeCampaign = async (event): Promise<void> => {
    event && event.stopPropagation();
    this.excuteCampaignAction('activate', i18n.t<string>('campaignList.labels.activeSuccess'));
  }

  deactiveCampaign = async (event): Promise<void> => {
    event && event.stopPropagation();
    this.excuteCampaignAction('deactivate', i18n.t<string>('campaignList.labels.deactiveSuccess'));
  }

  generateUpdateStatePayload = (selectedObjects: number[]) => {
    const payload = selectedObjects.map(objectId => {
      return {
        l2ChannelId: objectId
      };
    });
    return payload;
  }

  excuteCampaignAction = async (action: 'activate' | 'deactivate', successMessage) => {
    this.updateLoading(true);
    const updateStatePayload = [
      ...this.generateUpdateStatePayload(this.selectedCampaign)
    ];
    try {
      await this.manager.updateCampaignState(updateStatePayload, action);
      this.selectedCampaign = [];
      this.onCampaignChange();
      toast.success(successMessage);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateLoading(false);
  }

  deleteCampaign = async (campaignIds: Array<number>): Promise<void> => {
    this.updateLoading(true);
    try {
      await this.manager.deleteCampaigns(campaignIds);
      this.selectedCampaign = [];
      this.onCampaignChange(true);
      toast.success(i18n.t<string>('campaignList.labels.deleteSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateLoading(false);
  }

  allSelectedCampaignSameType () {
    if (this.selectedCampaign.length === 0) {
      return true;
    }

    const types = _.uniq(
      _.filter(this.campaignList, campaign =>
        (campaign.id !== undefined && this.selectedCampaign.indexOf(campaign.id) > -1))
        .map(campaign => campaign.adType)
    );
    return types.length === 1;
  }

  selectUnknowAdTypeCampaign () {
    if (this.selectedCampaign.length === 0) {
      return false;
    }

    const selectCampaignList = _.filter(
      this.campaignList, campaign =>
      (campaign.id !== undefined && this.selectedCampaign.indexOf(campaign.id) > -1)
    );
    return selectCampaignList.filter(campaign => !campaign.adType).length > 0;
  }

  canDeleteSelectedCampaigns (campaignIds: Array<number>) {
    if (campaignIds.length === 0) {
      return false;
    }

    const canDeleteCampaigns = _.filter(this.campaignList, campaign => {
      const selected = campaign.id !== undefined && campaignIds.indexOf(campaign.id) > -1;
      if (!selected) {
        return false;
      }
      return _.get(campaign, 'additionalInfo.creativeAmount.bindingCount', 0) === 0;
    });

    return canDeleteCampaigns.length === campaignIds.length;
  }

  setVisibilityOfDeleteConfirmModal (show: boolean) {
    this.showDeleteConfirmModal = show;
    this.updateState();
  }

  updateModelData (order: Order, campaignList: Array<RtbCampaignBasic>) {
    this.order = order;
    this.campaignList = campaignList;
    this.needUpdateViewModelData = true;
  }

  updateLoading (loading: boolean) {
    this.loading = loading;
    this.updateState();
  }

  updateState () {
    this.event.fireEvent(this);
  }
}
