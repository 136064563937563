import { PMaxCore } from 'core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';

export interface RetailRMNModel {
  readonly core: PMaxCore;
  readonly state: RetailRMNState;
  readonly event: UpdateEventListener<RetailRMNModel>;
}

export type RetailRMNProps = {
  readonly model: RetailRMNModel;
  readonly mainPagePath: string;
};

export type RetailRMNState = {

};

export class DefaultRetailRMNModel implements RetailRMNModel {

  core: PMaxCore;
  event: FireableUpdateEventListener<RetailRMNModel>;

  constructor (
    core: PMaxCore
  ) {
    this.core = core;
    this.event = new FireableUpdateEventListener<RetailRMNModel>();
  }

  get state () {
    return {

    };
  }
}
