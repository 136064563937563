import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { AgencyMemberListProps } from './AgencyMemberListModel';

import styles from './agencyMemberList.module.scss';
import _ from 'lodash';
import i18n from 'i18n';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';

function translatedHeader (column: any, columnIndex: number, { sortElement }): any {
  return (
    <Fragment>
      {i18n.t<string>(column.text)}
      {sortElement}
    </Fragment>
  );
}

function renderColumn (column: any, formatter: any | undefined = undefined): any {
  return _.omitBy({
    ...column,
    formatter: formatter,
    headerFormatter: translatedHeader
  }, _.isNil);
}

export class AgencyMemberList extends React.Component<AgencyMemberListProps> {

  nameFormatter = (cell: any, row: any): any => {
    return (
      <div className={styles.nameCell}>
        <Link
          to={{
            pathname: `/accounts/${row.account.id}`,
            state: { fromAgencyId: this.props.agencyId }
          }}
          className={styles.name}
        >
          {cell}
        </Link>
        <div className={styles.id}>
          {'ID: ' + row.account.id}
        </div>
      </div>
    );
  }

  statusFormatter = (cell: any): any => {
    return (
      <Fragment>
        {cell}
      </Fragment>
    );
  }

  floatingFormatter = (cell: any, row: any): any => {
    const actions = this.props.model.roleActions;
    const actionBtns = actions.map(action => {
      const show = action.show(row.account);
      const handler = () => action.handler(row.account, row.roleName);
      return show ? (
        <IconWithTooltip
          key={action.type}
          className={styles.action}
          icon={action.icon}
          onClick={handler}
          tooltipProps={{
            id: `${action.type}-${row.account.id}`,
            tooltip: i18n.t<string>(action.tooltip)
          }}
        />
      ) : undefined;
    });
    return (
      <TableRowToolBar className={styles.floatingEditArea}>
        <PermissionChecker permissionAware={hasFuncs(Permission.AGENCY_MEMBER_WRITE)}>
          {actionBtns}
        </PermissionChecker>
      </TableRowToolBar>
    );
  }

  render () {
    const model = this.props.model;
    const columns = [
      renderColumn(model.getColumnDefinition('name'), this.nameFormatter),
      renderColumn(model.getColumnDefinition('email')),
      renderColumn(model.getColumnDefinition('status'), this.statusFormatter),
      renderColumn(model.getColumnDefinition('permission')),
      renderColumn(model.editBtnsColumn, this.floatingFormatter)
    ];

    return (
      <div className={styles.agencyMemberList}>
        <StickableBootstrapTable
          hover
          keyField='id'
          data={model.members}
          columns={columns}
          stickFirstColumn
          stickLastColumn
          defaultSorted={model.defaultSorts}
          noDataIndication={i18n.t<string>(model.noDataDescription)}
        />
      </div>
    );
  }
}
