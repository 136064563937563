import { useCallback, useState } from 'react';
import { useCallAPI } from 'hooks/useCallAPI';
import { DefaultStoredValueManager } from 'core/storedValue/StoredValueManager';
import i18n from 'i18n';
import { validateMinimum } from 'utils/ValidateUtils';
import { isNil, omitBy } from 'lodash';
import { StoredValueAction } from 'core/storedValue/StoredValueLog';

export type StoredValueEditModel = {
  title: string,
  loading: boolean,
  showConfirm: boolean,
  setShowConfirm: (showConfirm: boolean) => void,
  onEditValue: (value: { amount: number, comment: string }) => void
  validate: (value: { amount: number, comment: string }) => {[key: string]: string | undefined} | undefined;
};

const defaultStoredValueManager = new DefaultStoredValueManager();

const useStoredValueModalModel = (
  adAgencyId: number,
  onClose: (refresh: boolean) => void,
  storedValueLogManager = defaultStoredValueManager
) => {

  const { loading, callAPIs } = useCallAPI();
  const [showConfirm, setShowConfirm] = useState(false);

  const callEditStoredValueAPI = useCallback((amount: number, action: string, description: string, comment?: string) => {
    setShowConfirm(false);
    callAPIs([
      () => storedValueLogManager.editStoredValue(adAgencyId, amount, action, description, comment)
    ], () => {
      onClose(true);
    });
  }, [adAgencyId, storedValueLogManager, callAPIs, onClose]);

  return {
    loading,
    showConfirm,
    setShowConfirm,
    callEditStoredValueAPI
  };
};

export const useStoredValueAddModalModel = (
  adAgencyId: number,
  remainingValue: number,
  onClose: (refresh: boolean) => void
): StoredValueEditModel => {
  const {
    callEditStoredValueAPI,
    ...otherData
  } = useStoredValueModalModel(adAgencyId, onClose);

  const onEditValue = useCallback(value => {
    callEditStoredValueAPI(value.amount, StoredValueAction.RECHARGE, '增加儲值額度', value.comment);
  }, [callEditStoredValueAPI]);

  const validate = useCallback((value: { amount: number, comment: string }) => {
    return omitBy({
      amount: validateMinimum(value.amount, 1)
    }, isNil);
  }, []);

  return {
    ...otherData,
    title: i18n.t<string>('storedValueEditModal.titles.addValueTitle'),
    onEditValue,
    validate
  };
};

export const useStoredValueEditModalModel = (
  adAgencyId: number,
  remainingValue: number,
  onClose: (refresh: boolean) => void
): StoredValueEditModel => {
  const {
    callEditStoredValueAPI,
    ...otherData
  } = useStoredValueModalModel(adAgencyId, onClose);

  const onEditValue = useCallback(value => {
    callEditStoredValueAPI(value.amount, StoredValueAction.EDIT_BUDGET, '編輯儲值額度', value.comment);
  }, [callEditStoredValueAPI]);

  const validate = useCallback((value: { amount: number, comment: string }) => {
    return omitBy({
      amount: validateMinimum(value.amount, remainingValue * -1)
    }, isNil);
  }, [remainingValue]);

  return {
    ...otherData,
    title: i18n.t<string>('storedValueEditModal.titles.editValueTitle'),
    onEditValue,
    validate
  };
};
