import { SelectOptions } from 'components/commonType';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { Actor } from 'core';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { AdvertiserListModel, DefaultAdvertiserListModel } from '../AdvertiserList/AdvertiserListModel';
import { isSystemAdmin } from 'helper/ActorHelper';

export interface AdvertiserHomeModel {
  readonly agencyId?: number;
  readonly event: UpdateEventListener<AdvertiserHomeModel>;
  readonly state: AdvertiserHomeState;
  readonly agencyOptions: SelectOptions[];
  readonly isSysAdmin: boolean;
  setAgencyId (agencyId?: number): void;
  setAgencyOptions (agencyOptions: SelectOptions[]): void;
  onUnmount (eventHandler?: number): void;
  getAdvertiserListModel (): AdvertiserListModel;
}

export type AdvertiserHomeProps = {
  readonly model: AdvertiserHomeModel
};

export type AdvertiserHomeState = {
  readonly agencyId?: number;
};

export class DefaultAdvertiserHomeModel implements AdvertiserHomeModel {

  agencyId?: number;
  isSysAdmin: boolean;
  event: FireableUpdateEventListener<AdvertiserHomeModel>;
  advertiserListModel?: AdvertiserListModel;

  constructor (
    actor: Actor,
    public agencyOptions: SelectOptions[]
  ) {
    this.isSysAdmin = isSystemAdmin(actor);
    this.event = new FireableUpdateEventListener<AdvertiserHomeModel>();
    this.agencyId = this.isSysAdmin ?
      SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY) :
      actor.agencyId === null ? undefined : actor.agencyId;
  }

  get state () {
    return {
      agencyId: this.agencyId
    };
  }

  setAgencyId = (agencyId?: number) => {
    if (this.agencyId === agencyId) {
      return;
    }
    this.agencyId = agencyId;
    this.updateState();
  }

  setAgencyOptions (agencyOptions: SelectOptions[]): void {
    this.agencyOptions = agencyOptions;
    this.updateState();
  }

  getAdvertiserListModel (): AdvertiserListModel {
    if (this.advertiserListModel && this.advertiserListModel.agencyId === this.agencyId) {
      return this.advertiserListModel;
    }

    this.advertiserListModel = new DefaultAdvertiserListModel(this.agencyId);
    return this.advertiserListModel;
  }

  onUnmount (eventHandler: number | undefined = undefined): void {
    if (eventHandler !== undefined) {
      this.event.remove(eventHandler);
    }
  }

  updateState () {
    this.event.fireEvent(this);
  }
}
