import { AxiosInstance } from 'axios';
import { Pmp, PmpFormType, PmpsWithPagination } from 'core/pmp/Pmp';
import client from './RestClient';
import { Pageable } from './Pageable';
import { SelectOptions } from 'components/commonType';

export interface PmpWebService {
  getPmps (
    pageable: Pageable,
    search?: string,
    status?: number[]
  ): Promise<PmpsWithPagination>;
  getPmpsOfAgency (agencyId: number, status?: number[]): Promise<Pmp[]>;
  getPmp (pmpId: number): Promise<Pmp>;
  createPmp (pmp: PmpFormType): Promise<void>;
  updatePmp (pmp: Pmp): Promise<void>;
  updatePmpState (pmpIds: number[], state: 'activate' | 'deactivate'): Promise<void>;
  getPmpSpaces (): Promise<SelectOptions[]>;
  getPmpActiveSpaces (): Promise<SelectOptions[]>;
  getPmpInactiveSpaces (): Promise<SelectOptions[]>;
  getPmpsWithin (startTime: string, endTime: string): Promise<Pmp[]>;
}

export class RestfulPmpWebService implements PmpWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  getPmps = async (pageable: Pageable, search: string = '', status: number[] = []): Promise<PmpsWithPagination> => {
    let { page, sizePerPage, sort, direction } = pageable;
    sort = sort ? sort : 'id';
    direction = direction ? direction : 'desc';
    const response = await this.restClient.get(`/pmp?name=${encodeURIComponent(search)}&page=${page}&size=${sizePerPage}&sort=${sort},${direction}&status=${status.join(',')}`);
    return {
      pagination: response.data.pagination,
      pmps: response.data.records
    };
  }

  getPmpsOfAgency = async (agencyId: number, status: number[] = []): Promise<Pmp[]> => {
    const response = await this.restClient.get(`/pmp/agency/${agencyId}?status=${status.join(',')}`);
    return response.data.records;
  }

  getPmp = async (pmpId: number): Promise<Pmp> => {
    const response = await this.restClient.get(`/pmp/${pmpId}`);
    return response.data;
  }

  createPmp = async (pmp: PmpFormType): Promise<void> => {
    await this.restClient.post('/pmp', pmp);
  }

  updatePmp = async (pmp: Pmp): Promise<void> => {
    await this.restClient.put('/pmp', pmp);
  }

  updatePmpState = async (pmpIds: number[], state: 'activate' | 'deactivate'): Promise<void> => {
    await this.restClient.put(`/pmp/${state}?id=${pmpIds.join(',')}`);
  }

  getPmpSpaces = async (): Promise<SelectOptions[]> => {
    const response = await this.restClient.get('/pmp/space');
    return response.data.records;
  }

  getPmpActiveSpaces = async (): Promise<SelectOptions[]> => {
    const response = await this.restClient.get('/pmp/space/active');
    return response.data.records;
  }

  getPmpInactiveSpaces = async (): Promise<SelectOptions[]> => {
    const response = await this.restClient.get('/pmp/space/inactive');
    return response.data.records;
  }

  getPmpsWithin = async (startTime: string, endTime: string): Promise<Pmp[]> => {
    const response = await this.restClient.get(`/pmp/active?startTime=${startTime.replace(' ', 'T')}&endTime=${endTime.replace(' ', 'T')}`);
    return response.data.records;
  }
}
