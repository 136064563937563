import React from 'react';
import { CreativePreviewProps } from './CreativePreviewModel';

export class CreativePreview extends React.Component<CreativePreviewProps> {

  render () {
    const PreviewComponent: typeof React.Component = this.props.model.previewComponent;
    if (!PreviewComponent) {
      return <div/>;
    }

    return (
      <PreviewComponent creative={this.props.model.creative}/>
    );
  }
}
