import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { Advertiser } from 'core/advertiser/Advertiser';
import { DefaultAdvertiserManager, AdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { AdvertiserMemberListModel, DefaultAdvertiserMemberListModel } from '../AdvertiserMemberList/AdvertiserMemberListModel';
import _ from 'lodash';
import { AdvertiserAuditLogModel } from 'containers/AuditLog/AdvertiserAuditLogModel';
import { CompanyMember, PartnershipMode } from 'core';
import i18n from 'i18n';

export interface AdvertiserDetailModel {
  advertiser?: Advertiser;
  readonly advertiserId: string | number;
  readonly state: AdvertiserDetailState;
  readonly event: UpdateEventListener<AdvertiserDetailModel>;
  init (): Promise<void>;
  onUnmount (eventHandler?: number): void;
  getAdvertiserMemberListModel (): AdvertiserMemberListModel;
  getAuditLogModel (): AdvertiserAuditLogModel | undefined;
  switchList (listType: string | null, e): void;
  showTab (tabName: string | null, e): void;
}

export type AdvertiserDetailProps = {
  readonly model: AdvertiserDetailModel;
};

export type AdvertiserDetailState = {
  readonly loading: boolean;
  readonly advertiserViewData: any;
  readonly listType: any;
  readonly members: CompanyMember[];
  readonly dataTabName: string;
};

export class DefaultAdvertiserDetailModel implements AdvertiserDetailModel {

  advertiser?: Advertiser;
  event: FireableUpdateEventListener<AdvertiserDetailModel>;
  handler?: number;
  advertiserViewData: any;
  loading: boolean;
  advertiserMemberListModel?: AdvertiserMemberListModel;
  listType: string;
  members: CompanyMember[];
  dataTabName: string = 'basic';
  auditLogModel?: AdvertiserAuditLogModel;

  constructor (
    public advertiserId: string | number,
    private isSystemAdmin: boolean,
    private manager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    this.manager = manager;
    this.event = new FireableUpdateEventListener<AdvertiserDetailModel>();
    this.loading = false;
    this.members = [];
    this.listType = 'member';
  }

  get state (): AdvertiserDetailState {
    return {
      advertiserViewData: this.advertiserViewData,
      loading: this.loading,
      listType: this.listType,
      members: this.members,
      dataTabName: this.dataTabName
    };
  }

  onUnmount (eventHandler?: number) {
    this.advertiser = undefined;
    eventHandler !== undefined && this.event.remove(eventHandler);
  }

  getAdvertiserMemberListModel () {
    if (this.advertiserMemberListModel && this.advertiserMemberListModel.members === this.members) {
      return this.advertiserMemberListModel;
    }
    this.advertiserMemberListModel = new DefaultAdvertiserMemberListModel(this.advertiserId, this.members, this.refreshMember);
    return this.advertiserMemberListModel;
  }

  getAuditLogModel (): AdvertiserAuditLogModel | undefined {
    // TODO: temparary only Admin enable to see audit log
    if (!this.isSystemAdmin) {
      return undefined;
    }

    if (
      this.auditLogModel &&
      this.advertiserId === this.auditLogModel.advertiserId
    ) {
      return this.auditLogModel;
    }

    this.auditLogModel = new AdvertiserAuditLogModel(this.isSystemAdmin, this.advertiserId);
    return this.auditLogModel;
  }

  async init (): Promise<void> {
    this.updateState(true);
    try {
      this.advertiser = await this.manager.getAdvertiser(this.advertiserId);
      this.members = await this.manager.getAdvertiserMembers(this.advertiserId);
      this.genAdvertiserViewData();
      await this.getAuditLogModel()?.init(1, true);
    } catch (error) {}
    this.updateState(false);
  }

  refreshMember = async (): Promise<void> => {
    this.updateState(true);
    try {
      this.members = await this.manager.getAdvertiserMembers(this.advertiserId);
    } catch (error) {}
    this.updateState(false);
  }

  genAdvertiserViewData () {
    const advertiser = this.advertiser;
    if (!advertiser) {
      this.advertiserViewData = {};
      return;
    }
    this.advertiserViewData = _.omitBy({
      basic: _.omitBy({
        id: advertiser.id,
        partnershipMode:  this.isSystemAdmin
          ? i18n.t<string>(`common.partnershipMode.${PartnershipMode[advertiser.partnershipModeId].toLowerCase()}`)
          : undefined
      }, _.isUndefined)
    }, _.isUndefined);
  }

  switchList = (listType, e) => {
    e && e.stopPropagation();
    if (listType === null) {
      return;
    }
    this.listType = listType;
    this.updateState(false);
  }

  showTab = (tabName, e) => {
    e && e.stopPropagation();
    if (tabName === null) {
      return;
    }
    this.dataTabName = tabName;
    this.updateState(false);
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
