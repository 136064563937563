import { CampaignDashboardRecord, DashboardSummaryInfo } from 'core/campaignDashboard/CampaignDashboard';
import { AdType, CampaignState } from 'core/rtbCampaign/RtbCampaign';
import client from './RestClient';
import { AxiosInstance } from 'axios';

export interface CampaignDashboardWebService {
  getSummaryInfo: (
    adTypes?: AdType[]
  ) => Promise<DashboardSummaryInfo[]>;

  getCampaignList: (
    day: number,
    adTypes?: AdType[],
    campaignState?: CampaignState
  ) => Promise<CampaignDashboardRecord[]>;
}

export class RestfulCampaignDashboardWebService implements CampaignDashboardWebService {

  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getSummaryInfo (
    adTypes?: AdType[]
  ): Promise<DashboardSummaryInfo[]> {
    let url = `/campaign-dashboard/summary/info`;
    if (adTypes) {
      url = `${url}?adTypes=${adTypes.join(',')}`;
    }
    const response = await this.restClient.get(url);
    return response.data.records.map(record => ({
      ...record,
      ctr: `${record.impres > 0 ? (record.clicks * 100 / record.impres).toFixed(2) : 0}%`
    }));
  }

  async getCampaignList (
    day: number,
    adTypes?: AdType[],
    campaignState?: CampaignState
  ) {
    let url = `/campaign-dashboard/campaignList?day=${day}`;
    if (adTypes) {
      url = `${url}&adTypes=${adTypes.join(',')}`;
    }
    if (campaignState) {
      url = `${url}&campaignState=${campaignState}`;
    }
    const response = await this.restClient.get(url);
    return response.data.records;
  }
}
