import React, { useCallback, useMemo } from 'react';
import styles from './productListOverview.module.scss';
import { Product } from 'core/product/Product';
import { partial } from 'lodash';
import i18n from 'i18n';
import { Slider } from 'components/Slider/Slider';
import { DefaultSliderModel } from 'components/Slider/SliderModel';

export const ProductListOverview: React.FC<React.PropsWithChildren<{
  products: Product[],
  handleRemoveProduct?: (productId: string) => void
  handleRemoveAll?: () => void
}>> = ({
  products,
  handleRemoveProduct,
  handleRemoveAll,
  children
}) => {

  const overview = products.map(product => {
    return (
      <div key={product.productId} className={styles.overview}>
        <div className={styles.overviewLeft}>
          <div className={styles.checkbox}>
            {handleRemoveProduct &&
              <input
                type='checkbox'
                checked={true}
                onChange={partial(handleRemoveProduct, product.productId)}
                id={product.productId}
              />
            }
            <label htmlFor={product.productId} />
          </div>
          <img src={product.imgLink} alt={product.name} />
        </div>
        <div className={styles.overviewRight}>
          <div className={styles.productName}>{product.name}</div>
          <div className={styles.productId}>{`ID: ${product.productId}`}</div>
        </div>
      </div>
    );
  });

  const sliderModel = useMemo(() => new DefaultSliderModel(195, 92, false, false, 0, false), []);
  const handleRemoveAllAndClose = useCallback(() => {
    handleRemoveAll && handleRemoveAll();
    sliderModel.handleSelect(0);
  }, [handleRemoveAll, sliderModel]);

  return (
    <div className={styles.productListOverview}>
      <div className={styles.operateArea}>
        <div className={styles.selectedInfo}>
          {i18n.t<string>('productListOverview.labels.selectCount', { count: products.length })}
        </div>
        {children}
        {handleRemoveAll &&
          <div className={styles.removeAll} onClick={handleRemoveAllAndClose}>
            {i18n.t<string>('productListOverview.labels.removeAll')}
          </div>
        }
      </div>
      <div className={styles.seperationLine}/>
      <div className={styles.overviewArea}>
        <Slider
          prevIcon={<div className={styles.arrowLeft}/>}
          nextIcon={<div className={styles.arrowRight}/>}
          prevIconClassName={styles.arrowLeftContainer}
          nextIconClassName={styles.arrowRightContainer}
          fill={true}
          noDataIndication={overview.length === 0 ? i18n.t<string>('productListOverview.placeholders.noProduct') : undefined}
          model={sliderModel}
        >
          {overview}
        </Slider>
      </div>
    </div>
  );
};
