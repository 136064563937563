import React, { RefObject } from 'react';
import { OrderFormState, OrderFormProps } from './OrderFormModel';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingIndicator } from 'components/LoadingIndicator';
import i18n from 'i18next';
import styles from './orderForm.module.scss';
import { Order } from 'core/order/Order';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import NavigationPrompt from 'components/NavigationPrompt/NavigationPrompt';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { ErrorHandler } from 'components/form/FormErrorHandler/FormErrorHandler';
export class OrderForm extends React.PureComponent<RouteComponentProps<any> & OrderFormProps, OrderFormState> {

  handler: any;
  mainDomRef: RefObject<any> = React.createRef();

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  cancelSubmit = () => {
    this.props.history.goBack();
  }

  renderForm = (formProps: FormikProps<Order>) => {
    this.props.model.setFormikProps(formProps);
    const Component: typeof React.Component = this.props.model.contentComponent;
    return (
      <Form>
        <ErrorHandler
          parentRef={this.mainDomRef}
          isSubmitting={formProps.isSubmitting}
          submitCount={formProps.submitCount}
        />
        <>
          <fieldset>
            <legend>
              <span>{i18n.t<string>('orderForm.labels.basic')}</span>
            </legend>
            <div className={styles.fieldContent}>
              <Component {...this.props} formik={formProps}/>
            </div>
          </fieldset>
        </>
        <div className={styles.buttonArea}>
          <button
            type='submit'
            className='btn btn-primary btn-sm'
          >
            {i18n.t<string>('common.buttons.submit')}
          </button>
          <button
            type='button'
            className='btn btn-secondary btn-sm'
            onClick={this.cancelSubmit}
          >
            {i18n.t<string>('common.buttons.cancel')}
          </button>
        </div>
      </Form>
    );
  }

  render () {
    const model = this.props.model;
    const state = model.state;
    if (state.redirectPath) {
      return (
        <Redirect
          to={{
            pathname: state.redirectPath,
            state: {
              message: 'order form state redirect path'
            }
          }}
        />
      );
    }
    if (state.loading || !model.order) {
      return <LoadingIndicator />;
    }
    return (
      <div className={styles.orderFormContainer} ref={this.mainDomRef}>
        <NavigationPrompt />
        <div className={styles.titleArea}>
          <TitleWithBreadcrumbs
            title={model.title}
            routes={model.breadcrumbs}
          />
        </div>
        <div className={styles.titleBottomLine} />
        <Formik
          initialValues={model.order}
          enableReinitialize
          onSubmit={model.submit}
          validate={model.validate}
          validateOnBlur={false}
        >
          {this.renderForm}
        </Formik>
      </div>
    );
  }
}
