import React from 'react';
import styles from './agencyDetail.module.scss';
import { Nav } from 'react-bootstrap';
import Basic from './Basic/Basic';
import Addon from './Addon/Addon';
import { AgencyMemberTab } from './AgencyMemberTab';
import { AgencyDetailModel, AgencyDetailProps, AgencyDetailState } from './AgencyDetailModel';
import { LoadingIndicator } from 'components/LoadingIndicator';
import i18n from 'i18next';
import { hasFuncs, hasRoles, notRoles } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { RoleNames } from 'core';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { AuditLog } from 'containers/AuditLog/AuditLog';

export class AgencyDetail extends React.Component<AgencyDetailProps, AgencyDetailState> {
  model: AgencyDetailModel;
  handler: number;
  constructor (props: any) {
    super(props);
    this.model = this.props.model;
    this.state = this.model.state;
    this.handler = this.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidMount () {
    this.model.init();
  }

  componentDidUpdate (prevProps: AgencyDetailProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.model = this.props.model;
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  renderBasicInfo = () => {
    const {
      defaultAgency,
      isSystemViewOnly
    } = this.props.model;
    return <Basic AgencyDetail={defaultAgency} isSystemViewOnly={isSystemViewOnly} />;
  }

  renderAddonInfo = () => {
    return <Addon AgencyDetail={this.props.model.defaultAgency} />;
  }

  renderAuditLogInfo = () => {
    const auditLogModel = this.props.model.getAuditLogModel();
    if (!auditLogModel) {
      return <div />;
    }
    return (<AuditLog model={auditLogModel} />);
  }

  renderTitleWithBreadcrumbs = () => {
    return (
      <TitleWithBreadcrumbs
        title={i18n.t<string>('agencyDetail.labels.title')}
        routes={[
          { path: '/agencies', breadcrumb: i18n.t<string>('agencies.home.title') },
          { path: '/agencies/:agencyId', breadcrumb: DynamicBreadcrumb, props: { label: _.get(this.props.model.defaultAgency, 'companyName'), matchParam: 'agencyId' } }
        ]}
      />
    );
  }

  render () {
    const {
      defaultAgency,
      showTab
    } = this.props.model;
    const { isLoading } = this.state;
    if (isLoading) {
      return (<LoadingIndicator />);
    }
    return (
      <div className={styles.container}>
        <PermissionChecker
          permissionAware={notRoles(RoleNames.sysAdmin)}
          renderOtherwise={this.renderTitleWithBreadcrumbs}
        >
          <header>
            <h3>{i18n.t<string>('agencyDetail.labels.title')}</h3>
          </header>
        </PermissionChecker>
        <div className={styles.titleBottomLine} />
        <div className={styles.agencyDetail}>
          <div className={styles.topArea}>
            <div className={styles.title}>
              {`${i18n.t<string>('agency.companyName')}: ${defaultAgency.companyName}`}
            </div>
            <div className={styles.buttonArea}>
              <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
                <Link to={`${defaultAgency.id}/edit`}>{i18n.t<string>('agencyDetail.labels.editAgency')}</Link>
              </PermissionChecker>
            </div>
          </div>
          <div className={styles.infoArea}>
            <Nav
              activeKey={this.state.dataTabName}
              onSelect={showTab}
            >
              <Nav.Item>
                <Nav.Link eventKey='basic'>{i18n.t<string>('agencyDetail.labels.basicTab')}</Nav.Link>
              </Nav.Item>
              <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
                <Nav.Item>
                  <Nav.Link eventKey='addon'>{i18n.t<string>('agencyDetail.labels.addonFeatureTab')}</Nav.Link>
                </Nav.Item>
              </PermissionChecker>
              <PermissionChecker permissionAware={hasFuncs(Permission.SYS_ADMIN_MANAGEMENT)}>
                <Nav.Item>
                  <Nav.Link eventKey='auditLog'>{i18n.t<string>('agencyDetail.labels.auditLogTab')}</Nav.Link>
                </Nav.Item>
              </PermissionChecker>
            </Nav>
            {this.state.dataTabName === 'basic' && this.renderBasicInfo()}
            {this.state.dataTabName === 'addon' && this.renderAddonInfo()}
            {this.state.dataTabName === 'auditLog' && this.renderAuditLogInfo()}
          </div>
        </div>
        <div className={styles.memberList}>
          <PermissionChecker
            permissionAware={hasFuncs(Permission.AGENCY_MEMBER_READ)}
          >
            <AgencyMemberTab model={this.model.getAgencyMemberTabModel()} />
          </PermissionChecker>
        </div>
      </div>
    );
  }
}
