import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import i18n from 'i18next';
import { chain, compact, defaultTo, get, isEmpty, keyBy, merge, remove, set } from 'lodash';
import { SelectOptions } from 'components/commonType';
import { getRtbAgeGroupsByAgeRange } from 'core/limitation/l2ObjectTAOptions';
import { OPERATE } from 'enum/Operate';

export interface EditLimitationModel {
  readonly state: EditLimitationState;
  readonly event: UpdateEventListener<EditLimitationModel>;
  readonly limitationSetting: any;
  readonly limitationValue: any;
  readonly operationSet: any;
  readonly addonFeature: Array<any>;
  readonly errors: any;
  readonly taOptionsCache: {[key: string]: SelectOptions[]};
  validate: () => any;
  onLimitationChange: () => void;
  setLimitationSetting: (limitationSetting, operationSet) => void;
  updateLimitationValue: (limitationValue) => void;
  addLimitation: (operate: string, limitationType: string, label: string, value: string) => Promise<void>;
  setLimitation: (operate: string, limitationType: string, value: SelectOptions[]) => Promise<void>;
  clearLimitation: (operate: string, limitationType: string, clearError?: boolean) => void;
  removeLimitation: (operate: string, limitationType: string, value: string) => Promise<void>;
  cleanTaOptionsCache: (name: string) => void;
  showInventory: (inventory?: string, operate?: string) => void;
}

export type EditLimitationProps = {
  readonly model: EditLimitationModel;
};

export type EditLimitationState = {
  readonly errors: any;
  readonly loading: boolean;
  readonly show?: string;
  readonly inventory: string;
};

export class DefaultEditLimitationModel implements EditLimitationModel {
  event: FireableUpdateEventListener<EditLimitationModel>;
  errors: any;
  limitationValue: any;
  operationSet: any;
  addonFeature: Array<any>;
  taOptionsCache: {[key: string]: SelectOptions[]} = {};
  loading: boolean = false;
  show?: string;
  inventory: string = 'default';

  constructor (
    public limitationSetting,
    limitationValue,
    operationSet,
    addonFeature: Array<any>
  ) {
    this.event = new FireableUpdateEventListener<EditLimitationModel>();
    this.operationSet = operationSet;
    this.addonFeature = addonFeature;
    this.setLimitationValue(limitationValue, []);
  }

  get state (): EditLimitationState {
    return {
      errors: this.errors,
      loading: this.loading,
      show: this.show,
      inventory: this.inventory
    };
  }

  showInventory = (inventory: string = 'default', operate?: string) => {
    this.inventory = inventory;
    this.show = operate;
    this.updateState();
  }

  setLimitationSetting (limitationSetting, operationSet) {
    this.limitationSetting = limitationSetting;
    this.operationSet = operationSet;
  }

  updateLimitationValue (limitationValue) {
    const keepOriginTypes = this.limitationSetting
      .filter(setting => setting.disable === true)
      .map(setting => setting.name);
    this.setLimitationValue(limitationValue, keepOriginTypes);
    this.onLimitationChange();
  }

  setLimitationValue (limitationValue, keepOriginTypes: string[]) {
    if (!limitationValue) {
      return;
    }
    const result = {};
    const operates = Object.keys(limitationValue);
    const validOperates = chain(this.operationSet['need'])
      .concat(this.operationSet['notNeed'], this.operationSet['other'] ? 'other' : undefined)
      .flatten()
      .value();

    operates.forEach(operate => {
      if (!validOperates.includes(operate)) {
        return;
      }
      if (operate === 'other' && limitationValue[operate].length > 0) {
        // dealId...
        result[operate] = [...limitationValue[operate]];
        return;
      }
      const validateTypes = this.limitationSetting
        .filter(setting =>
          (!!setting.supportOperates && setting.supportOperates.includes(operate)) &&
          (setting.ignoreAddonFeature || this.addonFeature.includes(setting.addonFeature))
        )
        .map(setting => setting.name)
        .concat(['age_min', 'age_max', 'genders', 'gender']);
      const keepOriginLimitatons = this.limitationValue && this.limitationValue[operate] ? this.limitationValue[operate].filter(limitation => keepOriginTypes.includes(limitation.type)) : [];
      const limitationsToUpdate = limitationValue[operate]
        .filter(limitation => validateTypes.includes(limitation.type) && !keepOriginTypes.includes(limitation.type));
      const validLimitations = [...limitationsToUpdate, ...keepOriginLimitatons];
      if (validLimitations.length > 0) {
        result[operate] = validLimitations;
      }
    });
    this.limitationValue = result;
  }

  getOp = (operate) => {
    switch (operate) {
      case 'include':
        return 'inc';
      case 'exclude':
        return 'exc';
      case 'preferred':
        return 'Preferred';
      case 'nonPreferred':
        return 'NonPreferred';
      default:
        return 'exc';
    }
  }

  addLimitation = async (operate: string, limitationType: string, label: string, value: string) => {
    const newLimitation = {
      op: this.getOp(operate),
      type: limitationType,
      value: [{
        label: label,
        value: value
      }]
    };
    if (!this.limitationValue[operate]) {
      this.limitationValue[operate] = [newLimitation];
    } else {
      const limitation = this.limitationValue[operate].find(limitation => limitation.type === limitationType);
      if (limitation) {
        const target = limitation.value.find(limit => {
          return limit.value === value;
        });
        if (!target) {
          limitation.value.push({
            label: label,
            value: value
          });
        }
      } else {
        this.limitationValue[operate].push(newLimitation);
      }
    }
    await this.onLimitationChange();
  }

  setLimitation = async (operate: string, limitationType: string, value: SelectOptions[]) => {
    const newLimitation = {
      op: this.getOp(operate),
      type: limitationType,
      value
    };
    if (!this.limitationValue[operate]) {
      this.limitationValue[operate] = [newLimitation];
    } else {
      const limitation = this.limitationValue[operate].find(limitation => limitation.type === limitationType);
      if (limitation) {
        limitation.value = value;
      } else {
        this.limitationValue[operate].push(newLimitation);
      }
    }
    await this.onLimitationChange();
  }

  removeLimitation = async (operate: string, limitationType: string, value: string) => {
    if (!this.limitationValue[operate]) {
      return;
    }
    const limitation = this.limitationValue[operate].find(limitation => limitation.type === limitationType);
    if (!limitation) {
      return;
    }
    remove(limitation.value, (limitationOption: any) => limitationOption.value === value);
    if (limitation.value.length === 0) {
      remove(this.limitationValue[operate], (limitation: any) => limitation.type === limitationType);
    }
    await this.onLimitationChange();
  }

  clearLimitation = (operate: string, limitationType: string, clearError: boolean = false) => {
    clearError && this.removeError(operate, limitationType);
    if (!this.limitationValue[operate]) {
      return;
    }
    remove(this.limitationValue[operate], (limitation: any) => limitation.type === limitationType);
  }

  removeError = (operate: string, limitationType: string) => {
    if (!this.errors || !this.errors[operate]) {
      return;
    }
    delete this.errors[operate][limitationType];
  }

  cleanTaOptionsCache = (name: string) => {
    delete this.taOptionsCache[name];
    this.updateState();
  }

  findRepeated (type, operation, limitationValue, allLimitation) {
    if (!Array.isArray(limitationValue)) {
      return [];
    }
    let repeatedValue: any[] = [];
    const includeLimitation = defaultTo(allLimitation.include, []);
    const preferredLimitation = defaultTo(allLimitation.preferred, []);
    const includeAgeMin = includeLimitation.find(limitation => limitation.type === 'age_min');
    const ageMinLimitation = includeAgeMin || preferredLimitation.find(limitation => limitation.type === 'age_min');
    const ageMin = get(ageMinLimitation, 'value');
    const includeAgeMax = includeLimitation.find(limitation => limitation.type === 'age_max');
    const ageMaxLimitation = includeAgeMax || preferredLimitation.find(limitation => limitation.type === 'age_max');
    const ageMax = get(ageMaxLimitation, 'value');
    const ageValues = getRtbAgeGroupsByAgeRange(ageMin, ageMax);
    const ageOptValue = includeAgeMin ? 'include' : 'preferred';
    Object.keys(allLimitation).forEach(key => {
      if (key === operation) {
        return [];
      }
      const limitationsOfOperation = get(keyBy(allLimitation[key], 'type')[type], 'value', []);
      const values = limitationValue.map(value => value.value);
      repeatedValue.push(
        limitationsOfOperation.find(limitationOfOperation => values.includes(limitationOfOperation.value))
      );
      if (type === 'age' && operation !== ageOptValue) {
        repeatedValue.push(
          ageValues.find(pmax2AgeValue => values.includes(pmax2AgeValue.value))
        );
      }
    });
    return compact(repeatedValue);
  }

  onLimitationChange = async () => {
    const errors = await this.validate();
    if (isEmpty(errors)) {
      this.updateState();
    }
  }

  validateLimitationValue = async (setting, limitation) => {
    if (setting.ignoreValidateOption) {
      return undefined;
    }
    try {
      const taOptions = this.taOptionsCache[setting.name] ? this.taOptionsCache[setting.name] : await setting.cb();
      this.taOptionsCache[setting.name] = taOptions;
      const validValue = chain(taOptions)
        .concat(taOptions.map(value => value.options ? value.options : []))
        .flatten()
        .map(value => value.value.toString())
        .value();
      const invalidValue = limitation.value.filter(value => !validValue.includes(value.value.toString()));
      return invalidValue.length > 0 ? i18n.t<string>('editLimitation.errors.someSelectedOptionInvalid', { name: i18n.t<string>(setting.title).toLowerCase() }) : undefined;
    } catch (e) {
      return undefined;
    }
  }

  validateRequiredLimitation = (operate, limitaionsOfOperate) => {
    const requiredSettings = this.limitationSetting.filter(setting => {
      if (setting.requiredOperate && setting.requiredOperate.includes(operate)) {
        return true;
      }
      if (setting.showWithLimitation) {
        return limitaionsOfOperate.find(limitation => setting.showWithLimitation.includes(limitation.type));
      }
      return false;
    });

    let errors = {};
    if (requiredSettings.length > 0) {
      requiredSettings.forEach(setting => {
        const limitation = limitaionsOfOperate.find(limitation => setting.name === limitation.type);
        if (!limitation || limitation.value.length === 0) {
          set(errors, `${operate}.${setting.name}`, i18n.t<string>('formValidate.labels.emptyError'));
        }
      });
    }
    return errors;
  }

  validateLimitation = async (operate, limitation) => {
    const errors = {};
    const setting = this.limitationSetting.find(setting => setting.name === limitation.type);
    if (setting) {
      const {
        validator,
        cb
      } = setting;
      if (validator) {
        const finalValidator = typeof validator === 'function' ? setting.validator : setting.validator[operate];
        const error = finalValidator ? finalValidator(operate, limitation.value, this.limitationValue) : undefined;
        error && set(errors, `${operate}.${limitation.type}`, error);
      }
      if (cb) {
        const error = await this.validateLimitationValue(setting, limitation);
        error && set(errors, `${operate}.${limitation.type}`, error);
      }
    }
    const repeatValue = this.findRepeated(limitation.type, operate, limitation.value, this.limitationValue);
    repeatValue.length > 0 && set(errors, `${operate}.${limitation.type}`, i18n.t<string>('editLimitation.errors.repeat'));
    return errors;
  }

  validate = async () => {
    this.updateState(true);
    const errors = {};
    if (!this.limitationValue) {
      return errors;
    }
    const operates = Object.values(OPERATE);
    for (const operate of operates) {
      const limitaionsOfOperate = defaultTo(this.limitationValue[operate], []);
      for (const limitation of limitaionsOfOperate) {
        const limitationErrors = await this.validateLimitation(operate, limitation);
        merge(errors, limitationErrors);
      }
      const requiredErrors = this.validateRequiredLimitation(operate, limitaionsOfOperate);
      merge(errors, requiredErrors);
    }
    this.errors = errors;
    this.updateState();
    return errors;
  }

  updateState (loading = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
