import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { cancelRequest } from 'ws/RestClient';
import { compact } from 'lodash';

export const useCallAPI = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const requests = useRef(0);
  const isUnmount = useRef<boolean>(false);

  useEffect(() => {
    isUnmount.current = false;
    return () => {
      if (requests.current > 0) {
        requests.current = 0;
        cancelRequest();
      }
      isUnmount.current = true;
    };
  }, []);

  const callAPIs = useCallback(async (apiMethods: Function[], callback?: Function, errorHandler?: Function) => {
    if (!apiMethods) {
      return;
    }
    requests.current += apiMethods.length;
    try {
      setLoading(true);
      const responses = compact(await Promise.all(apiMethods.map(method => method())));
      callback && callback(...responses);
    } catch (error) {
      handleError(error, errorHandler);
    }
    requests.current -= apiMethods.length;
    requests.current === 0 && setLoading(false);
  }, []);

  const callAPIsBySequence = useCallback(async (apiMethods: Function[], callback?: Function, errorHandler?: Function) => {
    if (!apiMethods) {
      return;
    }
    requests.current += apiMethods.length;
    try {
      setLoading(true);
      let responses: any[] = [];
      for (const apiMethod of apiMethods) {
        const res = await apiMethod();
        res && responses.push(res);
      }
      callback && callback(...responses);
    } catch (error) {
      handleError(error, errorHandler);
    }
    requests.current -= apiMethods.length;
    requests.current === 0 && setLoading(false);
  }, []);

  function handleError (error, errorHandler?: Function) {
    if (error && ((error as Error).toString() === 'Cancel' || (error as Error).toString() === 'CanceledError')) {
      return;
    }
    if (isUnmount.current) {
      return;
    }
    setLoading(false);
    errorHandler ?
      errorHandler(error) :
      (error instanceof Error) && toast.error(error.message);
  }

  return {
    loading,
    callAPIs,
    callAPIsBySequence
  };
};
