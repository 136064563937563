import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import React, { useCallback } from 'react';
import i18n from 'i18n';
import classnames from 'classnames/bind';
import styles from './urlInputField.module.scss';
import { defaultTo } from 'lodash';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FormikInputFieldProps, FormikInputWithFieldLayoutProps, InputProps } from './InputField';
import { FormikFieldProps, withFormikField } from 'hoc/withFormikField';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';

const cx = classnames.bind(styles);

const UrlInputContent: React.FC<InputProps> = ({
  name,
  type,
  value,
  error,
  className,
  onChange,
  ...props
}: InputProps) => {

  const hasError = error !== undefined;
  const inputGroupclassName = cx('urlInputGroup', {
    error: hasError,
    disabled: props.disabled
  });
  const postfixClassName = cx({
    withoutBorder: false,
    error: hasError,
    disabled: props.disabled
  });

  const safeValue = defaultTo(value, '');
  const goTo = useCallback(() => {
    window.open(value, '_blank', 'noopener');
  }, [value]);

  return (
    <InputGroup className={inputGroupclassName}>
      <FormControl
        name={name}
        value={safeValue}
        disabled={props.disabled}
        className={className}
        onChange={onChange}
        type={type}
        step={props.step}
        min={props.min}
        max={props.max}
        as={props.as}
      />
      <InputGroup.Append>
        <InputGroup.Text className={postfixClassName}>
          <div onClick={goTo}>
            <IconWithTooltip
              icon={faExternalLinkAlt}
              tooltipProps={{
                id: `creativeFormAdLinkBtn`,
                tooltip: i18n.t<string>('creativeSetupFlow.labels.openLinkHint')
              }}
            />
          </div>
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
};
const MemorizedUrlInputContent = React.memo(UrlInputContent);

const FormikUrlInput = withFormikField<Omit<InputProps, 'value'> & FormikFieldProps>(MemorizedUrlInputContent);

const UrlInputField = withFieldLayout<InputProps & FieldLayoutProps>(UrlInputContent);
UrlInputField.defaultProps = {
  fieldContentWidth: 'auto'
};
const FormikUrlInputWithFieldLayout = withFormikField<FormikInputWithFieldLayoutProps>(UrlInputField);

export const FormikUrlInputField: React.FC<FormikInputFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikUrlInput {...props} />;
  } else {
    return <FormikUrlInputWithFieldLayout {...props} />;
  }
};
