import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

import i18n from 'i18n';

import { AccountDetailProps, AccountDetailState } from './AccountDetailModel';

import { LoadingIndicator } from 'components/LoadingIndicator';
import { ActorPermissionForm } from 'components/ActorPermissionForm';
import { AccountSummary, DefaultAccountSummaryModel } from '../AccountSummary';
import { AccountActorList, DefaultAccountActorListModel } from '../AccountActorList';
import styles from './accountDetail.module.scss';
import { AccountState } from 'core';
import { Modal } from 'components/Modal/Modal';

export class AccountDetail extends React.Component<AccountDetailProps, AccountDetailState> {

  handler?: number;

  constructor (props: any) {
    super(props);
    this.state = this.props.model.state;
  }

  componentDidUpdate (prevProps: AccountDetailProps) {
    if (prevProps.model !== this.props.model) {
      this.handler && prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.fetch();
    }
  }

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.props.model.fetch();
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  selected = (key, event) => {
    event && event.stopPropagation();
    if (key === null) {
      return;
    }
    this.props.model.currentKeyChanged(key);
  }

  renderModal (modalData) {
    const model = this.props.model;
    return (
      <Modal
        fullScreen={false}
        title={modalData.title}
        primaryButton={modalData.confirmBtnData && {
          title: modalData.confirmBtnData.title,
          callback: modalData.confirmBtnData.callback
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.close'),
          callback: model.hideModal
        }}
        dismiss={model.hideModal}
      >
        {modalData.message}
      </Modal>
    );
  }

  render () {
    const model = this.props.model;
    const permissionFormModel = model.permissionFormModel;
    if (!model.state.account) {
      return <LoadingIndicator />;
    }
    const isPending = model.state.account.status === AccountState.PENDING;
    return (
      <div className={styles.accountDetailContainer}>
        {this.state.modalData && this.renderModal(model.state.modalData)}
        {this.props.fromAgencyId ?
          <div className={styles.titleWithBreadcrumbs}>
            <div className={styles.title}>
              {i18n.t<string>(model.title)}
            </div>
            <div className={styles.breadcrumbs}>
              <span>
                <NavLink to={`/agencies/${this.props.fromAgencyId}`}>{i18n.t<string>('agencyDetail.labels.title')}</NavLink>
                <span className={styles.slash}>/</span>
              </span>
              <span>{model.state.account.name}</span>
            </div>
          </div> :
          <div className={styles.title}>
            {i18n.t<string>(model.title)}
          </div>
        }
        <div className={styles.titleBottomLine} />
        <div className={styles.accountDetail}>
          <div className={styles.topArea}>
            <div className={styles.accountName}>
              {i18n.t<string>('accountDetail.labels.name', { name: model.state.account.name })}
            </div>
            <div className={styles.buttonArea}>
              {model.editButtonVisible && <Link to={model.editPath}>{i18n.t<string>(model.editButtonLabel)}</Link>}
              {isPending && <div onClick={model.resendActivationEmail}>{i18n.t<string>('accountDetail.labels.resendActivationEmail')}</div>}
            </div>
          </div>
          <div className={styles.detail}>
            <div className={styles.sepLine} />
            <div className={styles.basic}>
              {this.state.account && <AccountSummary model={new DefaultAccountSummaryModel(this.state.account)} />}
            </div>
          </div>
        </div>
        <div className={styles.bottomArea}>
          <div className={styles.tabContainer}>
            <Nav activeKey={this.state.currentKey} onSelect={this.selected}>
              <Nav.Item>
                <Nav.Link eventKey='agency'>{i18n.t<string>('accounts.tabs.agencies')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='advertiser'>{i18n.t<string>('accounts.tabs.advertisers')}</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          {this.state.account && <AccountActorList model={new DefaultAccountActorListModel(this.state.account, this.state.currentKey, model.roleActions)} />}
        </div>
        {this.state.loading && <LoadingIndicator />}
        {permissionFormModel && <ActorPermissionForm model={permissionFormModel} />}
      </div>
    );
  }
}
