import React, { useCallback, useMemo, useState } from 'react';
import styles from './tenmaxReconciliation.module.scss';
import i18n from 'i18n';
import moment from 'moment';
import { DateRangePickerField } from 'components/form/field/DateRangePickerField';
import { CustomField } from 'components/form/field/CustomField';
import { Button } from 'react-bootstrap';
import { DefaultReportManager } from 'core/report/ReportManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ReconciliationReportType } from 'ws/ReportWebService';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { SelectField } from 'components/form/field/SelectField';
import { SelectOptions } from 'components/commonType';
import { Trans } from 'react-i18next';

const defaultReportManager = new DefaultReportManager();
const MAX_QUERY_DAYS_MONTHLY = 31;

export const TenmaxReconciliation: React.FC = () => {

  const [reportType, setReportType] = useState<SelectOptions>();
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');
  const [dayRangeError, setDayRangeError] = useState<string | undefined>(undefined);
  const { loading, callAPIs } = useCallAPI();

  const handleOnChangeReportType = useCallback((value: SelectOptions) => {
    setDayRangeError(undefined);
    setReportType(value);
    if (value.value === ReconciliationReportType.MONTH) {
      setFrom(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
      setTo(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
    } else {
      setFrom(moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'));
      setTo(moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD'));
    }
  }, []);

  const downloadReport = useCallback(() => {
    if (reportType) {
      callAPIs([() => defaultReportManager.downloadTenmaxReconciliationReport(reportType.value as ReconciliationReportType, from, to)]);
    }
  }, [callAPIs, reportType, from, to]);

  const renderScheduling = useCallback(() => {
    const endDateMoment = moment(to);
    const startDateMoment = moment(from);
    const days = endDateMoment.diff(startDateMoment, 'days');
    if (days > 0) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingDay'>
          Total <span className='text-dark'>{{ days: days + 1 }} days</span>
        </Trans>
      );
    }
  }, [from, to]);

  const reportTypeOptions = useMemo(() => createSelectOptionsFromEnum(ReconciliationReportType, 'report.labels.tenmaxReconciliation.'), []);

  const reportTypeDatePickerElementMap: { [key: string | number]: JSX.Element } = useMemo(() => {
    const renderStandardDayRange = () => {
      const setUpStandardDayRange = (from: string | undefined, to: string | undefined) => {
        if (from) {
          setFrom(moment(from).startOf('day').format('YYYY-MM-DD'));
        }
        if (to) {
          setTo(moment(to).endOf('day').format('YYYY-MM-DD'));
        }
      };
      return (
        <DateRangePickerField
          label={i18n.t<string>('report.labels.reportGran')}
          startDate={from}
          endDate={to}
          maxDate={moment().endOf('day').subtract(1, 'day').format('YYYY-MM-DD')}
          format={'YYYY-MM-DD'}
          onChange={setUpStandardDayRange}
          showTimePicker={false}
          hint={renderScheduling()}
        />
      );
    };
    const renderMonthRange = () => {
      const setUpMonthRange = (from: string | undefined, to: string | undefined) => {
        if (!from || !to) {
          return;
        }
        const momentFrom = moment(from).startOf('day');
        const momentTo = moment(to).endOf('day');
        const start = new Date(momentFrom.format('YYYY-MM-DD HH:mm:ss'));
        const end = new Date(momentTo.format('YYYY-MM-DD HH:mm:ss'));
        const diffTime = Math.abs(end.getTime() - start.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setFrom(momentFrom.format('YYYY-MM-DD'));
        setTo(momentTo.format('YYYY-MM-DD'));
        setDayRangeError(diffDays > MAX_QUERY_DAYS_MONTHLY ? i18n.t<string>('campaign.descriptions.moreThanDays', { days: MAX_QUERY_DAYS_MONTHLY }) : undefined);
      };
      return (
        <DateRangePickerField
          label={i18n.t<string>('report.labels.reportGran')}
          startDate={from}
          endDate={to}
          maxDate={moment().endOf('day').subtract(1, 'day').format('YYYY-MM-DD')}
          format={'YYYY-MM-DD'}
          onChange={setUpMonthRange}
          showTimePicker={false}
          hint={renderScheduling()}
          error={dayRangeError}
        />
      );
    };
    return {
      [ReconciliationReportType.STANDARD]: renderStandardDayRange(),
      [ReconciliationReportType.MONTH]: renderMonthRange()
    };
  }, [from, to, dayRangeError, renderScheduling]);

  return (
    <div className={styles.container}>
      {loading && <LoadingIndicator/>}
      <div className={styles.titleArea}>
        <div className={styles.title}>
          {i18n.t<string>('report.titles.tenmaxReconciliation')}
        </div>
      </div>
      <div className={styles.contentArea}>
        <SelectField
          label={i18n.t<string>('report.labels.reportType')}
          name='reportType'
          options={reportTypeOptions}
          value={reportType}
          onChange={handleOnChangeReportType}
        />
        {reportType && reportTypeDatePickerElementMap[reportType.value]}
        {reportType &&
          <CustomField formGroupClassName={styles.reportSearchBtnContainer} label='' name='reportDownloadButton'>
            <Button variant='primary' onClick={downloadReport} size='sm' disabled={dayRangeError !== undefined}>
              {i18n.t<string>('report.labels.download')}
            </Button>
          </CustomField>
        }
      </div>
    </div>
  );
};
