import { getDayOfWeekLabelByValue, getInitDaypart } from 'components/Dayparts/Dayparts';
import { SummaryData } from 'components/SummaryDetail/SummaryDetail';
import { SelectOptions } from 'components/commonType';
import { PmpFormType, PmpType } from 'core/pmp/Pmp';
import { camelCase, compact, defaultTo, isEmpty, omit, omitBy } from 'lodash';
import i18n from 'i18n';
import { useCoreContext } from 'contexts/coreContext';

export const usePmpSummaryModel = (
  pmp: PmpFormType | undefined,
  spaceOptions: SelectOptions[],
  errors?: {[key: string]: string}
): SummaryData[] => {

  const core = useCoreContext();
  const currency = defaultTo(core?.accountManager?.localeMeta?.currency, 'NTD');

  if (!pmp) return [];

  const adAgencyIdSummary = pmp.adAgencyId.join(', ');
  const spacesSummary = pmp.space.map(space => {
    const option = spaceOptions.find(option => option.value === space);
    return option ? option.label : space;
  }).join(', ');

  const dayPartValue = omitBy(omit(pmp.dayPart, 'enabled'), isEmpty);
  const dayPart = isEmpty(dayPartValue) ? getInitDaypart() : dayPartValue;
  const dayPartSummary = Object.keys(dayPart).map(day => {
    const value = dayPart[day];
    return `${getDayOfWeekLabelByValue(parseInt(day, 10))},${i18n.t<string>('daypart.labels.hourUnit')}: ${Array.isArray(value) ? value.join(', ') : value}`;
  }).join('\r\n');

  const getColor = (key: string) => errors && errors[key] ? 'red' : undefined;
  return compact([{
    label: i18n.t<string>('pmp.labels.adAgencyId'),
    value: adAgencyIdSummary
  }, {
    label: i18n.t<string>('pmp.labels.name'),
    value: pmp.name
  }, {
    label: i18n.t<string>('pmp.labels.budget'),
    value: `${currency} ${pmp.budget}`,
    valueColor: getColor('budget'),
    hint: errors?.budget,
    hintColor: getColor('budget')
  }, {
    label: i18n.t<string>('pmp.labels.duration'),
    value: `${pmp.startTime} ~ ${pmp.endTime}`,
    valueColor: getColor('dayRange'),
    hint: errors?.dayRange,
    hintColor: getColor('dayRange')
  }, {
    label: i18n.t<string>('pmp.labels.dayPart'),
    value: dayPartSummary
  }, {
    label: i18n.t<string>('pmp.labels.pmpType'),
    value: i18n.t<string>(`pmp.pmpTypes.${camelCase(pmp.pmpType)}`)
  }, pmp.pmpType === PmpType.PROGRAMMATIC_GUARANTEED ? {
    label: i18n.t<string>('pmp.labels.targetQuantity'),
    value: pmp.targetQuantity!
  } : undefined, {
    label: i18n.t<string>('pmp.labels.space'),
    value: spacesSummary
  }]);
};
