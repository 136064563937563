import { CreativeOverviewStateContent } from './CreativeOverviewStateContent';
import { CreativeOverviewStateContentModel } from './CreativeOverviewStateContentModel';
import { BindCreativeStateContent } from './BindCreativeStateContent';
import { CreativeSummaryStateContent } from './CreativeSummaryStateContent';
import i18n from 'i18next';
import { ModalState } from 'containers/Common/ModalStateFactory';
import { BindCreativeStateContentModel } from './BindCreativeStateContentModel';
import { CreativeSummaryStateContentModel } from './CreativeSummaryStateContentModel';
import { ModifyCreativeActivationStateContentModel } from './ModifyCreativeActivationStateContentModel';
import { ModifyCreativeActivationStateContent } from './ModifyCreativeActivationStateContent';
import { CampaignBindInfo } from 'core/binding/CampaignBindInfo';

abstract class BasicCreativeManagementState {

  campaigns: Array<CampaignBindInfo>;
  back?;
  next?;
  close?;

  constructor (campaigns: Array<CampaignBindInfo>) {
    this.campaigns = campaigns;
  }

  get title () {
    return this.campaigns.length === 1 ?
      this.campaigns[0].name :
      i18n.t<string>('bindCreativeStateContent.labels.batchEditTitle', { num: this.campaigns.length });
  }

  setBackFn = (back) => {
    this.back = back;
  }

  setNextFn = (next) => {
    this.next = next;
  }

  setCloseFn = (close) => {
    this.close = close;
  }

  canGoNext = () => {
    return true;
  }
}

export class CreativeOverviewState extends BasicCreativeManagementState implements ModalState {

  constructor (
    campaigns: Array<CampaignBindInfo>,
    public contentModel: CreativeOverviewStateContentModel
  ) {
    super(campaigns);
  }

  get content () {
    return CreativeOverviewStateContent;
  }

  get btnData (): any {
    return {};
  }
}

export class BindCreativeState extends BasicCreativeManagementState implements ModalState {

  constructor (
    campaigns: Array<CampaignBindInfo>,
    public contentModel: BindCreativeStateContentModel
  ) {
    super(campaigns);
  }

  get content () {
    return BindCreativeStateContent;
  }

  get btnData () {
    return {
      primaryButton: this.next && {
        title: i18n.t<string>('bindCreativeStateContent.buttons.goSummary'),
        callback: this.next
      },
      secondaryButton: this.back && {
        title: i18n.t<string>('common.buttons.cancel'),
        callback: this.back
      },
      dangerButton: this.close && {
        title: i18n.t<string>('common.buttons.close'),
        variant: 'secondary',
        callback: () => {
          this.close(false);
        }
      }
    };
  }

  canGoNext = () => {
    return this.contentModel.selectedCreatives.length > 0;
  }
}

export class ModifyCreativeActivationState extends BasicCreativeManagementState implements ModalState {

  constructor (
    campaigns: Array<CampaignBindInfo>,
    public contentModel: ModifyCreativeActivationStateContentModel
  ) {
    super(campaigns);
  }

  get content () {
    return ModifyCreativeActivationStateContent;
  }

  get btnData () {
    return {
      primaryButton: this.next && {
        title: i18n.t<string>('bindCreativeStateContent.buttons.goSummary'),
        callback: this.next
      },
      secondaryButton: this.back && {
        title: i18n.t<string>('common.buttons.cancel'),
        callback: this.back
      },
      dangerButton: this.close && {
        title: i18n.t<string>('common.buttons.close'),
        variant: 'secondary',
        callback: () => {
          this.close(false);
        }
      }
    };
  }

  canGoNext = () => {
    return this.contentModel.selectedCreatives.length > 0;
  }
}

export class CreativeSummaryState extends BasicCreativeManagementState implements ModalState {

  constructor (
    campaigns: Array<CampaignBindInfo>,
    public contentModel: CreativeSummaryStateContentModel
  ) {
    super(campaigns);
    this.next = async () => {
      await this.contentModel.save();
      this.close && this.close(true);
    };
  }

  get content () {
    return CreativeSummaryStateContent;
  }

  get btnData () {
    return {
      primaryButton: this.next && {
        title: i18n.t<string>('common.buttons.ok'),
        callback: this.next
      },
      secondaryButton: this.back && {
        title: i18n.t<string>('creativeSummaryState.buttons.back'),
        callback: this.back
      },
      dangerButton: this.close && {
        title: i18n.t<string>('common.buttons.cancel'),
        callback: () => {
          this.close(false);
        },
        variant: 'secondary'
      }
    };
  }
}
