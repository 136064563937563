import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { State, Order } from 'core/order/Order';
import { OrderManager, DefaultOrderManager } from 'core/order/OrderManager';
import { SelectOptions } from 'components/commonType';
import moment from 'moment';
import { LocaleMeta, AddonFeatureManager } from 'core';
import i18n from 'i18next';
import _ from 'lodash';
import { FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { CreateOrderFormContent } from './CreateOrderFormContent';
import { EditOrderFormContent } from './EditOrderFormContent';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { validateMinimum, validateEmpty, validateMax } from 'utils/ValidateUtils';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { renderErrors } from './OrderFormHintRenderFuntions';
import { DefaultStoredValueManager, StoredValueManager } from 'core/storedValue/StoredValueManager';
import { ROUTE_PATH } from 'enum/RoutePath';

export type OrderFormState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
  readonly needCheckModifyReason: boolean;
  readonly showBudgetInputField: boolean
};

export interface OrderFormModel {
  readonly state: OrderFormState;
  readonly event: UpdateEventListener<OrderFormModel>;
  readonly order?: Order;
  readonly advertiserList: SelectOptions[];
  readonly localeMeta?: LocaleMeta;
  readonly formikProps?: FormikProps<Order>;
  readonly title: string;
  readonly totalDays: number;
  readonly dateRangeMinDate: string;
  readonly dateRangeMaxDate: string;
  readonly contentComponent: any;
  readonly orderExternalTypes: SelectOptions[];
  readonly showEditButton: boolean;
  readonly isOrderHasApproved: boolean;
  readonly isOrderStarted: boolean;
  readonly breadcrumbs: any[];
  init (): Promise<void>;
  validate (order: Partial<Order>): any;
  setFormikProps (props: FormikProps<Order>): void;
  submit (values: Order): void;
  setNeedCheckModifyReason (needCheck: boolean): void;
  onUnmount (eventHandler): void;
  triggerBudgetInputField? ();
}

export type OrderFormProps = {
  readonly model: OrderFormModel;
};

abstract class DefaultOrderFormModel implements OrderFormModel {
  event: FireableUpdateEventListener<OrderFormModel>;
  loading: boolean;
  order: any;
  manager: OrderManager;
  advertiserList: SelectOptions[];
  formikProps?: FormikProps<Order>;
  redirectPath?: string;
  needCheckModifyReason: boolean;
  localeMeta: LocaleMeta;
  orderExternalTypes: SelectOptions[];
  addonFeatureManager: AddonFeatureManager;
  showBudgetInputField: boolean;
  remainingStoredValue?: number;

  constructor (
    manager: OrderManager,
    localeMeta: LocaleMeta,
    advertisers: SelectOptions[],
    addonFeatureManager: AddonFeatureManager,
    private storedValueManager: StoredValueManager = new DefaultStoredValueManager()
  ) {
    this.event = new FireableUpdateEventListener<OrderFormModel>();
    this.loading = true;
    this.manager = manager;
    this.advertiserList = advertisers ? advertisers : [];
    this.needCheckModifyReason = false;
    this.localeMeta = localeMeta;
    this.orderExternalTypes = [];
    this.addonFeatureManager = addonFeatureManager;
    this.showBudgetInputField = false;
  }

  setFormikProps (props: FormikProps<Order>) {
    this.formikProps = props;
  }

  abstract get contentComponent ();

  abstract get title ();

  abstract get breadcrumbs ();

  get dateRangeMinDate () {
    return moment().startOf('day').format('YYYY-MM-DD');
  }

  get dateRangeMaxDate () {
    return moment().startOf('day').add(10, 'years').format('YYYY-MM-DD');
  }

  get totalDays () {
    if (!this.formikProps) {
      return 0;
    }
    const formProps = this.formikProps;
    return moment(formProps.values.endDate).diff(moment(formProps.values.startDate), 'days') + 1;
  }

  abstract init (): Promise<void>;

  abstract validateBudget (order: Partial<Order>, currency: string, budgetMin: number);

  abstract validate (order?: Partial<Order>): any;

  async getRemainingStoredValue () {
    if (this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.STORED_VALUE) && this.localeMeta.agencyId) {
      this.remainingStoredValue = await this.storedValueManager.getRemainingStoredValue(this.localeMeta.agencyId);
    }
  }

  onUnmount = (eventHandler) => {
    this.event.remove(eventHandler);
    this.redirectPath = undefined;
    this.showBudgetInputField = false;
  }

  abstract submit (values: Order): void;

  get state (): OrderFormState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath,
      needCheckModifyReason: this.needCheckModifyReason,
      showBudgetInputField: this.showBudgetInputField
    };
  }

  get showEditButton (): boolean {
    const hideEditBtn = this.order ? _.includes([State.NOT_APPROVE, State.REJECT], this.order.state) || !!this.order.modifyReason : true;
    return !hideEditBtn;
  }

  get isOrderHasApproved (): boolean {
    const notApproved = this.order ? _.includes([State.NOT_APPROVE, State.REJECT], this.order.state) : true;
    return !notApproved;
  }

  get isOrderStarted (): boolean {
    if (!this.order) {
      return false;
    }
    return moment().startOf('day').isAfter(moment(this.order.startDate));
  }

  abstract setNeedCheckModifyReason (needCheck: boolean);

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }

}

export class CreateOrderFormModel extends DefaultOrderFormModel {

  constructor (
    advertisers: SelectOptions[],
    localeMeta: LocaleMeta,
    addonFeature: AddonFeatureManager,
    manager: OrderManager = new DefaultOrderManager()) {
    super(manager, localeMeta, advertisers, addonFeature);
  }

  get breadcrumbs () {
    return [
      { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
      { path: '/orders/new', breadcrumb: this.title }
    ];
  }

  async init (): Promise<void> {
    this.updateState(true);
    await this.getRemainingStoredValue();
    const advertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
    this.order = {
      advertiserId,
      orderType: 'TENMAX',
      budget: 0,
      state: State.NOT_APPROVE,
      startDate: moment().startOf('day').format('YYYY-MM-DD'),
      endDate: moment().endOf('day').format('YYYY-MM-DD'),
      currency: this.localeMeta.currency
    };
    this.updateState(false);
  }

  submit = async (values: Order) => {
    const newOrder = {
      ...values,
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD')
    };
    this.updateState(true);
    try {
      const order = await this.manager.createOrder(newOrder);
      this.updateState(false, `/orders/${order.orderNumber}`);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  validateOrderDateRange = (endDate?: string) => {
    const error = validateEmpty(endDate);
    if (error) {
      return error;
    }

    if (moment(endDate).isBefore(moment().startOf('day'))) {
      return i18n.t<string>('orderForm.errors.endDateError');
    }
  }

  validateBudget = (order: Partial<Order>, currency: string, budgetMin: number = 1) => {
    const budget = _.defaultTo(order.budget, 0);
    if (!_.isNil(this.remainingStoredValue) || this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.STORED_VALUE)) {
      const remainingStoredValue = _.defaultTo(this.remainingStoredValue, 0);
      const error = validateMax(budget, remainingStoredValue, currency, 'orderForm.errors.budgetExceedStoredValueError');
      if (error) {
        return error;
      }
    }
    return validateMinimum(budget, budgetMin);
  }

  validate = (order: Partial<Order>) => {
    return _.omitBy(
      {
        advertiserId: validateEmpty(order.advertiserId),
        projectName: validateEmpty(order.projectName),
        budget: this.validateBudget(order, this.order.currency),
        dateRange: this.validateOrderDateRange(order.endDate)
      }, _.isEmpty);
  }

  get contentComponent () {
    return CreateOrderFormContent;
  }

  get title () {
    return i18n.t<string>('orderForm.labels.createTitle');
  }

  setNeedCheckModifyReason (needCheck) {
    this.needCheckModifyReason = false;
  }

  updateState (loading: boolean, redirectPath?: string) {
    this.loading = loading;
    this.redirectPath = redirectPath;
    this.event.fireEvent(this);
  }
}

export class EditOrderFormModel extends DefaultOrderFormModel {

  orderNumber: string;
  originOrder?: Order = undefined;

  constructor (
    localeMeta: LocaleMeta,
    advertisers: SelectOptions[],
    orderNumber: string,
    addonFeature: AddonFeatureManager,
    manager: OrderManager = new DefaultOrderManager()) {
    super(manager, localeMeta, advertisers, addonFeature);
    this.orderNumber = orderNumber;
  }

  get breadcrumbs () {
    return [
      { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(this.order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t<string>('common.labels.edit'),
          label: _.get(this.order, 'projectName'),
          matchParam: 'orderNumber'
        }
      }
    ];
  }

  get contentComponent () {
    return EditOrderFormContent;
  }

  get title () {
    return i18n.t<string>('orderForm.labels.editTitle');
  }

  setNeedCheckModifyReason (needCheck) {
    this.needCheckModifyReason = needCheck;
    this.updateState(false);
  }

  async init (): Promise<void> {
    this.updateState(true);
    try {
      const order = await this.manager.getOrder(this.orderNumber);
      if (order.state === State.SETTLE || order.state === State.SETTLED) {
        this.updateState(false, `/orders/${order.orderNumber}/edit/${ROUTE_PATH.ERROR403}`);
        return;
      }
      this.originOrder = { ...order };
      const advertiserOption = this.advertiserList.find(advertiserOption => advertiserOption.value.toString() === order.advertiserId.toString());
      this.order = {
        ...order,
        advertiserName: advertiserOption ? advertiserOption.label : '',
        startDate: moment(order.startDate).format('YYYY-MM-DD'),
        endDate: moment(order.endDate).format('YYYY-MM-DD')
      };
      await this.getRemainingStoredValue();
      this.updateState(false);
    } catch (e) {
      this.updateState(false);
    }
  }

  validateBudget = (order: Partial<Order>, currency: string, budgetMin: number = 1) => {
    const budget = _.defaultTo(order.budget, 0);
    const defaultBudget = _.get(this.originOrder, 'budget', 0);
    if (defaultBudget === budget) {
      return;
    }

    if (!_.isNil(this.remainingStoredValue)) {
      const max = order.state && [State.NOT_APPROVE, State.REJECT].includes(order.state) ?
        this.remainingStoredValue :
        this.remainingStoredValue + defaultBudget;
      const error = validateMax(budget, max, currency, 'orderForm.errors.budgetExceedStoredValueError');
      if (error) {
        return error;
      }
    }
    return validateMinimum(budget, budgetMin);
  }

  submit = async (values: Order) => {
    const orderUpdateData = _.omit({
      ...values,
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD')
    }, ['advertiserName']);
    this.updateState(true);
    try {
      const order = await this.manager.updateOrder(orderUpdateData);
      this.updateState(false, `/orders/${order.orderNumber}`);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  needCheckBudget (order: Partial<Order>) {
    // budget field is a input if edit button is hide
    const hideEditBtn = _.includes([State.NOT_APPROVE, State.REJECT], order.state) || !!order.modifyReason;
    return hideEditBtn || this.state.showBudgetInputField;
  }

  validateOrderDateRange = (startDate, endDate) => {
    if (!this.order) {
      return;
    }
    const errors: any[] = [];
    if (this.order.campaignMinStartDate && moment(startDate).isAfter(moment(this.order.campaignMinStartDate))) {
      errors.push(i18n.t<string>('orderForm.labels.campaignMinDateError'));
    }
    if (this.order.campaignMaxEndDate && moment(endDate).isBefore(moment(this.order.campaignMaxEndDate))) {
      errors.push(i18n.t<string>('orderForm.labels.campaignMaxDateError'));
    }
    if (this.order.endDate !== endDate) {
      if (moment(endDate).isBefore(moment().startOf('day'))) {
        return i18n.t<string>('orderForm.errors.endDateError');
      }
    }
    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validate = (order: Partial<Order>) => {
    const budgetMin = this.order.budget - this.order.budgetBalance;
    let errors = _.omitBy(
      {
        projectName: validateEmpty(order.projectName),
        budget: this.needCheckBudget(order) ?
          this.validateBudget(order, this.order.currency, budgetMin) :
          undefined,
        modifyReason: this.needCheckModifyReason ? validateEmpty(order.modifyReason) : undefined,
        dateRange: this.validateOrderDateRange(order.startDate, order.endDate)
      }, (value) => value === undefined);

    return _.omitBy(
      errors
    , _.isUndefined);
  }

  triggerBudgetInputField () {
    this.showBudgetInputField = !this.showBudgetInputField;
    this.updateState(false);
  }

  canActorViewOrder (actor) {
    if (!this.order) {
      return false;
    }

    return !(actor &&
      this.order.agencyId !== actor.agencyId);
  }

  updateState (loading: boolean, redirectPath?: string) {
    this.loading = loading;
    this.redirectPath = redirectPath;
    this.event.fireEvent(this);
  }
}
