export enum CreateRtbCampaignSetupFlowStep {
  CHOOSE_ADTYPE,
  SETUP_CAMPAIGN,
  SETUP_CREATIVE,
  SUMMARY
}

export enum EditRtbCampaignSetupFlowStep {
  SETUP_CAMPAIGN,
  SUMMARY
}

export enum CreateKeywordCampaignSetupFlowStep {
  CHOOSE_ADTYPE,
  CHOOSE_PRODUCT,
  SETUP_CAMPAIGN,
  SUMMARY
}

export enum EditKeywordCampaignSetupFlowStep {
  CHOOSE_PRODUCT,
  SETUP_CAMPAIGN,
  SUMMARY
}
