import { Pagination } from 'core/pagination/Pagination';

export type AccessToken = {

  readonly token: string;
  readonly expires: number;
};

export type Actor = {

  readonly id: number;
  readonly role: string;
  readonly agencyId: number | null;
  readonly advertiserId: number | null;
  readonly roleName: string;
  readonly actorType: string;
  readonly companyName: string;
  readonly displayOrder: number;
  readonly accessToken: AccessToken;
  readonly permissions: Array<string>;
};

export type Account = {

  readonly id: number;
  readonly name: string;
  readonly email: string;
  readonly language: string;
  readonly isAdmin: boolean;
  readonly activated: boolean;
  readonly actors: Array<Actor>;
  readonly agencyId?: number;
  readonly companyName?: string;
  readonly status: AccountState;
};

export type AccountWithPagination = {
  pagination: Pagination;
  accounts: Account[];
};

export enum RoleNames {

  sysAdmin = 'ADMIN',
  agencyAdmin = 'AGENCY_ADMIN',
  agencyManager = 'AGENCY_MANAGER',
  agencySales = 'AGENCY_SALES',
  agencyReport = 'AGENCY_REPORT',
  adsAdmin = 'ADVERTISER_ADMIN',
  adsReport = 'ADVERTISER_REPORT',
  adsSales = 'ADVERTISER_SALES'
}

export enum AccountState {
  INACTIVE = 0,
  ACTIVE = 1,
  DELETED = 3,
  PENDING = 4
}
