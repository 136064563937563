import client from './RestClient';
import { AxiosInstance } from 'axios';

export interface LimitationWebService {
  getAgencyLimitationPreSet (type: string): Promise<Array<any>>;
}

export class RestfulLimitationWebService implements LimitationWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getAgencyLimitationPreSet (type: string): Promise<Array<Object>> {
    const resp = await this.restClient.get(`v2/default-limitations/${type}`);
    return resp.data.records;
  }
}
