import _ from 'lodash';

import client from './RestClient';
import { Order } from '../core/order/Order';
import { Pagination } from 'core/pagination/Pagination';
import { Pageable } from './Pageable';
import { defaultCampaignBidPrice } from 'containers/Agencies/AgencyForm/defaultCampaignBidPrice';
import { AxiosInstance } from 'axios';

export interface OrderWebService {
  getOrder (orderNumber: string): Promise<Order>;
  getOrderById (orderId: string | number): Promise<Order>;
  getOrders (advertiserId: number | null | undefined,
    pageable: Pageable,
    search?: string,
    deliveries?: string[]): Promise<OrdersWithPagination>;
  approveOrder (orderId: number): Promise<void>;
  rejectOrder (orderId: number): Promise<void>;
  createOrder (order: Omit<Order, 'id'>): Promise<Order>;
  updateOrder (order: Order): Promise<Order>;
  settleOrder (orderId: number): Promise<void>;
  getOrderName (orderId: number): Promise<string | null>;
}

export type OrdersWithPagination = {
  pagination: Pagination,
  orders: Array<Order>
};

function wrapCampaignConstraint (json: any): any {
  return {
    budgetMinimum: _.get(json, 'budgetMinimum'),
    campaignBudgetMinimum: _.get(json, 'campaignBudgetMinimum')
  };
}

function wrapBidPriceDataOfAdTypes (json: any): any {
  if (!json) {
    return [];
  }
  const result: any[] = [];
  const bidPriceSetting = _.merge(_.cloneDeep(defaultCampaignBidPrice), json);
  bidPriceSetting.forEach(bidPriceData => {
    let bidPriceDataResult: any = {
      type: _.get(bidPriceData, 'type')
    };
    bidPriceDataResult.autoBidCap = wrapBidPriceData(bidPriceData.autoBidCap);
    bidPriceDataResult.bidFloor = wrapBidPriceData(bidPriceData.bidFloor);
    result.push(bidPriceDataResult);
  });
  return result;
}

function wrapBidPriceData (json: any): any {
  if (!json) {
    return {};
  }
  let bidFloorResult = {};
  Object.keys(json).forEach((key) => {
    const resultKey = key.toLowerCase();
    bidFloorResult[resultKey] = json[key];
  });
  return bidFloorResult;
}

function wrapOrder (json: any): any {
  return _.omitBy({
    id: _.get(json, 'orderId'),
    agencyId: _.get(json, 'agencyId'),
    projectName: _.get(json, 'projectName'),
    orderNumber: _.get(json, 'orderNumber'),
    advertiserId: _.get(json, 'advertiserId'),
    budget: _.get(json, 'budget'),
    orderType: _.get(json, 'orderType'),
    originBudget: _.get(json, 'originBudget'),
    changeBudget: _.get(json, 'changeBudget'),
    externalId: _.get(json, 'externalId'),
    state: _.get(json, 'state'),
    spent: _.get(json, 'spent'),
    creator: _.get(json, 'creator'),
    creatorEmail: _.get(json, 'creatorEmail'),
    expectedSpent: _.get(json, 'expectedSpent'),
    actualSpent: _.get(json, 'actualSpent'),
    comments: _.get(json, 'comments'),
    startDate: _.get(json, 'startDate'),
    endDate: _.get(json, 'endDate'),
    createDate: _.get(json, 'createDate'),
    createDateTime: _.get(json, 'createDateTime'),
    campaignConstraint: wrapCampaignConstraint(_.get(json, 'campaignConstraint')),
    budgetBalance: _.get(json, 'budgetBalance'),
    currency: _.get(json, 'currency'),
    timezone: _.get(json, 'timezone'),
    impres: _.get(json, 'impres', 0),
    clicks: _.get(json, 'clicks', 0),
    modifyReason: _.get(json, 'modifyReason'),
    rtbCampaignRunningStatus: _.get(json, 'rtbCampaignRunningStatus'),
    needAlert: _.get(json, 'needAlert'),
    externalType: _.get(json, 'externalType'),
    campaignBidPrice: wrapBidPriceDataOfAdTypes(_.get(json, 'campaignBidPrice'))
  }, _.isNull);
}

function wrapOrderDetail (json: any): any {
  return wrapOrder(json);
}

function wrapOrderList (json: any): any {
  return _.defaultTo(json, []).flatMap((json: any) =>
    wrapOrder(json)
  );
}

function wrapPagination (json: any): any {
  return {
    page: _.get(json, 'page', 1),
    size: _.get(json, 'size', 10),
    totalCount: _.get(json, 'totalCount', 0)
  };
}

export class RestfulOrderWebService implements OrderWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getOrder (orderNumber: string): Promise<Order> {
    const response = await this.restClient.get(`/v2/adsOrders/orderNumbers/${orderNumber}`);
    return wrapOrderDetail(response.data);
  }

  async getOrderById (orderId: string | number): Promise<Order> {
    const response = await this.restClient.get(`/v2/adsOrders/${orderId}`);
    return wrapOrderDetail(response.data);
  }

  async getOrders (
    advertiserId: number | null | undefined,
    pageable: Pageable,
    search: string = '',
    deliveries: string[] = []): Promise<OrdersWithPagination> {
    let { page, sizePerPage, sort, direction } = pageable;
    sort = sort ? sort : 'remainingDay';
    direction = direction ? direction : 'desc';
    let response;
    let sortParam = sort === 'orderNumber' ? sort : `${sort},id`;
    let url = `/v2/adsOrders?search=${encodeURIComponent(search)}&page=${page}&size=${sizePerPage}&sort=${sortParam},${direction}&deliveries=${deliveries}`;
    url = advertiserId ? `${url}&advertiser_id=${advertiserId}` : url;
    response = await this.restClient.get(url);
    return {
      orders: wrapOrderList(response.data.records),
      pagination: wrapPagination(response.data.pagination)
    };
  }

  async approveOrder (orderId: number) {
    await this.restClient.put(`/v2/adsOrders/${orderId}/approve`);
  }

  async rejectOrder (orderId: number) {
    await this.restClient.put(`/v2/adsOrders/${orderId}/reject`);
  }

  async createOrder (order: Order) {
    const response = await this.restClient.post('/v2/adsOrders', order);
    return wrapOrderDetail(response.data);
  }

  async updateOrder (order: Order) {
    const response = await this.restClient.put('/v2/adsOrders', order);
    return wrapOrderDetail(response.data);
  }

  async settleOrder (orderId: number) {
    await this.restClient.put(`/adsOrders/settle/${orderId}`);
  }

  async getOrderName (orderId: number): Promise<string | null> {
    const response = await this.restClient.get(`/v2/adsOrders/${orderId}/name`);
    return response.data.result;
  }
}
