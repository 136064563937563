import React, { useState, useEffect, useCallback } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import { ImageFormProps } from './ImageFormModel';
import styles from './imageForm.module.scss';
import { ImageCreativeSizeHint } from './ImageCreativeSizeHint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FormConfig } from 'components/form/FormConfig';
import { FormContent } from 'components/form/Form';
import { partial } from 'lodash';

export const ImageForm: React.FunctionComponent<ImageFormProps & { formik: FormikContextType<any> }> = (props) => {

  const {
    hintModalData,
    renderHintModal,
    setHintModalData,
    getInitFormConfig
  } = props;

  const [sizeInstructionModalData, setSizeInstructionModalData] = useState<any>(undefined);
  const [formConfig, setFormConfig] = useState<FormConfig>(getInitFormConfig());

  const showSizeInstruction = useCallback(() => {
    const modalData = {
      component: ImageCreativeSizeHint,
      props: {
        closeHint: partial(setHintModalData, undefined)
      }
    };
    setSizeInstructionModalData(modalData);
    setHintModalData(modalData);
  }, [setHintModalData]);

  const closeSizeInstruction = useCallback(() => {
    setSizeInstructionModalData(undefined);
    setHintModalData(undefined);
  }, [setHintModalData]);

  useEffect(() => {
    if (hintModalData !== sizeInstructionModalData) {
      setSizeInstructionModalData(undefined);
    }
  }, [hintModalData, sizeInstructionModalData]);

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addFormikFileInput({
              type: 'image',
              className: styles.imageInput,
              label: i18n.t<string>('creativeSetupFlow.labels.image'),
              name: 'medias.image',
              validTypes: props.model.validTypes,
              hints: [],
              permanentHint: (
                <div>
                  <span className={styles.hint}>
                    {i18n.t<string>('creativeSetupFlow.labels.imageTypeHint')}
                  </span>
                  <span className={styles.hint}>
                    {i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })}
                  </span>
                  <div
                    className={styles.sizeInstructionBtn}
                    onClick={sizeInstructionModalData ?
                      closeSizeInstruction : showSizeInstruction
                    }
                  >
                    {i18n.t<string>('imageCreativeSizeHint.labels.sizeInstruction')}
                    <FontAwesomeIcon icon={faInfoCircle}/>
                  </div>
                </div>
              ),
              validate: props.model.validateImage
            })
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .withHintModal(renderHintModal())
        .build()
      )
      .build()
    );
  }, [
    props.basicFields,
    sizeInstructionModalData,
    props.model.validateImage,
    props.model.validTypes,
    renderHintModal,
    showSizeInstruction,
    closeSizeInstruction
  ]);

  return (
    <FormContent
      formConfig={formConfig}
    />
  );
};

export default connect(ImageForm);
