import { SelectOptions } from 'components/commonType';
import {
  AdRequestSourceWebService,
  RestfulAdRequestSourceWebService
} from 'ws/AdRequestSourceWebService';
import { SuggestKeywords } from 'core/product/Product';

export interface AdRequestSourceManager {
  getOS (): Promise<SelectOptions[]>;
  getSpaceTypes (): Promise<SelectOptions[]>;
  getDevice (): Promise<SelectOptions[]>;
  getProductHotKeywords (goodsCodes: string[]): Promise<SuggestKeywords[]>;
  getTrendingKeywords (): Promise<string[]>;
}

export class DefaultAdRequestSourceManager implements AdRequestSourceManager {
  webService: AdRequestSourceWebService;

  constructor (
    webService: AdRequestSourceWebService = new RestfulAdRequestSourceWebService()
  ) {
    this.webService = webService;
  }
  getOS (): Promise<SelectOptions[]> {
    return this.webService.getOS();
  }
  getSpaceTypes (): Promise<SelectOptions[]> {
    return this.webService.getSpaceTypes();
  }
  getDevice (): Promise<SelectOptions[]> {
    return this.webService.getDevice();
  }
  async getProductHotKeywords (goodsCodes: string[]): Promise<SuggestKeywords[]> {
    if (goodsCodes.length === 0) return Promise.resolve([]);
    return this.webService.getProductHotKeywords(goodsCodes);
  }
  async getTrendingKeywords (): Promise<string[]> {
    // only return top 5 trending keywords
    const keywords = await this.webService.getTrendingKeywords();
    return keywords.slice(0, 5);
  }
}
