import { Currency } from 'core/agency/Agency';

export enum ReportDimension {
  MONTH = 'month',
  DAY = 'day',
  HOUR = 'hour',
  AGENCY = 'agencyId',
  ADVERTISER = 'advertiserId',
  ORDER = 'adsOrderId',
  CAMPAIGN = 'campaignId',
  L2_CHANNEL = 'l2ChannelId',
  L1_OBJECT = 'l1ObjectId',
  CAMPAIGN_TYPE = 'adType',
  CREATIVE = 'creativeId',
  CREATIVE_TYPE = 'creativeType',
  DEVICE_TYPE = 'deviceType',
  PRODUCT = 'productId',
  SEARCH_KEYWORD = 'searchKeyword'
}

export enum ReportType {
  PERFORMANCE = 'performance',
  E_COMMERCE = 'eCommerce'
}

export enum ReportGran {
  MONTH = 'month',
  DAY = 'day',
  HOUR = 'hour'
}

export enum Metrics {
  IMPRES = 'impres',
  CLICKS = 'clicks',
  CTR = 'ctr',
  UNDER_BID_FLOOR = 'underBidFloor',
  SPENT = 'spent',
  MEDIA_COST = 'mediaCost',
  MEDIA_SPENT = 'mediaSpent',
  CPM = 'cpm',
  CPC = 'cpc'
}

export type ReportRecord = {
  dimensionID?: string;
  dimensionName: string;
  clicks: number;
  dataCost: number;
  impres: number;
  mediaCost: number;
  mediaSpent?: number;
  spent: number;
  underBidFloor?: number;
  winTotal: number;
  tags: Array<string>;
  parentId?: number | string;
  extra?: {[key: string]: any};
  directAddToCart?: number;
  directOrder?: number;
  directProductSalesCount?: number;
  directProductSalesAmount?: number;
  indirectAddToCart?: number;
  indirectOrder?: number;
  indirectProductSalesCount?: number;
  indirectProductSalesAmount?: number;
};

export type ReportCompareRecord = ReportRecord & {
  compareID: string;
  compareName: string;
};

export type ReportData = {
  allowMetrics: Array<Metrics>;
  dimension: ReportDimension;
  filter: any;
  from: string;
  to: string;
  records: Array<ReportRecord>;
  currency: Currency;
};
