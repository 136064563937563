import {
  ReconciliationReportType,
  ReportWebService,
  RestfulReportWebService
} from 'ws/ReportWebService';

import { ReportType, ReportDimension, ReportGran, ReportData } from './ReportData';

export interface ReportManager {
  downloadL1ObjectReport (order: {
    name: string,
    l1ObjectId: number
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[]): Promise<void>;
  getReportData (type: ReportType, dimension: ReportDimension, gran: ReportGran, filter: any, from: string, to: string): Promise<ReportData>;
  downloadTenmaxReconciliationReport (reportType: ReconciliationReportType, from: string, to: string): Promise<void>;
}

export class DefaultReportManager implements ReportManager {
  webService: ReportWebService;

  constructor (
    webService: ReportWebService = new RestfulReportWebService()
  ) {
    this.webService = webService;
  }
  async downloadL1ObjectReport (l1Object: {
    name: string,
    l1ObjectId: number
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[]): Promise<void> {
    await this.webService.downloadL1ObjectReport(l1Object, startDate, endDate, groupBy, selectFields);
  }
  async getReportData (type: ReportType, dimension: ReportDimension, gran: ReportGran, filter: any, from: string, to: string) {
    return this.webService.getReportData(type, dimension, gran, filter, from, to);
  }
  async downloadTenmaxReconciliationReport (reportType: ReconciliationReportType, from: string, to: string): Promise<void> {
    await this.webService.downloadTenmaxReconciliationReport(reportType, from, to);
  }
}
