import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router';

import './Reports.scss';

import { PerformanceReportModel } from './PerformanceReport/PerformanceReportModel';
import { PMaxCore, RoleNames } from 'core';
import i18n from 'i18n';
import { addOnEnabled, hasFuncs, hasRoles } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { ReportContent } from './ReportContent';
import { PerformanceReportDimension } from './PerformanceReport/PerformanceReportDimension';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { CampaignDashboard } from 'containers/CampaignDashboard/CampaignDashboard';
import { TenmaxReconciliation } from './TenmaxReconciliation/TenmaxReconciliation';

const PerformanceReportView = withErrorBoundary(ERROR_CODE.REPORT_PERFORMANCE, ReportContent);

export class Reports extends React.Component<RouteComponentProps<{}> & { core: PMaxCore }> {

  performanceReportModel: PerformanceReportModel;

  constructor (props) {
    super(props);
    const core = this.props.core;
    this.performanceReportModel = new PerformanceReportModel(
      core.authenticationManager.actor,
      core.accountManager.localeMeta,
      (newSearchPath, replace) => {
        const newPath = '/reports/performance' + newSearchPath;
        replace ? props.history.replace(newPath) : props.history.push(newPath);
      },
      core.addonFeatureManager
    );
  }

  renderPerformanceReport = (props) => {
    const actor = this.props.core.authenticationManager.actor;
    return (
      <div id='performance-report'>
        <PerformanceReportView
          key={actor ? actor.id : 0}
          lang={i18n.language}
          search={props.location.search}
          model={this.performanceReportModel}
          customDimensionComponent={PerformanceReportDimension}
        />
      </div>
    );
  }

  redirectToRoot = () => {
    return <Redirect to='/' />;
  }

  renderCampaignDashboard = () => {
    return <CampaignDashboard />;
  }

  renderTenmaxReconciliation = () => {
    return <TenmaxReconciliation />;
  }

  render () {
    const { sysAdmin, agencyAdmin, agencyManager, agencySales } = RoleNames;
    return (
      <div className='reports'>
        <Switch>
          <RouteWithPermission
            path='/reports/performance'
            permissionAware={hasFuncs(Permission.REPORT_ADS)}
            redirectPath={`/reports/performance/${ROUTE_PATH.ERROR403}`}
            render={this.renderPerformanceReport}
          />
          <RouteWithPermission
            path='/reports/campaign-dashboard'
            permissionAware={hasRoles(sysAdmin).or(hasRoles(agencyAdmin, agencyManager, agencySales).and(addOnEnabled(ADDONFEATURE.REPORT.REPORT_CAMPAIGN_DASHBOARD)))}
            redirectPath={`/reports/campaign-dashboard/${ROUTE_PATH.ERROR403}`}
            render={this.renderCampaignDashboard}
          />
          <RouteWithPermission
            path='/reports/tenmax-reconciliation'
            permissionAware={hasRoles(sysAdmin)}
            redirectPath={`/reports/tenmax-reconciliation/${ROUTE_PATH.ERROR403}`}
            render={this.renderTenmaxReconciliation}
          />
          <Route render={this.redirectToRoot} />
        </Switch>
      </div>
    );
  }
}
