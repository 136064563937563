import { L1ObjectFormChannelModel } from './L1ObjectFormChannelModel';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { Order } from 'core/order/Order';
import _ from 'lodash';
import { CampaignState, RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';
import { DefaultL1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { getPriceValue } from 'helper/CurrencyHelper';
import { BidStrategy } from 'core/l2Object/L2Object';

export abstract class L1ObjectEditFormChannelModel implements L1ObjectFormChannelModel {

  minBudgetPerDay: number = 0;
  minBudgetPerCampaign: number = 0;
  constructor (
    protected order: Order,
    public l1Object: L1Object,
    protected l1ObjectManager = new DefaultL1ObjectManager()
  ) {
    this.minBudgetPerDay = order.campaignConstraint.budgetMinimum;
    this.minBudgetPerCampaign = order.campaignConstraint.campaignBudgetMinimum;
  }

  abstract minBudget (values: Partial<L1Object>);

  abstract get totalBudget ();

  abstract init (callback: (data) => void);

  abstract validate (value: Partial<L1Object>);

  abstract getDefaultBidStrategy ();

  abstract showRemainBudget (values: Partial<L1Object>);

  abstract toChannelNativeData (l1Object: L1Object, extraData: any): any;
}

export class RTBCampaignGroupEditFormModel extends L1ObjectEditFormChannelModel {

  constructor (
    order: Order,
    l1Object: L1Object,
    private rtbCampaigns?: RtbCampaignBasic[],
    private campaignManager = new DefaultRtbCampaignManager()
  ) {
    super(order, l1Object);
  }

  minBudget (values: Partial<L1Object>) {
    const order = this.order;
    const managementBudgets = _.get(values, 'rtb.campaign_budgets', []);
    const decimalPlaceCount = 0;
    const ignoredCampaignStates = [CampaignState.DELETE];
    const validRtbCampaigns = this.rtbCampaigns
     ? this.rtbCampaigns.filter(campaign => !_.includes(ignoredCampaignStates, campaign.state))
     : [];
    if (validRtbCampaigns.length === 0) {
      return this.minBudgetPerDay;
    } else {
      const minBudgetOfRtbCampaign = validRtbCampaigns.reduce((acc, rtbCampaign) => {
        const managementBudget = managementBudgets.find(campaign => campaign.id === rtbCampaign.id?.toString());
        const campaignBudget = managementBudget ? managementBudget.budget : rtbCampaign.budget;
        const minBudget = campaignBudget;
        return acc + minBudget;
      }, 0);
      const expectedSpentOfRtbCampaign = _.floor(
        validRtbCampaigns.reduce((acc, rtbCampaign) => {
          return acc + rtbCampaign.expectedSpent;
        }, 0),
        decimalPlaceCount
      );
      return Math.min(order.budget, getPriceValue(order.currency, Math.max(minBudgetOfRtbCampaign, expectedSpentOfRtbCampaign)));
    }
  }

  get totalBudget () {
    if (!this.l1Object || !this.rtbCampaigns) {
      return 0;
    }
    let l1ObjectBudget = 0;
    const currentFieldValue = _.get(this.l1Object, 'budget');
    if (currentFieldValue !== '' && currentFieldValue !== undefined) {
      l1ObjectBudget = +_.defaultTo(currentFieldValue, 0).toString();
    } else {
      l1ObjectBudget = this.rtbCampaigns.reduce((acc, rtbCampaign) => {
        return rtbCampaign.budget ? acc + (+rtbCampaign.budget) : acc;
      }, 0);
    }
    return _.defaultTo(_.get(this.order, 'budgetBalance'), 0) + l1ObjectBudget;
  }

  init = async (callback: (data) => void) => {
    if (!this.rtbCampaigns) {
      this.rtbCampaigns = await this.campaignManager.getCampaignsOfGroup(this.l1Object.l1ObjectId);
    }
    callback({
      l2ObjectList: this.rtbCampaigns
    });
  }

  validate = (value: Partial<L1Object>) => {
    return {};
  }

  getDefaultBidStrategy () {
    if (!this.rtbCampaigns || this.rtbCampaigns.length === 0) {
      return BidStrategy.LOWEST_COST_WITHOUT_CAP;
    }
    return _.isNumber(this.rtbCampaigns[0].bidPrice) ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP;
  }

  showRemainBudget (values: Partial<L1Object>) {
    return true;
  }

  toChannelNativeData (l1Object: L1Object, extraData: any) {
    const rtb = l1Object.rtb ? {
      ...l1Object.rtb,
      bid_strategy: l1Object.rtb.bid_strategy ? l1Object.rtb.bid_strategy : null
    } : undefined;
    const l1ObjectObjective = l1Object.objective;
    const objective = this.l1ObjectManager.getNativeObjectiveValue(L1ObjectChannel.RTB, l1ObjectObjective);
    return {
      ...l1Object,
      objective,
      l1ObjectLifetimeBudget: _.isNil(l1Object.budget) ? 0 : l1Object.budget,
      rtb
    };
  }
}
