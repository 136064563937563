import { DatePickerProps } from 'components/DatePicker/DatePicker';
import { DateRangePickerProps } from 'components/DateRangePicker/DateRangePicker';
import FutureDateRangePicker from 'components/DateRangePicker/FutureDateRangePicker';
import { useField, useFormikContext } from 'formik';
import { defaultTo } from 'lodash';
import React, { useCallback } from 'react';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';
import { FormikFieldProps } from 'hoc/withFormikField';

type FormikDayRangeExtraProps = {
  startDateFormikName: string;
  endDateFormikName: string;
  format?: string;
  startDatePickerDisabled?: boolean;
  validateOnPopOverClose?: boolean;
  validate?: (startDate: string, endDate: string) => void;
  onChange?: (startDate: string, endDate: string) => void;
};

export interface FormikDateRangePickerProps extends FormikDayRangeExtraProps, Omit<DatePickerProps, 'disabled' | 'onChange' | 'date' | 'hasError'>, Omit<FormikFieldProps, 'onChange' | 'validate'> {}
export interface FormikDateRangePickerWithFieldLayoutProps extends FormikDateRangePickerProps, Omit<FieldLayoutProps, 'name'> {}
export type FormikDateRangePickerFieldProps = ({ withFieldLayout?: true } & FormikDateRangePickerWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikDateRangePickerProps);

export const DateRangePickerField = withFieldLayout<DateRangePickerProps & FieldLayoutProps>(FutureDateRangePicker);
DateRangePickerField.defaultProps = {
  fieldContentWidth: 'auto'
};

const useFormikDateRangePickerHook = (
  name: string,
  startDateFormikName: string,
  endDateFormikName: string,
  validateOnPopOverClose?: boolean,
  validate?: (startDate: string, endDate: string) => void,
  onChange?: (startDate: string, endDate: string) => void,
  onPopOverClosed?: () => Promise<void>
) => {
  const { values, submitCount, setValues, setFieldTouched, validateForm } = useFormikContext<any>();
  const startDate = values[startDateFormikName];
  const endDate = values[endDateFormikName];

  const hanldeValidate = useCallback((value) => {
    // value is undefined when date have not been modified
    const startDateToValidate = value ? value.startDate : startDate;
    const endDateToValidate = value ? value.endDate : endDate;
    return validate && validate(startDateToValidate, endDateToValidate);
  }, [startDate, endDate, validate]);

  const [, { touched: formikTouched, error }] = useField({ name, validate: validate ? hanldeValidate : undefined });
  const touched = defaultTo(submitCount, 0) > 0 || formikTouched;

  const handleChange = useCallback((startDate, endDate) => {
    setValues(prev => ({
      ...prev,
      [name]: {
        startDate,
        endDate
      },
      [startDateFormikName]: startDate,
      [endDateFormikName]: endDate
    }), !validateOnPopOverClose);
    setFieldTouched(name, true, !validateOnPopOverClose);
    // formik issues 2266 workaround
    setTimeout(() => {
      onChange && onChange(startDate, endDate);
    });
  }, [name, startDateFormikName, endDateFormikName, validateOnPopOverClose, setValues, setFieldTouched, onChange]);

  return {
    error,
    touched,
    startDate,
    endDate,
    onChange: handleChange,
    onPopOverClosed: validateOnPopOverClose ? async () => {
      onPopOverClosed && await onPopOverClosed();
      validateForm();
    } : onPopOverClosed
  };
};

const MemorizedDateRangePickerContent = React.memo(FutureDateRangePicker);

export const FormikDateRangePickerField: React.FC<FormikDateRangePickerFieldProps> = ({
  name,
  startDateFormikName,
  endDateFormikName,
  withFieldLayout,
  validateOnPopOverClose,
  onChange,
  validate,
  onPopOverClosed,
  ...props
}: FormikDateRangePickerFieldProps) => {

  const { error, touched, ...otherProps } = useFormikDateRangePickerHook(
    name,
    startDateFormikName,
    endDateFormikName,
    validateOnPopOverClose,
    validate,
    onChange,
    onPopOverClosed ? async () => onPopOverClosed() : undefined
  );
  const hasError = error !== undefined && touched;
  if (withFieldLayout === false) {
    return (
      <MemorizedDateRangePickerContent
        {...props}
        name={name}
        {...otherProps}
        hasError={hasError}
      />
    );
  } else {
    return (
      <DateRangePickerField
        {...props}
        {...otherProps}
        name={name}
        error={hasError ? error : undefined}
        hasError={hasError}
      />
    );
  }
};
