import React, { Fragment } from 'react';

import './AccountActorList.scss';

import _ from 'lodash';
import i18n from 'i18n';

import { AccountActorListProps } from './AccountActorListModel';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { Actor } from 'core/auth/Account';

function translatedHeader (column: any): any {
  return (
    <Fragment>
      {i18n.t<string>(column.text)}
    </Fragment>
  );
}

function renderColumn (column: any, formatter: any | undefined = undefined): any {
  return _.omitBy({
    ...column,
    formatter: formatter,
    headerFormatter: translatedHeader
  }, _.isNil);
}

export class AccountActorList extends React.Component<AccountActorListProps> {

  permissionFormatter = (cell: any, row: any): any => {
    return (
      <div className='actions-injectable'>
        {i18n.t<string>(`actor.roleNames.${_.camelCase(row.roleName)}`)}
      </div>
    );
  }

  floatingFormatter = (cell: any, row: any): any => {
    const actions = this.props.model.permissionActions.flatMap((action, index) => {
      const actionHandler = () => action.handler(row);
      return (
        <IconWithTooltip
          key={action.type}
          className='action'
          onClick={actionHandler}
          icon={action.icon}
          tooltipProps={{
            id: `accountActorListTip-${row.id}-${index}`,
            tooltip: i18n.t<string>(`accountActorList.labels.actionHint.${action.type}`)
          }}
        />
      );
    });
    return (
      <TableRowToolBar className={'actions'}>
        {actions}
      </TableRowToolBar>
    );
  }

  render () {
    const model = this.props.model;
    const columns = [
      renderColumn(model.nameColumn),
      renderColumn(model.permissionColumn, this.permissionFormatter),
      renderColumn(model.editBtnsColumn, this.floatingFormatter)
    ];
    return (
      <div className='account-actors'>
        <StickableBootstrapTable<Actor>
          keyField='id'
          data={model.actors}
          columns={columns}
          stickFirstColumn
          stickLastColumn
          defaultSorted={model.defaultSorts}
          noDataIndication={model.noDataDescription}
          hidePagination={true}
        />
      </div>
    );
  }
}
