import { usePmpSummaryModel } from 'containers/Pmp/PmpSummaryModel';
import i18n from 'i18n';
import { defaultTo } from 'lodash';
import { ReactElement } from 'react';
import { SelectOptions } from 'components/commonType';
import { Pmp } from 'core/pmp/Pmp';
import { CreateRtbCampaignSetupFlowStep } from 'containers/RtbCampaigns/RtbCampaignSetupFlow/RtbCampaignSetupFlowStep';

export const PmpSummary: React.FC<{
  pmp: Pmp,
  errors: {[key: string]: any},
  spaceOptions?: SelectOptions[],
  renderSummary: (data: any) => ReactElement
}> = ({
  pmp,
  errors,
  spaceOptions,
  renderSummary
}) => {

  const pmpSummaryData = usePmpSummaryModel(pmp, defaultTo(spaceOptions, []), errors);

  return (
    renderSummary({
      title: i18n.t<string>('campaignSummary.titles.basicSetting'),
      data: {
        general: {
          title: i18n.t<string>('campaignSummary.titles.generalInformation'),
          content: pmpSummaryData
        }
      },
      backStep: CreateRtbCampaignSetupFlowStep.SETUP_CAMPAIGN
    })
  );
};
