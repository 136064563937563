import React from 'react';
import classNames from 'classnames/bind';
import i18n from 'i18next';

import { CampaignAdTypeButtonProps } from './CampaignAdTypeButtonModel';
import styles from './campaignAdTypeButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class CampaignAdTypeButton extends React.Component<CampaignAdTypeButtonProps> {

  cssClassNames: any;

  constructor (props) {
    super(props);
    this.cssClassNames = classNames.bind(styles);
  }

  render () {
    const model = this.props.model;
    const adTypeButtonClassName = this.cssClassNames('campaignAdTypeButton');
    const useFontAwesome = typeof model.data.icon !== 'string';
    return (
      <div className={adTypeButtonClassName} onClick={this.props.model.onClick}>
        {
          useFontAwesome ?
          <FontAwesomeIcon icon={model.data.icon}/> :
            model.data.icon2x ?
            <img src={model.data.icon} srcSet={`${model.data.icon2x} 2x`} alt='logo' /> :
            <img src={model.data.icon} alt='logo' />
        }
        <div className={styles.title}>
          {i18n.t<string>(model.data.title)}
        </div>
        <div className={styles.description}>
          {i18n.t<string>(model.data.description)}
        </div>
      </div>
    );
  }
}
