import React, { useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import styles from './copyableOverlayTrigger.module.scss';

export const CopyableOverlayTrigger: React.FC<React.PropsWithChildren<{
  placement: 'bottom' | 'top' | 'left' | 'right',
  overlay: React.ReactNode,
  trigger?: 'click' | 'hover',
  show?: boolean,
  onTrigger?: (show: boolean) => void,
  onlyShowOverlay?: boolean
  container?: any
}>> = props => {

  const isControlled = props.show !== undefined;

  const [show, setShow] = useState<boolean>(false);
  const [target, setTarget] = useState<any>();
  const containerRef = useRef<any>(props.container ? props.container : null);

  const handleClick = (e) => {
    setTarget(e.target);
    if (isControlled) {
      props.onTrigger && props.onTrigger(!props.show);
    } else {
      setShow(!show);
    }
  };

  const handleMouseEnter = (e) => {
    if (isControlled) {
      props.onTrigger && props.onTrigger(true);
    } else {
      setShow(true);
    }
    setTarget(e.target);
  };

  const handleMouseLeave = () => {
    if (isControlled) {
      props.onTrigger && props.onTrigger(false);
    } else {
      setShow(false);
    }
  };

  const handleTipMouseEnter = () => {
    if (isControlled) {
      props.onTrigger && props.onTrigger(true);
    } else {
      setShow(true);
    }
  };

  const renderOverlay = overlayProps => {
    const { placement, arrowProps, show, popper, ...otherProps } = overlayProps;

    let top = '0px';
    if (placement === 'bottom') {
      top = '3px';
    }
    if (props.onlyShowOverlay) {
      return (
        <div
          {...otherProps}
          style={{ ...otherProps.style, zIndex: 1100 }}
        >
          {props.overlay}
        </div>
      );
    }

    return (
      <div
        {...otherProps}
        style={{ ...otherProps.style, zIndex: 1100, backgroundColor: '#242328', top }}
        className={styles.overlay}
        onMouseEnter={props.trigger === 'click' ? undefined : handleTipMouseEnter}
        onMouseLeave={props.trigger === 'click' ? undefined : handleMouseLeave}
      >
        {props.overlay}
      </div>
    );
  };

  const onHide = () => {
    props.onTrigger && props.onTrigger(false);
  };

  return (
    <div ref={props.container ? undefined : containerRef}>
      <div
        style={{ width: 'fit-content' }}
        onClick={props.trigger === 'click' ? handleClick : undefined}
        onMouseEnter={props.trigger === 'click' ? undefined : handleMouseEnter}
        onMouseLeave={props.trigger === 'click' ? undefined : handleMouseLeave}
      >
        {props.children}
      </div>
      {props.overlay &&
        <Overlay
          show={isControlled ? props.show : show}
          target={target}
          rootClose={props.trigger === 'click' ? true : undefined}
          placement={props.placement}
          container={containerRef.current}
          onHide={onHide}
        >
          {(props) => renderOverlay(props)}
        </Overlay>
      }
    </div>
  );
};
