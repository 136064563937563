import axios, { AxiosInstance } from 'axios';
import config from 'config';
import { autoLogout, injectToken, updateToken } from './RestClient';

export interface LoadingTimeWebService {
  recordLoadingTime (email: string, path: string, loadingTime: number, loadingComment?: string): Promise<void>;
}

export class RestfulLoadingTimeWebService implements LoadingTimeWebService {
  restClient: AxiosInstance;

  constructor () {
    this.restClient = axios.create({
      baseURL: config.baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    this.restClient.interceptors.request.use(injectToken);
    this.restClient.interceptors.response.use(updateToken, autoLogout);
  }

  recordLoadingTime (email: string, path: string, loadingTime: number | string, loadingComment?: string): Promise<void> {
    return this.restClient.post('/v2/loading-time', {
      path,
      loadingComment,
      loadingTime: typeof loadingTime === 'string' ? loadingTime : `${loadingTime}ms`,
      email
    });
  }
}
