import React from 'react';
import i18next from 'i18n';
import styles from './basic.module.scss';
import { InfoDisplaySection } from 'components/InfoDisplaySection/InfoDisplaySection';
import _ from 'lodash';

type BasicProps = {
  AgencyDetail: {};
  isSystemViewOnly: boolean;
};

function onOrOff (on) {
  if (on) {
    return i18next.t<string>('agency.yes');
  } else {
    return i18next.t<string>('agency.no');
  }
}

const dataFormatter = {
  selfBuy: onOrOff,
  language: value => i18next.t<string>(`common.language.${value.replace('_', '').toLowerCase()}`),
  noLimitSelfBuyStatus: onOrOff
};

const Basic: React.FC<BasicProps> = ({
  AgencyDetail
}) => {

  function getFieldData (fields) {
    return fields
      .filter(field => {
        const value: any = _.get(AgencyDetail, field, undefined);
        return value !== undefined && value !== null && value !== '';
      })
      .map(field => ({
        label: i18next.t<string>(`agency.${field}`),
        value: dataFormatter[field] ? dataFormatter[field](_.get(AgencyDetail, field)) : _.get(AgencyDetail, field)
      }));
  }

  AgencyDetail['limitPublisherStr'] = AgencyDetail['limitPublisher'].map(function (el) { return el; }).join('\n');

  const agencyInfoFields = ['id', 'companyName', 'vendorNumber'];
  const agencyInfoData = getFieldData(agencyInfoFields);

  return (
    <div className={styles.container}>
      <InfoDisplaySection data={agencyInfoData} title={i18next.t<string>('agency.information')} />
    </div>
  );
};

export default Basic;
