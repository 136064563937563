import React from 'react';
import { ReportDimension } from 'core/report/ReportData';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { Permission } from 'core/auth/Permission';
import styles from './reportTable.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import { hasFuncs } from 'core/permission/PermissionDSL';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

const nameFormatters = {
  [ReportDimension.PRODUCT]: name => _.isEmpty(name) ? i18n.t<string>('common.labels.noData') : name,
  [ReportDimension.SEARCH_KEYWORD]: name => _.isEmpty(name) ? i18n.t<string>('common.labels.noData') : name
};

export function getReportTableFormatters (onDimensionNameClick, onDateClick?) {

  const headerWithHintFormatter = (column, _1, { sortElement }) => {
    const dataField = column.dataField;
    return (
      <div className={styles.tableHeaderHint}>
        {column.text}
        <IconWithTooltip
          key={dataField}
          icon={faQuestionCircle}
          tooltipProps={{
            id: dataField,
            tooltip: i18n.t<string>(`reportTable.headers.hints.${dataField}`)
          }}
        />
        {sortElement}
      </div>
    );
  };

  const nameFormatter = (columnData: any, rowData: any, rowIndex: number, formatExtraData: any) => {
    if (columnData === undefined) {
      return <div/>;
    }
    const dimension = formatExtraData.dimension;
    const isDateDimension = dimension === ReportDimension.MONTH || dimension === ReportDimension.DAY || dimension === ReportDimension.HOUR;
    const onClick = () => {
      if (isDateDimension) {
        onDateClick && onDateClick(columnData);
      } else {
        if (rowData.id === '0') {
          return;
        }
        onDimensionNameClick(dimension, {
          label: rowData.name,
          value: rowData.id
        });
      }
    };
    const nameFormatter = nameFormatters[dimension];
    const nameValueClass = cx(styles.nameValue, {
      disabled: (isDateDimension && !onDateClick) || (!isDateDimension && rowData.id === '0')
    });
    const dimensionNeedId = [ReportDimension.PRODUCT, ReportDimension.CREATIVE].includes(dimension);
    return (
      <div className={styles.nameCell}>
        <div className={nameValueClass} onClick={onClick}>
          {nameFormatter ? nameFormatter(columnData) : columnData}
        </div>
        {dimensionNeedId &&
          <div className={styles.id}>
            {`ID: ${rowData.id}`}
          </div>
        }
      </div>
    );
  };

  const getLinkPathOfDimension = (dimension: ReportDimension, dimensionID: number | string) => {
    switch (dimension) {
      case ReportDimension.ORDER:
        return `/orders/${dimensionID}/redirectToDetail`;
      case ReportDimension.L2_CHANNEL:
        return `/campaigns/${dimensionID}/redirectToDetail`;
      case ReportDimension.L1_OBJECT:
        return `/campaign-groups/${dimensionID}/redirectToDetail`;
      case ReportDimension.CAMPAIGN:
      case ReportDimension.CREATIVE:
      default:
        return undefined;
    }
  };

  const getLinkPermissionsOfDimension = (dimension: ReportDimension) => {
    switch (dimension) {
      case ReportDimension.CAMPAIGN:
      case ReportDimension.L2_CHANNEL:
      case ReportDimension.L1_OBJECT:
        return [Permission.CAMPAIGN_READ, Permission.ORDER_READ];
      case ReportDimension.ORDER:
        return [Permission.ORDER_READ];
      case ReportDimension.CREATIVE:
        return [Permission.CREATIVE_READ];
      default:
        return [];
    }
  };

  const textFormatter = (text: any, row: any, rowIndex: number, formatExtraData: any) => {
    if (text === undefined) {
      return <div/>;
    }
    const textDecorator = _.get(formatExtraData, 'textDecorator', _.identity);
    return (
      <div className={styles.text}>
        {textDecorator(text)}
      </div>
    );
  };

  const tagFormatter = (tags) => {
    const renderTags = () => {
      return tags.map(tag => {
        return (
          <div key={tag}>
            {tag}
          </div>
        );
      });
    };
    return (
      <div className={styles.tagsContent}>
        {tags.length > 0 ? renderTags() : i18n.t<string>('campaignList.labels.emptyContent')}
      </div>
    );
  };

  const floatingEditBtnsFormatter = (cell: any, row: any, rowIndex: number, formatExtraData: any): any => {
    const dimension = formatExtraData.dimension;
    const linkPath = getLinkPathOfDimension(dimension, row.id);
    if (!linkPath) {
      return <div/>;
    }
    return (
      <PermissionChecker permissionAware={hasFuncs(...getLinkPermissionsOfDimension(dimension))}>
        <TableRowToolBar className={styles.floatingEditArea}>
          <Link
            to={linkPath}
            target='_blank'
          >
            <IconWithTooltip
              icon={faLink}
              tooltipProps={{
                id: `reportTableLinkTip-${row.id}`,
                tooltip: i18n.t<string>(`reportTable.labels.${dimension}LinkHint`)
              }}
            />
          </Link>
        </TableRowToolBar>
      </PermissionChecker>
    );
  };

  return {
    nameFormatter,
    textFormatter,
    tagFormatter,
    floatingEditBtnsFormatter,
    headerWithHintFormatter
  };
}
