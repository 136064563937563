import { Currency } from 'core/agency/Agency';

export type Order = {
  id: number;
  projectName: string;
  orderNumber: string;
  agencyId: number;
  advertiserId: number;
  orderType: 'TENMAX';
  budget: number;
  originBudget?: number;
  changeBudget?: number;
  externalId?: string;
  externalType?: string;
  state: State;
  spent: number;
  creator?: string;
  creatorEmail?: string;
  expectedSpent: number,
  actualSpent: number,
  comments?: string,
  startDate: string;
  endDate: string;
  createDate: string;
  createDateTime?: string;
  campaignConstraint: CampaignConstraint,
  budgetBalance: number;
  currency: Currency;
  timezone: string;
  impres: number;
  clicks: number;
  deepWarning?: boolean;
  warning?: boolean;
  danger?: boolean;
  modifyReason?: string;
  rtbCampaignRunningStatus?: any;
  needAlert?: boolean;
  campaignBidPrice: {
    type: string,
    autoBidCap: BidPriceFloor,
    bidFloor: BidPriceFloor
  }[]
};

export enum State {
  NOT_APPROVE = 1,
  REJECT = 2,
  APPROVE_NOT_START = 3,
  APPROVE_START = 4,
  APPROVE = 5,
  STOP = 6,
  DELETE = 7,
  INVALID = 8,
  CHANGE_PENDING = 9,
  SETTLE = 10,
  SETTLED = 11
}

export type CampaignConstraint = {
  budgetMinimum: number,
  campaignBudgetMinimum: number
};

export type BidPriceFloor = {
  cpc?: number,
  cpm?: number
};

export enum OrderDelivery {
  NOTSTART = 'notStart',
  PROGRESS = 'progress',
  FINISHED = 'finished'
}

export enum OrderCreationInfo {
  CREATOR = 'creator',
  CREATEDATETIME = 'createDateTime',
  CREATOREMAIL = 'creatorEmail'
}
