import { AbstractFormContentModel } from './FormContentModel';
import _ from 'lodash';
import i18n from 'i18n';
import ImageForm from './ImageForm';
import { Creative } from 'core/creative/Creative';
import { formatBytes } from 'utils/StringUtil';
import { renderErrors } from './CreativeFormHintRenderFunction';
import ImageFormMutliple from './ImageFormMultiple';
import { BasicFormProps } from './BasicFormProps';

export type ImageFormProps = {
  readonly model: ImageFormModel;
  readonly hintModalData?: any;
  readonly setHintModalData: (modalData: any) => void;
} & BasicFormProps;

export type MultiImageFormProps = {
  readonly model: MultiImageFormModel;
  readonly hintModalData?: any;
  readonly setHintModalData: (modalData: any) => void;
} & BasicFormProps;

export class ImageFormModel extends AbstractFormContentModel {

  constructor (public flowPageModel: any, public validWidthHeightMap: {[width: number]: number[]}) {
    super(flowPageModel);
  }

  public needBannerUrl: boolean = true;

  public validTypes: string[] = ['image/png'];

  getFormContent = (): any => {
    return ImageForm;
  }

  validateImage = (fileData) => {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (this.validTypes.indexOf(file.type) === -1) {
      return i18n.t<string>('creativeSetupFlow.labels.imageTypeErrorHint');
    }

    const errors: any[] = [];
    const width = fileData.width;
    const height = fileData.height;
    if (!Object.keys(this.validWidthHeightMap).includes(width.toString()) || !this.validWidthHeightMap[width].includes(height)) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.imageSizeErrorHint', { size: `${width} x ${height}` }));
    }

    if (file.size > 2097152) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '2MB' }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getInitTypeProperties () {
    return undefined;
  }

  getFormModelData (creative: Creative) {
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const extraImageUrl = _.get(creative, 'basic.bannerExtra.imageUrl');
    const extraWidth = _.get(creative, 'basic.bannerExtra.width');
    const extraHeight = _.get(creative, 'basic.bannerExtra.height');
    const medias = _.omitBy({
      image: _.omitBy({
        url: extraImageUrl !== undefined ? extraImageUrl : _.get(creativeValue, 'imageUrl'),
        width: extraWidth !== undefined ? extraWidth : _.get(creativeValue, 'w'),
        height: extraHeight !== undefined ? extraHeight : _.get(creativeValue, 'h')
      }, _.isUndefined)
    }, _.isEmpty);
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    return {
      basic: {
        ...creativeBasic,
        medias
      }
    };
  }
}

export class MultiImageFormModel extends ImageFormModel {

  getFormContent = () => {
    return ImageFormMutliple;
  }

  validateImages = (fileData: {
    [key: string]: {
      width: number,
      height: number,
      file: File
    }
  }) => {
    const fileDataArray = Object.values(_.defaultTo(fileData, {}));
    if (fileDataArray.length === 0) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    const typeErrorImageList: string[] = fileDataArray
      .filter(data => this.validTypes.indexOf(data.file.type) === -1)
      .map(data => data.file.name);
    if (typeErrorImageList.length > 0) {
      return `${i18n.t<string>('creativeSetupFlow.labels.imageMultipleTypeErrorHint')}: ${typeErrorImageList.join(', ')}`;
    }

    const validWidthHeightMap = {
      960: [320],
      1000: [60]
    };

    const errors: string[] = [];
    const widthHeightErrorImageList: string[] = fileDataArray
      .filter(data => {
        const width = data.width;
        const height = data.height;
        return !Object.keys(validWidthHeightMap).includes(width.toString()) ||
          !validWidthHeightMap[width].includes(height);
      })
      .map(data => data.file.name);
    widthHeightErrorImageList.length > 0 && errors.push(`${i18n.t<string>('creativeSetupFlow.labels.imageMultipleSizeErrorHint')}: ${widthHeightErrorImageList.join(', ')}`);

    const sizeErrorImageList: string[] = fileDataArray
      .filter(data => data.file.size > 2097152)
      .map(data => data.file.name);
    sizeErrorImageList.length > 0 && errors.push(`${i18n.t<string>('creativeSetupFlow.labels.storageMultipleErrorHint', { storage: '2MB' })}: ${sizeErrorImageList.join(', ')}`);

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getFormModelData (creative: Creative) {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    return {
      basic: {
        ...creativeBasic,
        medias: {}
      }
    };
  }
}
