import React, { Fragment, RefObject } from 'react';
import { Link, Redirect } from 'react-router-dom';

import styles from './accountForm.module.scss';

import { AccountFormProps, AccountFormValues, AccountFormModel, AccountFormState } from './AccountFormModel';

import i18n from 'i18n';
import { alertError } from 'components/AlertDialog';

import { LoadingIndicator } from 'components/LoadingIndicator';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import cx from 'classnames/bind';
import { FormikField } from 'components/form/field/FormikField';
import { Trans } from 'react-i18next';
import { CustomField } from 'components/form/field/CustomField';

export class AccountForm extends React.Component<AccountFormProps, AccountFormState> {
  handler?: number;
  classNames: any;
  formikRef: RefObject<any>;
  formikEventHandler?: number;

  constructor (props: AccountFormProps) {
    super(props);
    this.state = this.props.model.state;
    this.classNames = cx.bind(styles);
    this.formikRef = React.createRef();
  }

  componentDidMount () {
    this.registerEventHandler();
    this.registerFormikEvent();
    this.props.model.init();
  }

  componentDidUpdate (prevProps: AccountFormProps) {
    if (prevProps.model !== this.props.model) {
      this.handler && prevProps.model.event.remove(this.handler);
      this.registerEventHandler();
      this.formikEventHandler && prevProps.model.formikEvent.remove(this.formikEventHandler);
      this.registerFormikEvent();
      this.props.model.init();
    }
  }

  registerEventHandler = () => {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  registerFormikEvent = () => {
    this.formikEventHandler = this.props.model.formikEvent.add((event) => {
      if (event.isSubmit === 'FORMIK_SUBMIT') {
        this.formikRef.current.submitForm();
      }
    });
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler, this.formikEventHandler);
  }

  validate = (values: AccountFormValues): any => {
    return this.props.model.validate(values);
  }

  submit = async (values: AccountFormValues, actions: FormikHelpers<AccountFormValues>) => {
    await this.props.model.submit(values);
    actions.setSubmitting(false);
  }

  dismiss = () => {
    this.props.model.dismiss();
  }

  render () {
    const model = this.props.model;
    const drawForm = !(this.state.error === null && this.state.redirectPath !== null);
    return (
      <Fragment>
        {this.state.error === null && this.state.redirectPath !== null && <Redirect to={this.state.redirectPath} />}
        {drawForm && this.renderForm(model)}
      </Fragment>
    );
  }

  renderFormikContent = (formProps: FormikProps<AccountFormValues>) => {
    const model = this.props.model;
    const labelColumnValue = 3;
    const valueColumnValue = 9;
    const selectOptionsWidth = 460;
    const agencyLabel = i18n.t<string>('accounts.form.labels.agency');
    const emailLabel = i18n.t<string>('accounts.form.labels.email');
    const nameLabel = i18n.t<string>('accounts.form.labels.name');

    return (
      <Form>
        <div className={styles.formContent}>
          {
            model.agencyOptions &&
            <FormikField.Select
              label={agencyLabel}
              name='agencyId'
              labelColSm={labelColumnValue}
              inputColSm={valueColumnValue}
              options={model.agencyOptions ? model.agencyOptions : []}
              className={styles.agencyField}
              simpleValue
              required
            />
          }
          {
            model.emailEditable ?
            <FormikField.Input
              name='email'
              label={emailLabel}
              placeholder={emailLabel}
              required
            /> :
            <FormikField.Label
              name='email'
              label={emailLabel}
            />
          }
          <FormikField.Input
            name='name'
            label={nameLabel}
            placeholder={nameLabel}
            required
          />
          {
            model.roleOptions && model.roleOptions.length > 0 &&
              <FormikField.Select
                labelColSm={labelColumnValue}
                inputColSm={valueColumnValue}
                name='role'
                label={i18n.t<string>('accounts.form.labels.role')}
                options={model.roleOptions}
                fieldContentWidth={selectOptionsWidth}
                componentWidthFitParent={true}
                simpleValue
                required
              />
          }
          {model.isAdminEditable &&
            <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
              <FormikField.Checkbox
                name='isAdmin'
                label={i18n.t<string>('accounts.form.labels.isAdmin')}
                checkboxLabel={i18n.t<string>('accounts.form.labels.activated')}
              />
            </PermissionChecker>
          }
          {model.activatedEditable &&
            <FormikField.Checkbox
              name='activated'
              label={i18n.t<string>('accounts.form.labels.status')}
              checkboxLabel={i18n.t<string>('accounts.form.labels.activated')}
            />
          }
          {
            model.needAssignRoleHint &&
            <CustomField
              label=''
              name='assignRoleHint'
              labelColSm={labelColumnValue}
              inputColSm={valueColumnValue}
            >
              <div className={styles.hints}>
                <Trans i18nKey='accounts.form.hints.assignRole'>
                  <span className={styles.danger}>...</span>...
                </Trans>
              </div>
            </CustomField>
          }
        </div>
        <div className={styles.formButtons}>
          <button
            type='submit'
            className='btn btn-primary btn-sm'
            disabled={formProps.isSubmitting}
          >
            {i18n.t<string>('common.buttons.submit')}
          </button>
          <Link
            to={model.cancelPath}
            className='btn btn-secondary btn-sm'
          >
            {i18n.t<string>('common.buttons.cancel')}
          </Link>
        </div>
      </Form>
    );
  }

  renderForm (model: AccountFormModel) {
    const initialValues = model.initialValues();
    const className = this.classNames('accountForm', {
      isModalContent: model.isModalContent
    });
    return (
      <div className={className}>
        {this.state.loading && <LoadingIndicator />}
        {this.state.error && alertError(this.state.error, this.dismiss)}
        <h3 className={styles.title}>
          {i18n.t<string>(model.title)}
        </h3>
        <div className={styles.titleBottomLine} />
        <Formik
          innerRef={this.formikRef}
          initialValues={initialValues}
          enableReinitialize
          validate={this.validate}
          onSubmit={this.submit}
          validateOnBlur={false}
        >
          {this.renderFormikContent}
        </Formik>
      </div>
    );
  }
}
