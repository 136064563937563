import { AgencyManager, DefaultAgencyManager, PMaxCore } from 'core';
import { AdvertiserHomeModel, DefaultAdvertiserHomeModel } from './AdvertiserHome/AdvertiserHomeModel';
import { AdvertiserFormModel, CreateAdvertiserFormModel, EditAdvertiserFormModel } from './AdvertiserForm/AdvertiserFormModel';
import { AdvertiserDetailModel, DefaultAdvertiserDetailModel } from './AdvertiserDetail/AdvertiserDetailModel';
import _ from 'lodash';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { isSystemAdmin } from 'helper/ActorHelper';
import { SelectOptions } from 'components/commonType';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';

export interface AdvertisersModel {
  readonly state: AdvertisersState;
  readonly event: UpdateEventListener<AdvertisersModel>;
  readonly isSysAdmin: boolean;
  getAgencyOptions (): Promise<void>;
  getCreateAdvertiserFormModel (): AdvertiserFormModel;
  getEditAdvertiserFormModel (advertiserId): AdvertiserFormModel;
  getAdvertiserHomeModel (): AdvertiserHomeModel;
  getAdvertiserDetailModel (advertiserId): AdvertiserDetailModel;
  onUnmount (handler): void;
}

export type AdvertisersProps = {
  readonly model: AdvertisersModel;
};

export type AdvertisersState = {
  readonly loading: boolean;
};

export class DefaultAdvertisersModel implements AdvertisersModel {

  core: PMaxCore;
  advertiserHomeModel?: AdvertiserHomeModel;
  createAdvertiserFormModel?: CreateAdvertiserFormModel;
  editAdvertiserFormModel?: EditAdvertiserFormModel;
  advertiserDetailModel?: AdvertiserDetailModel;
  loading: boolean = true;
  event: FireableUpdateEventListener<AdvertisersModel> = new FireableUpdateEventListener<AdvertisersModel>();
  isSysAdmin: boolean;
  agencyOptions: SelectOptions[] = [];

  constructor (
    core: PMaxCore,
    private agencyManager: AgencyManager = new DefaultAgencyManager()
  ) {
    this.core = core;
    const actor = this.core.authenticationManager.actor;
    this.isSysAdmin = isSystemAdmin(actor);
  }

  get state () {
    return ({
      loading: this.loading
    });
  }

  async getAgencyOptions () {
    this.updateState(true);
    try {
      if (this.isSysAdmin) {
        this.agencyOptions = await this.agencyManager.getAgenciesOptions();
      } else {
        const agencyId = this.core.authenticationManager.actor!.agencyId!;
        const name = await this.agencyManager.getAgencyName(agencyId);
        this.agencyOptions = [{ value: agencyId, label: name ? name : agencyId.toString() }];
      }
    } catch (e) {}
    this.updateState();
  }

  getAdvertiserHomeModel () {
    const actor = this.core.authenticationManager.actor;
    let agencyId = _.get(actor, 'agencyId');
    agencyId = agencyId === null ? undefined : agencyId;
    if (this.advertiserHomeModel && agencyId === this.advertiserHomeModel.agencyId) {
      this.advertiserHomeModel.setAgencyOptions(this.agencyOptions);
      return this.advertiserHomeModel;
    }
    this.advertiserHomeModel = new DefaultAdvertiserHomeModel(actor!, this.agencyOptions);
    return this.advertiserHomeModel;
  }

  getCreateAdvertiserFormModel (): AdvertiserFormModel {
    const agencyId = this.isSysAdmin
      ? SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY)
      : this.core.authenticationManager.actor!.agencyId;

    if (this.createAdvertiserFormModel && this.createAdvertiserFormModel.agencyId === agencyId) {
      return this.createAdvertiserFormModel;
    }

    this.createAdvertiserFormModel = new CreateAdvertiserFormModel(
      this.agencyOptions,
      agencyId
    );
    return this.createAdvertiserFormModel;
  }

  getEditAdvertiserFormModel (advertiserId): AdvertiserFormModel {
    if (this.editAdvertiserFormModel &&
      this.editAdvertiserFormModel.advertiserId === advertiserId
    ) {
      return this.editAdvertiserFormModel;
    }
    this.editAdvertiserFormModel = new EditAdvertiserFormModel(
      advertiserId
    );
    return this.editAdvertiserFormModel;
  }

  getAdvertiserDetailModel (advertiserId) {
    if (this.advertiserDetailModel &&
      advertiserId === this.advertiserDetailModel.advertiserId
    ) {
      return this.advertiserDetailModel;
    }
    this.advertiserDetailModel = new DefaultAdvertiserDetailModel(
      advertiserId,
      this.isSysAdmin
    );
    return this.advertiserDetailModel;
  }

  onUnmount (handler) {
    this.advertiserHomeModel = undefined;
    this.createAdvertiserFormModel = undefined;
    this.editAdvertiserFormModel = undefined;
    this.advertiserDetailModel = undefined;
    handler && this.event.remove(handler);
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
