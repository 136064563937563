import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import i18n from 'i18next';
import _ from 'lodash';
import { SelectOptions } from 'components/commonType';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import { toast } from 'react-toastify';
import { CreativeFormData } from './SubSteps/FormContent/FormContentModel';
import { CreativeSummaryModel } from './SubSteps/SummaryContent/CreativeSummaryModel';
import { CreativeSetupFlowDataContextType } from '../CreativeSetupFlowDataContext';
import { L1ObjectChannel } from 'core/l1Object/L1Object';

export enum CreateCreativeSetupFlowStep {
  CHOOSE_ADVERTISER,
  SETUP_CREATIVE,
  SUMMARY
}

export enum EditCreativeSetupFlowStep {
  SETUP_CREATIVE,
  SUMMARY
}

export type CreativeSummaryStepState = {
  readonly loading: boolean;
  readonly basicSummaryData: any;
};
export interface CreativeSummaryStepModel {
  readonly state: CreativeSummaryStepState;
  readonly event: UpdateEventListener<CreativeSummaryStepModel>;
  readonly mediaSummaryComponent?: any;
  readonly mediaSummaryData?: any;
  readonly canChooseAdvertiser: boolean;
  getAdvertiserSummaryData: (advertisers: Array<SelectOptions>, advertiserId: number) => any;
  goLast: () => void;
  goStep: (stepIndex: number, subStepIndex: number) => void;
  submit: (event, callback?: () => void) => void;
  initContextData (contextData: CreativeSetupFlowDataContextType);
  initSummaryData (): Promise<any>;
  setBasicSummaryData (basicSummaryData);
  getFormDataToSubmit (): Promise<any>;
  getJsonToSubmit (): Promise<any>;
}

export type CreativeSummaryStepProps = {
  readonly model: CreativeSummaryStepModel;
};

abstract class DefaultCreativeSummaryStepModel implements CreativeSummaryStepModel {
  event: FireableUpdateEventListener<CreativeSummaryStepModel>;
  loading: boolean;
  finishedCallback?: (redirectData: {
    pathname: string,
    search?: string,
    state?: any
  }) => void;
  creativeFormData?: CreativeFormData;
  summaryContentModel?: CreativeSummaryModel;
  basicSummaryData?: any;
  mediaSummaryComponent?: any;
  mediaSummaryData?: any;

  constructor (
    protected type: string,
    public canChooseAdvertiser: boolean,
    public goLast: () => void,
    public goStep: (stepIndex: number, subStepIndex: number) => void,
    protected getUploadedFileData: (file: File) => Promise<any>,
    protected addUploadedFilesData: (file: File, data: any) => Promise<void>,
    protected creativeManager: CreativeManager = new DefaultCreativeManager(),
    protected campaignId?: string,
    protected orderNumber?: string,
    protected l1ObjectId?: string | null
  ) {
    this.event = new FireableUpdateEventListener<CreativeSummaryStepModel>();
    this.loading = false;
  }

  get state (): CreativeSummaryStepState {
    return {
      loading: this.loading,
      basicSummaryData: this.basicSummaryData
    };
  }

  get creativeSetupStepIndex () {
    if (this.type === 'create' && this.canChooseAdvertiser) {
      return CreateCreativeSetupFlowStep.SETUP_CREATIVE;
    } else {
      return EditCreativeSetupFlowStep.SETUP_CREATIVE;
    }
  }

  get creativeManagementSearchParams () {
    return `?campaignIds=${this.campaignId}&action=manage`;
  }

  initContextData (contextData: CreativeSetupFlowDataContextType) {
    if (contextData.creative !== this.creativeFormData) {
      this.summaryContentModel = contextData.getSummaryModel(contextData.creative.basic);
      this.creativeFormData = contextData.creative;
      this.finishedCallback = contextData.setFinishedRedirectData;
      this.initSummaryData();
    }
  }

  async initSummaryData () {
    if (this.summaryContentModel) {
      this.updateState(true);
      try {
        const summaryData = await this.summaryContentModel.getCreativeBasicSummaryData(this.getUploadedFileData, this.addUploadedFilesData);
        this.basicSummaryData = {
          title: i18n.t<string>('creativeSummaryStep.titles.basic'),
          backStep: this.creativeSetupStepIndex,
          data: {
            general: {
              content: summaryData
            }
          }
        };
        this.mediaSummaryComponent = this.getMediaSummaryComponent();
        this.mediaSummaryData = this.getCreativeMediaSummaryData();
      } catch (e) {}
      this.updateState(false);
    }
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }

  getAdvertiserSummaryData (advertisers, advertiserId) {
    const advertiser = _.find(advertisers, advertiser => advertiser.value === advertiserId);
    return {
      title: i18n.t<string>('stepSideBar.labels.creativeChooseAdvertiser'),
      backStep: CreateCreativeSetupFlowStep.CHOOSE_ADVERTISER,
      data: {
        advertiser: {
          title: undefined,
          content: [
            {
              label: i18n.t<string>('creativeSetupFlow.labels.advertiser'),
              value: advertiser ? advertiser.label : ''
            }
          ]
        }
      }
    };
  }

  getMediaSummaryComponent () {
    return this.summaryContentModel ? this.summaryContentModel.getMediaSummaryComponent() : undefined;
  }

  getFileSummaryData (image) {
    const file = _.get(image, 'file');
    const url = _.get(image, 'url');
    if (file || url) {
      return {
        file,
        url
      };
    }
  }

  getCreativeMediaSummaryData () {
    const mediaData = this.summaryContentModel ? this.summaryContentModel.getMediaSummary() : {};
    if (_.isEmpty(mediaData)) {
      return undefined;
    }
    return {
      title: i18n.t<string>('creativeSetupFlow.labels.creativeBinding'),
      backStep: this.creativeSetupStepIndex,
      goStep: this.goStep,
      data: mediaData
    };
  }

  async getFormDataToSubmit () {
    if (!this.summaryContentModel || !this.creativeFormData) {
      return;
    }
    return this.summaryContentModel.getBasicSubmitData();
  }

  async getJsonToSubmit () {
    if (!this.summaryContentModel) {
      return;
    }
    return this.summaryContentModel.getJsonSubmitData();
  }

  setBasicSummaryData (basicSummaryData) {
    this.basicSummaryData = basicSummaryData;
    this.updateState(false);
  }

  abstract submitMethod (submitData): Promise<void>;

  submit = async (evt, callback) => {
    this.updateState(true);
    _.defer(async () => {
      try {
        const submitData = await this.getFormDataToSubmit();
        if (!submitData) {
          this.updateState(false);
          return;
        }

        await this.submitMethod(submitData);
        this.updateState(false);
      } catch (e) {
        (e instanceof Error) && toast.error(e.message);
        this.updateState(false);
      }
      callback && callback();
    });
  }
}

export class CreateCreativeSummaryStepModel extends DefaultCreativeSummaryStepModel {
  async submitMethod (submitData) {
    await this.createCreativeByJson(submitData);
  }

  createCreative = async (creative: FormData) => {
    await this.creativeManager.createCreative(creative);
    toast.success(i18n.t<string>('creativeSetupFlow.labels.createSuccess'));
    this.finishedCallback && this.finishedCallback({
      pathname: '/creatives'
    });
  }

  createCreativeByJson = async (creativeJson: any) => {
    const data = await this.creativeManager.createCreativesByJson(creativeJson);
    toast.success(i18n.t<string>('creativeSetupFlow.labels.createSuccess'));
    this.finishedCallback && this.finishedCallback({
      pathname: '/creatives',
      state: {
        errors: _.get(data, 'error')
      }
    });
  }
}

export class EditCreativeSummaryStepModel extends DefaultCreativeSummaryStepModel {
  async submitMethod (submitData) {
    await this.creativeManager.updateCreative(submitData);
    toast.success(i18n.t<string>('creativeSetupFlow.labels.updateSuccess'));
    if (_.isNil(this.orderNumber) || _.isNil(this.campaignId)) {
      this.finishedCallback && this.finishedCallback({
        pathname: '/creatives'
      });
    } else {
      this.finishedCallback && this.finishedCallback({
        pathname: `/orders/${this.orderNumber}/campaign-groups/${this.l1ObjectId}`,
        search: this.creativeManagementSearchParams
      });
    }
  }
}

abstract class CreateCreativeAndBindSummaryStepModel extends DefaultCreativeSummaryStepModel {

  abstract get channel ();

  async submitMethod (submitData) {
    await this.creativeManager.createCreativesByJsonAndBind(
      this.channel,
      submitData,
      this.campaignId!
    );
    toast.success(i18n.t<string>('creativeSetupFlow.labels.createSuccess'));
    this.finishedCallback && this.finishedCallback({
      pathname: `/orders/${this.orderNumber}/campaign-groups/${this.l1ObjectId}`,
      search: this.creativeManagementSearchParams
    });
  }
}

export class CreateCreativeAndBindRTBCampaignSummaryStepModel extends CreateCreativeAndBindSummaryStepModel {
  get channel () {
    return L1ObjectChannel.RTB;
  }
}
