import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import './Agencies.scss';

import { AgencyDetail } from './AgencyDetail';
import { AgencyForm } from './AgencyForm';
import { AgenciesHome } from './AgenciesHome';
import { AgenciesProps } from './AgenciesModel';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';

const AgencyHomeView = withErrorBoundary(ERROR_CODE.AGENCY_HOME, AgenciesHome);
// const CreateAgencyView = withErrorBoundary(ERROR_CODE.AGENCY_CREATE, AgencyForm);
const EditAgencyByAdminView = withErrorBoundary(ERROR_CODE.AGENCY_EDIT_BY_ADMIN, AgencyForm);
const AgencyDetailView = withErrorBoundary(ERROR_CODE.AGENCY_DETAIL, AgencyDetail);
// const EditAgencyByAgencyView = withErrorBoundary(ERROR_CODE.AGENCY_EDIT_BY_AGENCY, AgencyFormForAgency);
export class Agencies extends React.Component<AgenciesProps> {

  componentWillUnmount () {
    this.props.model.onUnmount();
  }

  renderAgencyHomeView = () => {
    const model = this.props.model;
    if (model.canViewAllAgencies) {
      return (<AgencyHomeView model={model.homeModel} />);
    } else if (model.isAgencyView && model.operatorAgencyPath) {
      return (<Redirect push to={model.operatorAgencyPath} />);
    }
    return (
      <Redirect
        push
        to={{
          pathname: `/agencies/${ROUTE_PATH.ERROR403}`,
          state: { message: 'cannot view agencies' }
        }}
      />
    );
  }

  // renderAgencyCreateView = () => {
  //   if (this.props.model.canCreateAgency) {
  //     return (<CreateAgencyView model={this.props.model.getCreateAgencyFormModel()} />);
  //   }
  //   return (
  //     <Redirect
  //       push
  //       to={{
  //         pathname: `/agencies/new/${ROUTE_PATH.ERROR403}`,
  //         state: { message: 'cannot create agency' }
  //       }}
  //     />
  //   );
  // }

  renderAgencyDetailView = (props) => {
    const agencyId = parseInt(props.match.params.agencyId, 10);
    if (isNaN(agencyId)) {
      return (
        <Redirect
          push
          to={{
            pathname: `${props.match.url}/${ROUTE_PATH.ERROR404}`,
            state: { message: 'invalid agency id' }
          }}
        />
      );
    }
    if (this.props.model.canViewAgency() && !isNaN(agencyId)) {
      const defaultAgencyDetailModel = this.props.model.getAgencyDetailModel(agencyId);
      defaultAgencyDetailModel.isSystemViewOnly = this.props.model.isSystemViewOnly;
      return (<AgencyDetailView model={defaultAgencyDetailModel} />);
    }
    return (
      <Redirect
        push
        to={{
          pathname: `${props.match.url}/${ROUTE_PATH.ERROR403}`,
          state: { message: 'cannot view agency' }
        }}
      />
    );
  }

  renderAgencyEditView = (props) => {
    const agencyId = parseInt(props.match.params.agencyId, 10);
    if (isNaN(agencyId)) {
      return (
        <Redirect
          push
          to={{
            pathname: `${props.match.url}/${ROUTE_PATH.ERROR404}`,
            state: { message: 'invalid agency id' }
          }}
        />
      );
    }
    if (this.props.model.canEditAgency && !isNaN(agencyId)) {
      const editAgencyDetailModel = this.props.model.getEditAgencyFormModel(agencyId);
      return (<EditAgencyByAdminView model={editAgencyDetailModel} />);
    }
    return (
      <Redirect
        push
        to={{
          pathname: `${props.match.url}/${ROUTE_PATH.ERROR403}`,
          state: { message: 'cannot edit agency' }
        }}
      />
    );
  }

  render () {
    return (
      <div className='agencies'>
        <Switch>
          {/* <Route
            path='/agencies/new'
            render={this.renderAgencyCreateView}
          /> */}
          <Route
            exact
            path='/agencies/:agencyId'
            render={this.renderAgencyDetailView}
          />
          <Route
            exact
            path='/agencies/:agencyId/edit'
            render={this.renderAgencyEditView}
          />
          <Route
            render={this.renderAgencyHomeView}
          />
        </Switch>
      </div>
    );
  }
}
