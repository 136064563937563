import React from 'react';
import styles from './listEditBar.module.scss';
import { Nav, NavDropdown, NavItem } from 'react-bootstrap';
import Tags from 'components/Tags/Tags';
import i18n from 'i18n';
import NavLink from 'react-bootstrap/NavLink';

export const ListEditBar: React.FC<React.PropsWithChildren<{
  selectedItems: (string | number)[],
  handleRemoveSelect: () => void
}>> & {
  Item: typeof NavItem,
  Link: typeof NavLink,
  Dropdown: typeof NavDropdown
} = ({
  selectedItems,
  handleRemoveSelect,
  children
}) => {

  const selectedCount = selectedItems.length;
  const tagsValue = [{ label: `${i18n.t<string>('campaignList.labels.selected')}  ${selectedCount}`, value: selectedCount }];

  return (
    <div className={styles.container}>
      <Nav variant='pills' className={styles.listEditBar}>
        <Tags value={tagsValue} disableInput onChange={handleRemoveSelect} />
        {children}
      </Nav>
    </div>
  );
};

ListEditBar.Item = Nav.Item;
ListEditBar.Link = Nav.Link;
ListEditBar.Dropdown = NavDropdown;
