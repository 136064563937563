import _ from 'lodash';
import {
  LimitationWebService,
  RestfulLimitationWebService
} from 'ws/LimitationWebService';

const defaultlimitationJson = {
  'include': [],
  'exclude': [],
  'preferred': [],
  'nonPreferred': [],
  'other': []
};

export interface LimitationManager {
  getLimitationPreSet (type: string): Promise<Object>;
}

export class DefaultLimitationManager implements LimitationManager {
  webService: LimitationWebService;

  constructor (
    webService: LimitationWebService = new RestfulLimitationWebService()
  ) {
    this.webService = webService;
  }

  async getLimitationPreSet (type: string): Promise<Object> {
    const opMap = {
      inc: 'include',
      exc: 'exclude',
      Preferred: 'preferred',
      NonPreferred: 'nonPreferred'
    };

    let limitationPreSet: any = _.cloneDeep(defaultlimitationJson);
    if (type === 'creative') {
      return limitationPreSet;
    }
    const agencyLimitationPreSet = await this.webService.getAgencyLimitationPreSet(type);
    agencyLimitationPreSet.forEach(element => {
      element.value = element.limits ?
        element.limits.map(limit => ({
          ...limit,
          isGroup: element.isGroup ? element.isGroup : false
        })) :
        [];
      delete element['limits'];
      let setIndex = limitationPreSet[opMap[element.op]].findIndex(set => set.type === element.type);
      if (setIndex === -1) {
        limitationPreSet[opMap[element.op]].push(element);
        return;
      }
      limitationPreSet[opMap[element.op]][setIndex] = element;
    });
    return limitationPreSet;
  }
}
