import axios from 'axios';
import client from './RestClient';

export interface AWSS3WebService {
  uploadFileToCloud (file: File, bucket: string): Promise<string>;
}

export class RestfulAWSS3WebService implements AWSS3WebService {

  constructor (
    private restClient: any = axios,
    private pmaxRestClient: any = client
  ) {}

  async uploadFileToCloud (file: File, bucket: string) {
    // prevent file name include specific sign: #, &,/,=
    const fileName = `file-${new Date().getTime()}.png`;
    const res = await this.pmaxRestClient.get(`/v2/creatives/create-file-url?bucket=${bucket}&fileName=${fileName}&contentType=${file.type}`);
    const presignedUrl = res.data.url;
    await this.restClient.put(presignedUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });
    return presignedUrl.split('?')[0];
  }
}
