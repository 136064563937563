import { floor } from 'lodash';

export function getCpm (spent: number, impressions: number) {
  let cpm = 0;
  if (impressions !== 0) {
    cpm = floor(spent, 2) * 1000 / impressions;
  }

  return floor(cpm, 2);
}

export function getCpc (spent: number, clicks: number) {
  let cpc = 0;
  if (clicks !== 0) {
    cpc = floor(spent, 2) / clicks;
  }

  return floor(cpc, 2);
}

export function getCtr (impres: number, clicks: number) {
  let vctr = 0;
  if (impres !== 0) {
    vctr = (clicks / impres) * 100;
  }
  return floor(vctr, 2);
}

export const getPercentValue = (divisor: number, dividend: number, decorator?: (value: number) => number) => {
  return `${dividend === 0 ? 0.00.toFixed(2) : (decorator ? decorator((divisor / dividend) * 100) : floor((divisor / dividend) * 100, 2)).toFixed(2)}%`;
};

export const getDivideValue = (divisor: number, dividend: number, decorator?: (value: number) => number) => {
  if (dividend === 0) {
    return 0;
  }
  return decorator ? decorator(divisor / dividend) : divisor / dividend;
};

export const metricsAdder = (acc, value) => {
  if (!value) {
    return acc;
  }
  return acc + value;
};

export const metricsFormatter = (formatter, value) => {
  let target = value;
  if (!value) {
    target = 0;
  }
  return formatter(target);
};
