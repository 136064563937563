import { useCallback, useEffect, useState } from 'react';
import { AddonFeatureManager, AuthenticationManager } from 'core';
import { DefaultL1ObjectManager, L1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { useRouteMatch } from 'react-router';
import moment from 'moment';
import i18n from 'i18n';
import _ from 'lodash';
import { numberWithCommas } from 'utils/StringUtil';
import { DefaultOrderManager, OrderManager } from 'core/order/OrderManager';
import { Order, State } from 'core/order/Order';
import { ReportType } from 'core/report/ReportData';
import { useCallAPI } from 'hooks/useCallAPI';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { toast } from 'react-toastify';
import { getPercentValue } from 'helper/MetricsHelper';
import { DefaultStoredValueManager, StoredValueManager } from 'core/storedValue/StoredValueManager';

export type OrderDetailModelData = {
  l1ObjectList: any[],
  canSettle: boolean,
  settleTips: string,
  loading: boolean
  order?: Order,
  viewData: any,
  showDetail: boolean,
  showApproveButton: boolean,
  showRejectButton: boolean,
  canEditOrder: boolean,
  showSettleButton: boolean,
  remainingStoredValueError: string | undefined;
  toggleShowDetail: () => void,
  settleOrder: () => Promise<void>;
  rejectOrder: () => Promise<void>;
  approveOrder: () => Promise<void>;
  getReportLink: (type: ReportType) => string;
  refreshOrderDetail: () => Promise<void>
};

const defaultL1ObjectManager = new DefaultL1ObjectManager();
const defaultOrderManager = new DefaultOrderManager();
const defaultStoredValueManager = new DefaultStoredValueManager();

export const useOrderDetailModel = (
  orderNumber: string,
  authenticationManager: AuthenticationManager,
  addonFeatureManager: AddonFeatureManager,
  l1ObjectManager: L1ObjectManager = defaultL1ObjectManager,
  orderManager: OrderManager = defaultOrderManager,
  storedValueManager: StoredValueManager = defaultStoredValueManager
): OrderDetailModelData => {

  const match = useRouteMatch();
  const [showDetail, setShowDetail] = useState(true);
  const [viewData, setViewData] = useState<any>({});
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const { loading, callAPIs, callAPIsBySequence } = useCallAPI();
  const [l1ObjectList, setL1ObjectList] = useState<any[]>([]);
  const [remainingStoredValueError, setRemainingStoredValueError] = useState<string | undefined>(undefined);

  const genOrderViewData = useCallback((order: Order) => {
    const orderViewData = getOrderViewData(
      order
    );
    setViewData(orderViewData);
  }, []);

  const fetchOrderData = useCallback(async (matchIsExact) => {
    callAPIs([
      () => orderManager.getOrder(orderNumber)
    ], (newOrder) => {
      callAPIs([
        (!matchIsExact) ? () => [] : () => l1ObjectManager.getL1Objects(newOrder.id)
      ], (l1ObjectList) => {
        setL1ObjectList(l1ObjectList);
      });
      setOrder(newOrder);
      genOrderViewData(newOrder);
    });
  }, [orderNumber, orderManager, callAPIs, l1ObjectManager, genOrderViewData]);

  useEffect(() => {
    (!match.isExact && order === undefined) && fetchOrderData(false);
  }, [order, fetchOrderData, match.isExact]);

  useEffect(() => {
    match.isExact && fetchOrderData(true);
  }, [fetchOrderData, match.isExact]);

  const settleOrder = useCallback(async () => {
    if (!order) {
      return;
    }

    callAPIsBySequence([
      () => orderManager.settleOrder(order.id),
      () => orderManager.getOrder(order.orderNumber)
    ], updatedOrder => {
      setOrder(updatedOrder);
      genOrderViewData(updatedOrder);
      toast.success(i18n.t<string>('orderDetail.labels.settleSuccess'));
    });
  }, [callAPIsBySequence, orderManager, order, genOrderViewData]);

  const approveOrder = useCallback(async () => {
    if (!order) {
      return;
    }
    callAPIsBySequence([
      () => orderManager.approveOrder(order.id),
      () => orderManager.getOrder(order.orderNumber)
    ], updatedOrder => {
      setOrder(updatedOrder);
      genOrderViewData(updatedOrder);
      toast.success(i18n.t<string>('orderDetail.labels.approveSuccess'));
    });
  }, [callAPIsBySequence, orderManager, order, genOrderViewData]);

  const rejectOrder = useCallback(async () => {
    if (!order) {
      return;
    }
    callAPIsBySequence([
      () => orderManager.rejectOrder(order.id),
      () => orderManager.getOrder(order.orderNumber)
    ], updatedOrder => {
      setOrder(updatedOrder);
      genOrderViewData(updatedOrder);
      toast.success(i18n.t<string>('orderDetail.labels.rejectSuccess'));
    });
  }, [callAPIsBySequence, orderManager, order, genOrderViewData]);

  const checkRemainingStoredValue = useCallback(async order => {
    const error = await validateBudgetWithRemainingStoredValue(order, addonFeatureManager, storedValueManager, orderManager);
    error && setRemainingStoredValueError(error);
  }, [addonFeatureManager, storedValueManager, orderManager]);

  useEffect(() => {
    checkRemainingStoredValue(order);
  }, [order, checkRemainingStoredValue]);

  const toggleShowDetail = useCallback(() => {
    setShowDetail(value => !value);
  }, []);

  if (!order) {
    return {
      viewData,
      loading,
      showDetail,
      showApproveButton: false,
      showRejectButton: false,
      canEditOrder: false,
      showSettleButton: false,
      l1ObjectList,
      canSettle: false,
      settleTips: '',
      remainingStoredValueError,
      settleOrder,
      rejectOrder,
      approveOrder,
      getReportLink: () => '/reports/performance',
      toggleShowDetail,
      refreshOrderDetail: () => fetchOrderData(true)
    };
  }

  return {
    order,
    viewData,
    loading,
    showDetail,
    showApproveButton: orderManager.canOrderBeApproved(order),
    showRejectButton: orderManager.canOrderBeReject(order),
    canEditOrder: orderManager.canOrderBeEdit(authenticationManager.actor, order),
    showSettleButton: showSettleButton(order, addonFeatureManager),
    l1ObjectList,
    canSettle: orderManager.canOrderBeSettled(order),
    settleTips: getSettleTips(order),
    remainingStoredValueError,
    settleOrder,
    rejectOrder,
    approveOrder,
    getReportLink: _.partial(getReportLink, order),
    toggleShowDetail,
    refreshOrderDetail: () => fetchOrderData(true)
  };
};

const getOrderStateDes = (state) => {
  if (State.CHANGE_PENDING === state || State.NOT_APPROVE === state) {
    return i18n.t<string>('orderDetail.labels.waitingForReview');
  }
  if (State.REJECT === state) {
    return i18n.t<string>('orderDetail.labels.reject');
  }
  if (State.SETTLE === state) {
    return i18n.t<string>('orderDetail.labels.settle');
  }
  if (State.SETTLED === state) {
    return i18n.t<string>('orderDetail.labels.settled');
  }
  return i18n.t<string>('orderDetail.labels.approve');
};

const getRemainDayDesc = (startDate, endDate) => {
  const now = moment();
  const today = now.startOf('day');
  if (moment(startDate).isAfter(today)) {
    return i18n.t<string>('orderDetail.labels.notstart');
  }

  if (moment(endDate).add(1, 'day').startOf('day').isBefore(now)) {
    return i18n.t<string>('orderDetail.labels.finished');
  }

  if (moment(endDate).startOf('day').isSame(today)) {
    return i18n.t<string>('orderDetail.labels.today');
  }

  return i18n.t<string>('orderDetail.labels.days', { days: moment(endDate).diff(today, 'days') + 1 });
};

const getOrderViewData = (
  order: Order
) => {
  let totalBudget: any = formatPriceWithCurrency(order.currency, order.budget);
  let remainBudget: any = formatPriceWithCurrency(order.currency, order.budgetBalance);
  let modifyReason: any = undefined;
  if (order.state === State.CHANGE_PENDING) {
    if (order.changeBudget) {
      totalBudget = {
        origin: i18n.t<string>('orderDetail.labels.originBudget', { budget: formatPriceWithCurrency(order.currency, order.budget) }),
        change: i18n.t<string>('orderDetail.labels.changeBudget', { budget: formatPriceWithCurrency(order.currency, order.changeBudget) })
      };
      remainBudget = {
        origin: i18n.t<string>('orderDetail.labels.originBudget', { budget: formatPriceWithCurrency(order.currency, order.budgetBalance) }),
        change: i18n.t<string>('orderDetail.labels.changeBudget', { budget: formatPriceWithCurrency(order.currency, order.budgetBalance + order.changeBudget - order.budget) })
      };
    }
    modifyReason = order.modifyReason;
  }

  const orderViewData: any = {
    id: order.id,
    name: order.projectName,
    startDate: order.startDate,
    endDate: order.endDate,
    basic: _.omitBy({
      orderNumber: order.orderNumber,
      dateRange: `${order.startDate} ~ ${order.endDate}`,
      remainDays: getRemainDayDesc(order.startDate, order.endDate),
      totalBudget: totalBudget,
      alreadySpent: formatPriceWithCurrency(order.currency, order.spent),
      remainBudget: remainBudget,
      progress: getPercentValue(order.spent, order.budget, value => value),
      status: getOrderStateDes(order.state),
      modifyReason,
      creator: order.creator,
      creatorEmail: order.creatorEmail
    }, _.isUndefined),
    performance: {
      impres: numberWithCommas(order.impres),
      clicks: numberWithCommas(order.clicks),
      ctr: getPercentValue(order.clicks, order.impres)
    }
  };
  return orderViewData;
};

const getSettleTips = (order: Order): string => {
  if (order.state === State.SETTLE) {
    return i18n.t<string>('orderDetail.labels.settleWait');
  }

  if (order.state === State.SETTLED) {
    return i18n.t<string>('orderDetail.labels.settledNoNeedSettle');
  }

  return '';
};

const getReportLink = (order: Order, type: ReportType): string => {
  const from = encodeURIComponent(moment(order.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
  const to = encodeURIComponent(moment(order.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
  return `/reports/performance?type=${type}&dimension=adsOrderId&from=${from}&to=${to}&adsOrderId=${order.id}`;
};

const showSettleButton = (order: Order, addonFeatureManager: AddonFeatureManager): boolean => {
  const permissionAllowOrderSettlement = addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.AGENCY_ALLOW_ORDER_SETTLEMENT);
  const stateAllowSettlement =
    order.state !== State.CHANGE_PENDING &&
    order.state !== State.NOT_APPROVE &&
    order.state !== State.REJECT;
  return permissionAllowOrderSettlement && stateAllowSettlement;
};

const validateBudgetWithRemainingStoredValue = async (order: Order | undefined, addonFeatureManager: AddonFeatureManager, storedValueManager: StoredValueManager, orderManager: OrderManager): Promise<string | undefined> => {
  if (!order) {
    return;
  }

  if (addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.STORED_VALUE) && orderManager.canOrderBeApproved(order)) {
    const remainingStoredValue = await storedValueManager.getRemainingStoredValue(order.agencyId);
    const budgetToCompare = !_.isNil(order.changeBudget) ? order.changeBudget - order.budget : order.budget;
    if (!_.isNil(remainingStoredValue) && budgetToCompare > remainingStoredValue) {
      return i18n.t<string>('orderDetail.errors.budgetExceedStoredValueError', { amount: formatPriceWithCurrency(order.currency, remainingStoredValue) });
    }
  }
};
