import { L1ObjectObjective } from 'core/l1Object/L1Object';

export enum L2ObjectOptimizationGoal {
  IMPRESSIONS = 'IMPRESSIONS',
  CLICKS = 'CLICKS',
  UNSPECIFIED = 'UNSPECIFIED'
}

export enum BidStrategy {
  LOWEST_COST_WITHOUT_CAP = 'LOWEST_COST_WITHOUT_CAP',
  LOWEST_COST_WITH_BID_CAP = 'LOWEST_COST_WITH_BID_CAP'
  // COST_CAP = 'COST_CAP'
}

export const objectiveOptimizeMap = {
  [L1ObjectObjective.AWARENESS]: [
    L2ObjectOptimizationGoal.IMPRESSIONS
  ],
  [L1ObjectObjective.TRAFFIC]: [
    L2ObjectOptimizationGoal.CLICKS
  ]
};
