import { Product } from 'core/product/Product';
import React from 'react';
import { ProductFilter } from './ProductFilter';

export type ProductListDataContextType = {
  selectedProducts: Product[];
  handleSelectedProducts: (fn: (selectedProducts: Product[]) => Product[]) => void;
  handleFilterChanged: (filter: ProductFilter) => void;
};

export const ProductListDataContext = React.createContext<ProductListDataContextType>({
  selectedProducts: [],
  handleSelectedProducts: () => {
   // This is intentionally left blank
  },
  handleFilterChanged: () => {
    // This is intentionally left blank
  }
});
