import React from 'react';
import { OrderFormProps } from './OrderFormModel';
import i18n from 'i18next';
import styles from './orderForm.module.scss';
import { notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { FormikField } from 'components/form/field/FormikField';
import { FormikProps } from 'formik';
import { Order } from 'core/order/Order';

export const CreateOrderFormContent: React.FunctionComponent<OrderFormProps & { formik: FormikProps<Order> }> = (props) => {

  return (
    <div className={styles.createOrderForm}>
      <PermissionChecker
        permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsSales, RoleNames.adsReport)}
      >
        <FormikField.Select
          label={i18n.t<string>('orderForm.labels.advertiser')}
          name='advertiserId'
          options={props.model.advertiserList}
          simpleValue
        />
      </PermissionChecker>
      <FormikField.Input
        label={i18n.t<string>('orderForm.labels.name')}
        name='projectName'
        className={styles.orderInput}
      />
      <FormikField.DateRangePicker
        label={i18n.t<string>('orderForm.labels.dateRange')}
        className={styles.orderInput}
        minDate={props.model.dateRangeMinDate}
        startDateFormikName='startDate'
        endDateFormikName='endDate'
        name='dateRange'
        format={'YYYY-MM-DD'}
        showTimePicker={false}
        fieldContentWidth={346}
        hint={i18n.t<string>('orderForm.labels.totaldates', { num: props.model.totalDays })}
        hoverHint={i18n.t<string>('orderForm.labels.dateCannotModifyHint')}
      />
      <FormikField.InputGroup
        label={i18n.t<string>('orderForm.labels.budget')}
        prefix={props.model.localeMeta ? props.model.localeMeta.currency : 'NTD'}
        name='budget'
        type='number'
      />
      <FormikField.TextArea
        label={i18n.t<string>('orderForm.labels.remark')}
        name='comments'
      />
    </div>
  );
};
