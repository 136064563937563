import React, { Fragment } from 'react';
import PermissionContext from 'contexts/permissionContext';
import { PermissionAware } from 'core';
import cx from 'classnames';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';

interface PermissionCheckerProps {
  permissionAware: PermissionAware;
  renderOtherwise?: any;
  className?: string;
  redirectTo?: string;
}

const PermissionChecker: React.FC<React.PropsWithChildren<PermissionCheckerProps>> = (props) => {
  const children = React.Children.map(
    props.children,
    child => {
      if (!React.isValidElement(child)) {
        return child;
      }
      return React.cloneElement(child, _.omitBy({
        ...child.props,
        ..._.omit(props, ['permissionAware', 'renderOtherwise', 'children']),
        className: cx(child.props.className, props.className)
      }, _.isEmpty));
    }
  );
  const redirectTo = (redirectPath) => {
    return (
      <Redirect
        to={{
          pathname: redirectPath,
          state: {
            message: `route permission not granted and redirect to ${redirectPath}: ${JSON.stringify(props.permissionAware)}`
          }
        }}
      />
    );
  };
  return (
    <PermissionContext.Consumer>
      {permissionContext => {
        if (!permissionContext) {
          return <Fragment/>;
        }
        return (
          <Fragment>
            {props.permissionAware.visible(permissionContext) ?
              children :
              props.redirectTo ? redirectTo(props.redirectTo) : props.renderOtherwise()
            }
          </Fragment>
        );
      }}
    </PermissionContext.Consumer>
  );
};

PermissionChecker.defaultProps = {
  renderOtherwise: () => undefined
};

export default PermissionChecker;
