import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styles from './accountList.module.scss';
import i18n from 'i18n';

export function nameFormatter (cell: any, row: any): any {
  return (
    <div className={styles.nameCell}>
      <Link to={`/accounts/${row.id}`}>{cell}</Link>
      <div className={styles.id}>
        {'ID: ' + row.id}
      </div>
    </div>
  );
}

export function statusFormatter (cell: any, row: any): any {
  return (
    <Fragment>
      {row.activated ? i18n.t<string>('accounts.active') : i18n.t<string>('accounts.inactive')}
    </Fragment>
  );
}
