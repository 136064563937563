import {
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { SelectOptions } from 'components/commonType';
import { AddonFeatureManager } from 'core';
import { CreativeType } from 'core/creative/Creative';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import _ from 'lodash';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { ROUTE_PATH } from 'enum/RoutePath';
import { CreativeFormBasicData, CreativeFormData, FormContentModel } from './FlowSteps/SubSteps/FormContent/FormContentModel';
import {
  MultiImageSummaryModel,
  CreativeSummaryModel,
  ImageSummaryModel,
  ProductNativeSummaryModel,
  CreateProductNativeSummaryModel,
  RichProductNativeSummaryModel,
  CreateRichProductNativeSummaryModel
} from './FlowSteps/SubSteps/SummaryContent/CreativeSummaryModel';
import { CreativeSetupFlowDataContextType } from './CreativeSetupFlowDataContext';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import {
  CreateCreativeAndBindRTBCampaignSummaryStepModel,
  CreateCreativeSummaryStepModel,
  CreativeSummaryStepModel,
  EditCreativeSummaryStepModel
} from './FlowSteps/CreativeSummaryStepModel';
import { MultiImageFormModel, ImageFormModel } from './FlowSteps/SubSteps/FormContent/ImageFormModel';
import { CreativeSetupStepModel, DefaultCreativeSetupStepModel } from './FlowSteps/CreativeSetupStepModel';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { computeChecksumMd5 } from 'utils/Md5Utils';
import { DefaultOrderManager, OrderManager } from 'core/order/OrderManager';
import { AD_TYPE_MAP_CREATIVE_TYPE, AdType, RtbCampaign } from 'core/rtbCampaign/RtbCampaign';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { ProductNativeFormModel } from './FlowSteps/SubSteps/FormContent/ProductNativeFormModel';
import { Product } from 'core/product/Product';
import { ProductFilter } from 'containers/RetailRMN/Products/ProductFilter';
import { CreativeSetupFlowPageModel, RedirectData } from './CreativeSetupFlowPageModelInterface';
import { Order } from 'core/order/Order';
import { RichProductNativeFormModel } from './FlowSteps/SubSteps/FormContent/RichProductNativeFormModel';

export type CreativeSetupFlowProps = {
  readonly model: CreativeSetupFlowPageModel;
};

export type CreativeSetupFlowState = {
  readonly loading: boolean;
  readonly redirectData?: RedirectData;
  readonly creative?: CreativeFormData;
  readonly finished: boolean;
  readonly modalData?: any;
  readonly canCancel: boolean;
};

export abstract class DefaultCreativeSetupFlowPageModel implements CreativeSetupFlowPageModel {

  event: FireableUpdateEventListener<CreativeSetupFlowPageModel>;
  loading: boolean;
  redirectData?: RedirectData;
  initCreative: any;
  creative?: CreativeFormData;
  finished: boolean;
  creativeSetupStepModel?: CreativeSetupStepModel;
  uploadedFiles: { [key: string]: string } = {};
  cache: { [key: string]: string } = {};
  validCreativeTypes = this.creativeManager.getCreativeTypes();
  modalData?: any;
  canCancel: boolean;

  constructor (
    public canChooseAdvertiser: boolean,
    public advertisers: Array<SelectOptions>,
    public addonFeatureManager: AddonFeatureManager,
    public campaignId?: string,
    public orderNumber?: string,
    public l1ObjectId?: string,
    protected creativeManager: CreativeManager = new DefaultCreativeManager(),
    protected advertiserManager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    this.event = new FireableUpdateEventListener<CreativeSetupFlowPageModel>();
    this.loading = false;
    this.finished = false;
    this.canCancel = true;
  }

  abstract get type ();

  abstract init ();

  getInitCreativeType () {
    return this.supportedCreativeType[0];
  }

  getUploadedFileData = async (file: File) => {
    const fileCheckSum = await computeChecksumMd5(file);
    return this.uploadedFiles[fileCheckSum];
  }

  addUploadedFilesData = async (file, url) => {
    const fileCheckSum = await computeChecksumMd5(file);
    this.uploadedFiles[fileCheckSum] = url;
  }

  get dataContenxt (): CreativeSetupFlowDataContextType {
    return {
      creative: this.creative,
      initCreative: this.initCreative,
      advertisers: this.advertisers,
      supportedCreativeType: this.supportedCreativeType,
      setCreative: this.setCreative,
      setFinishedRedirectData: this.setFinishedRedirectData,
      getFormContentModelOfType: this.getFormContentModelOfType.bind(this),
      getSummaryModel: this.getSummaryModel.bind(this)
    };
  }

  get supportedCreativeType () {
    let result: CreativeType[] = [];
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.IMAGE) &&
      result.push(CreativeType.IMAGE);
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.PRODUCT_NATIVE) &&
      result.push(CreativeType.PRODUCT_NATIVE);
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.RICH_PRODUCT_NATIVE) &&
      result.push(CreativeType.RICH_PRODUCT_NATIVE);
    return result.filter(type => this.validCreativeTypes.includes(type));
  }

  getCreativeSetupStepModel (
    creativeType: CreativeType,
    type: string,
    goLast: (() => void) | undefined,
    goNext: () => void,
    registerValidateMethod: (validateMethod) => any
  ): CreativeSetupStepModel {
    if (this.creativeSetupStepModel) {
      return this.creativeSetupStepModel;
    }
    this.creativeSetupStepModel = new DefaultCreativeSetupStepModel(
      creativeType,
      type,
      goLast,
      goNext,
      registerValidateMethod
    );
    return this.creativeSetupStepModel;
  }

  abstract getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel;

  setCreative = (creative: CreativeFormData) => {
    if (this.event.count === 0) { // already unmount
      return;
    }
    this.creative = creative;
    this.updateState(false);
  }

  get state (): CreativeSetupFlowState {
    return {
      loading: this.loading,
      redirectData: this.redirectData,
      creative: this.creative,
      finished: this.finished,
      modalData: this.modalData,
      canCancel: this.canCancel
    };
  }

  setRedirectData (redirectData?: RedirectData) {
    this.redirectData = redirectData;
  }

  setFinishedRedirectData = (redirectData?: RedirectData) => {
    this.redirectData = redirectData;
    this.finished = true;
    this.updateState(false);
  }

  setModalData (data?: { render: any; props: any; }): void {
    this.modalData = data;
    this.updateState(false);
  }

  get isModalOpen (): boolean {
    return !!this.state.modalData;
  }

  getFormContentModelOfType (type: CreativeType): FormContentModel {
    switch (type) {
      case CreativeType.IMAGE:
        return new ImageFormModel(this, {
          960: [320],
          1000: [60]
        });
      case CreativeType.PRODUCT_NATIVE:
        return new ProductNativeFormModel(this);
      case CreativeType.RICH_PRODUCT_NATIVE:
        return new RichProductNativeFormModel(this);
      default:
        throw new Error('unsupported type');
    }
  }

  getSummaryModel (creativeBasicFormData: CreativeFormBasicData): CreativeSummaryModel | undefined {
    const type = creativeBasicFormData.creativeType;
    switch (type) {
      case CreativeType.IMAGE:
        return new ImageSummaryModel(creativeBasicFormData);
      case CreativeType.PRODUCT_NATIVE:
        return new ProductNativeSummaryModel(creativeBasicFormData);
      case CreativeType.RICH_PRODUCT_NATIVE:
        return new RichProductNativeSummaryModel(creativeBasicFormData);
      default:
        return;
    }
  }

  cancel = () => {
    const campaignParam = `campaignIds=${this.campaignId}`;
    const redirectPath = _.isNil(this.orderNumber) || _.isNil(this.l1ObjectId) || _.isNil(this.campaignId) ?
      '/creatives' :
      `/orders/${this.orderNumber}/campaign-groups/${this.l1ObjectId}?${campaignParam}&action=manage`;
    this.redirectData = {
      pathname: redirectPath
    };
    this.updateState(false);
  }

  onUnmount (handler) {
    this.event.remove(handler);
    this.redirectData = undefined;
    this.creative = undefined;
    this.creativeSetupStepModel = undefined;
    this.finished = false;
    this.creativeSetupStepModel = undefined;
    this.uploadedFiles = {};
    this.cache = {};
  }

  addCache (key: string, data: any) {
    this.cache[key] = data;
  }

  getCache (key: string) {
    return this.cache[key];
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export class CreateCreativeSetupFlowPageModel extends DefaultCreativeSetupFlowPageModel {

  get type () {
    return 'create';
  }

  async init () {
    this.updateState(true);
    try {
      const advertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
      const creativeType = this.getInitCreativeType();
      const model = this.getFormContentModelOfType(creativeType);
      this.creative = {
        basic: {
          advertiserId,
          creativeType: creativeType,
          tenmaxCategory: 'e_commerce'
        }
      };

      _.set(this.creative, 'basic.typeProperties', model ? model.getInitTypeProperties() : undefined);
      this.initCreative = JSON.parse(JSON.stringify(this.creative));
    } catch (e) {}
    this.updateState(false);
  }

  getFormContentModelOfType (type: CreativeType): FormContentModel {
    if (type === CreativeType.IMAGE) {
      return new MultiImageFormModel(this, {
        960: [320],
        1000: [60]
      });
    }
    return super.getFormContentModelOfType(type);
  }

  getSummaryModel (creativeBasicFormData: CreativeFormBasicData): CreativeSummaryModel | undefined {
    const type = creativeBasicFormData.creativeType;
    if (type === CreativeType.IMAGE) {
      return new MultiImageSummaryModel(creativeBasicFormData);
    }
    if (type === CreativeType.PRODUCT_NATIVE) {
      return new CreateProductNativeSummaryModel(creativeBasicFormData);
    }
    if (type === CreativeType.RICH_PRODUCT_NATIVE) {
      return new CreateRichProductNativeSummaryModel(creativeBasicFormData);
    }
    return super.getSummaryModel(creativeBasicFormData);
  }

  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel {
    return new CreateCreativeSummaryStepModel(
      this.type,
      this.canChooseAdvertiser,
      goLast,
      goStep,
      this.getUploadedFileData,
      this.addUploadedFilesData,
      this.creativeManager,
      this.campaignId,
      this.orderNumber,
      this.l1ObjectId
    );
  }
}

export class CreateProductNativeCreativeSetupFlowPageModel extends DefaultCreativeSetupFlowPageModel {

  constructor (
    public products: Product[],
    public filters: ProductFilter,
    ...params: ConstructorParameters<typeof DefaultCreativeSetupFlowPageModel>
  ) {
    super(...params);
  }

  get type () {
    return 'create';
  }

  async init () {
    this.updateState(true);
    try {
      const model = this.getFormContentModelOfType(CreativeType.PRODUCT_NATIVE);
      this.creative = {
        basic: {
          advertiserId: this.filters.advertiser,
          creativeType: CreativeType.PRODUCT_NATIVE,
          tenmaxCategory: 'e_commerce'
        }
      };

      _.set(this.creative, 'basic.typeProperties', model ? model.getInitTypeProperties() : undefined);
      this.initCreative = JSON.parse(JSON.stringify(this.creative));
    } catch (e) {}
    this.updateState(false);
  }

  get supportedCreativeType () {
    let result: CreativeType[] = [];
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.PRODUCT_NATIVE) &&
      result.push(CreativeType.PRODUCT_NATIVE);
    return result;
  }

  getFormContentModelOfType (type: CreativeType): FormContentModel {
    return new ProductNativeFormModel(this, this.products);
  }

  getSummaryModel (creativeBasicFormData: CreativeFormBasicData): CreativeSummaryModel | undefined {
    return new CreateProductNativeSummaryModel(creativeBasicFormData);
  }

  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel {
    return new CreateCreativeSummaryStepModel(
      this.type,
      this.canChooseAdvertiser,
      goLast,
      goStep,
      this.getUploadedFileData,
      this.addUploadedFilesData,
      this.creativeManager,
      this.campaignId,
      this.orderNumber,
      this.l1ObjectId
    );
  }

  cancel = () => {
    this.redirectData = {
      pathname: '/products',
      state: {
        filters: this.filters
      }
    };
    this.updateState(false);
  }

  setFinishedRedirectData = (redirectData?: RedirectData) => {
    if (redirectData) {
      this.redirectData = {
        pathname: '/products',
        state: {
          ..._.defaultTo(redirectData.state, {}),
          filters: this.filters
        }
      };
    }
    this.finished = true;
    this.updateState(false);
  }
}

export class EditCreativeSetupFlowPageModel extends DefaultCreativeSetupFlowPageModel {

  constructor (
    public creativeId: number,
    ...args: ConstructorParameters<typeof DefaultCreativeSetupFlowPageModel>
  ) {
    super(...args);
  }

  get type () {
    return 'edit';
  }

  async init () {
    this.updateState(true);
    try {
      const creativeFromServer = await this.creativeManager.getCreative(this.creativeId);
      const contentFormModel = this.getFormContentModelOfType(creativeFromServer.basic.creativeType);
      if (!contentFormModel) {
        this.redirectData = {
          pathname: `/creatives/${creativeFromServer.basic.creativeId}/edit/${ROUTE_PATH.ERROR404}`
        };
        this.updateState(false);
        return;
      }
      this.creative = contentFormModel.getFormModelData(creativeFromServer);
      this.initCreative = JSON.parse(JSON.stringify(this.creative));
    } catch (e) {}
    this.updateState(false);
  }

  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel {
    return new EditCreativeSummaryStepModel(
      this.type,
      this.canChooseAdvertiser,
      goLast,
      goStep,
      this.getUploadedFileData,
      this.addUploadedFilesData,
      this.creativeManager,
      this.campaignId,
      this.orderNumber,
      this.l1ObjectId
    );
  }
}

abstract class CreateCreativeFromL2ObjectSetupFlowPageModel extends CreateCreativeSetupFlowPageModel {

  constructor (
    canChooseAdvertiser: boolean,
    advertisers: Array<SelectOptions>,
    addonFeatureManager: AddonFeatureManager,
    campaignId?: string,
    orderNumber?: string,
    l1ObjectId?: string,
    private orderManager: OrderManager = new DefaultOrderManager()
  ) {
    super(canChooseAdvertiser, advertisers, addonFeatureManager, campaignId, orderNumber, l1ObjectId);
  }

  abstract get supportedCreativeType ();

  abstract initCampaign (): Promise<string | undefined>;

  async init () {
    if (!this.orderNumber) {
      return;
    }
    this.updateState(true);
    try {
      const campaignName = await this.initCampaign();
      const order = await this.orderManager.getOrder(this.orderNumber);
      const advertiserId = order.advertiserId;
      const creativeType = this.supportedCreativeType[0];
      const model = this.getFormContentModelOfType(creativeType);
      this.creative = {
        basic: {
          name: campaignName,
          advertiserId,
          creativeType,
          tenmaxCategory: 'e_commerce'
        }
      };
      _.set(this.creative, 'basic.typeProperties', model ? model.getInitTypeProperties() : undefined);
      this.initCreative = JSON.parse(JSON.stringify(this.creative));
    } catch (e) {}
    this.updateState(false);
  }

  abstract getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel;
}

export class CreateCreativeFromRtbCampaignSetupFlowPageModel extends CreateCreativeFromL2ObjectSetupFlowPageModel {

  campaign?: RtbCampaign;

  constructor (
    canChooseAdvertiser: boolean,
    advertisers: Array<SelectOptions>,
    addonFeatureManager: AddonFeatureManager,
    campaignId?: string,
    orderNumber?: string,
    l1ObjectId?: string,
    private campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager()
  ) {
    super(canChooseAdvertiser, advertisers, addonFeatureManager, campaignId, orderNumber, l1ObjectId);
  }

  async initCampaign (): Promise<string | undefined> {
    if (!this.campaignId) {
      return;
    }
    try {
      this.campaign = await this.campaignManager.getCampaign(this.campaignId);
      this.canCancel = this.campaign.basic.adType !== AdType.PMP;
      return this.campaign.basic.name;
    } catch (e) {}
    return;
  }

  get supportedCreativeType () {
    let result: CreativeType[] = [];
    if (!this.campaign || !this.campaign.basic.adType) {
      return result;
    }

    AD_TYPE_MAP_CREATIVE_TYPE[this.campaign.basic.adType].forEach(creativeType => {
      const addonName: any = `option_${creativeType}`;
      this.addonFeatureManager.isFeatureEnable(addonName) &&
        result.push(creativeType);
    });
    return result.filter(type => this.validCreativeTypes.includes(type));
  }

  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel {
    return new CreateCreativeAndBindRTBCampaignSummaryStepModel(
      this.type,
      this.canChooseAdvertiser,
      goLast,
      goStep,
      this.getUploadedFileData,
      this.addUploadedFilesData,
      this.creativeManager,
      this.campaignId,
      this.orderNumber,
      this.l1ObjectId
    );
  }
}

export class CreateCreativeInL2ObjectSetupFlowPageModel extends CreateCreativeSetupFlowPageModel {

  constructor (
    canChooseAdvertiser: boolean,
    advertisers: Array<SelectOptions>,
    addonFeatureManager: AddonFeatureManager,
    public campaign: RtbCampaign,
    private order: Order
  ) {
    super(canChooseAdvertiser, advertisers, addonFeatureManager);
  }

  get supportedCreativeType () {
    let result: CreativeType[] = [];
    if (!this.campaign || !this.campaign.basic.adType) {
      return result;
    }

    AD_TYPE_MAP_CREATIVE_TYPE[this.campaign.basic.adType].forEach(creativeType => {
      const addonName: any = `option_${creativeType}`;
      this.addonFeatureManager.isFeatureEnable(addonName) &&
        result.push(creativeType);
    });
    return result.filter(type => this.validCreativeTypes.includes(type));
  }

  async init () {
    this.updateState(true);
    try {
      const campaignName = this.campaign.basic.name;
      const advertiserId = this.order.advertiserId;
      const creativeType = this.supportedCreativeType[0];
      const model = this.getFormContentModelOfType(creativeType);
      this.creative = {
        basic: {
          name: campaignName,
          advertiserId,
          creativeType,
          tenmaxCategory: 'e_commerce'
        }
      };
      _.set(this.creative, 'basic.typeProperties', model ? model.getInitTypeProperties() : undefined);
      this.initCreative = JSON.parse(JSON.stringify(this.creative));
    } catch (e) {}
    this.updateState(false);
  }

  getFormContentModelOfType (type: CreativeType): FormContentModel {
    if (type === CreativeType.IMAGE) {
      return new ImageFormModel(this, {
        960: [320],
        1000: [60]
      });
    }
    return super.getFormContentModelOfType(type);
  }

  getSummaryModel (creativeBasicFormData: CreativeFormBasicData): CreativeSummaryModel | undefined {
    const type = creativeBasicFormData.creativeType;
    if (type === CreativeType.IMAGE) {
      return new ImageSummaryModel(creativeBasicFormData);
    }
    return super.getSummaryModel(creativeBasicFormData);
  }
}
