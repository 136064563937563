import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import React from 'react';
import { AuditLogProps, AuditLogState } from './AuditLogModel';
import i18n from 'i18n';
import { LoadingIndicator } from 'components/LoadingIndicator';
import Select from 'components/Select/Select';
import styles from './auditLog.module.scss';

export class AuditLog<T extends object> extends React.Component<AuditLogProps<T>, AuditLogState<T>> {

  handler?: number;

  constructor (props: any) {
    super(props);
    this.state = this.props.model.state;
  }

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    if (!this.state.auditLogData) {
      this.props.model.init(1, true);
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      this.props.model.init(1, true);
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  handleTableChange = (type, props) => {
    if (type === 'sort') {
      this.props.model.sortAuditLog(props.sortField, props.sortOrder);
      return;
    }
    this.props.model.init(props.page);
  }

  renderFilter () {
    const filterData = this.props.model.getFilters();
    return (
      Object.keys(filterData).map(filterKey => {
        const data = filterData[filterKey];
        return (
          <div className={styles.filterListContainer} key={filterKey}>
            <div className={styles.title}>
              {i18n.t<string>(`auditLog.labels.${filterKey}Title`)}
            </div>
            <Select
              className={styles.filterList}
              options={data.options}
              name={filterKey}
              simpleValue
              value={data.value}
              onChange={data.onSelect}
              placeholder={i18n.t<string>(`auditLog.placeholders.${filterKey}`)}
            />
          </div>
        );
      })
    );
  }

  render () {
    const model = this.props.model;
    const rowClasses = (auditLog) => {
      let classes: any = null;
      if (!auditLog.msg || auditLog.msg.length === 0) {
        classes = styles.defaultCursor;
      }
      return classes;
    };
    return (
      <div className={styles.auditLog} key={i18n.language}>
        {this.state.loading && <LoadingIndicator/>}
        <div className={styles.filterArea}>
          {this.renderFilter()}
        </div>
        <StickableBootstrapTable<T>
          stickFirstColumn
          remote
          keyField='id'
          rowClasses={rowClasses}
          data={this.state.auditLogData ? this.state.auditLogData : []}
          columns={model.getColumns()}
          onTableChange={this.handleTableChange}
          noDataIndication={i18n.t<string>('common.labels.noData')}
          expandRow={model.getExpandRenderer()}
          defaultSorted={model.sortDescriptor}
          pagination={{
            page: model.state.pagination.page,
            sizePerPage: model.state.pagination.size,
            totalSize: model.state.pagination.totalCount
          }}
        />
      </div>
    );
  }
}
