import { AgencyDetail, Actor } from 'core';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import {
  AgencyManager,
  DefaultAgencyManager
} from 'core/agency/AgencyManager';
import { AgencyMemberTabModel, DefaultAgencyMemberTabModel } from './AgencyMemberTabModel';
import data from '../AgencyForm/agencyData';
import { AgencyAuditLogModel } from 'containers/AuditLog/AgencyAuditLogModel';

const defaultAgency: AgencyDetail = data.defaultAgency;

export interface AgencyDetailModel {
  readonly agencyId?: number;
  readonly operator?: Actor | null;
  readonly defaultAgency: AgencyDetail;
  readonly title: string;
  readonly currentAccountId: number;
  state: AgencyDetailState;
  isSystemViewOnly: boolean;
  event: UpdateEventListener<AgencyDetailModel>;
  init (): Promise<void>;
  getAgencyMemberTabModel (): AgencyMemberTabModel;
  getAuditLogModel (): AgencyAuditLogModel | undefined;
  showTab (tabName: string | null, e): void;
}

export interface AgencyDetailProps {
  readonly model: AgencyDetailModel;
}

export type AgencyDetailState = {
  isLoading: boolean;
  dataTabName: string;
};

export class DefaultAgencyDetailModel implements AgencyDetailModel {
  agencyDetail: AgencyDetail;
  modelEvent: FireableUpdateEventListener<AgencyDetailModel>;
  isLoading: boolean;
  isSystemViewOnly: boolean;
  agencyManager: AgencyManager;
  agencyId: number;
  operator: Actor | null;
  agencyMemberTabModel?: AgencyMemberTabModel;
  dataTabName: string = 'basic';
  auditLogModel?: AgencyAuditLogModel;

  constructor (
    agencyId: number,
    operator: Actor | null,
    public currentAccountId: number,
    agencyManager: AgencyManager = new DefaultAgencyManager()
  ) {
    this.agencyId = agencyId;
    this.operator = operator;
    this.agencyManager = agencyManager;
    this.modelEvent = new FireableUpdateEventListener<AgencyDetailModel>();
    this.isLoading = true;
    this.isSystemViewOnly = operator ? operator.role === 'ROOT' : false;
    this.agencyDetail = defaultAgency;
  }

  async init (): Promise<void> {
    try {
      const { detail } = await this.agencyManager.fetchAgency(this.agencyId);
      this.agencyDetail = detail;
      await this.getAuditLogModel()?.init(1, true);
      this.updateState(false);
    } catch (e) {}
  }

  showTab = (tabName, e) => {
    e && e.stopPropagation();
    if (tabName === null) {
      return;
    }
    this.dataTabName = tabName;
    this.updateState(false);
  }

  get defaultAgency (): AgencyDetail {
    return this.agencyDetail;
  }

  get title (): string {
    return 'agency.form.titles.edit';
  }

  get state (): AgencyDetailState {
    return {
      isLoading: this.isLoading,
      dataTabName: this.dataTabName
    };
  }

  get event (): UpdateEventListener<AgencyDetailModel> {
    return this.modelEvent;
  }

  updateState (
    isLoading: boolean
  ) {
    this.isLoading = isLoading;
    this.modelEvent.fireEvent(this);
  }

  getAgencyMemberTabModel (): AgencyMemberTabModel {
    if (this.agencyMemberTabModel) {
      return this.agencyMemberTabModel;
    }
    this.agencyMemberTabModel = new DefaultAgencyMemberTabModel(this.agencyId, this.agencyManager, this.operator, this.currentAccountId);
    return this.agencyMemberTabModel;
  }

  getAuditLogModel (): AgencyAuditLogModel | undefined {
    // TODO: temparary only Admin enable to see audit log
    const isAdmin = this.isSystemViewOnly;
    if (!isAdmin) {
      return undefined;
    }

    if (
      this.auditLogModel &&
      this.agencyId === this.auditLogModel.agencyId
    ) {
      return this.auditLogModel;
    }

    this.auditLogModel = new AgencyAuditLogModel(isAdmin, this.agencyId);
    return this.auditLogModel;
  }
}
