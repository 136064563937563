import React from 'react';
import classNames from 'classnames/bind';

import { SelectOptions } from 'components/commonType';
import styles from './selectItemComponent.module.scss';
import { SelectItemComponentState, SelectItemComponentProps, ItemType } from './SelectItemComponentModel';
import i18n from 'i18next';
export class SelectItemComponent extends React.Component<SelectItemComponentProps, SelectItemComponentState> {

  data: SelectOptions;
  hasOptions: boolean;
  cssClassNames: any;
  itemRef: { current: null | HTMLDivElement };
  handler?: number;

  constructor (props) {
    super(props);
    this.data = this.props.model.data;
    this.state = this.props.model.state;
    this.hasOptions = !!this.props.model.data.options;
    this.cssClassNames = classNames.bind(styles);
    this.itemRef = React.createRef();
  }

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.updateItemHeight();
  }

  componentDidUpdate (prevProps, prevStates) {
    if (prevProps.model !== this.props.model) {
      this.data = this.props.model.data;
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.hasOptions = !!this.props.model.data.options;
      this.updateItemHeight();
    } else if (prevStates.isOpen !== this.state.isOpen) {
      this.updateItemHeight();
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  updateItemHeight () {
    this.itemRef.current && this.props.model.setItemHeight(this.itemRef.current.clientHeight);
  }

  selectValue = (data) => {
    if (this.props.model.isSelected(data)) {
      return;
    }

    this.props.model.onSelect(data);
  }

  getOptionContent = (data) => (
    <React.Fragment>
      {this.props.model.itemSetting.i18nPrefix ?
        i18n.t<string>(`${this.props.model.itemSetting.i18nPrefix}.${data.value.toString().toLowerCase().replace(/-|\s/g, '_')}`) :
        data.label
      }
      {!this.props.model.itemSetting.hideExtra && data.extra !== undefined && data.extra !== null && !Array.isArray(data.extra) ?
        <div className={styles.extra}>
          {data.extra.i18nKey ? i18n.t<string>(data.extra.i18nKey, { value: data.extra.value }) : data.extra}
        </div> : undefined
      }
    </React.Fragment>
  )

  getOptions = () => {
    const model = this.props.model;
    if (!model.data.options) {
      return [];
    }

    const readonly = model.itemSetting.readonly;
    return model.data.options.map((option, index) => {
      const optionClassName = this.cssClassNames('selectItem', {
        selected: model.isSelected(option),
        disabled: model.disabled || model.isOptionDisabled(option.value),
        readonly: readonly
      });
      const clickFunc = readonly ? undefined : () => this.selectValue(option);
      return (
        <div
          className={optionClassName}
          key={index}
          onClick={clickFunc}
        >
          {this.getOptionContent(option)}
        </div>
      );
    });
  }

  render () {
    const model = this.props.model;
    const clickFunc = () => this.selectValue(model.data);
    if (
      model.itemSetting.type === ItemType.withCategory ||
      (model.data.options && model.data.options.length > 0) ||
      (model.itemSetting.type === ItemType.autoByGroupSetting && model.data.isGroup)
    ) {
      const categorySelectable = model.itemSetting.categorySelectable === true && !model.itemSetting.readonly;
      const itemClassName = this.cssClassNames('selectItemWithOptions', {
        open: this.state.isOpen
      });
      const categoryContainerClassName = this.cssClassNames('categoryContainer', {
        selected: model.isSelected(model.data),
        disabled: model.disabled
      });
      const categoryClassName = this.cssClassNames('category', {
        selectable: categorySelectable
      });

      return (
        <div
          className={itemClassName}
          ref={this.itemRef}
        >
          <div className={categoryContainerClassName}>
            <div className={styles.arrowContainer} onClick={this.props.model.toggleOpen}>
              <div className={styles.arrow} />
            </div>
            <div
              className={categoryClassName}
              onClick={categorySelectable ? clickFunc : undefined}
            >
              {this.getOptionContent(model.data)}
            </div>
          </div>
          <div className={styles.options}>
            {this.getOptions()}
          </div>
        </div>
      );
    } else {
      const readonly = model.itemSetting.readonly;
      const itemClassName = this.cssClassNames('selectItem', {
        selected: model.isSelected(model.data),
        disabled: model.disabled,
        readonly: readonly
      });

      return (
        <div
          className={itemClassName}
          onClick={readonly ? undefined : clickFunc}
          ref={this.itemRef}
        >
          {this.getOptionContent(model.data)}
        </div>
      );
    }
  }
}
