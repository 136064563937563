import React, { useCallback, useMemo } from 'react';
import i18n from 'i18n';
import { ImageMultipleInput } from 'components/Image/ImageMultipleInput';
import styles from './multiImageInputField.module.scss';
import classnames from 'classnames/bind';
import { useField, useFormikContext } from 'formik';
import { default as ReactTags } from 'components/Tags/Tags';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';
import { FormikFieldProps } from 'hoc/withFormikField';
import { defaultTo, get } from 'lodash';

const cx = classnames.bind(styles);

interface MultiImageInputProps {
  validTypes: string[];
  className?: string;
  name: string;
  disabled?: boolean;
  files?: any[];
  maxWidth?: number;
  maxHeight?: number;
  onChange: (fileData: any) => void;
}

export interface FormikMultiImageInputProps extends Omit<MultiImageInputProps, 'onChange'>, FormikFieldProps {}
export interface FormikMultiImageInputWithFieldLayoutProps extends Omit<MultiImageInputProps, 'onChange'>, Omit<FieldLayoutProps, 'hint' | 'name'>, FormikFieldProps {
  hints: string[];
}
export type FormikMultiImageInputFieldProps = ({ withFieldLayout?: true } & FormikMultiImageInputWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikMultiImageInputProps);

const MultiImageDetail = ({
  onChange,
  images
}) => {
  const onImageChange = useCallback((imageOptions) => {
    images ?
      onChange(imageOptions.length === 0 ? undefined : imageOptions.map(option => images.find(image => image.file.name === option.value))) :
      onChange(undefined);
  }, [images, onChange]);

  if (!images) {
    return <div/>;
  }

  const value = images.map(image => ({ label: image.file.name, value: image.file.name }));
  return (
    <ReactTags
      value={value}
      disableInput
      onChange={onImageChange}
    />
  );
};

const MultiImageInputContent = (({
  files,
  className,
  ...props
}: MultiImageInputProps) => {
  return (
    <div className={styles.imageFormInputWithList}>
      <div className={className}>
        <ImageMultipleInput
          files={files}
          {...props}
        />
      </div>
      {files &&
        <div className={styles.selectImageList}>
          {<MultiImageDetail images={files} onChange={props.onChange}/>}
        </div>
      }
    </div>
  );
});

const MemorizedMultiImageInputContent = React.memo(MultiImageInputContent);

const MultiImageInputWithFieldLayout = withFieldLayout<MultiImageInputProps & Omit<FieldLayoutProps, 'hint' | 'name'>>(MultiImageInputContent);

const FormikMultiImageInput: React.FC<FormikMultiImageInputProps> = ({
  name,
  onChange,
  validate,
  className,
  ...props
}) => {

  const { setFieldValue, setFieldTouched, submitCount } = useFormikContext<any>();

  const [{ value }, { error, touched: formikTouched }] = useField({ name, validate });
  const touched = defaultTo(submitCount, 0) > 0 || formikTouched;

  const handleChange = useCallback((newValue) => {
    setFieldValue(name, newValue);
    setFieldTouched(name, true);
    // formik issues 2266 workaround
    setTimeout(() => {
      onChange && onChange(newValue);
    });
  }, [name, setFieldValue, setFieldTouched, onChange]);

  const hasError = error !== undefined && touched;
  const inputClass = cx('imageInput', {
    emptyImageInput: !value,
    previewMultipleInput: value && value.length > 1,
    showErrorBorder: hasError,
    showSolidBorder: !!value
  });

  return (
    <MemorizedMultiImageInputContent
      {...props}
      name={name}
      files={value}
      onChange={handleChange}
      className={inputClass}
    />
  );
};

const FormikMultiImageInputWithFieldLayout: React.FC<FormikMultiImageInputWithFieldLayoutProps> = ({
  name,
  onChange,
  validate,
  hints,
  className,
  ...props
}) => {

  const { setFieldValue, setFieldTouched, submitCount } = useFormikContext<any>();

  const [{ value }, { error, touched: formikTouched }] = useField({ name, validate });
  const touched = defaultTo(submitCount, 0) > 0 || formikTouched;

  const hint = useMemo(() => {
    const hintElements = hints ? hints.map((hint, index) => {
      if (index === hints.length - 1) {
        return hint;
      }
      return <span key={index}>{hint}<br/></span>;
    }) : '';
    let hint: string | React.JSX.Element = <>{hintElements}</>;
    if (value) {
      hint = i18n.t<string>('creativeSetupFlow.labels.successHint');
    }
    return hint;
  }, [hints, value]);

  const handleChange = useCallback((newValue) => {
    setFieldValue(name, newValue);
    setFieldTouched(name, true);
    // formik issues 2266 workaround
    setTimeout(() => {
      onChange && onChange(newValue);
    });
  }, [name, setFieldValue, setFieldTouched, onChange]);

  const hasError = error !== undefined && touched;
  const inputClass = cx('imageInput', {
    emptyImageInput: !value,
    previewMultipleInput: value && value.length > 1,
    showErrorBorder: hasError,
    showSolidBorder: !!value
  });

  return (
    <MultiImageInputWithFieldLayout
      {...props}
      name={name}
      files={value}
      onChange={handleChange}
      className={inputClass}
      error={hasError ? error : undefined}
      hint={hasError ? undefined : hint}
      fieldContentWidth='auto'
    />
  );
};

export const FormikMultiImageInputField: React.FC<FormikMultiImageInputFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikMultiImageInput {...props} />;
  } else {
    const hints = get(props, 'hints', []);
    return <FormikMultiImageInputWithFieldLayout {...props} hints={hints}/>;
  }
};
