import React, { ReactElement } from 'react';
import moment from 'moment';
import classnames from 'classnames/bind';
import _ from 'lodash';
import { DAILY_BUDGET_STATE } from 'containers/RtbCampaigns/RtbCampaignSetupFlow/RtbCampaignForm/RtbCampaignBasicFormModel';
import {
  DailyBudgetPlan,
  RtbCampaignPlanType
} from 'core/rtbCampaign/RtbCampaign';
import styles from './rtbCampaignInfo.module.scss';
import i18n from 'i18n';
import { AlertDialog } from 'components/AlertDialog';
import { Trans } from 'react-i18next';
import { FormikField } from 'components/form/field/FormikField';
import { LabelField } from 'components/form/field/LabelField';
import { CustomField } from 'components/form/field/CustomField';
import { RadioField } from 'components/form/field/RadioField';
import warningIcon from 'assets/warning.svg';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';

const cx = classnames.bind(styles);

export type CampaignInfoState = {
  alertContent?: string | ReactElement | ReactElement[],
  needShowDailyBudgetAlert: boolean
};
class RtbCampaignInfo extends React.Component<any, CampaignInfoState> {

  turnOnNeedShowDailyBudgetAlert: () => void;
  rtbCampaignManager: RtbCampaignManager;

  budgetTypeOptions = [{
    label: i18n.t<string>('campaignInfo.labels.schedule'),
    value: DailyBudgetPlan.SCHEDULE,
    extra: i18n.t<string>('campaignInfo.labels.cannotModifyHint')
  }, {
    label: i18n.t<string>('campaignInfo.labels.daily'),
    value: DailyBudgetPlan.DAILY,
    extra: i18n.t<string>('campaignInfo.labels.cannotModifyHint')
  }];

  constructor (props) {
    super(props);
    this.state = {
      alertContent: undefined,
      needShowDailyBudgetAlert: false
    };
    this.turnOnNeedShowDailyBudgetAlert = _.partial(this.setNeedShowDailyBudgetAlert, true);
    this.rtbCampaignManager = new DefaultRtbCampaignManager();
  }

  getDailyBudgetTips () {
    const { dailyBudgetState } = this.props;
    switch (dailyBudgetState) {
      case DAILY_BUDGET_STATE.OVER_BUDGET:
        return (
          <Trans i18nKey='campaign.descriptions.overDailyBudget'>
            ...<span className='text-danger'>...</span>...
          </Trans>
        );
      case DAILY_BUDGET_STATE.UNDER_BUDGET:
        return (
          <Trans i18nKey='campaign.descriptions.underDailyBudget'>
            ...<span className='text-danger'>...</span>...
          </Trans>
        );
      case DAILY_BUDGET_STATE.MEET_BUDGET:
        return (
          <Trans i18nKey='campaign.descriptions.meetDailyBudget'>
            ...<span className='text-dark'>...</span>...
          </Trans>
        );
      case DAILY_BUDGET_STATE.DEFAULT:
      default:
        return;
    }
  }

  setNeedShowDailyBudgetAlert = (needShow) => {
    this.setState({
      needShowDailyBudgetAlert: needShow
    });
  }

  checkIsUnderBudget = () => {
    if (this.state.needShowDailyBudgetAlert && this.props.dailyBudgetState === DAILY_BUDGET_STATE.UNDER_BUDGET) {
      this.setState({
        alertContent: (
          <Trans i18nKey='campaign.descriptions.underDailyBudget'>
            ...<span className='text-danger'>...</span>...
          </Trans>
        )
      });
    }
  }

  dismissAlertWindow = () => {
    this.setState({
      alertContent: undefined,
      needShowDailyBudgetAlert: false
    });
  }

  renderTargetBudgetInput = () => {
    const {
      currency,
      showBudgetHint,
      remainBudget
    } = this.props;

    let hint: ReactElement | undefined;
    if (showBudgetHint) {
      hint = (
        <Trans i18nKey='campaign.descriptions.remainBudget'>
          Remain budget <span className='text-dark'>{{ currency }}{{ remainBudget }}</span>
        </Trans>
      );
    }

    const inputProps = {
      label: i18n.t<string>('campaignInfo.labels.totalBudget'),
      prefix: currency,
      name: 'budget',
      type: 'number',
      min: 0,
      autoComplete: 'off',
      onBlur: this.checkIsUnderBudget,
      onChange: this.turnOnNeedShowDailyBudgetAlert,
      hint,
      fieldContentWidth: 424,
      postComponent: this.renderCampaignAnalyticsHint('lifetimeBudgetRank')
    };

    return (
      <FormikField.InputGroup
        {...inputProps}
      />
    );
  }

  renderDailyBudgetPlan () {
    const {
      currency,
      dailyBudget
    } = this.props;
    const isScheduleDistribution = _.isNil(dailyBudget) || dailyBudget === 0;
    const postComponent = this.renderCampaignAnalyticsHint('dailyBudgetRank');
    return (
      <CustomField
        label={i18n.t<string>('campaignInfo.labels.dailyBudgetPlan')}
        name='dailyBudgetPlan'
      >
        {
          isScheduleDistribution ?
          <LabelField
            value={i18n.t<string>('campaignSummary.labels.budgetScheduleDistribution')}
            formGroupClassName={styles.marginBottom0}
            postComponent={postComponent}
          /> :
          <>
            <LabelField
              value={i18n.t<string>('campaignInfo.labels.daily')}
              formGroupClassName={styles.marginBottom7}
            />
            <FormikField.InputGroup
              prefix={currency}
              name='dailyTargetBudget'
              hint={this.getDailyBudgetTips()}
              type='number'
              min={0}
              autoComplete='off'
              formGroupClassName={styles.marginBottom0}
              postComponent={postComponent}
            />
          </>
        }
      </CustomField>
    );
  }

  renderCampaignScheduling () {
    const { startDate, endDate } = this.props;
    const endDateMoment = moment(endDate);
    const startDateMoment = moment(startDate);
    const days = endDateMoment.diff(startDateMoment, 'days') + 1;
    const hours = endDateMoment.diff(startDateMoment, 'hours') + 1;
    if (days > 1) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingDay'>
          Total <span className='text-dark'>{{ days }} days</span>
        </Trans>
      );
    } else if (days === 1) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingHour'>
          Total <span className='text-dark'>{{ hours }} hours</span>
        </Trans>
      );
    }
  }

  renderCampaignAnalyticsHint (fieldName: string) {
    const { campaignAnalytics } = this.props;
    const hint = this.rtbCampaignManager.getCampaignAnalyticsHint(campaignAnalytics, fieldName);
    if (!hint) {
      return undefined;
    }
    return (
      <div className={styles.campaignAnalyticsHint}>
        <img
          src={warningIcon}
          alt='warning'
        />
        {hint}
      </div>
    );
  }

  render () {
    const {
      orderName,
      minDate,
      maxDate,
      canEditPriceModel,
      priceModelOptions,
      currency,
      priceModel,
      canUseDailyBudget,
      canEditBudgetPlan,
      onChangeDailyBudgetPlan: handleChangeDailyBudgetPlan,
      onChangePriceModel: handleChangePriceModel,
      showTagsPlaceholder,
      isCampaignStart,
      dailyBudgetType,
      showDeliverType,
      showTags,
      bidPriceRange
    } = this.props;

    const isRSCpc = priceModel === RtbCampaignPlanType.RS_CPC;
    const showBidPrice = isRSCpc || priceModel === RtbCampaignPlanType.RS_CPM;
    const bidPriceHint = bidPriceRange.recommend ?
      <Trans i18nKey={'campaign.descriptions.rsCpcPriceMinimum'}>
        The price have to over <span className='text-dark'>{{ min: currency + bidPriceRange.min }}</span>, recommend {{ rmin: currency + bidPriceRange.recommend.min }}~{{ rmax: currency + bidPriceRange.recommend.max }}
      </Trans> :
      bidPriceRange.min && bidPriceRange.min > 0 ?
      <Trans i18nKey={'campaign.descriptions.orderPriceTips'}>
        The price have to over <span className='text-dark'>{{ min: currency + bidPriceRange.min }}</span>
      </Trans>
      : '';
    const priceModelClassName = cx('priceModel', {
      'componentWithTips': true
    });
    const dateRangePickerClassName = cx('componentWithTips', {
      disableStart: isCampaignStart
    });
    const onChangeSchedule = () => {
      this.setNeedShowDailyBudgetAlert(true);
    };
    const priceModelFormatter = priceModel => i18n.t<string>(`campaign.labels.${priceModel}`);
    return (
      <fieldset>
        <legend>
          <span>{i18n.t<string>('campaignInfo.labels.title')}</span>
        </legend>
        <div className={styles.component}>
          {this.state.alertContent &&
            <AlertDialog
              title={i18n.t<string>('common.warning')}
              message={this.state.alertContent}
              danger={false}
              dismiss={this.dismissAlertWindow}
            />
          }
          <LabelField
            label={i18n.t<string>('campaignInfo.labels.order')}
            name='orderName'
            value={orderName}
          />
          <FormikField.Input
            label={i18n.t<string>('campaignInfo.labels.campaignName')}
            name='name'
          />
          <FormikField.DateRangePicker
            label={i18n.t<string>('campaignInfo.labels.dayRange')}
            className={dateRangePickerClassName}
            minDate={minDate}
            maxDate={maxDate}
            startDateFormikName='startDate'
            endDateFormikName='endDate'
            name='dayRange'
            timeInterval={60}
            startDatePickerDisabled={isCampaignStart}
            onPopOverClosed={this.checkIsUnderBudget}
            onChange={onChangeSchedule}
            hoverHint={i18n.t<string>('campaignInfo.labels.dateCannotModifyHint')}
            hint={this.renderCampaignScheduling()}
            fieldContentWidth={424}
          />
          {
            canEditPriceModel ?
            <FormikField.Select
              className={priceModelClassName}
              name='priceModel'
              label={i18n.t<string>('campaignInfo.labels.priceModel')}
              simpleValue
              options={priceModelOptions}
              onChange={handleChangePriceModel}
              hoverHint={i18n.t<string>('campaignInfo.labels.cannotModifyPriceModelHint')}
            /> :
            <FormikField.Label
              name='priceModel'
              label={i18n.t<string>('campaignInfo.labels.priceModel')}
              formatter={priceModelFormatter}
            />
          }
          {showBidPrice &&
            <FormikField.InputGroup
              label={i18n.t<string>('l1Object.labels.bidStrategy.lowest_cost_with_bid_cap')}
              name='bidPrice'
              prefix={currency}
              type='number'
              min={0}
              className={styles.bidPrice}
              hint={bidPriceHint}
              postComponent={this.renderCampaignAnalyticsHint('bidPrice')}
            />
          }
          {this.renderTargetBudgetInput()}
          {canUseDailyBudget && !canEditBudgetPlan &&
            this.renderDailyBudgetPlan()}
          {canUseDailyBudget && canEditBudgetPlan && (
            <RadioField
              name='dailyBudgetType'
              label={i18n.t<string>('campaignInfo.labels.dailyBudgetPlan')}
              className={styles.radio}
              useOptionExtraAsHoverTip={true}
              options={this.budgetTypeOptions}
              value={dailyBudgetType}
              onChange={handleChangeDailyBudgetPlan}
              fieldContentWidth={424}
              postComponent={(+dailyBudgetType === DailyBudgetPlan.SCHEDULE)
                ? this.renderCampaignAnalyticsHint('dailyBudgetRank')
                : undefined
              }
            />
          )}
          {+dailyBudgetType === DailyBudgetPlan.DAILY && canEditBudgetPlan && (
            <FormikField.InputGroup
              label=''
              formGroupClassName={styles.dailyBudgetInput}
              name='dailyTargetBudget'
              prefix={currency}
              hint={this.getDailyBudgetTips()}
              type='number'
              min={0}
              autoComplete='off'
              onBlur={this.checkIsUnderBudget}
              onChange={this.turnOnNeedShowDailyBudgetAlert}
              postComponent={this.renderCampaignAnalyticsHint('dailyBudgetRank')}
            />
          )}
          {showDeliverType &&
            <FormikField.ToggleButton
              name='deliverType'
              label={i18n.t<string>('campaign.labels.deliverType')}
              options={this.props.campaignDeliverTypeOptions}
            />
          }
          {showTags &&
            <FormikField.Tags
              label={i18n.t<string>('campaign.labels.tags')}
              name='tags'
              placeholder={showTagsPlaceholder ? i18n.t<string>('campaign.placeholders.tags') : ''}
            />
          }
        </div>
      </fieldset>
    );
  }
}

export default RtbCampaignInfo;
