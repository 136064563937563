import { LoadingIndicator } from 'components/LoadingIndicator';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import i18n from 'i18n';
import { PmpListColumns, usePmpListModel } from 'containers/Pmp/PmpListModel';
import { Button } from 'react-bootstrap';
import { useCallback } from 'react';
import { defaultTo } from 'lodash';
import { SelectOptions } from 'components/commonType';
import { Pmp, PmpType, TargetType } from 'core/pmp/Pmp';
import { AdType, DeliverType, RtbCampaignBasic, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { getCpc, getCpm } from 'helper/MetricsHelper';

export const SelectPmpStep: React.FC<{
  goNext: (pmp: Pmp, campaign: {
    basic: Partial<RtbCampaignBasic>,
    limitations: any
  }) => void,
  goLast: () => void,
  spaceOptions?: SelectOptions[],
  defaultPmp?: Pmp,
  pmpListOfAgency?: Pmp[]
}> = ({
  goNext,
  goLast,
  spaceOptions,
  defaultPmp,
  pmpListOfAgency
}) => {

  const {
    loading,
    columns,
    filterdPmps,
    selectedPmp,
    onHandleSelect
  } = usePmpListModel(
    defaultTo(spaceOptions, []),
    true,
    defaultPmp ? [defaultPmp.id] : [],
    pmpListOfAgency,
    false,
    false
  );

  const completeAndCheck = useCallback(() => {
    const pmp = filterdPmps.find(pmp => pmp.id === selectedPmp[0]);
    if (!pmp) return;

    const isCPC = pmp.pmpType === PmpType.PROGRAMMATIC_GUARANTEED && pmp.targetType === TargetType.CLICK;
    const bidPrice = pmp.pmpType === PmpType.PROGRAMMATIC_GUARANTEED ?
      pmp.targetType === TargetType.CLICK ?
        getCpc(pmp.budget, defaultTo(pmp.targetQuantity, 0)) :
        getCpm(pmp.budget, defaultTo(pmp.targetQuantity, 0)) :
      0.01;
    const campaignBasic = {
      name: pmp.name,
      budget: pmp.budget,
      startDate: pmp.startTime,
      endDate: pmp.endTime,
      adType: AdType.PMP,
      priceModel: isCPC ? RtbCampaignPlanType.FCPC : RtbCampaignPlanType.FCPM,
      optimize: isCPC ? L2ObjectOptimizationGoal.CLICKS : L2ObjectOptimizationGoal.IMPRESSIONS,
      orderPrice: bidPrice,
      bidPrice: bidPrice,
      deliverType: DeliverType.ACCELERATED
    };
    goNext(pmp, {
      basic: campaignBasic,
      limitations: {
        other: [{
          type: 'dealId',
          value: [{ label: pmp.dealId, value: pmp.dealId }]
        }]
      }
    });
  }, [filterdPmps, selectedPmp, goNext]);

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      {loading && <LoadingIndicator/>}
      <StickableBootstrapTable
        customPadding={false}
        keyField={'id'}
        data={filterdPmps}
        columns={columns}
        noDataIndication={i18n.t<string>('selectPmpStep.placeholders.noData')}
        defaultSorted={[{
          dataField: PmpListColumns.NAME,
          order: 'desc'
        }]}
        selectedRows={selectedPmp}
        onSelect={onHandleSelect}
        hidePagination={true}
      />
      <div style={{ padding: '30px' }}>
        <Button disabled={selectedPmp.length === 0} style={{ marginRight: '20px' }} size='sm' onClick={completeAndCheck}>{i18n.t<string>('common.buttons.completeAndCheck')}</Button>
        <Button variant='secondary' size='sm' onClick={goLast}>{i18n.t<string>('common.buttons.back')}</Button>
      </div>
    </div>
  );
};
