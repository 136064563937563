export enum CampaignListColumns {
  NAME = 'nameColumn',
  STATE = 'stateColumn',
  OPTIMIZE = 'optimize',
  DELIVERY = 'deliveryColumn',
  CREATIVE = 'creativeColumn',
  SCHEDULE = 'scheduleColumn',
  PROGRESS = 'progressColumn',
  BUDGET = 'budgetColumn',
  PRICE = 'priceColumn',
  LIMITATION = 'limitationColumn',
  IMPRES = 'impresColumn',
  CLICKS = 'clicksColumn',
  CPC = 'cpcColumn',
  CTR = 'ctrColumn',
  TAGS = 'tagsColumn',
  SPENT = 'spents',
  DIRECT_ADD_TO_CART = 'directAddToCartColumn',
  DIRECT_ORDER = 'directOrderColumn',
  DIRECT_PRODUCT_SALES_COUNT = 'directProductSalesCountColumn',
  DIRECT_PRODUCT_SALES_AMOUNT = 'directProductSalesAmountColumn',
  DIRECT_CONVERSION_RATE = 'directConversionRateColumn',
  DIRECT_ROAS = 'directRoasColumn',
  INDIRECT_ADD_TO_CART = 'indirectAddToCartColumn',
  INDIRECT_ORDER = 'indirectOrderColumn',
  INDIRECT_PRODUCT_SALES_COUNT = 'indirectProductSalesCountColumn',
  INDIRECT_PRODUCT_SALES_AMOUNT = 'indirectProductSalesAmountColumn',
  INDIRECT_CONVERSION_RATE = 'indirectConversionRateColumn',
  INDIRECT_ROAS = 'indirectRoasColumn',
  EDITBTNS = 'editBtnsColumn'
}

export const basicColumns = [
  CampaignListColumns.NAME,
  CampaignListColumns.STATE,
  CampaignListColumns.DELIVERY,
  CampaignListColumns.CREATIVE,
  CampaignListColumns.SCHEDULE,
  CampaignListColumns.PROGRESS,
  CampaignListColumns.BUDGET,
  CampaignListColumns.PRICE,
  CampaignListColumns.OPTIMIZE,
  CampaignListColumns.LIMITATION,
  CampaignListColumns.TAGS,
  CampaignListColumns.EDITBTNS
];

export const performanceColumns = [
  CampaignListColumns.NAME,
  CampaignListColumns.PROGRESS,
  CampaignListColumns.BUDGET,
  CampaignListColumns.LIMITATION,
  CampaignListColumns.IMPRES,
  CampaignListColumns.CLICKS,
  CampaignListColumns.CPC,
  CampaignListColumns.CTR,
  // CampaignListColumns.UUCOUNT,
  CampaignListColumns.SPENT,
  CampaignListColumns.TAGS,
  CampaignListColumns.EDITBTNS
];

export const ecommerceColumns = [
  CampaignListColumns.NAME,
  CampaignListColumns.DIRECT_ADD_TO_CART,
  CampaignListColumns.DIRECT_ORDER,
  CampaignListColumns.DIRECT_PRODUCT_SALES_COUNT,
  CampaignListColumns.DIRECT_PRODUCT_SALES_AMOUNT,
  CampaignListColumns.DIRECT_CONVERSION_RATE,
  CampaignListColumns.DIRECT_ROAS,
  CampaignListColumns.INDIRECT_ADD_TO_CART,
  CampaignListColumns.INDIRECT_ORDER,
  CampaignListColumns.INDIRECT_PRODUCT_SALES_COUNT,
  CampaignListColumns.INDIRECT_PRODUCT_SALES_AMOUNT,
  CampaignListColumns.INDIRECT_CONVERSION_RATE,
  CampaignListColumns.INDIRECT_ROAS,
  CampaignListColumns.EDITBTNS
];
