import React from 'react';
// import { Link } from 'react-router-dom';

import styles from './agenciesHome.module.scss';
import i18n from 'i18n';

import { SearchBar } from 'components/SearchBar';
import { AgencyList, DefaultAgencyListModel } from '../AgencyList';
import { AgenciesHomeProps, AgenciesHomeState } from './AgenciesHomeModel';
import { LoadingIndicator } from 'components/LoadingIndicator';

export class AgenciesHome extends React.Component<AgenciesHomeProps, AgenciesHomeState> {

  handler: number;

  constructor (props: any) {
    super(props);
    this.state = this.props.model.state;
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidMount () {
    this.props.model.fetch();
  }

  componentDidUpdate (prevProps: AgenciesHomeProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.fetch();
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  render () {
    return (
      <div className={styles.agenciesHome}>
        {this.state.loading && <LoadingIndicator />}
        <div className={styles.topPanel}>
          <h3>{i18n.t<string>('agencies.home.title')}</h3>
        </div>
        <div className={styles.operationPanel}>
          {/* <Link
            to='/agencies/new'
            className={'btn btn-tertiary btn-sm'}
          >
            {i18n.t<string>('agencies.home.addAgency')}
          </Link> */}
          <SearchBar model={this.props.model} />
        </div>
        <div className={styles.listContainer}>
          <AgencyList model={new DefaultAgencyListModel(this.state.agencies, this.state.pagination, this.props.model.onAgencyListChange)} />
        </div>
      </div>
    );
  }
}
