import React, { useCallback, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import './LoadingIndicator.scss';

import i18n from 'i18n';
import { useCoreContext } from 'contexts/coreContext';
import { defaultTo, get } from 'lodash';
import { useLocation, matchPath } from 'react-router-dom';
import { RestfulLoadingTimeWebService } from 'ws/LoadingTimeWebService';

export type LoadingIndicatorProps = {

  readonly text?: string;
  readonly recordEveryTime?: boolean;
  readonly loadingComment?: string;
};

const needRecordLoadingTimeRoutes = [
  '/orders',
  '/orders/:orderNumber',
  '/orders/:orderNumber/campaign-groups/:l1ObjectId'
  // '/reports/campaign-dashboard',
  // '/reports/performance'
];

const defaultLoadingTimeWebService = new RestfulLoadingTimeWebService();

export const LoadingIndicator: React.FC<LoadingIndicatorProps> & {
  loadingData: {
    pathname?: string;
    toleranceTimer?: NodeJS.Timeout;
    loadingTime?: number;
    loaded: boolean;
  };
} = (props: LoadingIndicatorProps) => {

  const [loadingLayer, setLoadingLayer] = React.useState<HTMLDivElement>();

  const core = useCoreContext();
  const email = get(core, 'authenticationManager.account.email', '');
  const location = useLocation();
  const pathname = location.pathname + location.search;
  const needRecordLoadingTime = useMemo(() => needRecordLoadingTimeRoutes.some(route => {
    const match = matchPath(location.pathname, route);
    return !!match && match.isExact && Object.values(match.params).every(param => {
      return !isNaN(Number(param));
    });
  }), [location.pathname]);

  const resetLoadingData = useCallback(() => {
    LoadingIndicator.loadingData.loaded = false;
    LoadingIndicator.loadingData.pathname = '';
    LoadingIndicator.loadingData.loadingTime = Date.now();
    LoadingIndicator.loadingData.toleranceTimer && clearTimeout(LoadingIndicator.loadingData.toleranceTimer);
  }, []);

  const timeoutErrorHandler = useCallback((event: Event) => {
    if (!(event instanceof ErrorEvent)) {
      return;
    }
    const code = get(event, 'error.response.status');
    if (code !== 504) {
      return;
    }
    LoadingIndicator.loadingData.toleranceTimer && clearTimeout(LoadingIndicator.loadingData.toleranceTimer);
    defaultLoadingTimeWebService.recordLoadingTime(email, pathname, '504 time out', props.loadingComment);
  }, [email, pathname, props.loadingComment]);

  useEffect(() => {
    const samePage = LoadingIndicator.loadingData.pathname === pathname;
    if (!samePage || props.recordEveryTime) {
      // new page, reset loading data
      resetLoadingData();
      LoadingIndicator.loadingData.pathname = pathname;
    }
    const loadingLayer = document.createElement('div');
    document.body.appendChild(loadingLayer);
    setLoadingLayer(loadingLayer);
    window.addEventListener('api-error', timeoutErrorHandler);
    return () => {
      loadingLayer && document.body.removeChild(loadingLayer);
      window.removeEventListener('api-error', timeoutErrorHandler);
      if (LoadingIndicator.loadingData.loaded) {
        return;
      }
      if (props.recordEveryTime) {
        defaultLoadingTimeWebService.recordLoadingTime(email, pathname, Date.now() - defaultTo(LoadingIndicator.loadingData.loadingTime, 0), props.loadingComment);
        resetLoadingData();
        return;
      }
      // if no loading indicator appears on the same page within 1s,
      // it can be considered that the loading is completed
      const toleranceTime = 1000;
      // clear last loader timer
      LoadingIndicator.loadingData.toleranceTimer && clearTimeout(LoadingIndicator.loadingData.toleranceTimer);
      LoadingIndicator.loadingData.toleranceTimer = setTimeout(() => {
        const finished = document.querySelectorAll('.loading-indicator').length === 0;
        if (finished && needRecordLoadingTime) {
          LoadingIndicator.loadingData.loaded = true;
          defaultLoadingTimeWebService.recordLoadingTime(email, pathname, Date.now() - defaultTo(LoadingIndicator.loadingData.loadingTime, 0) - toleranceTime, props.loadingComment);
        }
      }, toleranceTime);
    };
  }, [email, pathname, needRecordLoadingTime, props.recordEveryTime, props.loadingComment, resetLoadingData, timeoutErrorHandler]);

  return loadingLayer ? ReactDOM.createPortal(
    <div className='loading-indicator'>
      <div className='board'>
        <div className='roller' />
        <div className='message'>{i18n.t<string>(defaultTo(props.text, ''))}</div>
      </div>
    </div>,
    loadingLayer
  ) : null;
};

LoadingIndicator.loadingData = {
  loaded: false
};

LoadingIndicator.defaultProps = {
  text: 'common.messages.loading',
  recordEveryTime: false
};
