import React, { Fragment, useMemo, useRef } from 'react';
import styles from './productHome.module.scss';
import i18n from 'i18n';
import { useProductHomeModel } from './ProductHomeModel';
import { Formik, FormikProps } from 'formik';
import { Form, NavDropdown } from 'react-bootstrap';
import { ProductFilter } from './ProductFilter';
import { FormikField } from 'components/form/field/FormikField';
import { notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ProductList } from './ProductList';
import { Redirect } from 'react-router-dom';
import { get, partial } from 'lodash';
import { InputField } from 'components/form/field/InputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export type ProductHomeProps = {
  defaultFilters?: ProductFilter;
  enableCreateProductNativeCreative: boolean;
  title?: string;
};

export const ProductHome: React.FC<ProductHomeProps> = (props) => {

  const searchBarRef = useRef<any>(null);
  const {
    defaultFilters,
    enableCreateProductNativeCreative,
    title = i18n.t<string>('productHome.labels.title')
  } = props;

  const {
    loading,
    redirectData,
    filters,
    minPrice,
    maxPrice,
    pagination,
    allProducts,
    productsData,
    advertiserOptions,
    defaultOrderOptions,
    editActions,
    handleSearchString,
    handleMinPriceInput,
    handleMaxPriceInput,
    handleSubmit,
    validate,
    onProductListChange,
    category1Options,
    category2Options,
    category3Options,
    category4Options,
    handleCategory1Change,
    handleCategory2Change,
    handleCategory3Change,
    resetFilters,
    selectedFilterTypes,
    filterTypeOptions,
    handleAddFilterType
  } = useProductHomeModel(enableCreateProductNativeCreative, defaultFilters);

  const cannotSelectFilters = !get(filters, 'advertiser');

  const filterTypeElementMap: { [key: string]: JSX.Element } = useMemo(() => {
    const renderPriceMarginFilter = () => {
      const priceMarginClass = cx('fieldContent', 'priceMargin');
      return (
        <div className={priceMarginClass}>
          <InputField
            label={i18n.t<string>('productHome.filters.labels.priceMargin')}
            name='minPrice'
            fieldContentWidth={118}
            isFlexibleContent={true}
            value={minPrice ? minPrice.toString() : ''}
            onChange={handleMinPriceInput}
            className={styles.priceInput}
            type='number'
            disabled={cannotSelectFilters}
            placeholder={i18n.t<string>('productHome.placeholders.minPrice')}
          />
          <div className={styles.tilde}>~</div>
          <InputField
            name='maxPrice'
            fieldContentWidth={118}
            isFlexibleContent={true}
            value={maxPrice ? maxPrice.toString() : ''}
            onChange={handleMaxPriceInput}
            className={styles.priceInput}
            type='number'
            disabled={cannotSelectFilters}
            placeholder={i18n.t<string>('productHome.placeholders.maxPrice')}
          />
        </div>
      );
    };
    const renderDefaultOrderFilter = () => {
      return (
        <div className={styles.fieldContent}>
          <FormikField.Select
            label={i18n.t<string>('productHome.filters.labels.defaultOrder')}
            name='defaultOrder'
            options={defaultOrderOptions}
            fieldContentWidth={266}
            componentWidthFitParent={true}
            isFlexibleContent={true}
            simpleValue
            className={styles.productHomeSelect}
            disabled={cannotSelectFilters}
          />
        </div>
      );
    };
    return {
      priceMargin: renderPriceMarginFilter(),
      defaultOrder: renderDefaultOrderFilter()
    };
  }, [cannotSelectFilters, minPrice, maxPrice, defaultOrderOptions, handleMinPriceInput, handleMaxPriceInput]);

  const renderFilters = (formProps: FormikProps<ProductFilter>) => {
    const productHomeFilterAdderClass = cx('fieldContent', 'productHomeFilterAdder');
    const onProductOwnerChange = () => {
      resetFilters();
      searchBarRef.current && searchBarRef.current.clear();
    };
    const renderFilterTypeList = () => {
      const items = filterTypeOptions.map(filterType => (
        <NavDropdown.Item
          key={filterType}
          onClick={partial(handleAddFilterType, filterType)}
        >
          {i18n.t<string>(`productHome.filters.labels.${filterType}`)}
        </NavDropdown.Item>
      ));
      return (
        filterTypeOptions.length > 0 ?
        <div className={productHomeFilterAdderClass}>
          <FormikField.Custom
            label=''
            name='productHomeFilterAdder'
            fieldContentWidth={266}
            isFlexibleContent={true}
          >
            <div className={styles.filterAdder}>
            <FontAwesomeIcon icon={faPlus} />
            <div className={styles.filterToAddList}>
              <NavDropdown title={i18n.t<string>('productHome.labels.filterCondition')} id='productHomeFilterList'>
                {items}
              </NavDropdown>
            </div>
          </div>
          </FormikField.Custom>
        </div> : <div />
      );
    };
    return (
      <Form>
        <>
          {!get(defaultFilters, 'advertiser') &&
            <div className={styles.fieldContent}>
              <FormikField.Select
                label={i18n.t<string>('productHome.filters.labels.advertiser')}
                name='advertiser'
                options={advertiserOptions}
                fieldContentWidth={266}
                componentWidthFitParent={true}
                isFlexibleContent={true}
                simpleValue
                className={styles.productHomeSelect}
                onChange={onProductOwnerChange}
              />
            </div>
          }
          <div className={styles.fieldContent}>
            <FormikField.Select
              label={i18n.t<string>('productHome.filters.labels.category')}
              name='category1'
              options={category1Options}
              fieldContentWidth={266}
              componentWidthFitParent={true}
              isFlexibleContent={true}
              simpleValue
              onChange={handleCategory1Change}
              className={styles.productHomeSelect}
              isClearable={true}
              disabled={cannotSelectFilters}
            />
            <FormikField.Select
              name='category2'
              options={category2Options}
              fieldContentWidth={266}
              componentWidthFitParent={true}
              isFlexibleContent={true}
              simpleValue
              onChange={partial(handleCategory2Change, formProps.values.category1)}
              className={styles.productHomeSelect}
              isClearable={true}
              disabled={cannotSelectFilters}
            />
            <FormikField.Select
              name='category3'
              options={category3Options}
              fieldContentWidth={266}
              componentWidthFitParent={true}
              isFlexibleContent={true}
              simpleValue
              onChange={partial(handleCategory3Change, formProps.values.category1, formProps.values.category2)}
              className={styles.productHomeSelect}
              isClearable={true}
              disabled={cannotSelectFilters}
            />
            <FormikField.Select
              name='category4'
              options={category4Options}
              fieldContentWidth={266}
              componentWidthFitParent={true}
              isFlexibleContent={true}
              simpleValue
              className={styles.productHomeSelect}
              isClearable={true}
              disabled={cannotSelectFilters}
            />
          </div>
          {selectedFilterTypes.map(filterType => {
            return (
              <Fragment key={filterType}>
                {filterTypeElementMap[filterType]}
              </Fragment>
            );
          })}
          {renderFilterTypeList()}
        </>
      </Form>
    );
  };

  return (
    <>
      {redirectData && <Redirect to={redirectData} />}
      {loading && <LoadingIndicator />}
      <div className={styles.container}>
        <div className={styles.titleArea}>
          <div className={styles.title}>
            {title}
          </div>
          <PermissionChecker
            permissionAware={notRoles(RoleNames.sysAdmin, RoleNames.adsReport)}
          >
            <div className={styles.filtersFormContainer}>
              <Formik
                initialValues={filters}
                enableReinitialize
                onSubmit={handleSubmit}
                validate={validate}
                validateOnBlur={false}
              >
                {renderFilters}
              </Formik>
            </div>
          </PermissionChecker>
        </div>
        <ProductList
          filterSet={filters}
          productList={productsData}
          allProducts={allProducts}
          pagination={pagination}
          editActions={editActions()}
          onListChange={onProductListChange}
          onSearchStringChange={handleSearchString}
        />
      </div>
    </>
  );
};
