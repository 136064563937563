import React, { useMemo } from 'react';
import i18n from 'i18n';
import { CampaignDashboardColumns, useCampaignDashboardModel } from './CampaignDashboardModel';
import { LoadingIndicator } from 'components/LoadingIndicator';
import styles from './campaignDashboard.module.scss';
import { InfoDisplaySection } from 'components/InfoDisplaySection/InfoDisplaySection';
import Select from 'components/Select/Select';
import { AdType, CampaignState } from 'core/rtbCampaign/RtbCampaign';
import { SearchBar } from 'components/SearchBar';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { SelectOptions } from 'components/commonType';

export type CampaignDashboardProps = {};

export const CampaignDashboard: React.FC<CampaignDashboardProps> = () => {

  const {
    days,
    loading,
    timeOption,
    todaySummary,
    searchString,
    yesterdaySummary,
    columns,
    filteredList,
    // campaignToChangeBidWeight,
    campaignStatus,
    adType,
    searched,
    download,
    setAdType,
    setDays,
    setTimeOption,
    setSearchString,
    setCampaignStatus,
    onSearchButtonClick
    // updateCampaignBidWeight,
    // hideManageBidWeightModal
  } = useCampaignDashboardModel();

  const dayOptions = useMemo(() => [1, 7, 30, 60, 90, 180].map(day => ({
    label: day.toString(),
    value: day
  })), []);

  const timeOptions = useMemo(() => [{
    label: i18n.t<string>('campaignDashboard.labels.future'),
    value: 1
  }, {
    label: i18n.t<string>('campaignDashboard.labels.ago'),
    value: -1
  }], []);

  const campaignStatusOptions = useMemo(() => [{
    label: i18n.t<string>('common.placeholder.all'),
    value: -1
  }, {
    label: i18n.t<string>('campaignList.labels.activateState'),
    value: CampaignState.ACTIVATE
  }, {
    label: i18n.t<string>('campaignList.labels.deactivateState'),
    value: CampaignState.DEACTIVATE
  }], []);

  const adTypeOptions = useMemo(() => {
    const options: SelectOptions[] = [
      // {
      //   label: i18n.t<string>('adType.display'),
      //   value: AdType.DISPLAY
      // },
      {
        label: i18n.t<string>('adType.keyword'),
        value: AdType.KEYWORD
      }
    ];
    options.unshift({
      label: i18n.t<string>('common.placeholder.all'),
      value: ''
    });
    return options;
  }, []);

  return (
    <div className={styles.campaignDashboard}>
      {loading && <LoadingIndicator/>}
      <div className={styles.title}>
        {i18n.t<string>('campaignDashboard.titles.main')}
      </div>
      <div className={styles.titleBottomLine} />
      <div className={styles.summary}>
        <InfoDisplaySection data={yesterdaySummary} title={i18n.t<string>('campaignDashboard.titles.yesterday')} />
        <InfoDisplaySection data={todaySummary} title={i18n.t<string>('campaignDashboard.titles.today')} />
      </div>
      <div className={styles.campaignList}>
        <div className={styles.filterArea}>
          <div className={styles.flexArea}>
            <div className={styles.dayFilter}>
              <div>
                {i18n.t<string>('campaignDashboard.labels.endsin')}
              </div>
              <Select
                className={styles.daySelect}
                options={dayOptions}
                name='dayOptions'
                simpleValue
                value={days}
                onChange={setDays}
                maxMenuHeight={150}
              />
              <div>
                {i18n.t<string>('campaignDashboard.labels.days')}
              </div>
              <Select
                className={styles.timeOption}
                options={timeOptions}
                name='timeOptions'
                simpleValue
                value={timeOption}
                onChange={setTimeOption}
                maxMenuHeight={150}
              />
            </div>
            <div className={styles.filterWithTitle}>
              <div className={styles.filterTitle}>
                {i18n.t<string>('campaignDashboard.labels.adType')}
              </div>
              <Select
                className={styles.filter}
                options={adTypeOptions}
                name='adTypeOptions'
                simpleValue
                value={adType}
                onChange={setAdType}
                maxMenuHeight={150}
              />
            </div>
            <div className={styles.filterWithTitle}>
              <div className={styles.filterTitle}>
                {i18n.t<string>('campaignDashboard.labels.campaignStatus')}
              </div>
              <Select
                className={styles.filter}
                options={campaignStatusOptions}
                name='campaignStatusOptions'
                simpleValue
                value={campaignStatus}
                onChange={setCampaignStatus}
                maxMenuHeight={150}
              />
            </div>
            {searched &&
              <SearchBar
                model={{
                  placeholder: i18n.t<string>('campaignDashboard.placeholders.searchbar'),
                  search: setSearchString,
                  defaultValue: searchString
                }}
              />
            }
          </div>
          <button className={`btn btn-primary btn-sm ${styles.searchButton}`} onClick={onSearchButtonClick}>
            {i18n.t<string>('campaignDashboard.buttons.search')}
          </button>
        </div>
        {searched &&
          <div className={styles.downloadContainer}>
            <span onClick={download} className={styles.downloadWording}>
              <FontAwesomeIcon icon={faDownload} className={styles.downloadIcon} />
              {i18n.t<string>('campaignDashboard.buttons.download')}
            </span>
          </div>
        }
        <div className={styles.listContent}>
          <StickableBootstrapTable
            stickFirstColumn
            stickLastColumn
            keyField={CampaignDashboardColumns.ID}
            data={filteredList}
            columns={columns}
            defaultSorted={[{
              dataField: CampaignDashboardColumns.ID,
              order: 'desc'
            }]}
            noDataIndication={searched ? i18n.t<string>('campaignDashboard.labels.noData') : i18n.t<string>('campaignDashboard.labels.pleaseSearch')}
          />
        </div>
      </div>
    </div>
  );
};
