import React from 'react';
import styles from './storedValueList.module.scss';
import { Link } from 'react-router-dom';
import { StoredValueAgencyRecord } from 'core/storedValue/StoredValueLog';

const nameFormatter = (id: number, summary: StoredValueAgencyRecord): any => {
  return (
    <div className={`${styles.nameCell}`}>
      <Link
        to={`/stored-value/${id}`}
        className={styles.name}
      >
        {summary.supplierName}
      </Link>
      <div className={styles.id}>
        {'ID: ' + id}
      </div>
    </div>
  );
};

const formatters = {
  nameFormatter
};
export default formatters;
