import _ from 'lodash';

import { Actor } from 'core/auth/Account';

import * as parser from 'utils/ActorRoleParser';
import { Permission } from 'core/auth/Permission';

export type PermissionContext = {

  readonly actor?: Actor | null;
  readonly agencyAddon?: string[] | null;
};

export interface PermissionAware {

  visible (context: PermissionContext): boolean;
}

export interface ActorPermissionAware {

  name: string;
  visible (actor?: Actor | null): boolean;
}

export interface AgencyPermissionAware {

  visible (agencyAddon?: string[] | null): boolean;
}

export type ActorRolePermissionDescriptor = { [index: string]: Array<string> };

export class InverseActorPermission implements ActorPermissionAware {
  name = 'InverseActorPermission';
  permission: ActorPermissionAware;

  constructor (permission: ActorPermissionAware) {
    this.permission = permission;
  }

  visible (actor?: Actor | null): boolean {
    return !this.permission.visible(actor);
  }
}

export class ActorRolePermission implements ActorPermissionAware {
  name = 'ActorRolePermission';
  roleDescriptors: ActorRolePermissionDescriptor;

  constructor (roleDescriptors: ActorRolePermissionDescriptor) {
    this.roleDescriptors = roleDescriptors;
  }

  visible (actor?: Actor | null): boolean {
    if (actor) {
      const type: string = parser.typeOf(actor).toLowerCase();
      const role: string = parser.roleOf(actor).toLowerCase();
      const rolePermissions = this.roleDescriptors[type];
      if (rolePermissions) {
        return rolePermissions.includes(role);
      }
    }
    return false;
  }
}

export class ActorFuncPermission implements ActorPermissionAware {
  name = 'ActorFuncPermission';
  funcPermissions: Array<Permission>;

  constructor (funcPermissions: Array<Permission>) {
    this.funcPermissions = funcPermissions;
  }

  visible (actor?: Actor | null): boolean {
    if (actor) {
      const permissions = actor.permissions;
      if (permissions) {
        return _.intersection(permissions, this.funcPermissions).length === this.funcPermissions.length;
      }
    }
    return false;
  }
}

export class AgencyAddOnPermission implements AgencyPermissionAware {
  keys: Array<string>;

  constructor (keys: Array<string>) {
    this.keys = keys;
  }

  visible (agencyAddon?: string[] | null): boolean {
    if (agencyAddon) {
      return _.intersection(agencyAddon, this.keys).length !== 0;
    }
    return false;
  }
}

export class InConditionPermission implements PermissionAware {
  name = 'InConditionPermission';
  constructor (private condition: () => boolean) {}

  visible (): boolean {
    return this.condition();
  }
}

export class PermissionItem implements PermissionAware {
  name = 'PermissionItem';
  actorPermission: ActorPermissionAware | null;
  agencyPermission: AgencyPermissionAware | null;
  both: boolean;
  andPermissionAwares: PermissionAware[];
  orPermissionAwares: PermissionAware[];

  constructor (actorPermission: ActorPermissionAware | null, agencyPermission: AgencyPermissionAware | null = null, bothRequired: boolean = true) {
    this.actorPermission = actorPermission;
    this.agencyPermission = agencyPermission;
    this.both = bothRequired;
    this.andPermissionAwares = [];
    this.orPermissionAwares = [];
  }

  visible (context: PermissionContext): boolean {
    const actorVisible = this.actorPermission ? this.actorPermission.visible(context.actor) : true;
    const agencyVisible = this.agencyPermission ? this.agencyPermission.visible(context.agencyAddon) : true;
    const visible = this.both ? (actorVisible && agencyVisible) : (actorVisible || agencyVisible);
    const andVisible = this.andPermissionAwares.reduce((visible, permission) => visible && permission.visible(context), true);
    const orVisible = this.orPermissionAwares.reduce((visible, permission) => visible || permission.visible(context), false);
    return (visible && andVisible) || orVisible;
  }

  and (permissionItem: PermissionAware) {
    this.andPermissionAwares = _.concat(this.andPermissionAwares, permissionItem);
    return this;
  }

  or (permissionItem: PermissionAware) {
    this.orPermissionAwares = _.concat(this.orPermissionAwares, permissionItem);
    return this;
  }
}
