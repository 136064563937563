import React from 'react';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import i18n from 'i18n';
import styles from './storedValueList.module.scss';
import { SearchBar } from 'components/SearchBar';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { StoredValueListColumns, useStoredValueListModel } from './StoredValueListModel';

export type StoredValueListProps = {};

export const StoredValueList: React.FC<StoredValueListProps> = () => {

  const {
    state,
    columns,
    loading,
    onSort,
    onChangePage,
    onHandleSearch
  } = useStoredValueListModel();

  const handleTableChange = (type, props) => {
    if (type === 'sort') {
      onSort(props.sortField, props.sortOrder);
      return;
    }
    onChangePage(props.page);
  };

  return (
    <div className={styles.storedValueList}>
      {loading && <LoadingIndicator />}
      <div className={styles.titleArea}>
        <div className={styles.title}>
          {i18n.t<string>('storedValueList.labels.title')}
        </div>
      </div>
      <div className={styles.filterArea}>
        <SearchBar
          model={{
            placeholder: i18n.t<string>('storedValueList.placeholders.searchbar'),
            search: onHandleSearch,
            defaultValue: state.searchString
          }}
        />
      </div>
      <div className={styles.contentArea}>
        <StickableBootstrapTable
          stickFirstColumn
          remote
          keyField={StoredValueListColumns.ID}
          data={state.agencies}
          columns={columns}
          noDataIndication={i18n.t<string>('storedValueList.labels.noData')}
          defaultSorted={[{
            dataField: StoredValueListColumns.ID,
            order: 'desc'
          }]}
          onTableChange={handleTableChange}
          pagination={{
            page: state.page,
            sizePerPage: state.size,
            totalSize: state.totalCount
          }}
        />
      </div>
    </div>
  );
};
