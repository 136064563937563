import { AgencyDetail, Currency, Language, PartnershipMode, TimeZone } from 'core';
import { toOptions } from 'utils/EnumUtil';
import { defaultCampaignBidPrice } from './defaultCampaignBidPrice';

const defaultAgency: AgencyDetail = {
  addonFeatures: {
    channel: {
      openRTB: true
    },
    creatives: {
      option_1: true,
      option_9: true,
      option_12: true
    },
    company: {
      advertiserViewSpentInReport: true,
      orderBudgetMaximum: false,
      allowOrderSettlement: true,
      fixedExchangeRate: true,
      storedValue: true,
      advAdminInfoRestriction: true
    },
    campaign: {
      fix_cpc: false,
      fix_cpm: false,
      revenueSharing: true,
      budgetDominate: true,
      bidPriceConstraint: true
    },
    report: {
      campaignDashboard: true
    },
    campaignLimitation: {
      spaceType: true,
      device: true
    }
  },
  addonProps: {
    orderBudgetMaximum: 10000,
    fixedExchangeRate: 30,
    campaignBidPrice: defaultCampaignBidPrice
  },
  agencyId: 0,
  comComment: '',
  companyName: '',
  vendorNumber: '',
  currency: Currency.NTD,
  language: Language.ZHTW,
  limitPublisher: [],
  noLimitSelfBuyStatus: false,
  priority: 1,
  selfBuy: false,
  targetBudgetMinimum: 0,
  campaignBudgetMinimum: 0,
  partnershipModeId: PartnershipMode.SELF_SERVING,
  timeZone: TimeZone.TAIPEI
};

const data = {
  defaultAgency,
  timeZoneOptions: toOptions(TimeZone),
  currencyOptions: toOptions(Currency),
  languageOptions: toOptions(Language)
};

export default data;
