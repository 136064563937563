import { CampaignAnalytics } from 'core/rtbCampaign/RtbCampaign';
import React from 'react';

export type CampaignSetupFlowDataContextType = {
  campaignAnalytics: CampaignAnalytics | undefined;
};

export const CampaignSetupFlowDataContext = React.createContext<CampaignSetupFlowDataContextType>({
  campaignAnalytics: undefined
});
