import { Creative, CreativeOfCampaign, CreativeType } from './Creative';
import { CreativesWithPagination, CreativeWebService, RestfulCreativeWebService, WaitingBindGetParams } from 'ws/CreativeWebService';
import { Pageable } from 'ws/Pageable';
import { BindingWebService, RestfulBindingWebService } from 'ws/BindingWebService';
import { L1ObjectChannel } from 'core/l1Object/L1Object';

export interface CreativeManager {
  getCreativesByCampaignId (campaignId: string | number, report?: boolean): Promise<CreativeOfCampaign[]>;
  getWaitingBindCreatives (payload: WaitingBindGetParams): Promise<CreativeOfCampaign[]>;
  reviewCreatives (creativeIds: Array<number>, adxs: Array<string>): Promise<void>;
  getCreative (creativeId: number): Promise<Creative>;
  getCreatives (creativeType: CreativeType, advertiserId: number | undefined, pageable: Pageable, search?: string): Promise<CreativesWithPagination>;
  createCreative (creative: FormData): Promise<{
    creativeId: number
  }>;
  createCreativesByJson (creatives: any): Promise<{
    data: any[],
    error: {
      error_message: string | null,
      name: string
    }[]
  } | undefined>;
  createCreativesByJsonAndBind (channel: L1ObjectChannel, creatives: any, campaignId: string): Promise<void>;
  updateCreative (creative: FormData): Promise<number>;
  cloneCreatives (creatives: Array<number>): Promise<void>;
  deleteCreative (creativeIds: Array<number>): Promise<void>;
  getCreativeName (creativeId: number): Promise<string | null>;
  getCreativeTypes (): CreativeType[];
}

export class DefaultCreativeManager implements CreativeManager {

  webService: CreativeWebService;

  constructor (
    webService: CreativeWebService = new RestfulCreativeWebService(),
    private bindingWebService: BindingWebService = new RestfulBindingWebService()
  ) {
    this.webService = webService;
  }

  async getCreativesByCampaignId (campaignId: string | number, report?: boolean): Promise<CreativeOfCampaign[]> {
    return this.webService.getCreativesByCampaignId(campaignId, report);
  }

  async getWaitingBindCreatives (payload: WaitingBindGetParams): Promise<CreativeOfCampaign[]> {
    return this.webService.getWaitingBindCreatives(payload);
  }

  async reviewCreatives (creativeIds: Array<number>, adxs: Array<string>): Promise<void> {
    return this.webService.reviewCreatives(creativeIds, adxs);
  }

  async getCreative (creativeId: number): Promise<Creative> {
    return this.webService.getCreative(creativeId);
  }

  async getCreatives (creativeType: CreativeType, advertiserId: number | undefined, pageable: Pageable, search?: string): Promise<CreativesWithPagination> {
    return this.webService.getCreatives(creativeType, advertiserId, pageable, search);
  }

  async createCreative (creative: FormData): Promise<{
    creativeId: number
  }> {
    return this.webService.createCreative(creative);
  }

  async createCreativesByJson (creatives: any): Promise<{
    data: any[],
    error: {
      error_message: string | null,
      name: string
    }[]
  } | undefined> {
    return this.webService.createCreativesByJson(creatives);
  }

  async updateCreative (creative: FormData): Promise<number> {
    return this.webService.updateCreative(creative);
  }

  async createCreativesByJsonAndBind (channel: L1ObjectChannel, creatives: any, campaignId: string): Promise<void> {
    const data = await this.webService.createCreativesByJson(creatives);
    if (!data) {
      return;
    }
    const creativeIds = data.data.map(newCreative => newCreative.creative.creativeId.toString());
    return this.bindingWebService.createBinding(channel, [{
      l2ChannelIds: [campaignId],
      creativeIds: creativeIds,
      active: true
    }]);
  }

  async cloneCreatives (creatives: Array<number>): Promise<void> {
    return this.webService.cloneCreatives(creatives);
  }

  async deleteCreative (creativeIds: Array<number>): Promise<void> {
    return this.webService.deleteCreative(creativeIds);
  }

  async getCreativeName (creativeId: number): Promise<string | null> {
    return this.webService.getCreativeName(creativeId);
  }

  getCreativeTypes (): CreativeType[] {
    const types = Object.keys(CreativeType).filter((item) => {
      return isNaN(Number(item));
    });
    return types.map(type => CreativeType[type]);
  }
}
