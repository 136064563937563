import React from 'react';
import { CreativePreview } from 'components/CreativePreview/CreativePreview';
import { DefaultCreativePreviewModel } from 'components/CreativePreview/CreativePreviewModel';
import { faPencilAlt, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import styles from './creativeList.module.scss';
import i18n from 'i18next';
import _ from 'lodash';
import { CreativeListModel } from './CreativeListModel';
import { Link } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { Status } from 'components/Status/Status';
import { CreativeReviews } from 'components/CreativeReview/CreativeReviews';

export const getCreativeListFormatters = (model: CreativeListModel) => {

  const creativeWritePermission = hasFuncs(Permission.CREATIVE_WRITE);

  const nameIdFormatter = (nameIdData: any, creativeData: any, _1, formatExtraData) => {
    const renderOtherInfo = _.get(formatExtraData, 'renderOtherInfo');
    if (creativeData.id === 0) {
      return <div>{i18n.t<string>('creativeList.labels.nameIdSummary', { count: nameIdData })}</div>;
    }
    const handleOnSelect = (e) => {
      model.handleOnSelect(nameIdData.id);
    };
    const renderLabel = () => <span className={styles.creativeName}>{nameIdData.name}</span>;
    return (
      <div className={styles.creativeCell}>
        <div className={styles.selectCheckBox}>
          <PermissionChecker permissionAware={creativeWritePermission}>
            <input
              type='checkbox'
              checked={creativeData.selected}
              onChange={handleOnSelect}
              id={`creativeListInput${nameIdData.id}`}
            />
            <label htmlFor={`creativeListInput${nameIdData.id}`} />
          </PermissionChecker>
        </div>
        <div className={styles.info}>
          <PermissionChecker
            permissionAware={creativeWritePermission}
            renderOtherwise={renderLabel}
          >
            <Link
              to={`/creatives/${nameIdData.id}/edit`}
              className={styles.creativeName}
            >
              {nameIdData.name}
            </Link>
          </PermissionChecker>
          <div className={styles.creativeID}>
            {`ID: ${nameIdData.id}`}
          </div>
          {
            renderOtherInfo && renderOtherInfo(creativeData)
          }
        </div>
      </div>
    );
  };

  const productIdRenderer = (productId: any) => {
    if (!productId) {
      return <div />;
    }
    return (
      <div className={styles.creativeID}>
        {`${i18n.t<string>('creativeList.labels.productID')}: ${productId}`}
      </div>
    );
  };

  const nameIdHeaderFormatter = (nameIdHeader: any, columnIndex: number, { sortElement }) => {
    return (
      <div className={styles.nameIdHeader}>
        <div className={styles.selectCheckBox}>
          <PermissionChecker permissionAware={creativeWritePermission}>
            <input
              type='checkbox'
              checked={model.state.viewModelData.length === model.state.selectedCreatives.length}
              ref={el => el && (el.indeterminate = model.state.viewModelData.length !== model.state.selectedCreatives.length && model.state.selectedCreatives.length > 0)}
              onChange={model.handleOnSelectAll}
              id={'creativeNameIdHeaderFormatter'}
            />
            <label htmlFor='creativeNameIdHeaderFormatter' />
          </PermissionChecker>
        </div>
        {i18n.t<string>(nameIdHeader.text)}
        {sortElement}
      </div>
    );
  };

  const previewFormatter = (previewData: any, creative: any) => {
    if (!previewData) {
      return <div />;
    }
    return (
      <div className={`${styles.creativeCell} ${styles.previewCell}`}>
        <div className={styles.creativeReview}>
          <CreativePreview model={new DefaultCreativePreviewModel(previewData)} />
        </div>
        <IconWithTooltip
          placement='right'
          className={styles.detailBtn}
          onClick={model.showCreativeDetailModal.bind(model, previewData)}
          icon={faEye}
          tooltipProps={{
            id: `creativeListPreviewHint-${creative.id}`,
            tooltip: i18n.t<string>('creativeList.labels.previewHint')
          }}
        />
      </div>
    );
  };

  const textFormatter = (text: any) => {
    if (!text) {
      return <div />;
    }
    return (
      <div className={styles.creativeCell}>
        <div className={styles.text}>
          {text}
        </div>
      </div>
    );
  };

  const urlFormatter = (url: any) => {
    if (!url) {
      return <div />;
    }
    return (
      <div className={styles.creativeCell}>
        <a href={url} target='_blank' rel='noopener noreferrer'>{url}</a>
      </div>
    );
  };

  const approvalFormatter = (creativeData: any) => {
    if (!creativeData) {
      return <div />;
    }

    return (
      <CreativeReviews
        showCreativeType={true}
        creativeData={creativeData}
        reviewCreative={model.reviewCreative.bind(model)}
      />
    );
  };

  const streamingFormatter = (streamingSetting: { inStream: boolean, outStream: boolean }) => {
    if (!streamingSetting) {
      return <div />;
    }
    let content = streamingSetting.inStream ?
      i18n.t<string>('creativeList.labels.inStream') :
      i18n.t<string>('creativeList.labels.outStream');
    if (streamingSetting.inStream && streamingSetting.outStream) {
      content = i18n.t<string>('creativeList.labels.unlimited');
    }
    return (
      <div className={styles.creativeCell}>
        <span style={{ whiteSpace: 'nowrap' }}>
          {content}
        </span>
      </div>
    );
  };

  const skipSettingFormatter = (skipSetting) => {
    if (!skipSetting) {
      return <div />;
    }
    const { skippable, nonSkippable, skipOffset } = skipSetting;
    return (
      <div className={styles.creativeCell}>
        {skippable &&
          <>
            {`${i18n.t<string>('creativeList.labels.skippable')} : ${i18n.t<string>('creativeList.labels.skipOffset', { offset: skipOffset })}`}
            <br />
          </>
        }
        {nonSkippable &&
          <>
            {i18n.t<string>('creativeList.labels.nonSkippable')}
            <br />
          </>
        }
        {(!skippable && !nonSkippable) && i18n.t<string>('creativeList.labels.nonSkippable')}
      </div>
    );
  };

  const floatingEditBtnsFormatter = (cell: any, creative: any): any => {
    const canNotDeleteCreative = _.get(creative, 'bindingCampaign', []).length > 0;
    return (
      <PermissionChecker permissionAware={hasFuncs(Permission.CREATIVE_WRITE).or(hasFuncs(Permission.CREATIVE_DEL))}>
        <TableRowToolBar className={styles.floatingEditArea}>
          <PermissionChecker permissionAware={creativeWritePermission}>
            <Link to={`/creatives/${creative.id}/edit`}>
              <IconWithTooltip
                icon={faPencilAlt}
                tooltipProps={{
                  id: `creativeListEditTip-${creative.id}`,
                  tooltip: i18n.t<string>('creativeList.labels.editHint')
                }}
              />
            </Link>
            <IconWithTooltip
              icon={faCopy}
              onClick={model.cloneCreatives.bind(model, [creative.id])}
              tooltipProps={{
                id: `creativeListCloneTip-${creative.id}`,
                tooltip: i18n.t<string>('creativeList.labels.cloneHint')
              }}
            />
          </PermissionChecker>
          <PermissionChecker permissionAware={hasFuncs(Permission.CREATIVE_DEL)}>
            <IconWithTooltip
              disabled={canNotDeleteCreative}
              icon={faTrashAlt}
              onClick={model.showDeleteCreativeModal.bind(model, [creative.id])}
              tooltipProps={{
                id: `creativeListDeleteTip-${creative.id}`,
                tooltip: canNotDeleteCreative ?
                  i18n.t<string>('creativeList.labels.cannotDeleteHint') :
                  i18n.t<string>('creativeList.labels.deleteHint')
              }}
            />
          </PermissionChecker>
        </TableRowToolBar>
      </PermissionChecker>
    );
  };

  const stateFormatter = status => {
    return (
      <Status
        label={status ?
          i18n.t<string>('campaignCreativeList.status.enable') :
          i18n.t<string>('campaignCreativeList.status.disable')}
        color={status ? 'success' : 'black'}
      />
    );
  };

  const productNativeStateFormatter = (status, creative) => {
    const stockAvailable = creative.stockAvailable;
    let label = status ?
      i18n.t<string>('campaignCreativeList.status.enable') :
      i18n.t<string>('campaignCreativeList.status.disable');
    let color = status ? 'success' : 'black';
    if (!stockAvailable) {
      label = i18n.t<string>('campaignCreativeList.status.outOfStock');
      color = 'black';
    }
    return (
      <Status
        label={label}
        color={color}
      />
    );
  };

  return {
    nameIdFormatter,
    previewFormatter,
    textFormatter,
    urlFormatter,
    approvalFormatter,
    floatingEditBtnsFormatter,
    nameIdHeaderFormatter,
    streamingFormatter,
    skipSettingFormatter,
    stateFormatter,
    productNativeStateFormatter,
    productIdRenderer
  };
};
