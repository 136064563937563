import { CreativeListRecord, CreativeOfCampaign } from 'core/creative/Creative';
import React from 'react';
import styles from './creativePreview.module.scss';

export const ProductNativeCreativePreview: React.FC<{creative: CreativeOfCampaign | CreativeListRecord}> = (props) => {

  const productData = props.creative.product;
  if (!productData) {
    return <div/>;
  }

  return (
    <div className={styles.normalCreativePreview}>
      <img src={productData.imgLink} alt={'preview'} />
    </div>
  );
};
