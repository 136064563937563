type mediaSummaryData = {
  typeProperties: any,
  medias: any
};

export const ProductNativeMediaSummary: React.FunctionComponent<{ mediaSummary: mediaSummaryData }> = (props) => {
  return (
    <div>
      {/* TODO: preview */}
    </div>
  );
};
