import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import React from 'react';
import styles from './titleWithBreadcrumbs.module.scss';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

export const TitleWithBreadcrumbs: React.FC<{
  title: string,
  routes: BreadcrumbsRoute[]
}> = ({
  title,
  routes
}) => {
  return (
    <div className={styles.titleWithBreadcrumbs}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.breadcrumbs}>
        <Breadcrumbs routes={routes}/>
      </div>
    </div>
  );
};
