import React, { Fragment } from 'react';
import styles from './creativeReviews.module.scss';
import { ADX, CreativeType } from 'core/creative/Creative';
import i18n from 'i18next';
import { CreativeReviewItem } from './CreativeReviewItem';
import _ from 'lodash';

export class CreativeReviews extends React.PureComponent<{
  showCreativeType: boolean;
  creativeData: any,
  reviewCreative: (adx, id) => void
}> {

  renderTitleRow = (sortedAdx) => {
    return (
      <>
        {this.props.showCreativeType && <span />}
        {sortedAdx.map(adx => {
          return <span key={adx}>{ADX[adx]}</span>;
        })}
      </>
    );
  }

  renderApprovalData = (key, data, sortedAdx) => {
    const creativeId = data.creativeId;
    const reviewData = data.reviewData;
    return (
      <Fragment key={key}>
        {this.props.showCreativeType && <span className={styles.title}>{key}</span>}
        {sortedAdx.map(adx => {
          const reviewCreative = (id) => {
            this.props.reviewCreative(adx, id);
          };
          const state = _.get(reviewData[adx], 'state');
          if (_.isNil(state)) {
            return <div key={adx} style={{ textAlign: 'center' }}>{i18n.t<string>('common.labels.noData')}</div>;
          }
          return (
            <CreativeReviewItem
              key={adx}
              adx={adx}
              approvalData={reviewData[adx]}
              creativeId={creativeId}
              reviewCreative={reviewCreative}
            />
          );
        })}
      </Fragment>
    );
  }

  render () {
    const creativeData = this.props.creativeData;
    const approvalStatus = creativeData.approvalStatus;
    if (!approvalStatus || Object.keys(approvalStatus).length === 0) {
      return <div />;
    }

    let approvalData = {
      [i18n.t<string>(`creativeList.labels.approvalDefault${creativeData.creativeType}`)]: {
        creativeId: creativeData.creativeId,
        reviewData: approvalStatus
      }
    };

    const adxs: string[] = [];
    Object.values(approvalData).forEach(data => {
      Object.keys(data.reviewData).forEach(key => {
        if (!(adxs.includes(key))) {
          adxs.push(key);
        }
      });
    });

    const typeClass = CreativeType[creativeData.creativeType] ? styles[CreativeType[creativeData.creativeType].toLowerCase()] : '';
    return (
      <div className={`${styles.approvalData} ${typeClass} ${styles[`count${adxs.length + (this.props.showCreativeType ? 1 : 0)}`]}`}>
        {this.renderTitleRow(adxs)}
        {Object.keys(approvalData).map(key => {
          return this.renderApprovalData(key, approvalData[key], adxs);
        })}
      </div>
    );
  }
}
