import React from 'react';
import i18n from 'i18next';
import { ChooseAdTypeStepProps, ChooseAdTypeStepState } from './ChooseAdTypeStepModel';
import { CampaignAdTypeButton } from '../CampaignAdTypeButton/CampaignAdTypeButton';
import styles from './chooseAdTypeStep.module.scss';
import { Modal } from 'components/Modal/Modal';
import { alertMessage } from 'components/AlertDialog';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

export class ChooseAdTypeStep extends React.Component<ChooseAdTypeStepProps, ChooseAdTypeStepState> {

  handler?: number;
  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps: ChooseAdTypeStepProps) {
    if (prevProps.model !== this.props.model) {
      this.handler && prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  renderButtons () {
    const buttonModels = this.props.model.getAdTypeButtonModels();
    const buttonsClass = cx(styles.buttons, {
      center: buttonModels.length === 1
    });
    if (buttonModels.length === 0) {
      return alertMessage(i18n.t<string>('common.notice'), i18n.t<string>('campaign.labels.noSupportedAdType'), this.props.model.backToL1ObjectDetail);
    }
    return (
      <div className={buttonsClass}>
        {buttonModels.map((model, index) => (
          <CampaignAdTypeButton key={index} model={model}/>
        ))}
      </div>
    );
  }

  render () {
    const modalData = this.props.model.state.changeAdTypeAlertModal;
    return (
      <div className={styles.chooseAdTypeStep}>
        <div className={styles.title}>
          {i18n.t<string>('campaign.labels.adTypePageTitle')}
        </div>
        <div className={styles.buttonArea}>
          {this.renderButtons()}
        </div>
        {
          modalData &&
          <Modal
            title={i18n.t<string>(modalData.title)}
            primaryButton={modalData.primaryBtn && {
              title: modalData.primaryBtn.title,
              callback: modalData.primaryBtn.callback
            }}
            secondaryButton={modalData.secondaryBtn && {
              title: modalData.secondaryBtn.title,
              callback: modalData.secondaryBtn.callback
            }}
          >
            {modalData.message}
          </Modal>
        }
      </div>
    );
  }
}
