import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { ADVERTISER_COLUMNS, advertiserListColumnSettings } from './advertiserListColumnSettings';
import _ from 'lodash';
import { AdvertisersWithPagination } from 'ws/AdvertiserWebService';
import { Pagination } from 'core/pagination/Pagination';
import { SortableList, AbstractSortableList } from 'containers/Common/AbstractSortableList';

export interface AdvertiserListModel extends SortableList {
  readonly agencyId?: number;
  readonly state: AdvertiserListState;
  readonly event: UpdateEventListener<AdvertiserListModel>;
  readonly columnSettings: Array<any>;
  readonly searchString: string;
  init (page?: number): Promise<void>;
  handleOnSearch (searchString: string);
  sortAdvertisers (field, direction): void;
  onUnmount (handler: number): void;
}

export type AdvertiserListProps = {
  readonly model: AdvertiserListModel;
};

export type AdvertiserListState = {
  readonly loading: boolean;
  readonly viewModelData: Array<any>;
  readonly pagination?: Pagination;
};

export class DefaultAdvertiserListModel extends AbstractSortableList implements AdvertiserListModel {
  event: FireableUpdateEventListener<AdvertiserListModel>;
  loading: boolean;
  advertiserManager: AdvertiserManager;
  advertiserWithPagination?: AdvertisersWithPagination;
  columnSettings: Array<any>;
  viewModelData: Array<any>;
  agencyId?: number;
  searchString: string;
  debouncedInit: any;
  pagination?: Pagination;

  constructor (agencyId: number | undefined, advertiserManager: AdvertiserManager = new DefaultAdvertiserManager()) {
    super(ADVERTISER_COLUMNS.NAMEID, 'desc');
    this.event = new FireableUpdateEventListener<AdvertiserListModel>();
    this.loading = true;
    this.agencyId = agencyId;
    this.advertiserManager = advertiserManager;
    this.columnSettings = advertiserListColumnSettings();
    this.searchString = '';
    this.debouncedInit = _.debounce(this.init.bind(this), 1000);
    this.viewModelData = [];
  }

  get state (): AdvertiserListState {
    return {
      loading: this.loading,
      viewModelData: this.viewModelData,
      pagination: this.pagination
    };
  }

  async init (page: number | undefined = 1) {
    this.updateState(true);
    try {
      let sort;
      switch (this.sortField) {
        // case ADVERTISER_COLUMNS.CATEGORY:
        //   sort = 'company.category';
        //   break;
        // case ADVERTISER_COLUMNS.WEBSITE:
        //   sort = 'company.comAddress';
        //   break;
        default:
          sort = this.sortField;
      }
      const pageable = { page, sizePerPage: 10, sort, direction: this.sortOrder };
      this.advertiserWithPagination = await this.advertiserManager.getAdvertisers(this.agencyId, pageable, this.searchString);
      this.pagination = this.advertiserWithPagination.pagination;
      this.updateViewModelData();
    } catch (e) {
      this.updateState(false);
    }
  }

  sortAdvertisers = (field, direction) => {
    const currentSortSetting = this.sortDescriptor[0];
    if (field === currentSortSetting.dataField && direction === currentSortSetting.order) {
      return;
    }
    this.handleSort(field, direction);
    this.init();
  }

  handleOnSearch = (searchString: string) => {
    this.searchString = searchString;
    if (this.searchString === '') {
      this.debouncedInit && this.debouncedInit.cancel();
      this.init();
    } else {
      this.debouncedInit();
    }
  }

  updateViewModelData () {
    if (!this.advertiserWithPagination) {
      return {};
    }
    this.viewModelData = this.advertiserWithPagination.advertisers.map(advertiser => {
      return {
        name: advertiser.advertiserName,
        [ADVERTISER_COLUMNS.NAMEID]: advertiser.id
        // [ADVERTISER_COLUMNS.WEBSITE]: advertiser.website,
        // [ADVERTISER_COLUMNS.CATEGORY]: advertiser.category
      };
    });
    this.updateState();
  }

  onUnmount (handler: number): void {
    this.event.remove(handler);
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
