import { LoadingIndicator } from 'components/LoadingIndicator';
import React, { useCallback } from 'react';
import { StoredValueEditType, StoredValueLogColumns, useStoredValueDetailModel } from './StoredValueDetailModel';
import styles from './storedValueDetail.module.scss';
import i18n from 'i18n';
import { InfoDisplaySection } from 'components/InfoDisplaySection/InfoDisplaySection';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import { StoredValueEditModal } from '../StoredValueEditModal/StoredValueEditModal';
import { useStoredValueAddModalModel, useStoredValueEditModalModel } from '../StoredValueEditModal/StoredValueEditModalModel';

export const StoredValueDetail: React.FC = () => {

  const {
    agencyId,
    loading,
    columns,
    storedValueLog,
    storedValueInfo,
    usedValueInfo,
    editType,
    remainingValue,
    agencyInfo,
    setEditType,
    onEditValueModalClose
  } = useStoredValueDetailModel();

  const agencyName = agencyInfo.name;
  const currency = agencyInfo.currency;
  const onClickAddValue = useCallback(() => {
    setEditType(StoredValueEditType.ADD);
  }, [setEditType]);
  const onClickEditValue = useCallback(() => {
    setEditType(StoredValueEditType.EDIT);
  }, [setEditType]);

  if (!storedValueLog) {
    return <LoadingIndicator />;
  }

  return (
    <div className={styles.storedValueDetail}>
      {loading && <LoadingIndicator />}
      {editType !== StoredValueEditType.NONE &&
        <StoredValueEditModal
          agencyId={agencyId}
          agencyName={agencyName}
          currency={currency}
          remainingValue={remainingValue}
          onClose={onEditValueModalClose}
          useModel={
            editType === StoredValueEditType.ADD ?
            useStoredValueAddModalModel :
            useStoredValueEditModalModel
          }
        />
      }
      <div className={styles.title}>
        {i18n.t<string>('storedValueDetail.labels.title', { agencyName })}
      </div>
      <div className={styles.titleBottomLine} />
      <div className={styles.detailArea}>
        <div className={styles.topArea}>
          <div className={styles.buttonArea}>
            <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
              <div onClick={onClickAddValue}>
                {i18n.t<string>('storedValueDetail.buttons.addValue')}
              </div>
              <div onClick={onClickEditValue}>
                {i18n.t<string>('storedValueDetail.buttons.editValue')}
              </div>
            </PermissionChecker>
          </div>
        </div>
        <InfoDisplaySection data={storedValueInfo} columnWidth={300} />
        <div className={styles.sepLine} />
        <InfoDisplaySection data={usedValueInfo} columnWidth={300} />
      </div>
      <div className={styles.table}>
        <div className={styles.tableTitle}>
          {i18n.t<string>('storedValueLog.labels.title')}
        </div>
        <StickableBootstrapTable
          stickFirstColumn
          keyField='storedValueId'
          data={storedValueLog}
          columns={columns}
          noDataIndication={i18n.t<string>('storedValueLog.labels.noData')}
          defaultSorted={[{
            dataField: StoredValueLogColumns.DATE,
            order: 'desc'
          }]}
        />
      </div>
    </div>
  );
};
