import React from 'react';
import { DatePicker as NativeDatePicker, DatePickerProps } from 'components/DatePicker/DatePicker';
import { FormikFieldProps, withFormikField } from 'hoc/withFormikField';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';

interface DatePickerContentProps extends Omit<DatePickerProps, 'date'> {
  value: any;
  error?: any;
}

export interface FormikDatePickerProps extends FormikFieldProps, Omit<DatePickerProps, 'disabled' | 'onChange' | 'date' | 'hasError'> {}
export interface FormikDatePickerWithFieldLayoutProps extends Omit<DatePickerProps, 'disabled' | 'onChange' | 'date' | 'hasError'>, FormikFieldProps, Omit<FieldLayoutProps, 'name'> {}
export type FormikDatePickerFieldProps = ({ withFieldLayout?: true } & FormikDatePickerWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikDatePickerProps);

const DatePickerContent: React.FC<DatePickerContentProps> = ({
  value,
  error,
  onChange,
  ...props
}: DatePickerContentProps) => {

  const hasError = error !== undefined;
  return (
    <NativeDatePicker
      {...props}
      onChange={onChange}
      date={value}
      hasError={hasError}
    />
  );
};

const MemorizedDatePickerContent = React.memo(DatePickerContent);

// export const DatePickerField = withFieldLayout<FieldLayoutProps & DatePickerContentProps>(DatePickerContent);
export const FormikDatePicker = withFormikField<FormikDatePickerProps>(MemorizedDatePickerContent);
export const FormikDatePickerWithFieldLayout = withFormikField<FormikDatePickerWithFieldLayoutProps>(withFieldLayout(DatePickerContent));

export const FormikDatePickerField: React.FC<FormikDatePickerFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikDatePicker {...props} />;
  } else {
    return <FormikDatePickerWithFieldLayout {...props} />;
  }
};
