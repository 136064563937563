import { L1ObjectFormChannelModel } from './L1ObjectFormChannelModel';
import { L1Object, L1ObjectChannel, L1ObjectObjective } from 'core/l1Object/L1Object';
import { Order } from 'core/order/Order';
import _, { defaultTo } from 'lodash';
import { BidStrategy } from 'core/l2Object/L2Object';
import { DefaultL1ObjectManager } from 'core/l1Object/L1ObjectManager';

export abstract class L1ObjectCreateFormChannelModel implements L1ObjectFormChannelModel {

  constructor (
    protected order: Order,
    protected l1ObjectManager = new DefaultL1ObjectManager()
  ) {}

  abstract get minBudget ();

  abstract get canEditBidStrategy ();

  abstract init (callback: (data) => void);

  abstract validate (value: Partial<L1Object>);

  abstract initChannelData (valueUpdater: (updateFunc: (prevValue: any) => any) => void);

  abstract showRemainBudget (values: Partial<L1Object>);

  abstract toChannelNativeData (l1Object: L1Object, extraData: any): any;

  getDefaultBidStrategy = () => BidStrategy.LOWEST_COST_WITHOUT_CAP;
}
export class RTBCampaignGroupCreateFormModel extends L1ObjectCreateFormChannelModel {

  get minBudget () {
    return this.order.campaignConstraint.budgetMinimum;
  }

  get canEditBidStrategy () {
    return false;
  }

  init = async (callback: (data) => void) => {};

  initChannelData (valueUpdater: (updateFunc: (prevValue: any) => any) => void) {
    valueUpdater(prev => {
      const prevRtb = defaultTo(prev.rtb, {});
      return {
        ...prev,
        objective: L1ObjectObjective.TRAFFIC,
        rtb: {
          ...prevRtb,
          bid_strategy: undefined
        }
      };
    });
  }

  validate = (value: Partial<L1Object>) => {
    return {};
  }

  getDefaultBidStrategy = () => BidStrategy.LOWEST_COST_WITH_BID_CAP;

  showRemainBudget (values: Partial<L1Object>) {
    return true;
  }

  toChannelNativeData (l1Object: L1Object, extraData: any) {
    const order = extraData.order;
    const l1ObjectObjective = l1Object.objective;
    const objective = this.l1ObjectManager.getNativeObjectiveValue(L1ObjectChannel.RTB, l1ObjectObjective);
    return {
      ...l1Object,
      objective,
      l1ObjectLifetimeBudget: _.isNil(l1Object.budget) ? 0 : l1Object.budget,
      adsOrderId: order.id
    };
  }
}
