import { BasicFormProps } from './BasicFormProps';
import { AbstractFormContentModel, CreativeFormData } from './FormContentModel';
import _ from 'lodash';
import i18n from 'i18next';
import { Creative } from 'core/creative/Creative';
import ProductNativeForm from './ProductNativeForm';
import { Product } from 'core/product/Product';
import { ProductFilter } from 'containers/RetailRMN/Products/ProductFilter';
import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModelInterface';

export type ProductNativeFormProps = {
  readonly model: ProductNativeFormModel;
} & BasicFormProps;

export class ProductNativeFormModel extends AbstractFormContentModel {

  public needBannerUrl: boolean = false;
  selectedProducts: Product[];
  filterSet?: ProductFilter;

  constructor (
    public flowPageModel: CreativeSetupFlowPageModel,
    public products: Product[] = []
  ) {
    super(flowPageModel);
    this.selectedProducts = [...products];
  }

  getInitTypeProperties (): any {
    return {
      products: this.products
    };
  }

  getFormContent = (): any => {
    return ProductNativeForm;
  }

  getFormModelData (creative: Creative): CreativeFormData {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    const typeProperties = {
      product: _.get(creativeBasic, 'product')
    };
    return {
      basic: {
        ...creativeBasic,
        typeProperties
      }
    };
  }

  validateProducts = () => {
    if (this.products.length === 0) {
      return i18n.t<string>('productNativeCreativeForm.errors.cannotBeEmpty');
    }
    return undefined;
  }

  setProductListModal = (modalData: any): void => {
    this.flowPageModel.setModalData(modalData);
  }

  refreshModalData = (): void => {
    this.selectedProducts = [...this.products];
    this.setProductListModal(undefined);
  }

  handleProductsButtonClick = (modalData: any) => {
    this.refreshModalData();
    this.setProductListModal(modalData);
  }

  handleSelectedProducts = (fn: (selectedProducts: Product[]) => Product[]): void => {
    this.selectedProducts = fn(this.selectedProducts);
  }

  handleFilterChanged = (filterSet: ProductFilter): void => {
    this.filterSet = filterSet;
  }

  handleRemoveProduct = (values: any): void => {
    const remainProductsId: string[] = _.map(values, value => value.value.toString());
    this.products = _.filter(this.products, product => remainProductsId.includes(product.productId.toString()));
    this.refreshModalData();
  }

  onModalConfirm = () => {
    this.products = [...this.selectedProducts];
    this.refreshModalData();
  }

  onModalCancel = () => {
    this.refreshModalData();
  }
}
