import _ from 'lodash';
import { Account, AccountManager, Actor, AuthenticationManager, DefaultAccountManager, RoleNames } from 'core';

import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { validateEmail, validateEmpty } from 'utils/ValidateUtils';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import { SelectOptions } from 'components/commonType';
import { AdvertiserRole, AgencyRole } from 'core/companyMember/CompanyRole';
import * as SelectOptionsUtils from 'utils/SelectOptionsUtils';

export interface AccountFormValues {
  name: string;
  email: string;
  isAdmin: boolean;
  activated: boolean;
  agencyId?: number;
  role?: string;
}

export interface FormikSubmitModel {
  readonly isSubmit: 'FORMIK_SUBMIT' | 'PRE_SUBMIT' | 'POST_SUBMIT';
}

export type AccountFormState = {

  readonly loading: boolean;
  readonly error: Error | null;
  readonly redirectPath: string | null;
};

export interface AccountFormModel {

  readonly title: string;
  readonly cancelPath: string;
  readonly emailEditable: boolean;
  readonly activatedEditable: boolean;
  readonly isAdminEditable: boolean;
  readonly isModalContent: boolean;
  readonly agencyOptions?: SelectOptions[];

  readonly state: AccountFormState;
  readonly event: UpdateEventListener<AccountFormModel>;
  readonly formikEvent: UpdateEventListener<FormikSubmitModel>;
  readonly roleOptions: SelectOptions[];
  readonly needAssignRoleHint: boolean;

  shouldUpdateModel (params: any);
  init (): Promise<void>;
  dismiss (): void;
  initialValues (): AccountFormValues;
  validate (values: AccountFormValues): any;
  submit (values: AccountFormValues): Promise<void>;
  submitAndValide (): void;
  onUnmount (eventKey?: number, formikEventKey?: number): void;
}

export type AccountFormProps = {

  readonly model: AccountFormModel;
};

const defaultValues: AccountFormValues = {
  name: '',
  email: '',
  isAdmin: false,
  activated: true
};

function validateName (name: string): string | null {
  return _.isEmpty(name) ? i18n.t<string>('accounts.form.messages.nameRequired') : null;
}
abstract class AbstractAccountForm {
  error: Error | null;
  loading: boolean;
  redirectPath: string | null;

  manager: AccountManager;
  event: FireableUpdateEventListener<AccountFormModel>;
  modelFormikEvent: FireableUpdateEventListener<FormikSubmitModel>;

  constructor (manager: AccountManager) {
    this.error = null;
    this.loading = false;
    this.manager = manager;
    this.redirectPath = null;
    this.event = new FireableUpdateEventListener<AccountFormModel>();
    this.modelFormikEvent = new FireableUpdateEventListener<FormikSubmitModel>();
  }

  get state (): AccountFormState {
    return {
      error: this.error,
      loading: this.loading,
      redirectPath: this.redirectPath
    };
  }

  get formikEvent (): UpdateEventListener<FormikSubmitModel> {
    return this.modelFormikEvent;
  }

  get isAdminEditable () {
    return true;
  }

  get roleOptions (): SelectOptions[] {
    return [];
  }

  abstract model (): AccountFormModel;

  abstract get isModalContent ();

  abstract get needAssignRoleHint ();

  preSubmit () {
    if (this.isModalContent) {
      this.modelFormikEvent.fireEvent({ isSubmit: 'PRE_SUBMIT' });
    }
    this.updateState(true);
  }

  postSubmit (redirectPath: string | undefined = '/accounts') {
    if (this.isModalContent) {
      this.updateState(false);
      this.modelFormikEvent.fireEvent({ isSubmit: 'POST_SUBMIT' });
    } else {
      this.updateState(false, null, redirectPath, 'common.messages.succeeded');
    }
  }

  submitAndValide () {
    this.modelFormikEvent.fireEvent({ isSubmit: 'FORMIK_SUBMIT' });
  }

  dismiss () {
    this.error = null;
    this.event.fireEvent(this.model());
  }

  updateState (loading: boolean, error: any = null, redirectPath: string | null = null, message: string | null = null) {
    this.error = error;
    message && toast.success(i18n.t<string>(message));
    this.loading = loading;
    this.redirectPath = redirectPath;
    this.event.fireEvent(this.model());
  }

  onUnmount (eventKey?: number, formikEventKey?: number): void {
    this.redirectPath = null;
    eventKey && this.event.remove(eventKey);
    formikEventKey && this.formikEvent.remove(formikEventKey);
  }
}

export class CreateAccountForm extends AbstractAccountForm implements AccountFormModel {

  agencyOptions?: SelectOptions[];
  cancelPath: string;

  constructor (
    cancelPath: string | undefined = '',
    manager: AccountManager = new DefaultAccountManager()
  ) {
    super(manager);
    this.cancelPath = cancelPath;
  }

  get isModalContent () {
    return false;
  }

  get title (): string {
    return 'accounts.form.titles.new';
  }

  get emailEditable (): boolean {
    return true;
  }

  get activatedEditable (): boolean {
    return false;
  }

  get needAssignRoleHint (): boolean {
    return true;
  }

  model (): AccountFormModel {
    return this;
  }

  initialValues (): AccountFormValues {
    return defaultValues;
  }

  validate (values: AccountFormValues): any {
    return _.omitBy({
      email: validateEmail(values.email),
      name: validateName(values.name)
    }, _.isNil);
  }

  async init () {
    return;
  }

  async submit (values: AccountFormValues) {
    this.preSubmit();
    try {
      await this.manager.createAccount({
        name: values.name,
        email: values.email,
        isAdmin: values.isAdmin
      });
      this.postSubmit();
    } catch (error) {
      this.updateState(false, error);
    }
  }

  shouldUpdateModel (params) {
    return false;
  }
}

export class CreateAgencyAccountForm extends CreateAccountForm implements AccountFormModel {

  agencyId: number | string;
  actor: Actor | null;

  constructor (
    agencyId: number | string,
    actor: Actor | null,
    cancelPath: string | undefined = '',
    manager: AccountManager = new DefaultAccountManager()
  ) {
    super(cancelPath, manager);
    this.agencyId = agencyId;
    this.actor = actor;
  }

  get isModalContent () {
    return true;
  }

  get roleOptions () {
    const permittedOptions = SelectOptionsUtils.createSelectOptionsFromEnum(
      AgencyRole, 'agency.labels.'
    );
    return permittedOptions;
  }

  get needAssignRoleHint (): boolean {
    return false;
  }

  initialValues (): AccountFormValues {
    return {
      ...defaultValues,
      role: ''
    };
  }

  validate (values: AccountFormValues): any {
    const basicError = super.validate(values);

    return _.omitBy({
      ...basicError,
      role: validateEmpty(values.role)
    }, _.isNil);
  }

  async submit (values: AccountFormValues) {
    this.preSubmit();
    try {
      await this.manager.createAgencyAccount(this.agencyId, {
        name: values.name,
        email: values.email,
        isAdmin: values.isAdmin,
        role: values.role
      });
      this.postSubmit();
    } catch (error) {
      this.updateState(false, error);
    }
  }
}

export class CreateAdvertiserAccountForm extends CreateAccountForm implements AccountFormModel {

  advertiserId: number | string;

  constructor (
    advertiserId: number | string,
    cancelPath: string | undefined = '',
    manager: AccountManager = new DefaultAccountManager()
  ) {
    super(cancelPath, manager);
    this.advertiserId = advertiserId;
  }

  get isModalContent () {
    return true;
  }

  get roleOptions (): SelectOptions[] {
    return SelectOptionsUtils.createSelectOptionsFromEnum(
      AdvertiserRole, 'advertiserMemberForm.labels.'
    );
  }

  get needAssignRoleHint (): boolean {
    return false;
  }

  initialValues (): AccountFormValues {
    return {
      ...defaultValues,
      role: ''
    };
  }

  validate (values: AccountFormValues): any {
    const basicError = super.validate(values);

    return _.omitBy({
      ...basicError,
      role: validateEmpty(values.role)
    }, _.isNil);
  }

  async submit (values: AccountFormValues) {
    this.preSubmit();
    try {
      await this.manager.createAdvertiserAccount(this.advertiserId, {
        name: values.name,
        email: values.email,
        isAdmin: values.isAdmin,
        role: values.role
      });
      this.postSubmit();
    } catch (error) {
      this.updateState(false, error);
    }
  }
}

export class EditAccountForm extends AbstractAccountForm implements AccountFormModel {
  accountId: number;
  account: Account | null;
  rootPath: string = '/accounts';

  constructor (
    accountId: number,
    private authenticationManager: AuthenticationManager,
    manager: AccountManager = new DefaultAccountManager()
  ) {
    super(manager);
    this.account = null;
    this.accountId = accountId;
  }

  get isModalContent () {
    return false;
  }

  get title (): string {
    return 'accounts.form.titles.edit';
  }

  get cancelPath (): string {
    return `${this.rootPath}/${this.accountId}`;
  }

  get emailEditable (): boolean {
    return false;
  }

  get activatedEditable (): boolean {
    return true;
  }

  get needAssignRoleHint (): boolean {
    return false;
  }

  model (): AccountFormModel {
    return this;
  }

  initialValues (): AccountFormValues {
    return this.account ? {
      name: this.account.name,
      email: this.account.email,
      isAdmin: this.account.isAdmin,
      activated: this.account.activated
    } : defaultValues;
  }

  validate (values: AccountFormValues): any {
    return _.omitBy({
      name: validateName(values.name)
    }, _.isNil);
  }

  async init () {
    this.updateState(true);
    try {
      this.account = await this.manager.getAccount(this.accountId);
      this.updateState(false);
    } catch (error) {
      // use redirect to reload form
      this.updateState(false, error, `${this.rootPath}/${this.accountId}/edit`);
    }
  }

  async submit (values: AccountFormValues) {
    this.preSubmit();
    try {
      await this.manager.updateAccount({
        id: this.accountId,
        name: values.name,
        isAdmin: values.isAdmin,
        activated: values.activated
      });

      // log out if system admin remove admin role by self
      const currentAccount = this.authenticationManager.account;
      const isAdmin = this.authenticationManager.actor ?
        this.authenticationManager.actor.roleName === RoleNames.sysAdmin :
        false;
      if (isAdmin &&
        currentAccount &&
        currentAccount.id.toString() === this.accountId.toString() &&
        !values.isAdmin) {
        this.authenticationManager.logout();
      } else {
        this.postSubmit(`${this.rootPath}/${this.accountId}`);
      }
    } catch (error) {
      this.updateState(false, error);
    }
  }

  shouldUpdateModel (params) {
    return params.accountId !== this.accountId;
  }
}
