import React from 'react';
import styles from './productNativeCreativeDetail.module.scss';
import { CreativeBasic } from 'core/creative/Creative';

export const ProductNativeCreativeDetail: React.FC<{creative: Partial<CreativeBasic>}> = (props) => {
  const productData = props.creative.product;
  if (!productData) {
    return <div/>;
  }
  return (
    <div className={styles.productNativeCreativeDetailContainer}>
      <img src={productData.imgLink} alt={'preview'} />
    </div>
  );
};
