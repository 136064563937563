export type OAuthPCHState = {
  state: string;
  hmacState: string;
};

export type OAuthPCHException = {
  error: string;
  errorDescription: string;
};

export enum OAUTH_PCH_ERROR {
  INVALID_REQUEST = 'invalid_request',
  UNSUPPORTED_RESPONSE_TYPE = 'unsupported_response_type',
  UNAUTHORIZED_CLIENT = 'unauthorized_client',
  INVALID_SCOPE = 'invalid_scope',
  INVALID_CLIENT = 'invalid_client',
  ACCESS_DENIED = 'access_denied',
  INVALID_URI = 'invalid_uri',
  REDIRECT_URI_MISMATCH = 'redirect_uri_mismatch'
}
