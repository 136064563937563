import _ from 'lodash';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AccountManager, DefaultAccountManager, AuthenticationManager, Account } from 'core';
import { renderColumn, ColumnDefinition, SortDescriptor, sortableColumn } from 'components/TableColumn/TableColumn';
import { sudoNameFormatter } from './sudoListFormatters';
import { SearchBarModel } from 'components/SearchBar';

export const ACCOUNT_TYPE = {
  PLATFORM: 'platformAccounts'
};

export enum SudoListColumns {
  ACCOUNT_ID = 'accountId',
  NAME = 'name',
  EMAIL = 'email',
  COMPANY_NAME = 'companyName'
}

export const SudoListSortFieldsMapping = {
  [SudoListColumns.ACCOUNT_ID]: 'id',
  [SudoListColumns.NAME]: 'accName',
  [SudoListColumns.EMAIL]: 'login',
  [SudoListColumns.COMPANY_NAME]: 'agencyId'
};

export interface SudoModel extends SearchBarModel {
  readonly state: SudoModelState;
  readonly accounts: Array<Account>;
  readonly event: UpdateEventListener<SudoModel>;

  sudo (): void;
  getActiveAccount (): void;
  resetState (): void;
  changeTab (tabKey: string | null): void;
}

export type SudoModelProps = {
  readonly model: SudoModel;
};

export type SudoModelState = {
  readonly loading: boolean;
  readonly isSubmitted: boolean;
  readonly accountId?: number;
  readonly activeNav: string;
  readonly searchText: string;
  readonly sortField: string;
  readonly sortOrder: 'asc' | 'desc';
  readonly page: number;
  readonly size: number;
  readonly totalCount: number;
};

export class DefaultSudoModel implements SudoModel, SearchBarModel {
  modelCurrentList: Array<Account> = [];
  modelLoading: boolean = false;
  event: FireableUpdateEventListener<SudoModel> = new FireableUpdateEventListener<SudoModel>();
  placeholder: string = 'sudo.placeholders.searchbar';
  modelAccountId?: number;
  defaultValue?: string;
  debouncedFetch: any = _.debounce(this.getActiveAccount.bind(this), 1000);
  modelIsSubmitted: boolean = false;
  modelActiveNav: string = ACCOUNT_TYPE.PLATFORM;
  modelSearchText: string = '';
  modelSortField: string = SudoListSortFieldsMapping[SudoListColumns.ACCOUNT_ID];
  modelSortOrder: 'asc' | 'desc' = 'asc';
  modelPage: number = 1;
  modelSize: number = 20;
  modelTotalCount: number = 0;

  constructor (private authManager: AuthenticationManager, private accountManager: AccountManager = new DefaultAccountManager()) {
  }

  async search (keyword: string) {
    this.modelSearchText = keyword;
    if (this.modelSearchText === '') {
      this.debouncedFetch && this.debouncedFetch.cancel();
      await this.getActiveAccount();
    } else {
      this.debouncedFetch();
    }
  }

  async getActiveAccount (): Promise<void> {
    this.notify(true);
    // fetch all accounts only when search text is not empty
    if (this.modelSearchText === '') {
      this.modelCurrentList = [];
      this.modelPage = 1;
      this.modelSize = 20;
      this.modelTotalCount = 0;
      this.notify(false);
      return;
    }

    const platformAccounts = await this.accountManager.getAccountsWithPagination(this.modelPage, this.modelSize, this.modelSortField, this.modelSortOrder, this.modelSearchText);
    this.modelCurrentList = platformAccounts.accounts.filter(account => account.activated);
    this.modelPage = platformAccounts.pagination.page;
    this.modelSize = platformAccounts.pagination.size;
    this.modelTotalCount = platformAccounts.pagination.totalCount;
    this.notify(false);
  }

  async sudo () {
    if (!this.state.accountId) {
      return;
    }
    this.notify(true);
    await this.authManager.sudo(this.state.accountId);
    this.modelIsSubmitted = true;
    this.notify(false);
  }

  get accounts (): Array<Account> {
    return this.modelCurrentList;
  }

  get noDataDescription (): string {
    return 'accounts.placeholder.noDataAvailable';
  }

  getColumnDefinition (columnName: string): ColumnDefinition {
    return {
      ...sortableColumn(columnName, `accounts.headers.${columnName}`)
    };
  }

  changeTab = async (tabKey: string | null) => {
    if (tabKey === null || tabKey === this.modelActiveNav) {
      return;
    }
    this.modelActiveNav = tabKey;
    await this.getActiveAccount();
  }

  onSort = async (field, direction) => {
    const sortField = _.defaultTo(SudoListSortFieldsMapping[field], SudoListSortFieldsMapping[SudoListColumns.ACCOUNT_ID]);
    if (sortField === this.modelSortField && direction === this.modelSortOrder) {
      return;
    }
    this.modelPage = 1;
    this.modelSortField = sortField;
    this.modelSortOrder = direction;
    await this.getActiveAccount();
  }

  onChangePage = async (page) => {
    if (page === this.modelPage) {
      return;
    }
    this.modelPage = page;
    await this.getActiveAccount();
  }

  chooseAccount = ({ target }) => {
    const targetAccountId = parseInt(target.value, 10);
    this.modelAccountId = this.modelAccountId === targetAccountId ? undefined : targetAccountId;
    this.event.fireEvent(this);
  }

  setIsSubmitted (isSubmitted: boolean) {
    this.modelIsSubmitted = isSubmitted;
  }

  resetState () {
    this.modelAccountId = undefined;
    this.modelIsSubmitted = false;
    this.modelSearchText = '';
    this.modelActiveNav = ACCOUNT_TYPE.PLATFORM;
    this.modelCurrentList = [];
    this.modelPage = 1;
    this.modelSize = 20;
    this.modelTotalCount = 0;
  }

  get columns () {
    const nameFormatter = _.curry(sudoNameFormatter)(this.chooseAccount);
    const columns = [
      renderColumn({ ...this.getColumnDefinition(SudoListColumns.NAME), 'formatExtraData': this.state }, nameFormatter),
      renderColumn(this.getColumnDefinition(SudoListColumns.EMAIL))
    ];
    return columns;
  }

  get defaultSorts (): SortDescriptor {
    return [{
      dataField: SudoListSortFieldsMapping[SudoListColumns.ACCOUNT_ID],
      order: 'asc'
    }];
  }

  get state (): SudoModelState {
    return {
      loading: this.modelLoading,
      accountId: this.modelAccountId,
      isSubmitted: this.modelIsSubmitted,
      activeNav: this.modelActiveNav,
      searchText: this.modelSearchText,
      sortField: this.modelSortField,
      sortOrder: this.modelSortOrder,
      page: this.modelPage,
      size: this.modelSize,
      totalCount: this.modelTotalCount
    };
  }

  notify (loading: boolean) {
    this.modelLoading = loading;
    this.event.fireEvent(this);
  }
}
