import { AxiosInstance } from 'axios';
import client from './RestClient';
import { RecommendBidPrice } from 'core/rtbCampaign/RtbCampaign';

export interface PCHomeWebService {
  getRecommendBidPrice (keywords: string[]): Promise<RecommendBidPrice>;
}

export class RestfulPCHomeWebService implements PCHomeWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getRecommendBidPrice (keywords: string[]): Promise<RecommendBidPrice> {
    const response = await this.restClient.post('/pchome/suggestedKeywordsCpcBidRange', keywords);
    return response.data;
  }
}
