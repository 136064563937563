import React from 'react';
import styles from './productList.module.scss';
import i18n from 'i18n';
import _ from 'lodash';

const nameHeaderFormatter = (
  productsOfCurrentConditionLength: number,
  selectedProductsOfCurrentCondition: (number | string)[],
  hasSelectedProductsOfOtherCondition: boolean,
  onSelectAll,
  column: any,
  _1,
  { sortElement }
) => {
  const checked = productsOfCurrentConditionLength === selectedProductsOfCurrentCondition.length;
  return (
    <div className={styles.nameHeader}>
      <div className={styles.selectCheckBox}>
        <input
          type='checkbox'
          checked={checked}
          ref={element => element && (
            element.indeterminate = (hasSelectedProductsOfOtherCondition && selectedProductsOfCurrentCondition.length === 0) ||
              (!checked && selectedProductsOfCurrentCondition.length > 0)
          )}
          onChange={onSelectAll}
          id={'inputAll'}
        />
        <label htmlFor='inputAll' />
      </div>
      {i18n.t<string>(column.text)}
      {sortElement}
    </div>
  );
};

const nameFormatter = (productId: any, product, _2, formatExtraData: any): any => {
  const { selectedProducts, onSelect } = formatExtraData;

  return (
    <div className={styles.nameCell}>
      <div className={styles.selectCheckBox}>
        <input
          type='checkbox'
          checked={selectedProducts.indexOf(productId.toString()) > -1}
          onChange={_.partial(onSelect, productId.toString())}
          id={`input${productId}`}
        />
        <label htmlFor={`input${productId}`} />
      </div>
      <div className={styles.info}>
        <div className={styles.name}>
          {product.name}
        </div>
        <div className={styles.id}>
          {'ID: ' + productId}
        </div>
      </div>
    </div>
  );
};

const imageFormatter = (imageData: any) => {
  if (!imageData) {
    return <div />;
  }

  return (
    <div className={styles.imgCell}>
      <div className={styles.imgPreview}>
        <img src={imageData} alt={'product img detail'} />
      </div>
    </div>
  );
};

const formatters = {
  nameHeaderFormatter,
  nameFormatter,
  imageFormatter
};
export default formatters;
