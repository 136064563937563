import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import './Accounts.scss';

import { AccountsHome } from './AccountsHome';
import { AccountsProps, AccountsState } from './AccountsModel';
import { AccountDetail } from './AccountDetail';
import { AccountForm } from 'components/AccountForm';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';

const AccountHomeView = withErrorBoundary(ERROR_CODE.ACCOUNT_HOME, AccountsHome);
const CreateAccountView = withErrorBoundary(ERROR_CODE.ACCOUNT_CREATE, AccountForm);
const EditAccountView = withErrorBoundary(ERROR_CODE.ACCOUNT_EDIT, AccountForm);
const AccountDetailView = withErrorBoundary(ERROR_CODE.ACCOUNT_DETAIL, AccountDetail);

export class Accounts extends React.Component<AccountsProps, AccountsState> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.onUnmount(this.handler);
  }

  renderAccountHomeView = (props) => {
    const model = this.props.model;
    if (model.canViewAllAccounts) {
      return (<AccountHomeView model={model.getAccountHomeModel()} />);
    }
    return (
      <Redirect
        push
        to={{
          pathname: `${this.props.model.rootPath}/${ROUTE_PATH.ERROR403}`,
          state: { message: 'canViewAllAccounts test failed' }
        }}
      />
    );
  }

  renderCreateAccountView = () => {
    const model = this.props.model.getCreateAccountFormModel();
    if (this.props.model.canCreateAccount) {
      return (<CreateAccountView model={model} />);
    }
    return (
      <Redirect
        push
        to={{
          pathname: `${this.props.model.rootPath}/new/${ROUTE_PATH.ERROR403}`,
          state: { message: 'canCreateAccount test failed' }
        }}
      />
    );
  }

  renderViewAccountDetailView = (props) => {
    const model = this.props.model.getAccountDetailModel(props.match.params.accountId);
    if (this.props.model.canViewAccount && model) {
      const fromAgencyId = props.location.state && props.location.state.fromAgencyId;
      return (<AccountDetailView model={model} fromAgencyId={fromAgencyId}/>);
    }
    return (
      <Redirect
        push
        to={{
          pathname: `${props.match.url}/${ROUTE_PATH.ERROR403}`,
          state: { message: 'canViewAccount test failed' }
        }}
      />
    );
  }

  renderEditAccountView = (props) => {
    const model = this.props.model.getEditAccountFormModel(props.match.params.accountId);
    if (this.props.model.canEditAccount) {
      return (<EditAccountView model={model} />);
    }
    return (
      <Redirect
        push
        to={{
          pathname: `${props.match.url}/${ROUTE_PATH.ERROR403}`,
          state: { message: 'canEditAccount test failed' }
        }}
      />
    );
  }

  render () {
    return (
      <div className='accounts'>
        <Switch>
          <Route
            path={`${this.props.model.rootPath}/new`}
            render={this.renderCreateAccountView}
          />
          <Route
            exact
            path={`${this.props.model.rootPath}/:accountId`}
            render={this.renderViewAccountDetailView}
          />
          <Route
            exact
            path={`${this.props.model.rootPath}/:accountId/edit`}
            render={this.renderEditAccountView}
          />
          <Route
            render={this.renderAccountHomeView}
          />
        </Switch>
      </div>
    );
  }
}
