import React from 'react';
import i18n from 'i18next';
import styles from './imageCreativeSizeHint.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const ImageCreativeSizeHint: React.FunctionComponent<{
  sizeList?: { width: number, height: number }[],
  customStyles?: any,
  closeHint: () => void
}> = ({
  sizeList = [{ width: 960, height: 320 }, { width: 1000, height: 60 }],
  customStyles = styles,
  closeHint
}) => {

  const renderRecommendSize = () => {
    return sizeList.map((size) => (
      <div key={`${size.width} x ${size.height}`} className={customStyles[`size${size.width}x${size.height}`]}>
        {`${size.width} x ${size.height}`}
      </div>
    ));
  };

  return (
    <div className={styles.imageCreativeSizeHint}>
      <div className={styles.title}>
        <span>{i18n.t<string>('imageCreativeSizeHint.labels.sizeInstruction')}</span>
        <FontAwesomeIcon icon={faTimes} onClick={closeHint}/>
      </div>
      <div className={styles.recommendSize}>
        <div className={customStyles.recommendSizeContent}>
          {renderRecommendSize()}
        </div>
      </div>
    </div>
  );
};
