import { createSelectOptions } from 'utils/SelectOptionsUtils';
import i18n from 'i18n';

const generateAges = (range: number, ageMin: number): number[] => {
  return Array.from({ length: range }, (_, index) => index + ageMin);
};

const ages = generateAges(53, 13);

export const ageMinOptions = createSelectOptions(ages);
export const ageMaxOptions = [...ageMinOptions.filter(option => option.value !== 65), {
  label: '65+',
  value: 65
}];

export const genderOptions = [{
  label: i18n.t<string>('limitation.genders.unlimited'),
  value: -1
},{
  label: i18n.t<string>('limitation.genders.male'),
  value: 1
}, {
  label: i18n.t<string>('limitation.genders.female'),
  value: 2
}];

export const rtbCampaignAgeGroups = [
  { value: '1', label: '0~17' },
  { value: '2', label: '18~24' },
  { value: '3', label: '25~34' },
  { value: '4', label: '35~44' },
  { value: '5', label: '45~54' },
  { value: '6', label: '55~64' },
  { value: '7', label: '65+' }
];

const rtbGroupRangeMap = (function () {
  const results = {};
  for (let i = 0; i < ages.length; ++i) {
    if (ages[i] <= 17) {
      results[ages[i]] = '1';
    } else if (ages[i] <= 24) {
      results[ages[i]] = '2';
    } else if (ages[i] <= 34) {
      results[ages[i]] = '3';
    } else if (ages[i] <= 44) {
      results[ages[i]] = '4';
    } else if (ages[i] <= 54) {
      results[ages[i]] = '5';
    } else if (ages[i] <= 64) {
      results[ages[i]] = '6';
    } else {
      results[ages[i]] = '7';
    }
  }
  return results;
}());

export const getRtbAgeGroupsByAgeRange = (minAge, maxAge) => {
  const minGroupId = rtbGroupRangeMap[minAge];
  const maxGroupId = rtbGroupRangeMap[maxAge];
  return rtbCampaignAgeGroups.filter(ageGroup => ageGroup.value >= minGroupId && ageGroup.value <= maxGroupId);
};
