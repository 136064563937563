import React from 'react';
import styles from './creativeSummaryStep.module.scss';
import { CreativeSummaryStepProps, CreativeSummaryStepState } from './CreativeSummaryStepModel';
import i18n from 'i18next';
import { CreativeSetupFlowDataContext } from '../CreativeSetupFlowDataContext';
import SummaryDetail from 'components/SummaryDetail/SummaryDetail';
import { Button } from 'react-bootstrap';
import { LoadingIndicator } from 'components/LoadingIndicator';

export class CreativeSummaryStep extends React.Component<CreativeSummaryStepProps, CreativeSummaryStepState> {
  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.initSummaryData();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      this.props.model.setBasicSummaryData(prevProps.model.state.basicSummaryData);
      if (!this.props.model.state.basicSummaryData) {
        this.props.model.initSummaryData();
      }
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  renderSummaryDetails = (data) => {
    return Object.keys(data).map((key) =>
      <SummaryDetail
        key={key}
        title={data[key].title}
        data={data[key].content}
        prefixColor={data[key].titlePrefixColor}
      />
    );
  }

  renderSummaryTitleArea = (summaryData) => {
    return (
      <div className={styles.categoryTitle}>
        {summaryData.title}
        {summaryData.backStep !== undefined &&
          <div
            className={styles.backBtn}
            onClick={this.props.model.goStep.bind(undefined, summaryData.backStep, summaryData.backSubStep)}
          >
            {i18n.t<string>('campaignSummary.labels.backButtonTitle')}
          </div>
        }
      </div>
    );
  }

  renderMediaSummary = (summaryData) => {
    const MediaSummary = this.props.model.mediaSummaryComponent;
    if (!MediaSummary) {
      return <div />;
    }
    return (
      <div>
        {this.renderSummaryTitleArea(summaryData)}
        <MediaSummary mediaSummary={summaryData.data}/>
      </div>
    );
  }

  renderSummary = (summaryData) => {
    if (!summaryData) {
      return <div />;
    }
    return (
      <div>
        {this.renderSummaryTitleArea(summaryData)}
        <div>
          {this.renderSummaryDetails(summaryData.data)}
        </div>
      </div>
    );
  }

  render () {
    return (
      <CreativeSetupFlowDataContext.Consumer>
        {(creativeContext) => {
          const model = this.props.model;
          model.initContextData(creativeContext);
          if (!model.state.basicSummaryData) {
            return <LoadingIndicator />;
          }
          const advertiserSummaryData = model.getAdvertiserSummaryData(creativeContext.advertisers, creativeContext.creative.basic.advertiserId);
          return (
            <div className={styles.container}>
              {model.state.loading && <LoadingIndicator />}
              <div className={styles.title}>
                {i18n.t<string>('campaignSummary.titles.checkYourSettings')}
              </div>
              <div className={styles.content}>
                {model.canChooseAdvertiser && this.renderSummary(advertiserSummaryData)}
                {this.renderSummary(
                  model.canChooseAdvertiser ?
                    model.state.basicSummaryData :
                    {
                      ...advertiserSummaryData,
                      ...model.state.basicSummaryData
                    }
                  )
                }
                {model.mediaSummaryData && this.renderMediaSummary(model.mediaSummaryData)}
              </div>
              <div className={styles.buttonArea}>
                <Button variant='primary' size='sm' onClick={model.submit}>
                  {i18n.t<string>('common.buttons.submit')}
                </Button>
                <Button variant='secondary' size='sm' onClick={model.goLast}>
                  {i18n.t<string>('campaign.buttons.back')}
                </Button>
              </div>
            </div>
          );
        }}
      </CreativeSetupFlowDataContext.Consumer>
    );
  }
}
