import React from 'react';
import noDataSvg from 'assets/order/tableList-no-data.svg';
import styles from './tablePlaceholder.module.scss';

export const TablePlaceholder: React.FunctionComponent<{placeholder: string}> = ({ placeholder }) => {

  return (
    <div className={styles.noData}>
      <img src={noDataSvg} alt='noData' />
      <div>
        {placeholder}
      </div>
    </div>
  );
};
