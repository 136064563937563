import React, { useEffect } from 'react';
import i18n from 'i18n';
import { Trans } from 'react-i18next';

import { Button } from 'react-bootstrap';
import styles from './errorPage.module.scss';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { DefaultErrorManager } from 'core/ErrorManager';
import { useCoreContext } from 'contexts/coreContext';

const errorManager = new DefaultErrorManager();

export const ErrorPage: React.FC<any> = (props) => {

  const location = useLocation();
  const core = useCoreContext();
  const account = core ? core.authenticationManager.account : null;
  const email = account ? account.email : 'unknown';

  const message = _.get(location, 'state.message');
  useEffect(() => {
    if (message) {
      errorManager.post(message, props.errorCode.toString(), email);
    }
  }, [message, email, props.errorCode]);

  const getRedirectPath = () => {
    const currentPath = props.currentPath.replace(`/error${props.errorCode.toString()}`, '');
    const target = currentPath.slice(0, currentPath.lastIndexOf('/'));
    return _.isEmpty(target) ? '/' : target;
  };

  const onBackClicked = () => {
    props.onBackClicked(getRedirectPath());
  };

  return (
    <div className={styles.errorPage}>
      <img src={require(`assets/${props.errorCode}.png`)} srcSet={`${require(`assets/${props.errorCode}@2x.png`)} 2x`} alt='logo' />
      <div className={styles.label}>
        <Trans i18nKey={`errorPage.labels.${props.errorCode}`}>
          ...<br/>...<span>...</span>...
        </Trans>
      </div>
      <div className={styles.buttons}>
        <Button variant='primary' size='sm' onClick={onBackClicked}>
          {i18n.t<string>('errorPage.labels.back')}
        </Button>
      </div>
    </div>
  );
};
