import { defaultTo, get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import styles from './fileInputField.module.scss';
import classnames from 'classnames/bind';
import { ImageFormInput } from '../inputComponent/ImageFormInput';
import { FileFormInput } from '../inputComponent/FileFormInput';
import i18n from 'i18n';
import { useField, useFormikContext } from 'formik';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';
import { FormikFieldProps } from 'hoc/withFormikField';

const cx = classnames.bind(styles);

interface FileInputProps {
  name: string;
  value: any;
  className?: string;
  type: 'image' | 'other';
  icon?: any;
  validTypes?: string[];
  maxWidth?: number;
  maxHeight?: number;
  errorBorderHint: boolean;
  onChange?: (fileData: any) => void;
  fitContent?: boolean;
}

const FileInputContent = (({
  value,
  type,
  className,
  fitContent = true,
  ...props
}: FileInputProps) => {
  const file = get(value, 'file');
  const url = get(value, 'url');
  let FileInput;
  const inputClassName = cx(className, {
    fitContent: (file || url) && !props.icon && fitContent
  });
  if (type === 'image') {
    FileInput = ImageFormInput;
  } else {
    FileInput = FileFormInput;
  }
  return (
    <FileInput
      {...props}
      className={inputClassName}
      url={url}
      file={file}
    />
  );
});

const MemorizedFileInputContent = React.memo(FileInputContent);
const FileInputWithFieldLayout: React.FC<FileInputProps & FieldLayoutProps> = withFieldLayout<FileInputProps & FieldLayoutProps>(FileInputContent);

export interface FormikFileInputProps extends Omit<FileInputProps, 'value' | 'errorBorderHint'>, FormikFieldProps {}
export interface FormikFileInputWithFieldLayoutProps extends Omit<FileInputProps, 'value' | 'errorBorderHint'>, Omit<FieldLayoutProps, 'name' | 'hint'>, FormikFieldProps {
  hints: string[];
}
export type FormikFileInputFieldProps = ({ withFieldLayout?: true } & FormikFileInputWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikFileInputProps);

const FormikFileInput: React.FC<FormikFileInputProps> = ({
  name,
  onChange,
  validate,
  ...props
}) => {

  const { setFieldValue, setFieldTouched, submitCount } = useFormikContext<any>();

  const [{ value }, { touched: formikTouched, error }] = useField({ name, validate });
  const touched = defaultTo(submitCount, 0) > 0 || formikTouched;
  const hasError = error !== undefined && touched;

  const handleChange = useCallback((fileData) => {
    const newValue = {
      ...value,
      ...fileData
    };
    setFieldValue(name, newValue);
    setFieldTouched(name, true);
    // formik issues 2266 workaround
    setTimeout(() => {
      onChange && onChange(newValue);
    });
  }, [name, value, setFieldValue, setFieldTouched, onChange]);

  return (
    <MemorizedFileInputContent
      {...props}
      name={name}
      value={value}
      onChange={handleChange}
      errorBorderHint={hasError}
    />
  );
};

const FormikFileInputWithFieldLayout: React.FC<FormikFileInputWithFieldLayoutProps> = ({
  name,
  onChange,
  validate,
  hints,
  ...props
}) => {

  const { setFieldValue, setFieldTouched, submitCount } = useFormikContext<any>();

  const [{ value }, { error, touched: formikTouched }] = useField({ name, validate });
  const touched = defaultTo(submitCount, 0) > 0 || formikTouched;

  const hint = useMemo(() => {
    const file = get(value, 'file');
    const url = get(value, 'url');
    let hintElements = hints ? hints.map((hint, index) => {
      if (index === hints.length - 1) {
        return hint;
      }
      return <span key={index}>{hint}<br/></span>;
    }) : '';
    let hint: string | React.JSX.Element = <>{hintElements}</>;
    if (file || url) {
      hint = i18n.t<string>('creativeSetupFlow.labels.successHint');
    }
    return hint;
  }, [hints, value]);

  const hasError = error !== undefined && touched;

  const handleChange = useCallback((fileData) => {
    const newValue = {
      ...value,
      ...fileData
    };
    setFieldValue(name, newValue);
    setFieldTouched(name, true);
    // formik issues 2266 workaround
    setTimeout(() => {
      onChange && onChange(newValue);
    });
  }, [name, value, setFieldValue, setFieldTouched, onChange]);

  return (
    <FileInputWithFieldLayout
      {...props}
      name={name}
      value={value}
      onChange={handleChange}
      error={hasError ? error : undefined}
      hint={hasError ? undefined : hint}
      errorBorderHint={hasError}
      fieldContentWidth='auto'
    />
  );
};

export const FormikFileInputField: React.FC<FormikFileInputFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikFileInput {...props} />;
  } else {
    const hints = get(props, 'hints', []);
    return <FormikFileInputWithFieldLayout {...props} hints={hints}/>;
  }
};
