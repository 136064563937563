import React from 'react';
// import _ from 'lodash';
// import 'swagger-ui-react/swagger-ui.css';
// import styles from './swagger.module.scss';
// import { LoadingIndicator } from 'components/LoadingIndicator';
// const SwaggerUI = lazy(() =>
//   import('swagger-ui-react')
// );
const Swagger = (props: any) => {
  // const token = _.get(props, 'authenticationManager.currentActor.accessToken.token');
  // const requestInterceptor = (req) => {
  //   _.set(req,'headers.x-auth-token', token);
  //   return req;
  // };

  // const apiUrl = process.env.REACT_APP_WS_END_POINT;

  // return (
  //   <Suspense fallback={<LoadingIndicator/>}>
  //     <div className={styles.swaggerContainer}>
  //       <SwaggerUI url={`${apiUrl}/v2/api-docs`} requestInterceptor={requestInterceptor} />
  //     </div>
  //   </Suspense>
  // );
  return <div/>;
};

export default Swagger;
