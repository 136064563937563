import { CompanyMember, AgencyManager, Account, RoleManager, DefaultRoleManager, Actor } from 'core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ActorPermissionFormModel, editAgencyPermissionForm, addAgencyPermissionForm } from 'components/ActorPermissionForm/ActorPermissionFormModel';
import { AccountForm, CreateAgencyAccountForm } from 'components/AccountForm';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import _ from 'lodash';

export type AgencyMemberTabState = {
  readonly loading: boolean;
  readonly members: Array<CompanyMember> | null;
  readonly modalData: any;
  readonly searchString: string;
};

export interface AgencyMemberTabModel {

  readonly roleActions: any[];
  readonly permissionFormModel: ActorPermissionFormModel | null;
  readonly state: AgencyMemberTabState;
  readonly event: UpdateEventListener<AgencyMemberTabModel>;
  readonly agencyId: number;
  readonly operator: Actor | null;
  readonly filterMembers: CompanyMember[];

  addRole (): void;
  addAccount (): void;
  hideModal (): void;
  fetch (): Promise<void>;
  handleOnSearch (searchString: string): void;
}

export type AgencyMemberTabProps = {

  readonly model: AgencyMemberTabModel;
};
export class DefaultAgencyMemberTabModel implements AgencyMemberTabModel {
  agencyManager: AgencyManager;
  event: FireableUpdateEventListener<AgencyMemberTabModel>;

  loading: boolean;
  members: Array<CompanyMember> | null;
  agencyId: number;
  operator: Actor | null;
  roleActions: any[];
  permissionFormModel: ActorPermissionFormModel | null;
  modalData: any;
  searchString: string = '';
  filterMembers: CompanyMember[] = [];

  constructor (
    agencyId: number,
    agencyManager: AgencyManager,
    operator: Actor | null,
    private currentAccountId: number,
    private roleManager: RoleManager = new DefaultRoleManager()
  ) {
    this.members = null;
    this.loading = false;
    this.agencyId = agencyId;
    this.operator = operator;
    this.agencyManager = agencyManager;
    this.event = new FireableUpdateEventListener<AgencyMemberTabModel>();
    this.permissionFormModel = null;
    this.roleActions = [{
      type: 'edit-account',
      icon: faPencilAlt,
      tooltip: 'accountActorList.labels.actionHint.edit',
      show: () => true,
      handler: (account: Account, roleName: string) => {
        this.permissionFormModel = editAgencyPermissionForm(agencyId, this.operator, account, roleName, this.callback);
        this.event.fireEvent(this);
      }
    }, {
      type: 'delete-account',
      icon: faTrashAlt,
      tooltip: 'accountActorList.labels.actionHint.delete',
      show: (account) => {
        const isRoot = _.get(operator, 'roleName') === 'ADMIN';
        return isRoot || account.id !== this.currentAccountId;
      },
      handler: (account: Account) => {
        this.showDeleteRoleModal(account.id);
      }
    }];
  }

  get state (): AgencyMemberTabState {
    return {
      loading: this.loading,
      members: this.filterMembers,
      modalData: this.modalData,
      searchString: this.searchString
    };
  }

  async fetch (): Promise<void> {
    this.notify(true);
    try {
      this.members = await this.agencyManager.getMembers(this.agencyId);
      this.updateViewData();
      this.notify(false);
    } catch (error) {
      this.notify(false);
    }
  }

  postAddAccount = async () => {
    toast.success(i18n.t<string>('common.messages.succeeded'));
    await this.fetch();
    this.hideModal();
  }

  addRole = () => {
    this.permissionFormModel = addAgencyPermissionForm(this.agencyId, this.operator, this.callback);
    this.event.fireEvent(this);
  }

  addAccount = () => {
    const createModel = new CreateAgencyAccountForm(this.agencyId, this.operator);
    createModel.modelFormikEvent.add(event => {
      if (event.isSubmit === 'POST_SUBMIT') {
        this.postAddAccount();
      }
    });
    this.modalData = {
      title: i18n.t<string>('accounts.form.titles.new'),
      fullScreen: true,
      component: AccountForm,
      confirmBtnData: {
        callback: createModel.submitAndValide.bind(createModel),
        title: i18n.t<string>('common.buttons.submit')
      },
      componentProps: {
        model: createModel
      }
    };
    this.event.fireEvent(this);
  }

  showDeleteRoleModal = (accountId) => {
    this.modalData = {
      title: i18n.t<string>('accountActorList.labels.actionHint.delete'),
      fullScreen: false,
      message: i18n.t<string>('accountActorList.labels.deletePermissionContent'),
      confirmBtnData: {
        callback: () => this.deleteRole(accountId),
        title: i18n.t<string>('common.buttons.delete')
      }
    };
    this.event.fireEvent(this);
  }

  deleteRole = async (accountId) => {
    this.notify(true);
    try {
      await this.roleManager.deleteRole('agencies', this.agencyId, accountId);
    } catch (e) {}
    toast.success(i18n.t<string>('common.messages.succeeded'));
    await this.fetch();
    this.hideModal();
  }

  hideModal = () => {
    this.modalData = undefined;
    this.event.fireEvent(this);
  }

  callback = (cancelled: boolean, error: Error | null = null, showLoading: boolean = false) => {
    if (showLoading) {
      this.permissionFormModel = null;
      this.notify(true);
      return;
    }
    this.permissionFormModel = null;
    if (!cancelled && !error) {
      toast.success(i18n.t<string>('common.messages.succeeded'));
    }
    this.notify(false);
    if (!cancelled && error === null) {
      this.fetch();
    }
  }

  updateViewData () {
    if (!this.members) {
      this.filterMembers = [];
      return;
    }
    this.filterMembers = this.members.filter(member => this.searchString === '' || member.account.name.toLowerCase().includes(this.searchString.toLowerCase()));
  }

  handleOnSearch = (searchString: string) => {
    this.searchString = searchString;
    this.updateViewData();
    this.notify(false);
  }

  notify (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
