import { CreativeListRecord, CreativeType } from 'core/creative/Creative';
import { ImageCreativeDetail } from './ImageCreativeDetail';
import { ProductNativeCreativeDetail } from './ProductNativeCreativeDetail';
import { RichProductNativeCreativeDetail } from './RichProductNativeCreativeDetail';

const detailComponentSettings = {
  [CreativeType.IMAGE]: ImageCreativeDetail,
  [CreativeType.PRODUCT_NATIVE]: ProductNativeCreativeDetail,
  [CreativeType.RICH_PRODUCT_NATIVE]: RichProductNativeCreativeDetail
};

export interface CreativeDetailModel {
  creative: CreativeListRecord;
  detailComponent: any;
}

export type CreativeDetailProps = {
  model: CreativeDetailModel
};

export class DefaultCreativeDetailModel implements CreativeDetailModel {

  creative: CreativeListRecord;
  detailComponent: any;

  constructor (creative: CreativeListRecord) {
    this.creative = creative;
    this.detailComponent = detailComponentSettings[this.creative.creativeType];
  }
}
