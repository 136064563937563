import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { BindingCreateRequests, BindingWebService, RestfulBindingWebService } from 'ws/BindingWebService';

export interface BindingManager {
  createBinding (channel: L1ObjectChannel, createRequest: BindingCreateRequests): Promise<void>;
  updateBinding (channel: L1ObjectChannel, updateRequest: BindingCreateRequests): Promise<void>;
}

export class DefaultBindingManager implements BindingManager {
  webService: BindingWebService;

  constructor (
    webService: BindingWebService = new RestfulBindingWebService()
  ) {
    this.webService = webService;
  }

  async createBinding (channel: L1ObjectChannel, createRequest: BindingCreateRequests): Promise<void> {
    return this.webService.createBinding(channel, createRequest);
  }

  async updateBinding (channel: L1ObjectChannel, updateRequest: BindingCreateRequests): Promise<void> {
    return this.webService.updateBinding(channel, updateRequest);
  }
}
