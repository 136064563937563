import {
  SupportWebService,
  RestfulSupportWebService
} from 'ws/SupportWebService';

export interface SupportManager {
  downloadSupportFile (filePath: string, fileName: string): Promise<void>;
}

export class DefaultSupportManager implements SupportManager {
  webService: SupportWebService;

  constructor (
    webService: SupportWebService = new RestfulSupportWebService()
  ) {
    this.webService = webService;
  }

  async downloadSupportFile (filePath: string, fileName: string): Promise<void> {
    await this.webService.downloadSupportFile(filePath, fileName);
  }
}
