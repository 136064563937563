import themeConfig from './themeConfig';

const cloudStorageConfig = process.env.REACT_APP_CLOUD_STORAGE_CONFIG ? JSON.parse(process.env.REACT_APP_CLOUD_STORAGE_CONFIG) : {};
const oauthConfig = process.env.REACT_APP_OAUTH_CONFIG ? JSON.parse(process.env.REACT_APP_OAUTH_CONFIG) : {};

const config = {
  ...themeConfig,
  env: process.env.REACT_APP_ENV,
  cloudStorageConfig,
  oauthConfig
};

export default config;
