import { CreativeBasicFormModel, DefaultCreativeBasicFormModel } from './SubSteps/CreativeBasicFormModel';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import { CreativeFormBasicData, FormContentModel } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/SubSteps/FormContent/FormContentModel';
import { CreativeType } from 'core/creative/Creative';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';

export interface CreativeSetupStepModel {
  readonly type: string;
  readonly state: CreativeSetupStepState;
  readonly event: UpdateEventListener<CreativeSetupStepModel>;
  goLast?: () => void;
  goNext: (callback) => void;
  getBasicFormModel (
    creative: CreativeFormBasicData,
    supportedCreativeType: CreativeType[],
    formContentModelGetter: (creativeType: CreativeType) => FormContentModel | undefined
  ): CreativeBasicFormModel;
  getEditedCreative (): any;
  validate: () => any;
}

export type CreativeSetupStepProps = {
  readonly model: CreativeSetupStepModel;
};

export type CreativeSetupStepState = {
  readonly currentCreativeType: CreativeType;
};
export class DefaultCreativeSetupStepModel implements CreativeSetupStepModel {

  stepChangeListener?: (stepIndex, targetSubStep?: string) => void;
  basicFormModel?: CreativeBasicFormModel;
  event: FireableUpdateEventListener<CreativeSetupStepModel>;

  constructor (
    private currentCreativeType: CreativeType,
    public type: string,
    public goLast: (() => void) | undefined,
    public goNext: () => void,
    registerValidateMethod: (validateMethod) => void,
    private creativeManager: CreativeManager = new DefaultCreativeManager()
  ) {
    this.event = new FireableUpdateEventListener<CreativeSetupStepModel>();
    registerValidateMethod(this.validate);
  }

  get state () {
    return {
      currentCreativeType: this.currentCreativeType
    };
  }

  onCreativeTypeChange = (creativeType) => {
    this.currentCreativeType = creativeType;
    this.updateState();
  }

  getBasicFormModel (
    creative: CreativeFormBasicData,
    supportedCreativeType: CreativeType[],
    formContentModelGetter: (creativeType: CreativeType) => FormContentModel
  ) {
    if (this.basicFormModel && creative.advertiserId === this.basicFormModel.initCreative.advertiserId) {
      return this.basicFormModel;
    }
    this.basicFormModel = new DefaultCreativeBasicFormModel(
      this.type,
      creative,
      this.creativeManager,
      supportedCreativeType,
      (creativeType) => {
        this.onCreativeTypeChange(creativeType);
      },
      formContentModelGetter
    );
    return this.basicFormModel;
  }

  validate = async () => {
    const errors = this.basicFormModel ? await this.basicFormModel.validate() : {};
    return Object.keys(errors);
  }

  getEditedCreative = () => {
    if (!this.basicFormModel) {
      return {};
    }

    return {
      basic: this.basicFormModel.getCreativeBasicValue()
    };
  }

  updateState () {
    this.event.fireEvent(this);
  }
}
