import { CampaignState, RtbCampaignPlanType, RtbCampaignBasic, RtbOptimize } from 'core/rtbCampaign/RtbCampaign';
import { validateEmpty, validateMinimum, validateMinMax } from 'utils/ValidateUtils';
import i18n from 'i18n';
import _ from 'lodash';
import { formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import { Order } from 'core/order/Order';
import { AddonFeatureManager, LocaleMeta } from 'core';
import moment from 'moment';
import { L1Object } from 'core/l1Object/L1Object';
import { renderOverBudgetWording } from '../../../L2Objects/FormHintRenderFunctions';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';

export interface RtbCampaignBasicFormValidator {
  validate (l1Object: L1Object | undefined, campaignBasic: Partial<RtbCampaignBasic>, order: Order, isDailySchedule: boolean, localeMeta?: LocaleMeta): any;
}

export class DefaultRtbCampaignBasicFormValidator implements RtbCampaignBasicFormValidator {

  getBidPriceRange: (optimize: RtbOptimize) => {
    max?: number,
    min: number,
    recommend?: {
      min: number,
      max: number
    }
  };
  addonFeatureManager: AddonFeatureManager;
  validateStrategies: any;
  constructor (
    protected action: string,
    protected defaultCampaign: any,
    getBidPriceRange: (optimize: RtbOptimize) => {
      max?: number,
      min: number,
      recommend?: {
        min: number,
        max: number
      }
    },
    addonFeatureManager: AddonFeatureManager,
    private priceModelGetter: () => string[],
    private optimizeGetter: (priceModel: RtbCampaignPlanType) => L2ObjectOptimizationGoal[]
  ) {
    this.getBidPriceRange = getBidPriceRange;
    this.addonFeatureManager = addonFeatureManager;
    this.validateStrategies = {
      [RtbCampaignPlanType.RS_CPC]: this.validateRSCampaign.bind(this),
      [RtbCampaignPlanType.RS_CPM]: this.validateRSCampaign.bind(this),
      [RtbCampaignPlanType.FCPC]: this.validateNormalCampaign.bind(this),
      [RtbCampaignPlanType.FCPM]: this.validateNormalCampaign.bind(this),
      [RtbCampaignPlanType.RS]: this.validateRSCampaign.bind(this)
    };
  }

  validate (l1Object: L1Object | undefined, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, localeMeta?: LocaleMeta) {
    const errors = this.validateStrategies[campaignBasic.priceModel](l1Object, campaignBasic, order, isDailySchedule, localeMeta);
    return _.omitBy(errors, _.isEmpty);
  }

  validateBasic (order: Order, l1Object: L1Object | undefined, campaignBasic: RtbCampaignBasic) {
    return {
      name: this.validateName(campaignBasic),
      dayRange: this.validateDateRange(order, campaignBasic),
      priceModel: this.validatePriceModel(campaignBasic),
      optimize: this.validateOptimize(campaignBasic)
    };
  }

  validatePriceModel (campaignBasic) {
    if (!this.priceModelGetter().includes(campaignBasic.priceModel)) {
      return i18n.t<string>('campaign.descriptions.priceModelNotAvailable');
    }
  }

  validateOptimize (campaignBasic) {
    if (!this.optimizeGetter(campaignBasic.priceModel).includes(campaignBasic.optimize)) {
      return i18n.t<string>('campaign.descriptions.optimizeNotAvailable');
    }
  }

  validateRSCampaign (l1Object: L1Object | undefined, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, localeMeta?: LocaleMeta) {
    return {
      ...this.validateBasic(order, l1Object, campaignBasic),
      budget: this.validateBudget(campaignBasic, order),
      bidPrice: this.validateBidPrice(campaignBasic, order.currency, localeMeta),
      dailyTargetBudget: isDailySchedule && this.validateDailyTargetBudget(campaignBasic)
    };
  }

  validateNormalCampaign (l1Object: L1Object | undefined, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, localeMeta?: LocaleMeta) {
    return {
      ...this.validateBasic(order, l1Object, campaignBasic),
      budget: this.validateBudget(campaignBasic, order),
      bidPrice: this.validateBidPrice(campaignBasic, order.currency, localeMeta),
      dailyTargetBudget: isDailySchedule && this.validateDailyTargetBudget(campaignBasic)
    };
  }

  validateName (campaignBasic) {
    const name = campaignBasic.name;
    let error = validateEmpty(name);
    if (error) {
      return error;
    }
    if (name.length > 255) {
      return i18n.t<string>('campaign.descriptions.lengthError');
    }
  }

  validateDailyTargetBudget (campaignBasic) {
    return validateMinMax(
      campaignBasic.dailyTargetBudget,
      Math.min(1, campaignBasic.budget),
      campaignBasic.budget
    );
  }

  validateDateRange (order, campaignBasic) {
    let start = new Date(campaignBasic.startDate);
    let end = new Date(campaignBasic.endDate);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 92) {
      return i18n.t<string>('campaign.descriptions.moreThanDays', { days: 92 });
    }
    const now = moment();
    if (moment(campaignBasic.startDate).isBefore(order.startDate)) {
      return i18n.t<string>('campaign.descriptions.startDayBeforeOrder');
    }
    if (moment(campaignBasic.endDate).isAfter(moment(order.endDate).endOf('day'))) {
      return i18n.t<string>('campaign.descriptions.endDayAfterOrder');
    }

    const defaultCampaignStartDate = this.defaultCampaign.basic.startDate;
    const shouldCheckStartDay = !campaignBasic.id || moment(defaultCampaignStartDate).isAfter(now);
    if (shouldCheckStartDay && moment(campaignBasic.startDate).add(1, 'hours').isBefore(now)) {
      return i18n.t<string>('campaign.descriptions.createBeforeNow');
    }

    const defaultCampaignEndDate = this.defaultCampaign.basic.endDate;
    const shouldCheckEndDay = campaignBasic.id && defaultCampaignEndDate !== campaignBasic.endDate;
    if (shouldCheckEndDay && moment(campaignBasic.endDate).isBefore(now)) {
      return i18n.t<string>('campaign.descriptions.endDayBeforeNow');
    }
  }

  validateBudget (campaignBasic, order: Order) {
    if (this.action === 'create') {
      return this.validateBudgetOfCreateAction(campaignBasic, order);
    }
    return this.validateBudgetOfEditAction(campaignBasic, order);
  }

  validateBudgetOfCreateAction (
    campaignBasic: RtbCampaignBasic,
    order: Order
  ) {
    const budget = campaignBasic.budget;
    const totalBudget = order.budgetBalance;
    const remainBudget = totalBudget - budget;
    if (remainBudget < 0) {
      return renderOverBudgetWording(order.currency, totalBudget);
    }

    return this.validateBudgetBasic(campaignBasic, order);
  }

  validateBudgetOfEditAction (
    campaignBasic: RtbCampaignBasic,
    order: Order
  ) {
    const budget = campaignBasic.budget;
    if (this.defaultCampaign.basic.budget === campaignBasic.budget) {
      return undefined;
    }
    const totalBudget = order.budgetBalance;
    const remainBudget = totalBudget - budget + this.defaultCampaign.basic.budget;
    if (remainBudget < 0) {
      return renderOverBudgetWording(order.currency, totalBudget);
    }
    return this.validateBudgetBasic(campaignBasic, order);
  }

  validateBudgetBasic (
    campaignBasic: RtbCampaignBasic,
    order: Order
  ) {
    const budget = campaignBasic.budget;
    const spents = _.get(campaignBasic, 'spents', 0);
    const expectedSpent = _.get(campaignBasic, 'expectedSpent', 0);
    const campaignBudgetMinimum = order.campaignConstraint.campaignBudgetMinimum;
    const minBudget = Math.max(spents, expectedSpent, campaignBudgetMinimum);

    if (campaignBasic.state === CampaignState.DEACTIVATE) {
      return validateMinimum(
        budget,
        getPriceValue(order.currency, spents),
        'campaign.descriptions.smallerThanBudgetMinimum',
        order.currency
      );
    }

    let error = validateEmpty(budget);
    if (error) {
      return error;
    }

    let hintI18n = 'campaign.descriptions.smallerThanBudgetMinimum';
    let i18nParams: any = { min: formatPriceWithCurrency(order.currency, minBudget) };
    switch (minBudget) {
      case spents:
        hintI18n = 'campaign.descriptions.smallerThanSpents';
        break;
      case expectedSpent:
        hintI18n = 'campaign.descriptions.smallerThanExpectSpents';
        break;
      case campaignBudgetMinimum:
        hintI18n = 'campaign.descriptions.smallerThanCampaignBudgetMinimum';
        break;
      default:
        break;
    }

    const minValue = getPriceValue(order.currency, minBudget);
    if (budget < minValue) {
      return i18n.t<string>(hintI18n, { ...i18nParams });
    }
  }

  validateBidPrice (campaignBasic, currency: string, localeMeta) {
    const bidPrice = campaignBasic.bidPrice;
    let error = validateEmpty(bidPrice);
    if (error) {
      return error;
    }
    const optimize = campaignBasic.optimize;
    let bidPriceRange = this.getBidPriceRange(optimize);
    const valueNumber = typeof bidPrice === 'string' ? parseFloat(bidPrice) : bidPrice;
    if (valueNumber < bidPriceRange.min) {
      const bidPriceHint = bidPriceRange.recommend ?
        i18n.t<string>('campaign.descriptions.rsCpcPriceMinimum', { min: currency + bidPriceRange.min, rmin: currency + bidPriceRange.recommend.min, rmax: currency + bidPriceRange.recommend.max }) :
        i18n.t<string>('campaign.descriptions.priceMinimum', { min: currency + bidPriceRange.min });
      return bidPriceHint;
    }
  }

  validateFrequency = (frequency) => {
    const minIntervalDays = 1;
    const maxIntervalDays = 7;
    const minMaxFrequency = 1;
    const maxMaxFrequency = 20;
    const maxFrequency = frequency.maxFrequency;
    const intervalDays = frequency.intervalDays;
    const emptyMaxFrequency = validateEmpty(maxFrequency) && i18n.t<string>('campaignForm.errors.emptyMaxFrequency');
    const emptyIntervalDays = validateEmpty(intervalDays) && i18n.t<string>('campaignForm.errors.emptyIntervalDays');
    const intervalDaysOutofRange = validateMinMax(intervalDays, minIntervalDays, maxIntervalDays) &&
      i18n.t<string>('campaignForm.errors.intervalDaysOutofRange', { min: minIntervalDays, max: maxIntervalDays });
    const maxFrequencyOutofRange = validateMinMax(maxFrequency, minMaxFrequency, maxMaxFrequency) &&
      i18n.t<string>('campaignForm.errors.maxFrequencyOutofRange', { min: minMaxFrequency, max: maxMaxFrequency });
    return _.omitBy({
      maxFrequency: emptyMaxFrequency || maxFrequencyOutofRange,
      intervalDays: emptyIntervalDays || intervalDaysOutofRange
    }, _.isUndefined);
  }
}
