import * as ValidateUtils from 'utils/ValidateUtils';
import { Account, RoleNames, RoleManager, DefaultRoleManager, Actor } from 'core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';

export type PermissionFormState = {

  readonly validationError?: string;
  readonly email: string | null;
};

export interface FormCallback {
  (cancelled: boolean, error: Error | null, showLoading: boolean): void;
}

export interface ActorPermissionFormModel {

  readonly email: string;
  readonly selected: string;
  readonly options: Array<string>;
  readonly emailEditable: boolean;
  readonly validationError?: string;
  readonly event: UpdateEventListener<ActorPermissionFormModel>;
  readonly state: PermissionFormState;
  manager: RoleManager;

  cancel (): void;
  submit (): Promise<void>;
  select (roleName: string): void;
  setEmail (email: string): void;
}

export type ActorPermissionFormProps = {
  readonly model: ActorPermissionFormModel;
};

abstract class AbstractPermissionForm implements ActorPermissionFormModel {
  emailEditable: boolean;
  selected: string;
  options: Array<string>;
  callback: FormCallback;
  manager: RoleManager;
  event: FireableUpdateEventListener<ActorPermissionFormModel>;
  validationError?: string;
  email: string;
  scopeId: number;

  constructor (scopeId: number, roleName: string, options: Array<string>, callback: FormCallback) {
    this.manager = new DefaultRoleManager();
    this.callback = callback;
    this.options = options;
    this.selected = roleName;
    this.event = new FireableUpdateEventListener<ActorPermissionFormModel>();
    this.email = '';
    this.emailEditable = false;
    this.scopeId = scopeId;
  }

  cancel () {
    this.callback(true, null, false);
  }

  select (roleName: string) {
    if (this.options.includes(roleName)) {
      this.selected = roleName;
      this.event.fireEvent(this);
    }
  }

  setEmail (email: string) {
    this.email = email;
    this.event.fireEvent(this);
  }

  abstract submit ();

  get state (): PermissionFormState {
    return {
      validationError: this.validationError,
      email: this.email
    };
  }
}

export class AddPermissionFormModel extends AbstractPermissionForm {
  selected: string;
  options: Array<string>;
  callback: FormCallback;

  constructor (private scope: string, scopeId: number, roleName: string, options: Array<string>, callback: FormCallback) {
    super(scopeId, roleName, options, callback);
    this.callback = callback;
    this.options = options;
    this.selected = roleName;
    this.emailEditable = true;
  }

  async submit () {
    this.validationError = ValidateUtils.validateEmail(this.email);
    if (this.validationError) {
      this.event.fireEvent(this);
    } else {
      try {
        this.callback(false, null, true);
        await this.manager.addRole(this.scope, this.scopeId, this.email, this.selected);
        this.callback(false, null, false);
      } catch (error) {
        this.callback(false, new Error('failed to add role'), false);
      }
    }
  }
}

export class EditPermissionFormModel extends AbstractPermissionForm {
  account: Account;
  selected: string;
  options: Array<string>;
  callback: FormCallback;

  constructor (private scope: string, scopeId: number, account: Account, roleName: string, options: Array<string>, callback: FormCallback) {
    super(scopeId, roleName, options, callback);
    this.account = account;
    this.callback = callback;
    this.options = options;
    this.selected = roleName;
    this.email = account.email;
    this.emailEditable = false;
  }

  async submit () {
    try {
      this.callback(false, null, true);
      await this.manager.changeRole(this.scope, this.scopeId, this.account.email, this.selected);
      this.callback(false, null, false);
    } catch (error) {
      this.callback(false, error as Error, false);
    }
  }
}

export function addAgencyPermissionForm (agencyId: number, operator: Actor | null, callback: FormCallback): ActorPermissionFormModel {
  const { agencyAdmin, agencyManager, agencySales, agencyReport } = RoleNames;
  const options = [agencyAdmin, agencyManager, agencySales, agencyReport];
  return new AddPermissionFormModel('agencies', agencyId, agencyAdmin, options, callback);
}

export function editAgencyPermissionForm (agencyId: number, operator: Actor | null, account: Account, roleName: string, callback: FormCallback): ActorPermissionFormModel {
  const { agencyAdmin, agencyManager, agencySales, agencyReport } = RoleNames;
  const options = [agencyAdmin, agencyManager, agencySales, agencyReport];
  return new EditPermissionFormModel('agencies', agencyId, account, roleName, options, callback);
}

export function addAdvertiserPermissionForm (advertiserId: number, callback: FormCallback): ActorPermissionFormModel {
  const { adsAdmin, adsSales, adsReport } = RoleNames;
  const options = [adsAdmin, adsSales, adsReport];
  return new AddPermissionFormModel('advertisers', advertiserId, adsAdmin, options, callback);
}

export function editAdvertiserPermissionForm (advertiserId: number, account: Account, roleName: string, callback: FormCallback): ActorPermissionFormModel {
  const { adsAdmin, adsSales, adsReport } = RoleNames;
  const options = [adsAdmin, adsSales, adsReport];
  return new EditPermissionFormModel('advertisers', advertiserId, account, roleName, options, callback);
}
