import React from 'react';
import { PermissionAware } from 'core';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import _ from 'lodash';

interface RouteWithPermissionProps extends RouteProps {
  permissionAware: PermissionAware;
  redirectPath: string;
}

const RouteWithPermissoin: React.FC<RouteWithPermissionProps> = (props) => {
  const routeProps = _.omit(props, ['permissionAware', 'redirectPath']);
  const redirectTarget = () => (
    <Redirect
      to={{
        pathname: props.redirectPath,
        state: {
          message: routeProps.path ?
            `route permission of path ${routeProps.path} not granted: ${JSON.stringify(props.permissionAware)}` :
            `route permission not granted and redirect to ${props.redirectPath}: ${JSON.stringify(props.permissionAware)}`
        }
      }}
    />
  );
  return (
    <PermissionChecker permissionAware={props.permissionAware} renderOtherwise={redirectTarget}>
      <Route {...routeProps} />
    </PermissionChecker>
  );
};

export default React.memo(RouteWithPermissoin);
